import { Divider, Grid, Typography } from "@material-ui/core";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CommonButton, InputLabel, TextArea, TextInput, DropdownItem } from "../../atoms";
import { WaybillFormProps } from "./index.types";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import { useFormik } from "formik";
import { useValidation } from "../../../utills/validation";
import moment from "moment";
import { useRequest } from "../../../data/api/wrapper";
import DiscountBillable from "../../../data/models/billable-discount.modal";

const AddnewWaybillForm: FC<WaybillFormProps> = ({ data, clearForm, handleSubmit, edit }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "users"]);

   const { companies, counters, cars, billableItems, status } = useDropdown();

   const { waybillSchema } = useValidation();

   const responsible = useRequest((params) => dropdownApi.getUsers(params));
   const maindriver = useRequest((params) => dropdownApi.getUsers(params));
   const seconddriver = useRequest((params) => dropdownApi.getUsers(params));

   const _getResponsible = (value: string, userType: number) => {
      if (value.length < 3) return;
      if (userType === 1) {
         responsible.execute(value);
         maindriver.execute(value);
      } else if (userType === 2) {
         maindriver.execute(value);
      } else {
         seconddriver.execute(value);
      }
   };

   const formik = useFormik({
      initialValues: {
         additionalDriverId: null,
         startDate: moment(data?.startDate || Date.now()).format("YYYY-MM-DD"),
         endDate: moment(data?.endDate || Date.now() + 86400000).format("YYYY-MM-DD"),
         startTime: moment(data?.startDate || Date.now()).format("HH:mm"),
         endTime: moment(data?.endDate || new Date().getTime()).format("HH:mm"),
         userId: data?.responsible?.id || 0,
         mainDriverId: data?.driver.id || 0,
         pickupOrganizationId: data?.pickupCounter.id || 1,
         returnOrganizationId: data?.returnCounter.id || 1,
         carId: data?.car.id || 0,
         notes: "",
         statusId: data?.status?.id || 1,
         organizationId: data?.organization?.id || null,
         options: data?.optionals || ([] as DiscountBillable[]),
      },
      enableReinitialize: true,
      validationSchema: waybillSchema,
      onSubmit: (values) => {
         handleSubmit(values);
      },
   });

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);
   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <div className={globalClasses.paddingContainer}>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:company")}:`} />

               <AutoComplete
                  options={companies}
                  label={""}
                  defaultValue={companies.find((el) => el.id === formik.values.organizationId)}
                  placeholder={t("translations:organization")}
                  renderOption={(item) => item?.officialName || ""}
                  getOptionLabel={(item) => item?.officialName || ""}
                  onChange={(event, item) => formik.setFieldValue("organizationId", item?.id)}
               />

               <Typography className={globalClasses.errorText}>{formik.errors.organizationId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:responsible")}:`} />

               <AutoComplete
                  options={responsible?.data || []}
                  label={""}
                  defaultValue={data?.responsible || responsible.data?.find((el) => el.id === formik.values.userId)}
                  loading={responsible.loading}
                  placeholder={t("translations:responsible")}
                  renderOption={(item) => item?.name || ""}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(event, item) => {
                     formik.setFieldValue("userId", item?.id);
                     formik.setFieldValue("mainDriverId", item?.id);
                  }}
                  onInputChange={(name) => _getResponsible(name, 1)}
               />

               <Typography className={globalClasses.errorText}>{formik.errors.userId}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:mainDriver")}:`} />

               <AutoComplete
                  options={maindriver.data || []}
                  label={""}
                  defaultValue={data?.driver || maindriver.data?.find((el) => el.id === formik.values.mainDriverId)}
                  loading={maindriver.loading}
                  placeholder={t("translations:mainDriver")}
                  renderOption={(item) => item?.name || ""}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(event, item) => formik.setFieldValue("mainDriverId", item?.id)}
                  onInputChange={(name) => _getResponsible(name, 2)}
               />

               <Typography className={globalClasses.errorText}>{formik.errors.mainDriverId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:secondDriver")}:`} />
               <AutoComplete
                  options={seconddriver.data || []}
                  label={""}
                  defaultValue={seconddriver?.data?.find((el) => el.id === formik.values.mainDriverId)}
                  loading={seconddriver.loading}
                  placeholder={t("translations:secondDriver")}
                  renderOption={(item) => item?.name || ""}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(event, item) => formik.setFieldValue("additionalDriverId", item?.id)}
                  onInputChange={(name) => _getResponsible(name, 3)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.additionalDriverId}</Typography>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:pickupIn")}:`} />
               <AutoComplete
                  options={counters}
                  label={""}
                  defaultValue={counters.find((el) => el.id === formik.values.pickupOrganizationId)}
                  placeholder={""}
                  renderOption={(item) => item?.officialName || ""}
                  getOptionLabel={(item) => item?.officialName || ""}
                  onChange={(event, item) => formik.setFieldValue("pickupOrganizationId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.pickupOrganizationId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:pickupDate")}:`} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.startDate}
                        id="startDate"
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        disabled={false}
                        onChange={formik.handleChange}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.startDate}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:hour")}`} />
                     <TextInput
                        title=""
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}
                        type="time"
                        id={"startTime"}
                        value={formik.values.startTime}
                        disabled={false}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:deliverIn")}:`} />
               <AutoComplete
                  options={counters}
                  label={""}
                  defaultValue={counters.find((el) => el.id === formik.values.returnOrganizationId)}
                  placeholder={""}
                  renderOption={(item) => item?.officialName || ""}
                  getOptionLabel={(item) => item?.officialName || ""}
                  onChange={(event, item) => formik.setFieldValue("returnOrganizationId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.returnOrganizationId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:deliveryDate")}:`} />
                     <TextInput
                        title=""
                        value={formik.values.endDate}
                        type="date"
                        id="endDate"
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        disabled={false}
                        onChange={formik.handleChange}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.endDate}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:hour")}`} />
                     <TextInput
                        title=""
                        type="time"
                        id="endTime"
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}
                        value={formik.values.endTime}
                        disabled={false}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:vehicl")}`} />
               <AutoComplete
                  options={cars}
                  label={""}
                  defaultValue={cars.find((el) => el.id === formik.values.carId)}
                  placeholder={""}
                  renderOption={(item) => <DropdownItem car={item} />}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(event, item) => formik.setFieldValue("carId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.carId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:optionals")}`} />
               <AutoComplete
                  options={billableItems}
                  label={""}
                  multiple
                  defaultValue={formik.values.options}
                  placeholder={""}
                  renderOption={(item) => `${item?.name} - ${item?.value} €`}
                  getOptionLabel={(item) => `${item?.name} - ${item?.value} €`}
                  onChange={(event, item) => formik.setFieldValue("options", item)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.options}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
               <InputLabel label={`${t("translations:observation")}`} />
               <TextArea
                  value={formik.values.notes}
                  onChange={(event) => {
                     formik.setFieldValue("notes", event.target.value);
                  }}
               />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:status")}`} />
               <AutoComplete
                  options={status}
                  label={""}
                  defaultValue={status.find((el) => el.id === formik.values.statusId)}
                  placeholder={""}
                  renderOption={(item) => item?.name || ""}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(event, item) => formik.setFieldValue("statusId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.statusId}</Typography>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         {edit && <CommonButton title={t("translations:save")} onClick={formik.handleSubmit} />}
      </div>
   );
};

export default AddnewWaybillForm;
