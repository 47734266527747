import { FC } from "react";
import { Box } from "@material-ui/core";
import { Phone } from "@material-ui/icons";
import moment from "moment";
import Reservation from "../../../data/models/reservation.model";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { useTranslation } from "react-i18next";
import { TableButton } from "../../atoms";
import { padId } from "../../../utills/masks";
import { COLORS } from "../../../data/constants/Colors";

interface DelayedProps {
   data: Reservation[] | undefined;
   onReservationClick: (id: number, type?: number) => void;
   onCarPlateClick: (id: number) => void;
}
const DelayedDeliveries: FC<DelayedProps> = ({ data, onReservationClick, onCarPlateClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["dashboardStrings", "translations"]);
   console.log(data);
   return (
      <Box className={globalClasses.container}>
         <DataTable data={data || []} search={false}>
            <TableColumn
               headerText={t("dashboardStrings:enrollment")}
               render={(item) => (
                  <>
                     {/*  */}
                     <span
                        className={`${globalClasses.greyBox} ${globalClasses.hoverPointer}`}
                        onClick={() => {
                           onCarPlateClick(item.car.id);
                        }}
                     >
                        {item.carLicensePlate}
                     </span>
                     <div className={globalClasses.tableSmallText}>{item.carName}</div>
                  </>
               )}
            />
            <TableColumn
               headerText={t("dashboardStrings:driver")}
               render={(item) => (
                  <>
                     <div>{item.driverName}</div>
                     <div className={globalClasses.tableSmallText}>
                        <Phone className={globalClasses.smallIcon} />
                        {item.driverContact}
                     </div>
                  </>
               )}
            />
            <TableColumn headerText={t("dashboardStrings:estimatDel")} render={(item) => moment(item.endDate).format("DD/MM/YYYY HH:mm")} />
            <TableColumn
               headerText={t("dashboardStrings:reservation")}
               render={(item) => (
                  <TableButton
                     color={item.reservationType.id == 1 ? COLORS.primary : COLORS.greyText}
                     title={padId(item.reservationId, 4)}
                     onClick={() => {
                        onReservationClick(item.reservationId, item.reservationType.id);
                     }}
                  />
               )}
            />
         </DataTable>
      </Box>
   );
};

export default DelayedDeliveries;
