import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import { encodeDate, removeComma } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import { CommissionAdjustmentModel } from "../../../models/commission-adjustment.model";
import { Commission } from "../../../models/commission.model";
import { DivergentCategory } from "../../../models/divergent-category.modal";
import Financial from "../../../models/financials.model";
import { Invoice, InvoiceReport } from "../../../models/invoice.model";
import MaintenanceReport from "../../../models/maintenance-report.model";
import Pagination from "../../../models/pagination.model";
import { TollReport } from "../../../models/toll-reports.model";
import UngeneratedInvoices from "../../../models/ungenerated-invoices.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getDivergentCategories(
   startDate: string,
   endDate: string,
   page: number,
   size: number,
   search: string
): Promise<ApiResponse<Pagination<DivergentCategory[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url:
            API_ROUTES.divergentCategories +
            `?startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}&search=${search}&page=${page}&size=${size}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<DivergentCategory[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getUngeneratedInvoices(
   page: number,
   size: number,
   counters: number[],
   search: string
): Promise<ApiResponse<Pagination<UngeneratedInvoices[]>>> {
   let query = "";
   if (counters.length > 0) {
      query = `${counters.map((el) => "&OrganizationIds=" + el)}`;
      query = query.replaceAll(",", "");
   }
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.ungeneratedInvoices + `?Page=${page}&Size=${size}&Search=${search}${query}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<UngeneratedInvoices[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

interface CommissionResponse {
   counterId: number;
   fantasyName: string;
   commissionableValue: number;
   idealCommission: number;
   actualCommission: number;
   grossAmount: number;
   discount: number;
   netAmount: number;
   amount: number;
   tollAmount: number;
   iva: number;
   discountPerc: number;
   data: Commission[];
}

export async function getCommissionReport(params: {
   pickups: { id: number; name: string }[];
   tariffs: number[];
   startDate: string;
   endDate: string;
}): Promise<ApiResponse<CommissionResponse[]>> {
   const pickupQuery = removeComma(`?${params.pickups.map((el: any) => `Pickups=${el.id}&`)}`);
   const tariffQuery = removeComma(`${params.tariffs.map((el: any) => `Tariffs=${el}&`)}`);
   const date = `StartDate=${encodeURIComponent(encodeDate(params.startDate, ""))}&EndDate=${encodeURIComponent(encodeDate(params.endDate, ""))}`;

   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.commission + pickupQuery + tariffQuery + date,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }

      const tempData: ApiResponse<Commission[]> = resp.data;

      const result = Array.from(params.pickups, (el) => {
         let temp = {
            counterId: el.id,
            fantasyName: el.name,
            commissionableValue: 0,
            idealCommission: 0,
            actualCommission: 0,
            grossAmount: 0,
            discount: 0,
            netAmount: 0,
            amount: 0,
            tollAmount: 0,
            iva: 0,
            discountPerc: 0,
            data: [] as Commission[],
         };
         tempData.data.forEach((item, index) => {
            if (item.groupId == el.id) {
               temp.data.push(item);
               temp.commissionableValue += item.netAmount - item.discount;
               temp.idealCommission += (item.commissionRateBase / 100) * (item.netAmount - (item.discount + item.itemsDiscount) - item.tollAmount);
               temp.actualCommission += (item.commissionRate / 100) * (item.netAmount - (item.discount + item.itemsDiscount) - item.tollAmount);
               //calculate Table footer missing items
               temp.grossAmount += item.netAmount;
               temp.discount += item.discount + item.itemsDiscount;
               temp.netAmount += item.netAmount - (item.discount + item.itemsDiscount);
               temp.amount += item.amount;
               temp.tollAmount += item.tollAmount;
               temp.iva += (item.netAmount - item.discount - item.itemsDiscount) * 0.23;
               temp.discountPerc += ((item.discount + item.itemsDiscount) * 100) / item.netAmount;
            }
         });
         return temp;
      });
      return { ...tempData, data: result };
   } catch (error) {
      throw error;
   }
}
export async function getCommissionAdjustmentReport(
   startDate: string,
   endDate: string,
   counterId: number
): Promise<ApiResponse<CommissionAdjustmentModel>> {
   const encodedStartDate = encodeURIComponent(new Date(startDate).toISOString());
   const encodedEndDate = encodeURIComponent(new Date(endDate).toISOString());

   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.commissionAdjustment + `?StartDate=${encodedStartDate}&EndDate=${encodedEndDate}&BalconyId=${counterId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<CommissionAdjustmentModel> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getTollReports(startDate: string, endDate: string, types: number[]): Promise<ApiResponse<TollReport[]>> {
   const startDateForApi = encodeURIComponent(startDate);
   const endDateForApi = encodeURIComponent(endDate);
   let typeQuery = `${types.map((el) => "&Types=" + el)}`.replaceAll(",", "");
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.tollReport + `?StartDate=${startDateForApi}&EndDate=${endDateForApi}${typeQuery}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<TollReport[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getMaintenanceReport(params?: {
   CarId?: number;
   StartDate?: string;
   EndDate?: string;
   Page?: number;
   Size?: number;
   Search?: string;
}): Promise<ApiResponse<MaintenanceReport>> {
   const startDate = params?.StartDate ? encodeURIComponent(new Date(params?.StartDate!!).toISOString()) : "";
   const endDate = params?.EndDate ? encodeURIComponent(new Date(params?.EndDate!!).toISOString()) : "";
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url:
            API_ROUTES.maintenanceReport +
            `?CarId=${params?.CarId}&StartDate=${startDate}&EndDate=${endDate}&Page=${params?.Page}&Size=${params?.Size}&Search=${params?.Search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<MaintenanceReport> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getInvoices(params: any, invoiceData?: any): Promise<ApiResponse<InvoiceReport>> {
   let paginationQuery = `?Page=${params.page}&Size=${params.size}&Search=${params.searchQuery}`;
   let query = "";
   if (invoiceData) {
      let dataToUpload = {
         ...invoiceData,
         startDate: invoiceData.startDate ? encodeDate(invoiceData.startDate, "") : "",
         endDate: invoiceData.endDate ? encodeDate(invoiceData.endDate, "") : "",
      };
      const pickUpQuery = `${dataToUpload.Pickups.map((el: string) => "Pickups=" + el)}`.replaceAll(",", "");
      query = `&TaxId=${dataToUpload.nifId}&Serial=${dataToUpload.series}&Year=${dataToUpload.year}&ReservationId=${dataToUpload.reservation}&StartDate=${dataToUpload.startDate}&EndDate=${dataToUpload.endDate}&${pickUpQuery}&Types=${dataToUpload.documentType}`;
   }
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.invoices + paginationQuery + query,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<InvoiceReport> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getPendingFinancials(payload: any): Promise<ApiResponse<Financial[]>> {
   let startDate = "";
   let endDate = "";
   let company = "";
   let indivisual = "";
   if (payload.startDate) {
      startDate = encodeURIComponent(encodeDate(payload.startDate, ""));
   }
   if (payload.endDate) {
      endDate = encodeURIComponent(encodeDate(payload.endDate, ""));
   }
   if (payload.company) {
      company = "2";
   }
   if (payload.indivisual) {
      indivisual = "1";
   }
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url:
            API_ROUTES.pendingFinancials +
            `?StartDate=${startDate}&EndDate=${endDate}&Name=${payload.name}&ClientTypes=${company}&ClientTypes=${indivisual}`,
      });
      if (resp.status !== 200) {
         throw new Error("NOT FOUND");
      }
      const data: ApiResponse<Financial[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
