import { FC } from "react";
import { COLORS } from "../../../data/constants/Colors";
import Status from "../../../data/models/status.model";
import { statusStyles } from "./index.styles";

interface StatusProps {
   status: Status | undefined;
}

const StatusLabel: FC<StatusProps> = ({ status }) => {
   const classes = statusStyles();

   return (
      <span className={classes.container} style={{ background: status?.color }}>
         {status?.name}
      </span>
   );
};

export default StatusLabel;
