import { Box, Divider, Grid, Typography, useTheme } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { FC, useEffect } from "react";
import { COLORS } from "../../../../data/constants/Colors";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { InputLabel, IconButton, TextInput, AutoComplete, CurrencyInput } from "../../../atoms";
import { EditDiscountFormProps } from "./index.types";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";

const EditDiscountsForm: FC<EditDiscountFormProps> = (props) => {
   const { onSubmit, clearForm } = props;
   const { t } = useTranslation(["translations", "gen"]);
   const { discountSchema } = useValidation();
   const globalClasses = globalStyles();
   const theme = useTheme();
   const { vehicleCategories } = useDropdown();

   const discountType = [
      { id: 1, name: t("gen:percentage") },
      { id: 2, name: t("gen:absolute") },
   ];

   const formik = useFormik({
      initialValues: {
         categoryId: 0,
         minAmount: 1,
         discount: "",
         isPercentage: false,
      },
      validationSchema: discountSchema,
      onSubmit: (values) => {
         onSubmit(values);
      },
   });

   const getType = () => {
      if (formik.values.isPercentage) {
         return discountType[0];
      } else {
         return discountType[1];
      }
   };

   const setType = (item: any) => {
      if (item.id == 1) {
         formik.setFieldValue("isPercentage", true);
      } else {
         formik.setFieldValue("isPercentage", false);
      }
   };

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);
   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <Box className={`${globalClasses.container}`}>
         <Typography className={globalClasses.titleText}>{t("translations:newDiscount")}</Typography>
         <Grid container spacing={3}>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("gen:from")} />
               <TextInput
                  title={""}
                  disabled={false}
                  type="number"
                  onChange={(e) => {
                     formik.setFieldValue("minAmount", e.target.value);
                  }}
                  value={formik.values.minAmount}
                  id="from"
                  error={formik.touched.minAmount && Boolean(formik.errors.minAmount)}
                  helperText={formik.touched.minAmount && formik.errors.minAmount}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("translations:discount")} />
               <CurrencyInput
                  title=""
                  id="discount"
                  type="text"
                  value={formik.values.discount}
                  clear={clearForm}
                  disabled={false}
                  error={formik.touched.discount && Boolean(formik.errors.discount)}
                  helperText={formik.touched.discount && formik.errors.discount}
                  onChange={(value) => formik.setFieldValue("discount", value)}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  options={discountType}
                  defaultValue={getType()}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => setType(item)}
               />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:categor")} />
               <AutoComplete
                  options={vehicleCategories}
                  groupBy={(item) => item.tariff.name}
                  defaultValue={vehicleCategories.find((el) => formik.values.categoryId === el.categoryId)}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => formik.setFieldValue("categoryId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.categoryId}</Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <br />
               <IconButton
                  icon={<AddCircle style={{ color: theme.palette.background.paper, fontSize: 18 }} />}
                  color={COLORS.primary}
                  onClick={formik.handleSubmit}
               />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
      </Box>
   );
};

export default EditDiscountsForm;
