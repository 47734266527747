import React, { FC, useState } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { Box, Grid, Typography } from "@material-ui/core";
import { CheckBox, CommonButton, InputLabel, TextInput } from "../../../atoms";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";

interface FinancialProps {
   onSubmit: (data: any) => void;
}

const FinancialForm: FC<FinancialProps> = ({ onSubmit }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["users", "translations"]);

   const formik = useFormik({
      initialValues: {
         name: "",
         startDate: "",
         endDate: "",
         company: false,
         indivisual: false,
      },
      onSubmit: (values) => {
         onSubmit(values);
      },
   });

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("users:name")} />
               <TextInput helperText="" disabled={false} id="name" title="" type="text" onChange={formik.handleChange} />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput helperText="" disabled={false} id="startDate" title="" type="date" onChange={formik.handleChange} />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput helperText="" disabled={false} id="endDate" title="" type="date" onChange={formik.handleChange} />
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={formik.handleSubmit} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} className={globalClasses.flexRow}>
               <CheckBox
                  title={t("translations:company")}
                  checked={formik.values.company}
                  setChecked={() => {
                     formik.setFieldValue("company", !formik.values.company);
                  }}
               />
               <CheckBox
                  title={t("translations:individual")}
                  checked={formik.values.indivisual}
                  setChecked={() => {
                     formik.setFieldValue("indivisual", !formik.values.indivisual);
                  }}
               />
            </Grid>
         </Grid>
         <Typography className={globalClasses.smallerText}>
            * {t("translations:toDisplayAll")}, {t("translations:checkOnlyThe")} <b>{t("translations:company")}</b> {t("translations:and")}{" "}
            <b>{t("translations:individual")}</b>
         </Typography>
      </Box>
   );
};

export default FinancialForm;
