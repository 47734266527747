import { Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../../data/constants/Colors";

const companyName = process.env.REACT_APP_COMPANY_NAME;

const CopyrightText: FC = () => {
   const { t } = useTranslation("translations");
   return (
      <Typography style={{ fontSize: 11, color: COLORS.greyText }} align="center">
         {` © ${process.env.REACT_APP_NAME} -`}
         {` ${t("allRightsReserved")}  © `} {new Date().getFullYear()}
         {"."}
      </Typography>
   );
};

export default CopyrightText;
