import React from "react";

export const currency = (e: React.ChangeEvent<HTMLInputElement>) => {
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   value = value.replace(/^0[0-9].*$/, "");
   value = value.replace(/(\d)(\d{2})$/, "$1,$2");
   value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
   e.target.value = value;
   return e;
};

export const currencyNumber = (e: number | string | undefined) => {
   let value = `${e}`;
   value = value.replace(/\D/g, "");
   value = value.replace(/(\d)(\d{2})$/, "$1, $2");
   value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
   return value;
};

export const putDecimalPoints = (value: string | number | undefined | null) => {
   if (typeof value === "number") {
      value = value.toFixed(2);
   }
   let val = `${value || 0}`;
   if (val.includes(".")) {
      val = val.replace(".", ",");
   } else {
      val = val.concat(",00");
   }
   val = val.replace(/(?=(\d{3})+(\D))\B/g, ".");
   return val;
};

export const normalizeCurrency = (e: string | number) => {
   if (typeof e === "string") {
      let value = e;
      value = value.replace(".", "");
      value = value.replace(",", "");
      value = value.replace(" ", "");
      return parseInt(value) / 100;
   }
   return e;
};

export const taxId = (e: React.ChangeEvent<HTMLInputElement>) => {
   e.target.maxLength = 9;
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   value = value.replace(/^(\d{8})(\d)/, "$1-$2");
   e.target.value = value;
   return e;
};
export const taxIdString = (e: string | number) => {
   let value = e.toString();
   value = value.replace(/\D/g, "");
   value = value.replace(/^(\d{8})(\d)/, "$1-$2");
   return value;
};

export const carRegisteration = (e: React.ChangeEvent<HTMLInputElement>) => {
   let value = e.target.value;
   e.target.maxLength = 8;
   value = value.replace(/[^a-z0-9]/gi, "");
   value = value.replace(/(.{2})(?=.)/g, "$1-");
   value = value.toUpperCase();
   e.target.value = value;
   return e;
};

export const postCode = (e: React.ChangeEvent<HTMLInputElement>) => {
   e.target.maxLength = 8;
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   value = value.replace(/^(\d{4})(\d)/, "$1-$2");
   e.target.value = value;
   return e;
};

export const postCodeString = (e: string | number) => {
   let value = e.toString();
   value = value.replace(/\D/g, "");
   value = value.replace(/^(\d{4})(\d)/, "$1-$2");
   return value;
};

export const phone = (e: React.ChangeEvent<HTMLInputElement>) => {
   e.target.maxLength = 10;
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   e.target.value = value;
   return e;
};

export const latitude = (e: React.ChangeEvent<HTMLInputElement>) => {
   e.target.maxLength = 10;
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   value = value.replace(/(\d{2})(\d)/, "$1,$2");
   e.target.value = value;
   return e;
};
export const longitude = (e: React.ChangeEvent<HTMLInputElement>) => {
   e.target.maxLength = 10;
   let value = e.target.value;
   value = value.replace(/\D/g, "");
   value = value.replace(/(\d{3})(\d)/, "$1,$2");
   e.target.value = value;
   return e;
};

export const padId = (value: string | number, shift: number) => {
   return "#" + String(value).padStart(shift, "0");
};

export const normalize = (value: string) => {
   let tempValue = value.replace("-", "");
   return tempValue;
};
export const replaceComma = (value: string | number) => {
   let tempValue = `${value}`.replace(",", ".");
   return parseFloat(tempValue);
};
