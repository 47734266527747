import { CreditCard } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import User from "../../../data/models/user.model";

interface UserDropdownProps {
   user: User;
}

const UserDropdownItem: FC<UserDropdownProps> = ({ user }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation("transaction");
   return (
      <div>
         <div>{user.name}</div>
         <div className={globalClasses.tableSmallText}>
            {user.taxId}{" "}
            {user.hasCard && (
               <span>
                  / {t("hasCard")} <CreditCard className={globalClasses.smallIcon} />
               </span>
            )}
         </div>
      </div>
   );
};

export default UserDropdownItem;
