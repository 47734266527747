import { Box, Divider, Typography } from "@material-ui/core";
import React, { MutableRefObject, useState } from "react";
import { useTranslation } from "react-i18next";
import { CheckBox, Loader, StatusLabel, Toast } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { ProcessToll, TransactionSearchForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as tollsApi from "../../../data/api/requests/tolls/index";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { formatDateTime } from "../../../utills/helper";
import { putDecimalPoints } from "../../../utills/masks";
import useAPIError from "../../../data/context/error";
import { AxiosError } from "axios";
import { useRef } from "react";

const Process = () => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "vehicles", "gen"]);

   const { addError } = useAPIError();

   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [error, setError] = useState("");
   const [data, setData] = useState({
      registration: "",
      deviceNo: "",
      startDate: "",
      endDate: "",
   });
   const [transactionId, setTransactionId] = useState([] as number[]);

   const clearForm = useRef() as MutableRefObject<() => void>;

   const legacyTolls = useRequest((params) => tollsApi.legacyTolls(params, pagination.page, pagination.size));

   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState({ message: "", error: false });
   const [toast, setToast] = useState(false);

   const onChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
      setData({ ...data, [key]: e.target.value });
   };

   const search = () => {
      legacyTolls.execute(data);
   };

   const onCheckClick = (cod: number) => {
      setError("");
      let temp: number[] = [...transactionId];
      if (temp.includes(cod)) {
         temp.splice(temp.indexOf(cod), 1);
      } else {
         temp.push(cod);
      }
      setTransactionId(temp);
   };

   const process = (values: any) => {
      if (transactionId.length == 0) {
         return setError(t("gen:selectOne"));
      }
      setLoading(true);
      tollsApi
         .processToll(values, transactionId)
         .then((res) => {
            setSuccess({ message: res.message, error: false });
            setToast(true);
            legacyTolls.execute(data);
            setTransactionId([]);
            clearForm.current();
         })
         .catch((err: AxiosError) => {
            addError({ title: err.response?.data.title, message: err.response?.data.message });
         })
         .finally(() => setLoading(false));
   };

   return (
      <div>
         <Loader loading={legacyTolls.loading || loading} />

         {toast && (
            <Toast
               show={toast}
               message={success}
               handleClose={() => {
                  setToast(false);
               }}
            />
         )}

         <TitleContainer title={`${t("translations:legacyContracts")} (${t("translations:gimpsoft")})`} />
         <ContainerTopBar title={`${t("translations:search")} ${t("translations:transactions")}`}>
            <Divider className={globalClasses.dividerMargin} />
            <TransactionSearchForm onChange={onChange} onClick={search} />
         </ContainerTopBar>
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            {legacyTolls?.data && (
               <DataTable
                  data={legacyTolls.data.data || []}
                  search={false}
                  pagination
                  onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               >
                  <TableColumn
                     headerText="#"
                     render={(item) => (
                        <CheckBox
                           checked={transactionId.includes(item.transactionId)}
                           setChecked={() => {
                              onCheckClick(item.transactionId);
                           }}
                           title=""
                        />
                     )}
                  />
                  <TableColumn headerText="Cod." render={(item) => item.transactionId} />
                  <TableColumn headerText={t("translations:device")} render={(item) => item.obenumber} />
                  <TableColumn headerText={t("translations:vehicle")} render={(item) => item.carPlate} />
                  <TableColumn headerText={t("translations:tolls")} render={(item) => item.outTollName} />
                  <TableColumn headerText={t("translations:date")} render={(item) => formatDateTime(item.outDate)} />
                  <TableColumn headerText={t("translations:value")} render={(item) => putDecimalPoints(item.value)} />
                  <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               </DataTable>
            )}
            <Typography className={globalClasses.errorText}>{error}</Typography>
            <ProcessToll submit={process} clearForm={clearForm} />
         </Box>
      </div>
   );
};

export default Process;
