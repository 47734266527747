import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const announcementStyles = makeStyles((theme: Theme) => ({
   announcementBox: {
      backgroundColor: theme.palette.background.paper,
      padding: 15,
      marginTop: 20,
   },
   listItem: {
      color: COLORS.darkText,
   },
}));
