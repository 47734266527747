import NumberFormat, { NumberFormatValues } from "react-number-format";
import { TextInput } from "../..";

interface MaskedInputProps {
   format?: string;
   value: number | string | undefined | null;
   onValueChange?: (values: NumberFormatValues) => void;
   suffix?: string;
   thousandSeparator?: string;
   decimalSeparator?: string;
}

const MaskedInput = (props: MaskedInputProps) => {
   const { format, value, onValueChange, suffix, thousandSeparator, decimalSeparator } = props;
   return (
      <NumberFormat
         customInput={TextInput}
         format={format}
         value={value}
         onValueChange={onValueChange}
         suffix={suffix}
         thousandSeparator={thousandSeparator}
         decimalSeparator={decimalSeparator}
         decimalScale={2}
         fixedDecimalScale={true}
      />
   );
};

export default MaskedInput;
