import { Avatar, List, Typography } from "@material-ui/core";
import { FC } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { logoutUser } from "../../../data/api/requests/auth";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { clearSessionStorage } from "../../../utills/utills";
import { userDataStyle } from "./index.styles";
import { UserDataProps } from "./index.types";

const UserBadge: FC<UserDataProps> = ({ user }) => {
   const classes = userDataStyle();
   const history = useHistory();
   const { t } = useTranslation("translations");

   const logout = async () => {
      logoutUser()
         .then(async (res) => {
            await clearSessionStorage();
            history.push("");
         })
         .catch((err) => {
            throw err;
         });
   };

   return (
      <List className={classes.userControl}>
         <Avatar src={user?.profileImage} className={classes.avatar} />
         <Typography className={classes.name}>{user?.name}</Typography>
         <Dropdown>
            <Dropdown.Toggle variant="light" id="dropdown-basic" className={classes.dropdown}>
               {t("general")}
            </Dropdown.Toggle>

            <Dropdown.Menu style={{ paddingLeft: 10, paddingRight: 10 }}>
               <Dropdown.Item className={classes.dropdownItem} onClick={() => {history.push(`/users/form/${user?.id}`)}}>
                  {t("mydata")}
               </Dropdown.Item>
               <Dropdown.Item
                  className={classes.dropdownItem}
                  onClick={() => {
                     history.push(ROUTE_LINKS.CHANGE_PASSWORD);
                  }}
               >
                  {t("changePassword")}
               </Dropdown.Item>
               <Dropdown.Item
                  className={classes.dropdownItem}
                  onClick={() => {
                     logout();
                  }}
               >
                  {t("logout")}
               </Dropdown.Item>
            </Dropdown.Menu>
         </Dropdown>
      </List>
   );
};

export default UserBadge;
