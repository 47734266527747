import { Divider, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { FC, MutableRefObject, useRef } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton, ImagePicker, InputLabel, TextInput } from "../../../atoms";
import { formStyles } from "./index.styles";
import * as yup from "yup";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import Banner from "../../../../data/models/banner.model";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";

interface BannerProps {
   banner: Banner | undefined;
   onSubmit: (banner: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const BannerForm: FC<BannerProps> = ({ banner, onSubmit, clearForm }) => {
   const globalClasses = globalStyles();
   const classes = formStyles();
   const { t } = useTranslation(["users", "translations", "vehicles"]);

   const imageRef = useRef() as MutableRefObject<HTMLInputElement>;

   const formik = useFormik({
      initialValues: {
         name: banner?.name || "",
         file: File,
         fileString: banner?.file || "",
      },
      validationSchema: yup.object({
         name: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         fileString: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      enableReinitialize: true,
      onSubmit: (values) => {
         onSubmit(values);
      },
   });

   useEffectAfterMount(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
      imageRef.current.value = "";
   };

   return (
      <>
         <Grid container spacing={4}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
               <InputLabel label={t("users:name")} />
               <TextInput
                  disabled={false}
                  title=""
                  type="text"
                  id="name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
               <Typography variant={"caption"}>{t("translations:icon")}</Typography>
               <div>
                  <ImagePicker
                     imageRef={imageRef}
                     onChange={(event) => {
                        formik.setFieldValue("file", event.target.files[0]);
                        formik.setFieldValue("fileString", URL.createObjectURL(event.target.files[0]));
                     }}
                  />
               </div>
               <Typography className={globalClasses.errorText}>{formik.errors.fileString}</Typography>
               <div className={classes.imageContainer}>
                  <img
                     src={formik.values.fileString ? formik.values.fileString : "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                     className={classes.image}
                  />
               </div>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:save")} onClick={formik.handleSubmit} />
      </>
   );
};

export default BannerForm;
