export const COLORS: {
   cardBackground: string;
   borders: string;
   greenLabel: string;
   darkText: string;
   lightText: string;
   orangeText: string;
   lightGrey: string;
   greyBorder: string;
   blue: string;
   redLabel: string;
   redLight: string;
   iconInactive: string;
   menuActive: string;
   primary: string;
   greyText: string;
} = {
   cardBackground: "#ffffff",
   borders: "#e7eaec",
   greenLabel: "#1ab394",
   darkText: "rgb(93,96,98)",
   lightText: "rgb(141,141,141)",
   orangeText: "#2F4053",
   lightGrey: "#f4f4f4",
   greyBorder: "#e5e6e7",
   blue: "#1c84c6",
   redLabel: "#ed5565",
   redLight: "#f9d1d5",
   iconInactive: "rgb(30, 30, 30)",
   menuActive: "rgb(226, 237, 236)",
   primary: "#FBAD19",
   greyText: "#808080",
};

export const VALUES: {
   padding_small: number;
   margin_small: number;
   padding_normal: number;
   padding_large: number;
   margin_normal: number;
   margin_large: number;
   grid_spacing: number;
   borderRadius: number;
} = {
   padding_small: 3,
   padding_normal: 15,
   padding_large: 20,
   margin_small: 10,
   margin_normal: 15,
   margin_large: 20,
   grid_spacing: 3,
   borderRadius: 8,
};
