import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";
const drawerWidth = 220;
export const navBarStyles = makeStyles((theme: Theme) =>
   createStyles({
      appBar: {
         height: 50,
         backgroundColor: theme.palette.background.paper,
         [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
         },
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
         alignItems: "center",
         paddingRight: theme.spacing(2),
      },
      toolBar: {
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
         alignItems: "center",
      },
      menuButton: {
         marginRight: theme.spacing(2),
         marginLeft: theme.spacing(1),
         [theme.breakpoints.up("sm")]: {
            display: "none",
         },
      },
   })
);
