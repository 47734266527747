import AppRouter from "./routes/AppRouter";

import "./i18n";
import { BrowserRouter as Router } from "react-router-dom";
import withSplashScreen from "./pages/splashscreen/withSplashScreen";

function Main() {
   return (
      <Router>
         <AppRouter />
      </Router>
   );
}

export default withSplashScreen(Main);
