export enum actions {
   PROOF = "proof",
   SERVICES = "services",
   TRANSACTIONS = "transactions",
   TOLLS = "tolls",
   VEHICLE_HISTORY = "vehicle-history",
   CRM = "crm",
   NEW_USER = "new-user",
   NEW_COMPANY = "new-company",
   EDIT_RESERVATION = "edit-reservation",
   CONTRACT = "contract",
   CANCEL = "cancel",
   CONFIRM = "confirm",
   APPROVE = "approve",
}

export enum reservation_actions {
   START = "start",
   FINISH = "finish",
}

export enum crm_action {
   LIST_ALL = "list-all",
   USER_DETAIL = "user-detail",
   RESERVATION_DETAIL = "reservation-detail",
}

export enum route_actions {
   USER = "user",
   COMPANY = "company",
   SERVICES = "services",
}

export enum invoice_action {
   BACK = "back",
   RESERVATION = "reservation",
   CANCEL_INVOICE = "cancel-invoice",
}

export enum modal_action {
   CONFIRM = "confirm",
   CANCEL = "cancel",
}

export enum invoice_create {
   receipt = "receipt",
   credit_notes = "credit-notes",
}

export enum invoice_modal_actions {
   cancel = "cancel",
   confirm = "confirm",
   refresh = "refresh",
}

export enum user_buttons_actions {
   reset_password = "reset_password",
   credit_cards = "credit_cards",
   crm = "crm",
}
