import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { CheckBox, InputLabel, CommonButton, TextInput, AutoComplete } from "../../../atoms";
import { useTranslation } from "react-i18next";
import { featureFormStyles } from "./index.styles";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { FC, MutableRefObject } from "react";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import CarFeature from "../../../../data/models/car-feature.model";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";

interface FeatureProps {
   feature: CarFeature | undefined;
   submit: (feature: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const FeatureForm: FC<FeatureProps> = ({ feature, submit, clearForm }) => {
   const classes = featureFormStyles();

   const globalClasses = globalStyles();

   const { featureTypes } = useDropdown();

   const { featureSchema } = useValidation();

   const { t } = useTranslation(["translations"]);

   const formik = useFormik({
      initialValues: {
         name: feature?.name || "",
         shortName: feature?.shortName || "",
         typeId: feature?.type ? feature.type : featureTypes[0],
         isBoolean: feature ? feature.isBoolean : true,
      },

      validationSchema: featureSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
         submit(values);
      },
   });

   // console.log(feature);
   // console.log(formik.values.typeId);
   // console.log(featureTypes);

   useEffectAfterMount(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <>
         <Grid container spacing={3}>
            <Grid item xl={10} lg={10} md={10} xs={12}>
               <InputLabel label={t("translations:characteristic")} />
               <TextInput
                  title=""
                  id="name"
                  type="text"
                  disabled={false}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={10} lg={10} md={10} xs={12}>
               <InputLabel label={t("translations:shortName")} />
               <TextInput
                  title=""
                  id="shortName"
                  type="text"
                  disabled={false}
                  value={formik.values.shortName}
                  error={formik.touched.shortName && Boolean(formik.errors.shortName)}
                  helperText={formik.touched.shortName && formik.errors.shortName}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={10} lg={10} md={10} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={featureTypes}
                  defaultValue={formik.values.typeId}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => formik.setFieldValue("typeId", item)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.typeId}</Typography>
            </Grid>
         </Grid>
         <Box className={classes.checkboxContainer}>
            <CheckBox
               title={t("translations:quantitative")}
               checked={!formik.values.isBoolean}
               setChecked={() => {
                  formik.setFieldValue("isBoolean", !formik.values.isBoolean);
               }}
            />
         </Box>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:create")} onClick={formik.handleSubmit} />
      </>
   );
};

export default FeatureForm;
