import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { AlertDailog, Loader, Toast } from "../../../../components/atoms";
import { TitleContainer } from "../../../../components/molecules";
import { AlertModal, NewVehicleCategoryForm } from "../../../../components/organisms";
import * as categoryApi from "../../../../data/api/requests/categories/index";
import { useRequest } from "../../../../data/api/wrapper";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";

const NewCategory = () => {
   const { t } = useTranslation("translations");

   const { state } = useLocation<number>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const history = useHistory();

   const getCategory = useRequest(() => categoryApi.getCategoryByID(state));
   const addCategory = useRequest((params) => categoryApi.saveCategory(params));
   const updateCategory = useRequest((params) => categoryApi.updateCategory(params, state));

   useEffect(() => {
      if (state) {
         getCategory.execute();
      }
   }, []);

   const _Submit = (data: ReturnType<any>) => {
      if (state) {
         updateCategory.execute(data);
      } else {
         addCategory.execute(data);
      }
   };

   return (
      <div>
         <Loader loading={addCategory.loading || updateCategory.loading} />
         {updateCategory.show.toast && (
            <Toast
               message={updateCategory.message}
               show={updateCategory.show.toast}
               handleClose={() => {
                  updateCategory.close();
               }}
            />
         )}
         {addCategory.show.modal && (
            <AlertDailog
               show={addCategory.show.modal}
               title={addCategory.message.message}
               message={addCategory.message.description}
               primaryButtonText={t("backButton")}
               primaryButtonHandle={() => {
                  history.push(ROUTE_LINKS.VEHICLE_CATEGORIES);
               }}
               secondaryButtonText={t("addNew")}
               secondaryButtonHandle={() => {
                  addCategory.close();
                  clearForm.current();
               }}
            />
         )}
         <TitleContainer title={t("new") + " " + t("categor")} />

         <NewVehicleCategoryForm onSubmit={_Submit} data={getCategory.data} clearForm={clearForm} />
      </div>
   );
};

export default NewCategory;
