import React, { FC } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Menu, { MenuProps } from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ArrowDropDown, Home } from "@material-ui/icons";
import Organization from "../../../../data/models/organization.model";
import { COLORS } from "../../../../data/constants/Colors";
import { CounterDropdownStyles } from "./index.styles";
import { Box } from "@material-ui/core";

interface DropdownProps {
   options: Organization[];
   counter: number;
   onSelect: (organizationId: number) => void;
}

const StyledMenu = withStyles({
   paper: {
      border: `1px solid ${COLORS.borders}`,
   },
})((props: MenuProps) => (
   <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
         vertical: "bottom",
         horizontal: "center",
      }}
      transformOrigin={{
         vertical: "top",
         horizontal: "center",
      }}
      {...props}
   />
));

const StyledMenuItem = withStyles((theme) => ({
   root: {
      width: 250,
      color: COLORS.greyText,
      fontSize: 15,
      "&:focus": {
         backgroundColor: theme.palette.background.paper,
      },
   },
}))(MenuItem);

const CounterDropdown: FC<DropdownProps> = ({ options, counter, onSelect }) => {
   const classes = CounterDropdownStyles();

   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      if (options.length > 1) {
         setAnchorEl(event.currentTarget);
      } else {
         setAnchorEl(null);
      }
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const getCounterName = () => {
      const tempCounter = options.find((el) => el.organizationId == counter);
      return tempCounter?.fantasyName;
   };
   const handleSelect = (el: Organization) => {
      setAnchorEl(null);
      onSelect(el.organizationId!!);
   };

   return (
      <div>
         <Box className={classes.button} onClick={handleClick}>
            <span className={classes.iconText}>
               <Home className={classes.icon} />
               <span className={classes.text}>{getCounterName()}</span>
            </span>
            <ArrowDropDown className={classes.icon} />
         </Box>
         <StyledMenu id="customized-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {options.map(
               (el, index) =>
                  el.organizationId != counter && (
                     <StyledMenuItem key={`counter-${index}`} onClick={() => handleSelect(el)}>
                        <ListItemText primary={el.fantasyName} />
                     </StyledMenuItem>
                  )
            )}
         </StyledMenu>
      </div>
   );
};

export default CounterDropdown;
