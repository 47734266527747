import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { LocationOn } from "@material-ui/icons";
interface LabelProps {
  title: string;
  value: string;
}
const LocationLabel: FC<LabelProps> = ({ title, value }) => {
  const globalClasses = globalStyles();
  return (
    <Box className={`${globalClasses.flexRow} ${globalClasses.marginSmall}`}>
      <LocationOn className={globalClasses.tableIcon} />
      <Typography className={globalClasses.tableText}>{title}:</Typography>
      <Typography
        className={`${globalClasses.tableText} ${globalClasses.marginLeft}`}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default LocationLabel;
