export const API_ROUTES = {
   baseUrl: process.env.REACT_APP_BASE_URL,
   changeStatus: "change-status",
   //AUTH
   login: "/auth/Login",
   logout: "/auth/logout",
   changePassword: "/auth/change-password",
   refreshToken: "/auth/refresh-token",
   resetPassword: "/auth/ResetPassword",
   //Admins
   changeAdminStatus: "/administrators/{0}/status",
   getAdmins: "/administrators",
   //DASHBOARD ROUTES
   getReservationSummary: "/reservations/summary",
   getUngeneratedInvoices: "/invoices/ungenerated",
   getDelayedDeliveries: "/reservations/delayed-deliveries",
   getParkedCars: "/cars/parked",
   getPendingReservations: "/reservations/pending",
   //Reservations
   reservations: "/reservations",
   reservationById: "/reservations/{0}",
   reservationItems: "/reservations/{0}/reservation-items",
   deleteReservationItem: "/reservations/{0}/reservation-items/{1}",
   reservationCustomers: "/reservations/{0}/customers",
   addPendingItems: "/reservations/{0}/add-pending-items",
   tickets: "/tickets",
   changeTicketStatus: "/tickets/{0}/status",
   addTicketMessage: "/tickets/{0}/add-message",
   reservationTransactions: "/reservations/{0}/transactions",
   reservationConflicts: "/reservations/{0}/conflicts",
   reservationProof: "/reservations/{0}/proof",
   cancelReservation: "/reservations/{0}/cancel",
   reservationContract: "/reservations/{0}/contract",
   approveReservation: "/reservations/{0}/approve",
   startReservation: "/reservations/{0}/start",
   transferTariff: "/reservations/transfer-tariff",
   saveDossiar: "/reservations/{0}/save-dossier",
   sendContract: "/reservations/{0}/send-contract-by-email",
   finishReservation: "/reservations/{0}/finish",
   carHistory: "/reservations/{0}/cars-history",
   reservationTolls: "/tolls/by-reservation/{0}",
   //Waybills
   waybills: "/way-bills",
   updateWaybill: "/way-bills/{0}",
   //Users
   users: "/users",
   updateUsers: "/users/{0}",
   promoteAdm: "/administrators",
   sendMessage: "/users/{0}/send-message",
   resetUserPassword: "/users/{0}/reset-password",
   getUserByTaxId: "/users/by-fiscal-number",
   changeUserStatus: "/users/{0}/status",
   pendingFields: "/users/{0}/pending-fields",
   pendingDocuments: "/users/{0}/pending-documents",
   userPendingFinancials: "/users/{0}/pending-financials",
   userCards: "/cards?clientId={0}&type=1",
   deleteCard: "/cards/{0}",
   cards: "/cards",
   //Banners unique URL for CRUD
   banners: "/banners",

   //Brands unique URL for CRUD
   brands: "/brands",

   //CarFines unique URL for CRUD
   carFines: "/fines",

   //Car unique URL for CRUD
   cars: "/cars",
   updateCars: "/cars/{0}",
   recentReservations: "/cars/{0}/recent-reservations",
   freePeriods: "/cars/{0}/available-days",
   carReservavtionByDate: "/cars/{0}/search-reservation-by-date",
   changeCarStatus: "/cars/{0}/status",
   //Features
   features: "features",
   changeFeatureStatus: "/features/{0}/status",
   //Category unique URL for CRUD
   categories: "/categories",
   updateCategory: "/categories/{0}",
   changeCategoryStatus: "/categories/{0}/status",

   //Organization
   organizations: "/organizations",
   changeOrgStatus: "/organizations/{0}/status",
   //counters
   counters: "/counters",
   changeCounterStatus: "/counters/{0}/status",
   //Tariffs
   tariffs: "/tariffs",
   changeTariffsStatus: "/tariffs/{0}/status",
   //Maintenance
   maintenance: "/maintenances",
   maintenanceById: "/maintenances/{0}",
   getMaintenanceInfo: "/maintenances/receive/{0}",
   //Discounts
   discountsBillable: "/billable-items",
   discount: "/discounts",
   discountCampaign: "/campaigns",
   changeCampaignStatus: "/campaigns/{0}/status",
   deleteDiscount: "/billable-items/{0}/quantity-discounts/{1}",
   billableDiscounts: "/billable-items/{0}/quantity-discounts",
   stop: "/stop",
   start: "/start",
   requestDiscount: "/discount-request",
   getReservationDiscounts: "/discount-request/list/by-reservation/{0}",
   //Tolls
   unbilledTolls: "/tolls/pending",
   legacyTolls: "/tolls/migration",
   orphanTolls: "/tolls/with-no-reservation",
   registerPayment: "/tolls/register-payment",
   linkTo: "/tolls/link-to",
   processTolls: "/tolls/change-status-live",
   changeTollStatus: "/tolls/change-status-locally",
   //Reports
   divergentCategories: "/reports/divergent-categories",
   ungeneratedInvoices: "/reports/ungenerated-invoices",
   commission: "reports/commission",
   commissionAdjustment: "/reports/adjustment-commission",
   tollReport: "/reports/tolls",
   maintenanceReport: "/reports/maintenance",
   invoices: "/reports/invoices",
   pendingFinancials: "/reports/pending-financial",
   // Questions
   questions: "/questions",
   changeQuestionStatus: "/questions/{0}/status",
   // Tolerance
   tolerance: "/tolerance",
   communicationLogByRes: `/communication-logs?reservationId={0}`,
   communicationLogsByUser: "/communication-logs?userId={0}",
   communicationLogs: "/communication-logs",
   //invoices
   generateInvoice: "invoices",
   findInvoice: "/invoices/{0}",
   cancelInvoice: "/invoices/{0}/cancel",
   sendByEmail: "/invoices/{0}/send-by-email",
   creditMemo: "/invoices/credit-memo",
   findReceipt: "/invoices/{0}/find-receipt",
   createReceipt: "/invoices/{0}/receipts",
   refundInvoice: "/invoices/{0}/refund",
   //Dropdowns
   getCountries: "/locations/countries?page={0}&size={1}",
   getStatesByCountryId: "/locations/{0}/states",
   getCitiesByStateId: "/locations/{0}/cities",
   dropdownBanners: "/banners/names",
   dropdownCars: "/cars?page={0}&size={1}",
   getColors: "/cars/colors",
   getDropdownCarMaintenanceTypes: "/maintenances/types",
   getFuelTypes: "/cars/fuel-types",
   getCarFeatures: "/features/names",
   getCarFeatureTypes: "/features/types",
   getReservationStatuses: "/reservations/statuses",
   getDropdownOrganizations: "/organizations?page={0}&size={1}&search={2}",
   getBillableItems: "/billable-items?page={0}&size={1}&search={2}",
   getQuestions: "/questions/names",
   getDropdownUsers: "/users/all",
   getDropdownCounters: "/counters?page={0}&size={1}",
   getGroupTypes: "/groups/types",
   getDropdownCategories: "categories?page={0}&size={1}&search={2}&tariffId={3}",
   getCarBrands: "/brands?size={0}&page={1}&search={2}",
   getCrmSubjectTypes: "/communication-logs/subject-types",
   getModalTypes: "/communication-logs/modal-types",
   getReceptivities: "/communication-logs/receptivities",
   getCarFleets: "/cars/fleets",
   getPaymentMethods: "/payments/methods",
   getDropdownCampaigns: "/campaigns/names",
   getDocumentTypes: "/invoices/document-types",
   getDropdownFeatures: "/features/names",
   getTollStatusTypes: "/tolls/status-types",
   accountTypes: "/accounts/types",
   discountTypes: "/billable-items/types",
   // upload
   upload: "/upload",
   reports_divergent_categories: "/reports/GetDivergentCategories",
   pending_financials: "/reports/GetPendingFinancialReport",
   toll_reports: "/reports/GetTollsReport",
   commission_reports: "/reports/GetCommissionReport",
};
