import { Grid, Typography } from "@material-ui/core";
import { FC, MutableRefObject, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { currency } from "../../../../utills/masks";
import { useValidation } from "../../../../utills/validation";
import { AutoComplete, CommonButton, CurrencyInput, InputLabel, MaskedInput, TextArea, TextInput } from "../../../atoms";
import { useFormik } from "formik";
import { Save } from "@material-ui/icons";
import DiscountCampaigns from "../../../../data/models/discount-campaign.modal";
import { formatDateForInput } from "../../../../utills/helper";

const discountType = [
   { name: "Percentual", id: true },
   { name: "Absoluto", id: false },
];

export interface NewCampaignProps {
   data?: DiscountCampaigns;
   handleSubmit: (values: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const NewCampaignForm: FC<NewCampaignProps> = ({ data, handleSubmit, clearForm }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "users"]);

   const { campaignSchema } = useValidation();

   const { billableItems, vehicleCategories } = useDropdown();

   const formik = useFormik({
      initialValues: {
         startDate: data?.startDate ? formatDateForInput(data.startDate) : "",
         endDate: data?.endDate ? formatDateForInput(data.endDate) : "",
         name: data?.name ? data.name : "",
         description: data?.description ? data.description : "",
         rules: data?.rules ? data.rules : "",
         discount: data?.discount ? data.discount : "",
         isPercentage: data?.isPercentage ? true : false,
         itemId: data?.item ? data.item.id : 0,
         categories: data?.categories ? data.categories.map((el) => el.id) : [],
      },
      enableReinitialize: true,
      validationSchema: campaignSchema,
      onSubmit: (values) => {
         handleSubmit(values);
      },
   });

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <div>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
               <InputLabel label={t("users:name")} />
               <TextInput
                  id="name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  title=""
                  value={formik.values.name}
                  disabled={false}
                  type="text"
                  onChange={formik.handleChange}
               />
            </Grid>

            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
               <InputLabel label={t("translations:start")} />
               <TextInput
                  id="startDate"
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  title=""
                  value={formik.values.startDate}
                  disabled={false}
                  type="date"
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
               <InputLabel label={t("translations:end")} />
               <TextInput
                  id="endDate"
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  value={formik.values.endDate}
                  title=""
                  disabled={false}
                  type="date"
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container className={globalClasses.gridContainer} spacing={3}>
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
               <InputLabel label={t("translations:description")} />
               <TextArea id="description" value={formik.values.description} onChange={formik.handleChange} numRows={3} />
               <Typography className={globalClasses.errorText}>{formik.errors.description}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={8} sm={8} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                     <InputLabel label={t("translations:discountType")} />
                     <AutoComplete
                        options={discountType}
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        defaultValue={discountType.find((el) => el.id == formik.values.isPercentage)}
                        label=""
                        placeholder=""
                        onChange={(_, item) => formik.setFieldValue("isPercentage", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.isPercentage}</Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                     <InputLabel label={t("translations:value")} />
                     {formik.values.isPercentage ? (
                        <TextInput
                           id="discount"
                           error={formik.touched.discount && Boolean(formik.errors.discount)}
                           helperText={formik.touched.discount && formik.errors.discount}
                           title=""
                           value={formik.values.discount > 100 ? 100 : formik.values.discount}
                           disabled={false}
                           type="number"
                           onChange={(event) => {
                              if (parseFloat(event.target.value) > 100) return;
                              formik.setFieldValue("discount", event.target.value);
                           }}
                        />
                     ) : (
                        <CurrencyInput
                           disabled={false}
                           id={"discount"}
                           value={formik.values.discount.toString()}
                           clear={clearForm}
                           title=""
                           type="text"
                           error={formik.touched.discount && Boolean(formik.errors.discount)}
                           helperText={formik.touched.discount && formik.errors.discount}
                           onChange={(value) => {
                              formik.setFieldValue("discount", value);
                           }}
                        />
                     )}
                  </Grid>
               </Grid>
               <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                     <InputLabel label={t("translations:linkedCategories")} />
                     <AutoComplete
                        options={vehicleCategories}
                        label=""
                        groupBy={(item) => item?.tariff.name || ""}
                        placeholder=""
                        defaultValue={formik.values.categories.map((el) => vehicleCategories.find((item) => el == item.id))}
                        multiple
                        renderOption={(item) => item?.name || ""}
                        getOptionLabel={(item) => item?.name || ""}
                        onChange={(_, item) => {
                           let items = [] as number[];
                           item.map((el) => {
                              if (el) {
                                 items.push(el.id);
                              }
                           });
                           formik.setFieldValue("categories", items);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.categories}</Typography>
                  </Grid>

                  <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
                     <InputLabel label={t("translations:campaignItem")} />
                     <AutoComplete
                        label=""
                        placeholder=""
                        options={billableItems}
                        defaultValue={billableItems.find((el) => el.id === formik.values.itemId)}
                        getOptionLabel={(item) => item.name}
                        renderOption={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("itemId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.itemId}</Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
               <InputLabel label={t("translations:regulation")} />
               <TextArea id="rules" value={formik.values.rules} onChange={formik.handleChange} />
               <Typography className={globalClasses.errorText}>{formik.errors.rules}</Typography>
            </Grid>
         </Grid>
         <CommonButton title={t("translations:record")} onClick={formik.handleSubmit} icon={<Save />} />
      </div>
   );
};

export default NewCampaignForm;
