import React from "react";
import { ButtonProps } from "./index.types";
import { tableButtonStyles } from "./index.styles";
import { COLORS } from "../../../../data/constants/Colors";

const TableButton: React.FC<ButtonProps> = ({ title, onClick, icon, color }) => {
   const classes = tableButtonStyles();
   return (
      <button
         className={classes.container}
         style={{
            backgroundColor: color ? color : COLORS.primary,
         }}
         onClick={onClick}
      >
         <a>{icon}</a>
         {title}
      </button>
   );
};

export default TableButton;
