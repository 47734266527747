import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../../components/molecules";
import RemoveMaintenanceForm from "../../../components/organisms/forms/removeMaintenance/RemoveMaintenanceForm";
import { useRequest } from "../../../data/api/wrapper";
import * as maintenanceApi from "../../../data/api/requests/maintenance/index";
import { useHistory, useParams } from "react-router-dom";
import { AlertDailog, Loader } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { CheckCircleOutline } from "@material-ui/icons";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

const RemoveFromMaintenance = () => {
   const { t } = useTranslation(["translations", "maintenance"]);

   const globalClasses = globalStyles();

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const getMaintenanceDetails = useRequest(() => maintenanceApi.getMaintenanceInfoById(id));
   const removeCarFromMaintenance = useRequest((params) => maintenanceApi.removeFromMaintenance(id, params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getMaintenanceDetails.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const removeFromMaintenance = (values: any) => {
      removeCarFromMaintenance.execute(values);
   };

   return (
      <div>
         <Loader loading={removeCarFromMaintenance.loading} />

         {removeCarFromMaintenance.show.modal && (
            <AlertDailog
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               title={removeCarFromMaintenance.message.message}
               message={removeCarFromMaintenance.message.description}
               show={removeCarFromMaintenance.show.modal}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => history.push(ROUTE_LINKS.MAINTENANCE)}
            />
         )}

         <TitleContainer title={t("maintenance:removeFUll")} />
         <RemoveMaintenanceForm data={getMaintenanceDetails.data} handleSubmit={removeFromMaintenance} />
      </div>
   );
};

export default RemoveFromMaintenance;
