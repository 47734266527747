import { Theme, Typography } from "@material-ui/core";
import { BorderColor } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import React from "react";
import { LabelProps } from "./index.types";
import { inputLabelStyles } from "./index.styles";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";

const InputLabel: React.FC<LabelProps> = ({
  label,
  actionIcon,
  onClick,
  font,
}) => {
  const classes = inputLabelStyles();
  const globalClasses = globalStyles();
  return (
    <div className={globalClasses.flexRow}>
      <Typography
        style={{
          fontWeight: font ? "bold" : "normal",
          fontSize: font ? 14 : 13,
        }}
      >
        {label}
      </Typography>
      {actionIcon ? (
        <BorderColor className={classes.icon} onClick={onClick} />
      ) : null}
    </div>
  );
};

export default InputLabel;
