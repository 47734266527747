import { Button, CircularProgress } from "@material-ui/core";
import React from "react";
import { ButtonProps } from "./index.types";

const CommonButton: React.FC<ButtonProps> = ({
   title,
   onClick,
   icon,
   endIcon,
   fullWidth,
   color,
   margin,
   loading,
   disable,
   type,
   variant = "contained",
}) => {
   return (
      <Button
         variant={variant}
         size={"small"}
         color={"primary"}
         disableElevation
         type={type}
         style={{ marginTop: margin ? margin : 15, backgroundColor: color }}
         onClick={onClick}
         startIcon={icon}
         endIcon={endIcon}
         fullWidth={fullWidth ? fullWidth : false}
         disabled={disable}
      >
         {loading ? <CircularProgress size={25} /> : title}
      </Button>
   );
};

export default CommonButton;
