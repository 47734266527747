import ApiResponse from "../../../models/api-response.model";
import UploadResponse from "../../../models/file-upload-response.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export const uploadFile = async (
  fileData: FormData
): Promise<ApiResponse<UploadResponse>> => {
  try {
    const resp = await request({
      method: API_METHODS.POST,
      url: API_ROUTES.upload,
      data: fileData,
    });
    if (resp.status !== 200) {
      throw new Error("");
    }
    const data: ApiResponse<UploadResponse> = resp.data;
    return data;
  } catch (error) {
    throw error;
  }
};
