import { Card, CardActions, CardMedia, Divider, Grid, Slider, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useFormik } from "formik";
import { FC, useRef, useEffect, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { carRegisteration, currency } from "../../../../utills/masks";
import { useValidation } from "../../../../utills/validation";
import { CommonButton, CheckBox, InputLabel, AutoComplete, TextInput, CurrencyInput } from "../../../atoms";
import { newVehFormStyles } from "./NewVehicleForm.styles";
import Car from "../../../../data/models/car.model";
import CarFeature from "../../../../data/models/car-feature.model";
import { getFeatureInputs } from "../../../../utills/helper";
import { getVehicleData } from "../../../../utills/vehicle";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";

interface VehicleProps {
   car: Car | undefined;
   createVehicle: (vehicle: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const displacements = [
   { name: "1.0", id: 1, value: "1000" },
   { name: "1.4", id: 2, value: "1400" },
   { name: "1.6", id: 3, value: "1600" },
   { name: "1.8", id: 3, value: "1800" },
   { name: "2.0", id: 3, value: "2000" },
];

const NewVehicleForm: FC<VehicleProps> = ({ car, createVehicle, clearForm }) => {
   const globalClasses = globalStyles();

   const classes = newVehFormStyles();

   const { carSchema } = useValidation();

   const { t } = useTranslation(["translations", "vehicles", "users"]);

   const inputCoverFile = useRef<HTMLInputElement | null>(null);
   const inputMiniatureFile = useRef<HTMLInputElement | null>(null);

   const { colors, vehicleCategories, counters, companies, fleets, fuels, brands, carFeatures } = useDropdown();

   const formik = useFormik({
      initialValues: {
         imageUrl: "",
         coverImageUrl: "",
         fuelTypeId: 0,
         fuelLevel: 0,
         licenseDate: "",
         cubicCapacity: "",
         firstInspection: 0,
         inspectionFrequency: 0,
         colorId: 0,
         supplierId: 0,
         purchasePrice: "",
         image: File,
         coverImage: File,
         officeId: 0,
         licensePlate: "",
         carTypeId: 2,
         fleetId: 0,
         year: 0,
         mileage: 0,
         chassis: "",
         brandId: 0,
         name: "",
         shortName: "",
         categories: [0],
         vehicleFeatures: [
            {
               value: 0,
               highlight: false,
               featureId: 0,
            },
         ],
         contractFeatures: [
            {
               value: 0,
               highlight: false,
               featureId: 0,
            },
         ],
         features: [
            {
               value: 0,
               highlight: false,
               featureId: 0,
            },
         ],
      },
      validationSchema: carSchema,
      validateOnMount: false,
      validateOnBlur: false,
      onSubmit: (values) => {
         createVehicle(values);
      },
   });

   useEffect(() => {
      let unsubscribe = false;
      // formik.setFieldValue("features", getFeatureInputs(carFeatures));
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (car) {
         formik.setValues(getVehicleData(car, carFeatures));
      }
   }, [car]);

   useEffectAfterMount(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   const addVechicleFeature = (el: CarFeature, highlight: boolean) => {
      let tempfeature = { value: 0, highlight: highlight, featureId: el.id };
      const alreadyAdded = formik.values.vehicleFeatures.find((item) => item.featureId == el.id);
      if (!alreadyAdded) {
         formik.setFieldValue("vehicleFeatures", [...formik.values.vehicleFeatures, tempfeature]);
      } else {
         if (highlight) {
            const index = formik.values.vehicleFeatures.findIndex((temp) => temp.featureId == el.id);
            let newArray = formik.values.vehicleFeatures;
            newArray[index].highlight = highlight;
            formik.setFieldValue("vehicleFeatures", newArray);
            return;
         }
         let filteredArray = formik.values.vehicleFeatures.filter((temp) => {
            if (temp.featureId !== el.id) {
               return temp;
            }
         });
         formik.setFieldValue("vehicleFeatures", filteredArray);
      }
   };

   const addContractFeature = (el: CarFeature, highlight: boolean) => {
      let tempfeature = { value: 0, highlight: highlight, featureId: el.id };
      const alreadyAdded = formik.values.contractFeatures.find((item) => item.featureId == el.id);
      if (!alreadyAdded) {
         formik.setFieldValue("contractFeatures", [...formik.values.contractFeatures, tempfeature]);
      } else {
         if (highlight) {
            const index = formik.values.contractFeatures.findIndex((temp) => temp.featureId == el.id);
            let newArray = formik.values.contractFeatures;
            newArray[index].highlight = highlight;
            formik.setFieldValue("contractFeatures", newArray);
            return;
         }
         let filteredArray = formik.values.contractFeatures.filter((temp) => {
            if (temp.featureId !== el.id) {
               return temp;
            }
         });
         formik.setFieldValue("contractFeatures", filteredArray);
      }
   };

   const addInputfeature = (event: React.ChangeEvent<HTMLInputElement>, item: CarFeature) => {
      let tempFeatures = formik.values.features;
      const index = tempFeatures.findIndex((el) => el.featureId == item.id);
      formik.setFieldValue(`features[${index}].value`, event.target.value);
   };

   const getFeatureSelected = (item: CarFeature, data: typeof formik.values.vehicleFeatures) => {
      try {
         const found = data.filter((el) => el.featureId == item.id);
         if (found.length != 0) {
            return true;
         }
         return false;
      } catch (error) {
         return false;
      }
   };

   const getFeatureHighlight = (item: CarFeature, data: typeof formik.values.vehicleFeatures) => {
      const found = data.filter((el) => el.featureId == item.id && el.highlight);
      if (found.length !== 0) {
         return true;
      }
      return false;
   };

   const getError = (item: CarFeature) => {
      let tempFeatures = formik.values.features;
      const index = tempFeatures.findIndex((el) => el.featureId == item.id);
      if (!formik.errors.features) {
         return "";
      }
      //@ts-ignore
      return formik.errors?.features[index]?.value;
   };

   const getValue = (item: CarFeature) => {
      const value = formik.values.features.find((el) => el.featureId == item.id);
      return value?.value || "";
   };

   console.log(formik.values.features);

   return (
      <div>
         <input
            type="file"
            id="coverImage"
            ref={inputCoverFile}
            onChange={(event) => {
               if (event?.target?.files) {
                  formik.setFieldValue("coverImage", event.target?.files[0]);
                  formik.setFieldValue("coverImageUrl", URL.createObjectURL(event.target?.files[0]));
               }
            }}
            style={{ display: "none" }}
         />
         <input
            type="file"
            id="image"
            ref={inputMiniatureFile}
            onChange={(event) => {
               if (event?.target?.files) {
                  formik.setFieldValue("image", event.target?.files[0]);
                  formik.setFieldValue("imageUrl", URL.createObjectURL(event.target?.files[0]));
               }
            }}
            style={{ display: "none" }}
         />
         <Grid container spacing={3}>
            <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
               <Grid container spacing={4}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:brand")} />
                     <AutoComplete
                        options={brands}
                        defaultValue={brands.find((el) => el.id === formik.values.brandId)}
                        placeholder=""
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("brandId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.brandId}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:model")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="name"
                        type="text"
                        value={formik.values.name}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        helperText={formik.touched.name && formik.errors.name}
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("translations:shortName")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="shortName"
                        type="text"
                        value={formik.values.shortName}
                        error={formik.touched.shortName && Boolean(formik.errors.shortName)}
                        helperText={formik.touched.shortName && formik.errors.shortName}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:color")} />
                     <AutoComplete
                        options={colors}
                        defaultValue={colors.find((el) => el.id == formik.values.colorId)}
                        placeholder=""
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("colorId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.colorId}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:category")} />
                     <AutoComplete
                        options={vehicleCategories}
                        multiple
                        placeholder=""
                        defaultValue={vehicleCategories.filter((el) => formik.values.categories.find((item: any) => item === el.id))}
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => {
                           let temp: number[] = [];
                           item.map((el) => temp.push(el.id));
                           formik.setFieldValue("categories", temp);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.categories}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:currentLocation")} />
                     <AutoComplete
                        options={counters}
                        defaultValue={counters.find((el) => el.id == formik.values.officeId)}
                        placeholder=""
                        label=""
                        renderOption={(item) => item.officialName}
                        getOptionLabel={(item) => item.officialName}
                        onChange={(_, item) => formik.setFieldValue("officeId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.officeId}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:provider")} />
                     <AutoComplete
                        options={companies}
                        defaultValue={companies.find((el) => el.id == formik.values.supplierId)}
                        placeholder=""
                        label=""
                        renderOption={(item) => item.fantasyName}
                        getOptionLabel={(item) => item.fantasyName}
                        onChange={(_, item) => formik.setFieldValue("supplierId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.supplierId}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:purchasePrice")} />
                     <CurrencyInput
                        title=""
                        disabled={false}
                        id="purchasePrice"
                        type="text"
                        value={formik.values.purchasePrice}
                        error={formik.touched.purchasePrice && Boolean(formik.errors.purchasePrice)}
                        helperText={formik.touched.purchasePrice && formik.errors.purchasePrice}
                        onChange={(value) => {
                           formik.setFieldValue("purchasePrice", value);
                        }}
                     />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:fleetType")} />
                     <AutoComplete
                        options={fleets}
                        placeholder=""
                        defaultValue={fleets.find((el) => el.id == formik.values.fleetId)}
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("fleetId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.fleetId}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:fuel")} />
                     <AutoComplete
                        options={fuels}
                        placeholder=""
                        defaultValue={fuels.find((el) => el.id == formik.values.fuelTypeId)}
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("fuelTypeId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.fuelTypeId}</Typography>
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:registration")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="licensePlate"
                        type="text"
                        value={formik.values.licensePlate}
                        error={formik.touched.licensePlate && Boolean(formik.errors.licensePlate)}
                        helperText={formik.touched.licensePlate && formik.errors.licensePlate}
                        onChange={(event) => {
                           formik.setFieldValue("licensePlate", carRegisteration(event).target.value); // to
                        }}
                     />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:enrollmentDate")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="licenseDate"
                        type="date"
                        value={formik.values.licenseDate}
                        error={formik.touched.licenseDate && Boolean(formik.errors.licenseDate)}
                        helperText={formik.touched.licenseDate && formik.errors.licenseDate}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:chassis")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="chassis"
                        value={formik.values.chassis}
                        error={formik.touched.chassis && Boolean(formik.errors.chassis)}
                        helperText={formik.touched.chassis && formik.errors.chassis}
                        type="text"
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:mileage")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="mileage"
                        value={formik.values.mileage}
                        error={formik.touched.mileage && Boolean(formik.errors.mileage)}
                        helperText={formik.touched.mileage && formik.errors.mileage}
                        type="text"
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:year")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="year"
                        value={formik.values.year}
                        error={formik.touched.year && Boolean(formik.errors.year)}
                        helperText={formik.touched.year && formik.errors.year}
                        type="number"
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:displacement")} />
                     <AutoComplete
                        options={displacements}
                        placeholder=""
                        defaultValue={displacements.find((el) => el.value === formik.values.cubicCapacity)}
                        label=""
                        renderOption={(item) => item.name}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("cubicCapacity", item?.value)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.cubicCapacity}</Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:firstReveiw")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="firstInspection"
                        value={formik.values.firstInspection}
                        error={formik.touched.firstInspection && Boolean(formik.errors.firstInspection)}
                        helperText={formik.touched.firstInspection && formik.errors.firstInspection}
                        type="number"
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:reviewEvery")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="inspectionFrequency"
                        type="number"
                        value={formik.values.inspectionFrequency}
                        error={formik.touched.inspectionFrequency && Boolean(formik.errors.inspectionFrequency)}
                        helperText={formik.touched.inspectionFrequency && formik.errors.inspectionFrequency}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
               <Grid container spacing={4}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:fuelLevel")} />
                     <Slider
                        aria-label="fuel-level"
                        defaultValue={8}
                        // getAriaValueText={valuetext}
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={0}
                        max={8}
                        onChange={(e, value) => {
                           formik.setFieldValue("fuelLevel", value);
                        }}
                     />
                  </Grid>
                  {/* {carFeatures.inputs.map((el, i) =>
                     el.features.map((item, index) => (
                        <Grid item xl={2} lg={2} md={2} sm={12} xs={12} key={`inputs-${i}-${index}`}>
                           <InputLabel label={item.name} />
                           <TextInput
                              title=""
                              disabled={false}
                              id="shortName"
                              type="number"
                              value={getValue(item)}
                              helperText=""
                              onChange={(event) => addInputfeature(event, item)}
                           />
                           <Typography className={globalClasses.errorText}>{getError(item)}</Typography>
                        </Grid>
                     ))
                  )} */}
               </Grid>
            </Grid>
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
               <Typography className={globalClasses.mediumText}>{t("translations:characteristics")}</Typography>
               <div>
                  <Typography className={globalClasses.cardTitle}>{t("vehicles:features")}:</Typography>
               </div>
               <Typography color="error" className={globalClasses.smallErrorText}>
                  {formik.errors.vehicleFeatures}
               </Typography>
               {/* {carFeatures.checkboxes[0]?.features?.map((el, index) => (
                  <div key={`vehicle-${index}`}>
                     <CheckBox
                        title={el.name}
                        checked={getFeatureSelected(el, formik.values.vehicleFeatures)}
                        highlighted={getFeatureHighlight(el, formik.values.vehicleFeatures)}
                        highlight
                        setChecked={() => {
                           addVechicleFeature(el, false);
                        }}
                        setHighlight={(value) => {
                           addVechicleFeature(el, value);
                        }}
                     />
                  </div>
               ))} */}
               <div>
                  <Typography className={globalClasses.cardTitle}>{t("vehicles:contract")}:</Typography>
               </div>
               <Typography color="error" className={globalClasses.smallErrorText}>
                  {formik.errors.contractFeatures}
               </Typography>
               {/* {carFeatures.checkboxes[1].features.map((el, index) => (
                  <div key={`contract-${index}`}>
                     <CheckBox
                        title={el.name}
                        checked={getFeatureSelected(el, formik.values.contractFeatures)}
                        highlight
                        highlighted={getFeatureHighlight(el, formik.values.contractFeatures)}
                        setChecked={() => {
                           addContractFeature(el, false);
                        }}
                        setHighlight={(value) => {
                           addContractFeature(el, value);
                        }}
                     />
                  </div>
               ))} */}
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={3} sm={6} xs={12}>
               <Card className={classes.root}>
                  <CardMedia
                     className={classes.media}
                     image={formik.values.coverImageUrl || "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                     title="Upload Picture"
                  />
                  <CardActions disableSpacing>
                     <CommonButton
                        title={t("translations:coverPicture")}
                        onClick={() => {
                           inputCoverFile.current?.click();
                        }}
                        icon={<AddCircle />}
                     />
                  </CardActions>
               </Card>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <Card className={classes.root}>
                  <CardMedia
                     className={classes.media}
                     image={formik.values.imageUrl || "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                     title="Upload Picture"
                  />
                  <CardActions disableSpacing>
                     <CommonButton
                        title={t("translations:miniature")}
                        onClick={() => {
                           inputMiniatureFile.current?.click();
                        }}
                        icon={<AddCircle />}
                     />
                  </CardActions>
               </Card>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:save")} onClick={formik.handleSubmit} />
      </div>
   );
};

export default NewVehicleForm;
