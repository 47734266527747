import React, { useEffect, useState } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import { useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { sidebarStyles } from "./index.styles";
import { NavChildItem, NavigationBar, NavigationItem, UserData } from "../../molecules";
import useAuth from "../../../data/context/authContext/AuthContext";
import { ROUTES_TYPES, useSidebarRoutes } from "../../../routes/sidebarData/routes";

interface Props {
   window?: () => Window;
}
export default function Sidebar(props: Props) {
   const { user } = useAuth();
   const { window } = props;
   const classes = sidebarStyles();
   const theme = useTheme();
   const history = useHistory();
   const location = useLocation();
   const [pathname, setPathName] = useState(location.pathname);
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const { sidebarRoutes, setSidebarRoutes } = useSidebarRoutes();

   useEffect(() => {
      setPathName(location.pathname);
   }, [location]);

   const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
   };

   const onClickMenu = (route: ROUTES_TYPES, index: number) => {
      let temp = [...sidebarRoutes];
      if (route.children) {
         temp.forEach((el, i) => {
            if (index === i) {
               el.active = !el.active;
            } else {
               el.active = false;
            }
         });
         setSidebarRoutes(temp);
      } else {
         history.push(route.link);
      }
   };

   const drawer = (
      <div className={classes.drawerContainer}>
         <div className={classes.userControlContainer} />
         <UserData user={user} />
         <List>
            {sidebarRoutes.map((text, index) => (
               <React.Fragment key={text.route.toString()}>
                  {index % 3 === 0 ? <Divider /> : null}
                  <NavigationItem routeItem={text} pathName={pathname} onClick={() => onClickMenu(text, index)} />
                  {text.active &&
                     text.children &&
                     text.children.map((child, ind) => (
                        <React.Fragment key={ind.toString()}>
                           <NavChildItem
                              children={child}
                              pathName={pathname}
                              onClick={() => {
                                 history.push(child.route);
                              }}
                           />
                        </React.Fragment>
                     ))}
               </React.Fragment>
            ))}
         </List>
      </div>
   );

   const container = window !== undefined ? () => window().document.body : undefined;

   return (
      <>
         <CssBaseline />
         <NavigationBar onClick={handleDrawerToggle} user={user} />
         <CssBaseline />
         <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
               <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                     keepMounted: true,
                  }}
               >
                  {drawer}
               </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
               <Drawer
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
               >
                  {drawer}
               </Drawer>
            </Hidden>
         </nav>
      </>
   );
}
