import { Box, Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CommonButton, InputLabel, TextInput } from "../../../atoms";
interface AccountingFormProps {
   onChange: (e: string | number | number[] | undefined, key: string) => void;
   submit: () => void;
   data: any;
}
const AccountingForm: FC<AccountingFormProps> = ({ onChange, submit, data }) => {
   const globalClasses = globalStyles();
   const { counters, documentTypes } = useDropdown();
   console.log(documentTypes)
   const { t } = useTranslation(["translations", "users", "organization", "vehicles"]);
   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  id="startDate"
                  type="date"
                  onChange={(event) => onChange(event.target.value, "startDate")}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  id="startDate"
                  type="date"
                  onChange={(event) => onChange(event.target.value, "endDate")}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
               <InputLabel label={t("translations:reservation")} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  id="startDate"
                  type="number"
                  onChange={(e) => onChange(e.target.value, "reservation")}
               />
            </Grid>
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
               <InputLabel label={t("translations:counters")} />
               <AutoComplete
                  options={counters}
                  multiple
                  renderOption={(item) => item?.officialName}
                  defaultValue={counters.filter((el) => data.Pickups.find((item: any) => item === el.id))}
                  getOptionLabel={(item) => item.officialName}
                  placeholder={""}
                  label=""
                  onChange={(_, item) => {
                     let temp: number[] = [];
                     item.map((el) => {
                        if(el.id){
                        temp.push(el?.id)
                     }});
                     onChange(temp, "Pickups");
                  }}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:docType")} />
               <AutoComplete
                  options={documentTypes}
                  renderOption={(item) => item?.name}
                  defaultValue={documentTypes.find((el) => el.id === data.documentType)}
                  getOptionLabel={(item) => item.name}
                  placeholder={""}
                  label=""
                  onChange={(_, item) => {
                     onChange(item?.id, "documentType");
                  }}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:billNum")} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  id="billNumber"
                  type="number"
                  onChange={(e) => onChange(e.target.value, "billNumber")}
               />
            </Grid>
            <Grid item xl={1} lg={1} md={2} sm={6} xs={6}>
               <InputLabel label={t("vehicles:year")} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  maxLength={4}
                  id="year"
                  type="number"
                  onChange={(e) => {
                     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4);
                     onChange(e.target.value, "year");
                  }}
               />
            </Grid>
            <Grid item xl={1} lg={1} md={2} sm={6} xs={6}>
               <InputLabel label={t("translations:series")} />
               <TextInput disabled={false} title="" helperText={""} id="series" type="text" onChange={(e) => onChange(e.target.value, "series")} />
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
               <InputLabel label={`${t("users:nif")}/${t("users:taxId")} (${t("translations:noDotsDashes")})`} />
               <TextInput
                  disabled={false}
                  title=""
                  helperText={""}
                  id="nifId"
                  type="number"
                  onChange={(e) => {
                     onChange(e.target.value, "nifId");
                  }}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={6} xs={6}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={submit} icon={<Search />} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default AccountingForm;
