import { AddCircle } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TableButton } from "..";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import CarRecReserva from "../../../data/models/car-recent-reservation.model";
import { formatDateTime } from "../../../utills/helper";

interface ItemProps {
  reservation: CarRecReserva;
  onClick: (id: number) => void;
}

const RecentReservationItem: FC<ItemProps> = ({ reservation, onClick }) => {
  const { t } = useTranslation(["gen", "translations"]);

  const globalClasses = globalStyles();

  return (
    <>
      <div>
        <span className={globalClasses.cardTitle}>
          {t("translations:client")}:
        </span>
        <span
          className={`${globalClasses.tableText} ${globalClasses.marginLeft}`}
        >
          {reservation.clientUser}
        </span>
      </div>
      <div>
        <span className={globalClasses.cardTitle}>
          {t("translations:withDrawl")}:
        </span>
        <span
          className={`${globalClasses.tableText} ${globalClasses.marginLeft}`}
        >
          {formatDateTime(reservation.startDate)}
        </span>
      </div>
      <div>
        <span className={globalClasses.cardTitle}>{t("gen:location")}:</span>
        <span
          className={`${globalClasses.tableText} ${globalClasses.marginLeft}`}
        >
          {reservation.office}
        </span>
      </div>
      <TableButton
        icon={<AddCircle className={globalClasses.tableSmallIcon} />}
        title={t("translations:details")}
        onClick={() => {
          onClick(reservation.id);
        }}
      />
    </>
  );
};

export default RecentReservationItem;
