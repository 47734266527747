import { Divider, Grid } from "@material-ui/core";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { CommonButton, InputLabel, TextInput } from "../../../atoms";

interface PasswordProps {
  Submit: (passwords: any) => void;
}

const PasswordForm: FC<PasswordProps> = ({ Submit }) => {
  const globalClasses = globalStyles();
  const { t } = useTranslation(["vehicles", "translations"]);

  const formik = useFormik({
    initialValues: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: yup.object({
      password: yup
        .string()
        .min(2, t("vehicles:emptyField"))
        .required(t("vehicles:emptyField")),
      newPassword: yup
        .string()
        .min(6, t("vehicles:emptyField"))
        .required(t("vehicles:emptyField")),
      confirmPassword: yup
        .string()
        .test("passwords-match", t("vehicles:match"), function (value) {
          return this.parent.newPassword === value;
        }),
    }),
    onSubmit: (values) => {
      Submit(values);
    },
  });

  return (
    <>
      <Grid container spacing={3}>
        <Grid item lg={10} xl={10} md={10} sm={12} xs={12}>
          <InputLabel label={t("translations:password")} />
          <TextInput
            title=""
            disabled={false}
            type="password"
            id="password"
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item lg={10} xl={10} md={10} sm={12} xs={12}>
          <InputLabel label={t("translations:newPassword")} />
          <TextInput
            title=""
            disabled={false}
            type="password"
            id="newPassword"
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item lg={10} xl={10} md={10} sm={12} xs={12}>
          <InputLabel label={t("translations:confirmPassword")} />
          <TextInput
            title=""
            disabled={false}
            type="password"
            id="confirmPassword"
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>
      <Divider className={globalClasses.dividerMargin} />
      <CommonButton title="Salvar" onClick={formik.handleSubmit} />
    </>
  );
};

export default PasswordForm;
