import { Blob } from "buffer";
import { List } from "lodash";
import { formatDate } from "../../../../utills/helper";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import CarHistory from "../../../models/car-history";
import { Item } from "../../../models/discount-item.model";
import File from "../../../models/file.model";
import Pagination from "../../../models/pagination.model";
import ReservationConflict from "../../../models/reservation-conflicts.model";
import Reservation from "../../../models/reservation.model";
import { Ticket } from "../../../models/ticket.model";
import Transaction from "../../../models/transaction.model";
import { TransferTariff } from "../../../models/transfer-tax.model";
import User from "../../../models/user.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export async function getAllReservations(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Reservation[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservations + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Reservation[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewReservation(payload: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.reservations,
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addPendingItems(reservationId: number): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.addPendingItems.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateReservationById(reservationId: string, payload: any): Promise<ApiResponse<void>> {
   payload = { ...payload, notes: payload.note };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.reservationById.format(reservationId),
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getReservationById(reservationId: number): Promise<ApiResponse<Reservation>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationById.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Reservation> = resp.data;
      return data;
   } catch (error) {
      console.log(error);
      throw error;
   }
}

export async function saveReservationDossier(reservationId: string, dossier: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.saveDossiar.format(reservationId),
         data: { dossier },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function transferTariff(payload: any): Promise<ApiResponse<TransferTariff>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url:
            API_ROUTES.transferTariff + `?pickupOrganizationId=${payload.pickupOrganizationId}&returnOrganizationId=${payload.returnOrganizationId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<TransferTariff> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function reservationProof(reservationId: string): Promise<BlobPart> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         responseType: "blob",
         url: API_ROUTES.reservationProof.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: BlobPart = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function reservationContract(reservationId: string | number): Promise<ApiResponse<{ id: number; pdfFileBase64: string }>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationContract.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<{ id: number; pdfFileBase64: string }> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function sendContract(reservationId: string, email: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.sendContract.format(reservationId),
         data: { email },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function cancelReservation(reservationId: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.cancelReservation.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function approveReservation(reservationId: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.approveReservation.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function startReservation(reservationId: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.startReservation.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getTickets(page: number, size: number, counterId: number[], search: string): Promise<ApiResponse<Pagination<Ticket[]>>> {
   const query = `${counterId.map((item) => `CounterId=${item}&`)}`;
   const removeComma = query.replaceAll(",", "");
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.tickets + `?${removeComma}Page=${page}&Size=${size}&Search=${search}`, // To do Empty
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Ticket[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getTicketById(ticketId: string): Promise<ApiResponse<Ticket>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.tickets + `/${ticketId}`, // To do Empty
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Ticket> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeTicketStatus(ticketId: string, status: number): Promise<ApiResponse<Ticket>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeTicketStatus.format(ticketId),
         data: { ticketId, status },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Ticket> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addTicketMessage(payload: any): Promise<ApiResponse<void>> {
   let downlink = "";
   if (payload?.fileAttachment.size) {
      const file = new FormData();
      file.append("file", payload?.fileAttachment);
      const res = await uploadFile(file);
      downlink = res.data.file;
   }
   const dataToUpload = {
      message: payload.message,
      fileAttachment: downlink,
      fileAttachmentDescription: payload.fileAttachmentDescription,
   };
   console.log(dataToUpload);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.addTicketMessage.format(payload.id),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export const getReservationTransactions = async (reservationId: string): Promise<ApiResponse<Transaction[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationTransactions.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Transaction[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const addReservationTransaction = async (reservationId: string, payload: any): Promise<ApiResponse<void>> => {
   let dataToUpload = { ...payload, dueDate: encodeDate(payload.dueDate, ""), clientType: 1 };

   if (dataToUpload.discount === 0) dataToUpload.discount = null;

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.reservationTransactions.format(reservationId),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getConflicts = async (reservationId: string): Promise<ApiResponse<ReservationConflict>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationConflicts.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<ReservationConflict> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export async function getReservationCustomers(reservationId: string): Promise<ApiResponse<User[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationCustomers.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<User[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function finalizeReservation(payload: any): Promise<ApiResponse<any>> {
   console.log(payload);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.finishReservation.format(payload.reservationId),
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<User[]> = resp.data;
      return data;
   } catch (error) {
      console.log(error);
      throw error;
   }
}

export async function carHistory(reservationId: string): Promise<ApiResponse<CarHistory[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.carHistory.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<CarHistory[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function reservationTolls(reservationId: string): Promise<ApiResponse<Pagination<Transaction[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationTolls.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<Pagination<Transaction[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
