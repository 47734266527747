import { Box, Divider, Typography } from "@material-ui/core";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { useTranslation } from "react-i18next";
import { CommissionSearchForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import * as reportApi from "../../../data/api/requests/reports/index";
import { useRequest } from "../../../data/api/wrapper";
import { Loader, TableItem } from "../../../components/atoms";
import { formatDateTime } from "../../../utills/helper";
import { putDecimalPoints } from "../../../utills/masks";
import { Commission } from "../../../data/models/commission.model";
import { ReportProblem } from "@material-ui/icons";
import { COLORS } from "../../../data/constants/Colors";

const Commissions = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "dashboardStrings", "gen"]);

   const commissions = useRequest((params) => reportApi.getCommissionReport(params));

   const Search = (values: any) => {
      commissions.execute(values);
   };

   const onInvoiceClick = (id: number) => {};

   const onReservationClick = (id: number) => {
      history.push(`/reservation/form/${id}`);
   };

   const returnCommission = (commission: Commission) => {
      const { commissionRate, commissionRateBase, netAmount, discount, itemsDiscount, tollAmount, currentCommissionRateBase } = commission;
      let value = putDecimalPoints(((netAmount - discount) * (commissionRate / 100)).toFixed(2)) + " €";
      let title = "";
      let icon = null as ReactNode;
      let beforeText = "";
      if (commissionRateBase != currentCommissionRateBase) {
         icon = <ReportProblem style={{ color: COLORS.primary, fontSize: 13 }} />;
         beforeText = "##";
      }
      if (commissionRate == commissionRateBase) {
         title = `${putDecimalPoints((commissionRate / 100) * (netAmount - (discount + itemsDiscount) - tollAmount))}%`;
      } else {
         title = `${commissionRateBase}% => ${commissionRate}%`;
      }
      return <TableItem textBeforeTitle={beforeText} customIcon={icon} title={title} subtitle={value} />;
   };

   return (
      <div>
         <Loader loading={commissions.loading} />

         <TitleContainer title={t("translations:billingByBranches")} />

         <CommissionSearchForm Submit={Search} />
         {commissions.data?.map((el, index) => (
            <ContainerTopBar title={el.fantasyName} key={`commission-${el.fantasyName}-${index}`}>
               <DataTable data={el.data || []} search={false} shadedHead={true}>
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("dashboardStrings:reservation")}
                     render={(item) => (
                        <div className={globalClasses.linkText} onClick={() => onReservationClick(item.reservationId)}>
                           {item.reservationId}
                        </div>
                     )}
                  />
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("translations:date")}
                     render={(item) => item.registration && formatDateTime(item.registration)}
                  />
                  <TableColumn headerOptions={{}} headerText={t("dashboardStrings:grossValue")} render={(item) => putDecimalPoints(item.netAmount)} />
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("translations:discount")}
                     render={(item) => (
                        <TableItem
                           title={`${(((item.discount + item.itemsDiscount) * 100) / item.netAmount).toFixed(2)}%`}
                           subtitle={putDecimalPoints(item.discount + item.itemsDiscount) + "€"}
                        />
                     )}
                  />
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("dashboardStrings:netCash")}
                     render={(item) => putDecimalPoints((item.netAmount - (item.discount + item.itemsDiscount)).toFixed(2))}
                  />
                  <TableColumn headerOptions={{}} headerText={t("dashboardStrings:taxCommission")} render={(item) => returnCommission(item)} />
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("translations:vat")}
                     render={(item) => ((item.netAmount - item.discount) * 0.23).toFixed(2)}
                  />
                  <TableColumn headerOptions={{}} headerText={t("translations:total")} render={(item) => putDecimalPoints(item.amount)} />
                  <TableColumn headerOptions={{}} headerText={t("translations:tolls")} render={(item) => putDecimalPoints(item.tollAmount)} />
                  <TableColumn
                     headerOptions={{}}
                     headerText={`${t("dashboardStrings:employee")} / ${t("dashboardStrings:costumer")}`}
                     render={(item) => <TableItem title={item.sellerName} subtitle={item.clientName} />}
                  />
                  <TableColumn headerOptions={{}} headerText={t("gen:paymentMethod")} render={(item) => item.paymentMethod} />
                  <TableColumn
                     headerOptions={{}}
                     headerText={t("translations:invoice")}
                     render={(item) => (
                        <div onClick={() => onInvoiceClick(item.invoiceId)} className={globalClasses.linkText}>
                           {item.fullIdentifier}
                        </div>
                     )}
                  />
               </DataTable>
               <DataTable data={[1]} shadedHead search={false}>
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{"Total"}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn
                     headerText={t("dashboardStrings:grossValue")}
                     render={() => <div className={globalClasses.errorText}>{putDecimalPoints(el.grossAmount)}</div>}
                  />
                  <TableColumn
                     headerText={t("translations:discount")}
                     render={(item) => <div className={globalClasses.errorText}>{putDecimalPoints(el.discount)}</div>}
                  />
                  <TableColumn
                     headerText={t("dashboardStrings:netCash")}
                     render={(item) => <div className={globalClasses.blueText}>{putDecimalPoints(el.netAmount)}</div>}
                  />
                  <TableColumn
                     headerText={t("dashboardStrings:taxCommission")}
                     render={(item) => <div className={globalClasses.errorText}>{putDecimalPoints(el.actualCommission)}</div>}
                  />
                  <TableColumn
                     headerText={t("translations:vat")}
                     render={(item) => <div className={globalClasses.errorText}>{putDecimalPoints(el.iva)}</div>}
                  />
                  <TableColumn
                     headerText={t("translations:total")}
                     render={(item) => <div className={globalClasses.errorText}>{putDecimalPoints(el.amount)}</div>}
                  />
                  <TableColumn
                     headerText={t("translations:tolls")}
                     render={(item) => <div className={globalClasses.blueText}>{putDecimalPoints(el.tollAmount)}</div>}
                  />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
                  <TableColumn render={(item) => <div className={`${globalClasses.tableText} ${globalClasses.errorText}`}>{""}</div>} />
               </DataTable>
               <Divider className={globalClasses.dividerMargin} />
               <Typography className={globalClasses.titleText}>
                  {t("dashboardStrings:commissionableVal")}: € {putDecimalPoints(el.commissionableValue.toFixed(2))} ({t("dashboardStrings:netCash")}{" "}
                  - {t("translations:tolls")})
               </Typography>
               <Typography className={globalClasses.titleText}>
                  {t("dashboardStrings:idealCommission")}: € {putDecimalPoints(el.idealCommission.toFixed(2))}
               </Typography>
               <Typography className={globalClasses.titleText}>
                  {t("dashboardStrings:actualCommission")}: € {putDecimalPoints(el.actualCommission.toFixed(2))}
               </Typography>
               <Typography className={globalClasses.tableSmallText}>* {t("dashboardStrings:commissionNotIncludeVat")}.</Typography>
               <Typography className={globalClasses.tableSmallText}>* {t("dashboardStrings:tollColumnNotIncludeVat")}.</Typography>
               <Divider className={globalClasses.dividerMargin} />
            </ContainerTopBar>
         ))}
      </div>
   );
};

export default Commissions;
