import React, { FC } from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { Categories } from "../../../data/models/Movement.model";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { CarItem, TableButton, TableItem } from "../../atoms";
import { formatTime } from "../../../utills/helper";
import { COLORS } from "../../../data/constants/Colors";

interface Props {
   data?: Categories[];
   title: string;
   onReservationClick: (id: number) => void;
}

const DayMovementTable: FC<Props> = ({ data, title, onReservationClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["dashboardStrings", "translations"]);

   return (
      <Grid item xs={12} sm={12} md={6}>
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Box className={globalClasses.flexRow}>
               <Typography color={"textSecondary"} className={globalClasses.cardTitle}>
                  {title}
               </Typography>
               <Box className={globalClasses.badge} style={{ background: COLORS.greyBorder, color: COLORS.darkText }}>
                  {data?.length || 0}
               </Box>
            </Box>
            <Divider />
            <DataTable data={data || []} search={false}>
               <TableColumn
                  headerText={t("dashboardStrings:reservation")}
                  render={(item) => (
                     <TableButton
                        title={`${item.id} # ${t("dashboardStrings:reservation")}`}
                        onClick={() => {
                           onReservationClick(item.id);
                        }}
                     />
                  )}
               />
               <TableColumn headerText={t("dashboardStrings:enrollment")} render={(item) => <CarItem car={item.car} />} />
               <TableColumn headerText={t("dashboardStrings:driver")} render={(item) => item.user} />
               <TableColumn headerText={t("translations:hour")} render={(item) => formatTime(item.startDate)} />
            </DataTable>
         </Box>
      </Grid>
   );
};

export default DayMovementTable;
