import { Box, Card, CardActions, CardMedia, Divider, Grid, Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { AutoComplete, CommonButton, CurrencyInput, InputLabel, MaskedInput, TextInput } from "../../../atoms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { catFormStyle } from "./NewVehicleCategoryForm.styles";
import { useTranslation } from "react-i18next";
import { FC, useEffect, useRef } from "react";
import { CategoryProps } from "./index.types";
import { useFormik } from "formik";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { useValidation } from "../../../../utills/validation";
import { currency } from "../../../../utills/masks";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";
const NewVehicleCategoryForm: FC<CategoryProps> = ({ onSubmit, data, clearForm }) => {
   const globalClasses = globalStyles();
   const classes = catFormStyle();
   const inputCoverFile = useRef<HTMLInputElement | null>(null);
   const inputMiniatureFile = useRef<HTMLInputElement | null>(null);
   const { t } = useTranslation(["translations", "users", "vehicles"]);
   const { tariffs } = useDropdown();

   let { schema } = useValidation();

   const formik = useFormik({
      initialValues: {
         name: "",
         cta: "",
         pricePerDay: "",
         pricePerHour: "",
         tariffId: 0,
         image: null,
         coverImage: null,
         imageLink: "",
         coverLink: "",
      },
      validationSchema: schema,
      onSubmit: (values) => {
         onSubmit(values);
      },
   });
   useEffect(() => {
      if (data) {
         formik.values.name = data.name;
         formik.values.cta = data.cta;
         formik.values.tariffId = data.tariff.id;
         formik.values.pricePerDay = `${data.pricePerDay}`;
         formik.values.pricePerHour = `${data.pricePerHour}`;
         formik.values.imageLink = data.image;
         formik.values.coverLink = data.coverImage;
      }
   }, [data]);

   useEffectAfterMount(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <form onSubmit={formik.handleSubmit}>
            <input
               type="file"
               id="coverImage"
               ref={inputCoverFile}
               onChange={(event) => {
                  if (event?.target?.files) {
                     formik.setFieldValue("coverImage", event?.target?.files[0]);
                     formik.setFieldValue("coverLink", URL.createObjectURL(event.target.files[0]));
                  }
               }}
               style={{ display: "none" }}
            />
            <input
               type="file"
               id="image"
               ref={inputMiniatureFile}
               onChange={(event) => {
                  if (event?.target?.files) {
                     formik.setFieldValue("image", event.target.files[0]);
                     formik.setFieldValue("imageLink", URL.createObjectURL(event.target.files[0]));
                  }
               }}
               style={{ display: "none" }}
            />
            <Grid container spacing={3}>
               <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <InputLabel label={t("users:name")} />
                  <TextInput
                     id="name"
                     title=""
                     disabled={false}
                     type="text"
                     value={formik.values.name}
                     onChange={formik.handleChange}
                     error={formik.touched.name && Boolean(formik.errors.name)}
                     helperText={formik.touched.name && formik.errors.name}
                  />
               </Grid>
               <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                  <InputLabel label="CTA(Call to Action)" />
                  <TextInput
                     id="cta"
                     title=""
                     disabled={false}
                     type="text"
                     value={formik.values.cta}
                     onChange={formik.handleChange}
                     error={formik.touched.cta && Boolean(formik.errors.cta)}
                     helperText={formik.touched.cta && formik.errors.cta}
                  />
               </Grid>
               <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                  <InputLabel label={t("translations:teriff")} />
                  <AutoComplete
                     label=""
                     placeholder=""
                     options={tariffs}
                     defaultValue={tariffs.find((el) => el.tariffId === formik.values.tariffId)}
                     renderOption={(item) => item.name}
                     getOptionLabel={(item) => item.name}
                     onChange={(_, item) => {
                        formik.setFieldValue("tariffId", item?.id);
                     }}
                  />
                  <Typography className={globalClasses.errorText}>{formik.touched.tariffId && formik.errors.tariffId}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={3}>
               <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <InputLabel label={`${t("translations:daily")} (€)`} />
                  <CurrencyInput
                     id="pricePerDay"
                     title=""
                     disabled={false}
                     type="text"
                     value={formik.values.pricePerDay}
                     onChange={(value) => {
                        formik.setFieldValue("pricePerDay", value);
                     }}
                     error={formik.touched.pricePerDay && Boolean(formik.errors.pricePerDay)}
                     helperText={formik.touched.pricePerDay && formik.errors.pricePerDay}
                  />
               </Grid>
               <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <InputLabel label={`${t("translations:hours")} (€)`} />
                  <CurrencyInput
                     id="pricePerHour"
                     title=""
                     disabled={false}
                     type="text"
                     value={formik.values.pricePerHour}
                     onChange={(value) => {
                        formik.setFieldValue("pricePerHour", value);
                     }}
                     error={formik.touched.pricePerHour && Boolean(formik.errors.pricePerHour)}
                     helperText={formik.touched.pricePerHour && formik.errors.pricePerHour}
                  />
               </Grid>
            </Grid>
            <Grid container spacing={3}>
               <Grid item xl={4} lg={4} md={3} sm={6} xs={12}>
                  <Card className={classes.root}>
                     <CardMedia
                        className={classes.media}
                        image={formik.values.coverLink || "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                        title="Upload Picture"
                     />
                     <CardActions disableSpacing>
                        <CommonButton
                           title={t("translations:coverPicture")}
                           onClick={() => {
                              inputCoverFile.current?.click();
                           }}
                           icon={<AddCircle />}
                        />
                     </CardActions>
                  </Card>
               </Grid>
               <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
                  <Card className={classes.root}>
                     <CardMedia
                        className={classes.media}
                        image={formik.values.imageLink || "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                        title="Upload Picture"
                     />
                     <CardActions disableSpacing>
                        <CommonButton
                           title={t("translations:miniature")}
                           onClick={() => {
                              inputMiniatureFile.current?.click();
                           }}
                           icon={<AddCircle />}
                        />
                     </CardActions>
                  </Card>
               </Grid>
            </Grid>
            <Divider className={globalClasses.dividerMargin} />
            <CommonButton title={t("translations:create")} onClick={formik.handleSubmit} />
         </form>
      </Box>
   );
};

export default NewVehicleCategoryForm;
