import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaintenanceReportForm } from "../../../components/organisms";
import * as maintenanceReportApi from "../../../data/api/requests/reports/index";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { currencyNumber, putDecimalPoints } from "../../../utills/masks";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import moment from "moment";
import { Margin } from "../../../components/atoms";
import { COLORS } from "../../../data/constants/Colors";
import { getToday } from "../../../utills/helper";
const MaintenanceCosts = () => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "vehicles", "invoice", "organization", "maintenance", "gen"]);
   const [searchData, setSearchData] = useState({
      CarId: 0,
      StartDate: getToday(),
      EndDate: getToday(),
      Page: 0,
      Size: 10,
      Search: "",
   });
   const getReports = useRequest((params) => maintenanceReportApi.getMaintenanceReport(searchData));

   const handleChange = (key: any, value: string | number | undefined) => {
      setSearchData({ ...searchData, [key]: value });
   };

   useEffect(() => {
      let firstMount = true;
      if (!firstMount) {
         getReports.execute();
      }
      firstMount = false;
   }, [searchData.Page, searchData.Search, searchData.Size]);

   return (
      <div>
         <Box className={globalClasses.title}>{t("translations:maintenanceReports")}</Box>
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <MaintenanceReportForm
               loading={getReports.loading}
               onSearch={() => {
                  getReports.execute();
               }}
               onChange={handleChange}
               data={searchData}
            />
            {getReports.data && (
               <>
                  <Margin />
                  <DataTable
                     data={getReports.data?.maintenances?.data || []}
                     total={getReports.data?.maintenances.total}
                     search={false}
                     onChange={(page, rowsPerPage) => setSearchData({ ...searchData, Page: page, Size: rowsPerPage })}
                     onSearching={(query) => setSearchData({ ...searchData, Search: query })}
                  >
                     <TableColumn headerText="ID" render={(item) => item.id} />
                     <TableColumn headerText={t("organization:local")} render={(item) => item.local} />
                     <TableColumn headerText={t("maintenance:entry")} render={(item) => moment(item.startDate).format("DD/MM/YYYY")} />
                     <TableColumn headerText={t("gen:exit")} render={(item) => moment(item.endDate).format("DD/MM/YYYY")} />
                     <TableColumn headerText={t("translations:value")} render={(item) => putDecimalPoints(item.totalAmount)} />
                  </DataTable>
                  <Margin />
                  <Box style={{ backgroundColor: COLORS.redLabel, padding: 10, color: COLORS.cardBackground, fontWeight: "bolder" }}>
                     {t("invoice:totalMaintValue") + ": € " + putDecimalPoints(getReports.data?.amount)}
                  </Box>
               </>
            )}
         </Box>
      </div>
   );
};

export default MaintenanceCosts;
