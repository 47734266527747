import { Box, Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { CommonButton, Loader, Toast } from "../../../components/atoms";
import { TitleContainer } from "../../../components/molecules";
import { NewMessageForm } from "../../../components/organisms";
import { CheckboxProps, MessageFieldsProps } from "../../../components/organisms/forms/newMessage/index.types";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { messageStyles } from "./index.style";
import * as userApi from "../../../data/api/requests/users/index";
import User, { UserForUpload } from "../../../data/models/user.model";
import { emptyStringValidator } from "../../../utills/utills";
import { Message } from "../../../data/models/send-message.modal";
import { useRequest } from "../../../data/api/wrapper";
const initialData = {
   pushNotification: false,
   email: false,
   sms: false,
};
interface stateType {
   user: number;
}
function NewMessage() {
   const globalClasses = globalStyles();
   const classes = messageStyles();
   const sendMessage = useRequest((params) => userApi.sendMessageToUser(params));
   const { state } = useLocation<stateType>();
   const { t } = useTranslation(["users", "translations"]);
   const [checkedItems, setCheckedItems] = useState<typeof initialData>(initialData);
   const [message, setMessage] = useState({
      title: "",
      message: "",
      titleError: "",
      messageError: "",
   });
   const [user, setUser] = useState<User>();

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         _getUser();
      }
      return () => {
         unmounted = true;
      };
   }, []);

   useEffect(() => {
      if (sendMessage.data) {
         setMessage({
            title: "",
            message: "",
            titleError: "",
            messageError: "",
         });
         setCheckedItems({ pushNotification: false, email: false, sms: false });
      }
   }, [sendMessage.data]);

   const _getUser = () => {
      userApi
         .getUserById(JSON.stringify(state.user))
         .then((res) => {
            setUser(res.data);
            console.log(res.data);
         })
         .catch((err) => console.log(err));
   };

   const _onCheckboxClick = (key: keyof CheckboxProps) => {
      setCheckedItems({ ...checkedItems, [key]: !checkedItems[key] });
   };

   const _onChange = (key: keyof MessageFieldsProps, event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
      event.preventDefault();
      setMessage({ ...message, [key]: event.target.value });
   };

   const _sendMessage = () => {
      const titleError = emptyStringValidator(message.title, 1);
      const messageError = emptyStringValidator(message.message, 5);
      if (titleError || messageError) {
         return;
      }
      let optionArray: number[] = [];
      Object.values(checkedItems).map((key: any, index: any) => optionArray.push(key ? 1 : 0));
      const temp: Message = {
         userId: state.user,
         modals: optionArray,
         message: message.message,
         title: message.title,
      };
      sendMessage.execute(temp);
   };

   return (
      <div>
         <Loader loading={sendMessage.loading} />
         {sendMessage.show.toast && (
            <Toast
               show={sendMessage.show.toast}
               message={sendMessage.message}
               handleClose={() => {
                  sendMessage.close();
               }}
            />
         )}
         <TitleContainer title={t("users:test")} />
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <NewMessageForm checkBox={checkedItems} name={user?.name} data={message} onCheckboxClick={_onCheckboxClick} onChange={_onChange} />
            <Divider className={globalClasses.dividerMargin} />
            <CommonButton title="Enviar" onClick={_sendMessage} />
         </Box>
      </div>
   );
}
export default NewMessage;
