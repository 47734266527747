import { normalizeCurrency } from "../../../../utills/masks";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import { Invoice } from "../../../models/invoice.model";
import InvoiceCostumer from "../../../models/InvoiceCostumer.model";
import Receipt from "../../../models/receipt.modal";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export const generateInvoice = async (payload: { transactionId: number; type: string }): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.generateInvoice,
         data: { ...payload },
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getInvoiceById = async (id: string): Promise<ApiResponse<InvoiceCostumer>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.findInvoice.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<InvoiceCostumer> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const cancelInvoice = async (payload: { invoiceId: string; reason: string }): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.cancelInvoice.format(payload.invoiceId),
         data: { reason: payload.reason },
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const sendInvoiceByEmail = async (invoiceId: string, email: string): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.sendByEmail.format(invoiceId),
         data: { email: email },
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCreditMemo = async (referenceInvoiceId: string): Promise<ApiResponse<Invoice[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.creditMemo,
         data: { referenceInvoiceId },
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<Invoice[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getReceipt = async (invoiceId: string): Promise<ApiResponse<{ receipts: Receipt[]; total: string }>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.findReceipt.format(invoiceId),
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      let data: ApiResponse<{ receipts: Receipt[]; total: string }> = resp.data;
      data.data.receipts = resp.data.data;
      data.data.total = data.data.receipts
         .reduce((total, el) => {
            return (total += el.amount);
         }, 0)
         .toFixed(2);
      return data;
   } catch (error) {
      throw error;
   }
};

export const createReceipt = async (invoiceId: string, payload: any): Promise<ApiResponse<void>> => {
   const dataToUpload = { ...payload, date: encodeDate(payload.date, ""), value: normalizeCurrency(payload.value), invoiceId };

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.createReceipt.format(invoiceId),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const refundInvoice = async (payload: any, invoiceId: string): Promise<ApiResponse<void>> => {
   let dataToUpload = { ...payload, invoiceId };
   const items = dataToUpload.items.reduce((a: any, el: any) => {
      a.push({ id: el.id, quantity: el.quantity, quantityDiscount: el.quantityDiscount, itemValue: el.itemValue, discount: el.discount });
      return a;
   }, []);
   dataToUpload.items = items;
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.refundInvoice.format(invoiceId),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Unexpected error");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
