import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContainerTopBar } from "../../../components/molecules";
import { TitleContainer } from "../../../components/molecules";
import * as adminApi from "../../../data/api/requests/administrator/index";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { Loader, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { formatDate } from "../../../utills/helper";
import Pagination from "../../../data/models/pagination.model";
import User from "../../../data/models/user.model";
import { Administrator } from "../../../data/models/administrator.model";

function Administrators() {
   const { t } = useTranslation(["translations", "users", "vehicles"]);
   const [pagination, setPagination] = useState({ page: 1, size: 10 });
   const [searchString, setSearchString] = useState("");

   const admins = useRequest(() => adminApi.getAdministrators(pagination.page, pagination.size, searchString));
   const changeStatus = useRequest((params) => adminApi.changeAdminStatus(params));

   useEffect(() => {
      admins.execute();
   }, [pagination, searchString]);

   const _changeStatus = (id: number) => {
      changeStatus.execute(id);
   };

   useEffect(() => {
      if (changeStatus.data) {
         const index = admins.data?.data.findIndex((el) => el.id === changeStatus?.data?.id);
         console.log(index);
         const { data } = admins;
         let users: Pagination<Administrator[]> = Object.assign(data!!);
         users.data[index!!] = changeStatus.data;
         admins.setData(users);
      }
   }, [changeStatus.data]);

   return (
      <Box>
         <Loader loading={changeStatus.loading} />

         {changeStatus.show.toast && (
            <Toast
               show={changeStatus.show.toast}
               message={changeStatus.message}
               handleClose={() => {
                  changeStatus.close();
                  //getUsers.execute();
               }}
            />
         )}

         <TitleContainer title={t("users:listOfAdmins")} />

         <ContainerTopBar title={t("translations:listing")} activity={admins.loading}>
            <DataTable
               data={admins.data?.data || []}
               pagination
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               onSearching={(searchString) => setSearchString(searchString)}
               total={admins.data?.total}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("users:name")} render={(item) => item.name} />
               {/* <TableColumn headerText={t("translations:email")} render={(item) => item.email} /> */}
               <TableColumn headerText={t("users:telephone")} render={(item) => item.phone} />
               <TableColumn headerText={t("vehicles:registration")} render={(item) => formatDate(item.registration)} />
               {/* <TableColumn headerText={t("translations:groups")} render={(item) => item.category.name} /> */}
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn render={(item) => <TableDropDown options={[t("translations:changeStatus")]} onClick={() => _changeStatus(item.id)} />} />
            </DataTable>
         </ContainerTopBar>
      </Box>
   );
}

export default Administrators;
