import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React, { FC, ReactNode } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";

interface TableItemProps {
   title: string;
   subtitle?: string;
   onClick?: () => void;
   icon?: boolean;
   customIcon?: ReactNode;
   textBeforeTitle?: string;
   link?: boolean;
}

const TableItem: FC<TableItemProps> = ({ title, subtitle, onClick, icon, customIcon, textBeforeTitle, link }) => {
   const globalClasses = globalStyles();
   return (
      <>
         <div className={`${globalClasses.tableText} ${link && globalClasses.linkText}`} onClick={onClick}>
            {customIcon && (
               <>
                  {textBeforeTitle} {customIcon}{" "}
               </>
            )}
            {icon && <ArrowUpward className={globalClasses.tableIcon} />}
            {title}
         </div>
         <div className={globalClasses.tableSmallText}>
            {icon && <ArrowDownward className={globalClasses.smallIcon} />}
            {subtitle}
         </div>
      </>
   );
};

export default TableItem;
