import { Box } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { AlertDailog, CommonButton, Loader, Toast } from "../../../../components/atoms";
import { TitleContainer } from "../../../../components/molecules";
import { NewCampaignForm } from "../../../../components/organisms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import * as discountApi from "../../../../data/api/requests/disscounts/index";
import { useRequest } from "../../../../data/api/wrapper";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";

const NewCampaign = () => {
   const globalClasses = globalStyles();
   const history = useHistory();

   const { id } = useParams<{ id: string }>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const { t } = useTranslation(["translations", "users", "gen"]);

   const getCampaign = useRequest(() => discountApi.getDiscountCampaignById(id));
   const addCampaign = useRequest((params) => discountApi.addNewCampaign(params));
   const editCampaign = useRequest((params) => discountApi.updateCompaign(params, id));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         _getCampaign();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);
   const _getCampaign = () => {
      getCampaign.execute();
   };
   const submit = (values: any) => {
      if (id) {
         editCampaign.execute(values);
      } else {
         addCampaign.execute(values);
      }
   };

   return (
      <div>
         <Loader loading={addCampaign.loading || getCampaign.loading || editCampaign.loading} />
         {editCampaign.show.toast && <Toast show={editCampaign.show.toast} message={editCampaign.message} handleClose={() => editCampaign.close()} />}

         {addCampaign.show.modal && (
            <AlertDailog
               show={addCampaign.show.modal}
               title={addCampaign.message.message}
               message={addCampaign.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  history.push(ROUTE_LINKS.DISCOUNT_CAMPAIGN);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  addCampaign.close();
                  clearForm.current();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:campaign") : t("translations:new") + " " + t("translations:campaign")}
            status={getCampaign.data?.status}
         />

         <CommonButton
            title={t("translations:backButton")}
            onClick={() => {
               history.goBack();
            }}
            icon={<ArrowBack />}
         />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <NewCampaignForm handleSubmit={submit} data={getCampaign.data} clearForm={clearForm} />
         </Box>
      </div>
   );
};

export default NewCampaign;
