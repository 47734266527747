import { Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton, TextInput } from "..";
import { modal_action } from "../../../data/constants/button-action";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as yup from "yup";

interface ItemProps {
   onClick: (action: string, reason?: string) => void;
}

const CancelInvoiceItem: FC<ItemProps> = ({ onClick }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["invoice", "gen"]);

   const formik = useFormik({
      initialValues: {
         reason: "",
      },
      validationSchema: yup.object({
         reason: yup.string().min(10, t("invoice:inputError")).required(t("invoice:inputError")),
      }),
      onSubmit: (values) => {
         onClick(modal_action.CONFIRM, values.reason);
      },
   });

   return (
      <div className={globalClasses.paddingContainer}>
         <div className={globalClasses.alignCenter}>
            <ErrorOutline className={globalClasses.bigErrorIcon} />
         </div>
         <Typography className={`${globalClasses.textAlign} ${globalClasses.title}`}>{t("invoice:IrreversableAction")}</Typography>
         <Typography className={`${globalClasses.textAlign} ${globalClasses.errorText}`}>{t("invoice:attention")}</Typography>
         <Typography className={`${globalClasses.textAlign} ${globalClasses.cardTitle}`}>{t("invoice:reason")}</Typography>
         <div className={globalClasses.dividerMargin} />
         <TextInput
            title=""
            disabled={false}
            type="text"
            onChange={formik.handleChange}
            id="reason"
            error={formik.touched.reason && Boolean(formik.errors.reason)}
            helperText={formik.touched.reason && formik.errors.reason}
            value={formik.values.reason}
         />
         <div className={globalClasses.alignCenter}>
            <CommonButton title={t("gen:confirm")} onClick={formik.handleSubmit} color={COLORS.blue} />
            <span className={globalClasses.marginLeft} />
            <CommonButton title={t("gen:cancel")} onClick={() => onClick(modal_action.CANCEL)} color={COLORS.greyText} />
         </div>
      </div>
   );
};

export default CancelInvoiceItem;
