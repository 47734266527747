import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../components/molecules";
import { useRequest } from "../../data/api/wrapper";
import * as invoiceApi from "../../data/api/requests/invoice/index";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import { CommonButton } from "../../components/atoms";
import { ArrowBack } from "@material-ui/icons";
import { receiptStyles } from "./index.styles";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";

const Receipt = () => {
   const { t } = useTranslation(["invoice", "translations", "dashboardStrings"]);
   const { id } = useParams<{ id: string }>();
   const { state } = useLocation();
   const history = useHistory();

   const classes = receiptStyles();
   const globalClasses = globalStyles();

   const getInvoice = useRequest(() => invoiceApi.getInvoiceById(id));

   useEffect(() => {
      getInvoice.execute();
   }, []);

   return (
      <div>
         <TitleContainer title={t("invoice:creditNotes")} />
         <CommonButton icon={<ArrowBack />} title={t("translations:backButton")} onClick={() => history.goBack()} />
         <span className={globalClasses.marginLeft} />
         <CommonButton title={t("dashboardStrings:reservation")} onClick={() => history.push(`/reservation/form/${state}`)} />
         <Box className={classes.container}>
            {getInvoice?.data?.invoice?.pdfFileBase64 ? (
               <embed
                  src={`data:application/pdf;base64,${getInvoice?.data.invoice.pdfFileBase64}`}
                  type="application/pdf;base64,base64encodedpdf"
                  width="100%"
                  height="100%"
               />
            ) : (
               <Box className={globalClasses.redLabel}>
                  <Typography className={`${globalClasses.errorText}`}>{t("invoice:receiptError")}</Typography>
                  <Typography className={`${globalClasses.errorText}`}>{t("invoice:receiptErrorDesc1")}</Typography>
                  <Typography className={`${globalClasses.errorText}`}>
                     {t("invoice:receiptErrorDesc2")} #{id}
                  </Typography>
               </Box>
            )}
         </Box>
      </div>
   );
};

export default Receipt;
