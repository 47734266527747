import React from "react";
import { formatDate, formatTime } from "../../../utills/helper";

interface DateLabelProps {
   date?: string;
   showTimeNextLine?: boolean;
   time?: boolean;
}

const DateLabel = (props: DateLabelProps) => {
   const { date, showTimeNextLine, time } = props;
   if (date)
      return (
         <div>
            {formatDate(date)} {time && (showTimeNextLine ? <div>{formatTime(date)}</div> : formatTime(date))}
         </div>
      );
   return <div></div>;
};

export default DateLabel;
