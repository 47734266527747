import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import { ServiceForm } from "../../../components/organisms";
import { IconButton, Toast } from "../../../components/atoms";
import { ContainerTopBar, PopupModal, RequestDiscountItem, ServiceHeader, TitleContainer } from "../../../components/molecules/index";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../../data/api/wrapper";
import * as serviceApi from "../../../data/api/requests/reservation-services/index";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import * as discountApi from "../../../data/api/requests/disscounts/index";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { Alert, Loader, TableItem } from "../../../components/atoms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { formatDateTime } from "../../../utills/helper";
import { Delete } from "@material-ui/icons";
import { COLORS } from "../../../data/constants/Colors";
import { API_ROUTES } from "../../../data/api/apiRoutes";
import DiscountBillable from "../../../data/models/billable-discount.modal";

const ReservationServices = () => {
   const globalClasses = globalStyles();

   const history = useHistory();
   const { reservationId } = useParams<{ reservationId: string }>();
   const { t } = useTranslation("translations");

   const [reservationDiscounts, setReservationDiscounts] = useState([] as DiscountBillable[]);

   const reservation = useRequest((params) => reservationApi.getReservationById(params));
   const services = useRequest((params) => serviceApi.getReservationService(params));
   const addService = useRequest((resId, params) => serviceApi.addNewReservationService(resId, params));
   const requestDiscount = useRequest((params) => discountApi.requestDiscount(params));

   const clearInput = useRef() as MutableRefObject<() => void>;

   const [loading, setLoading] = useState(false);
   const [showDiscountReqModal, setShowDiscountReqModal] = useState(false);
   const [item, setItem] = useState<number>();

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (addService.data) {
         services.setData(addService.data);
         clearInput.current();
      }
   }, [addService.data]);

   const getData = () => {
      reservation.execute(reservationId);
      services.execute(reservationId);
      getReservationDiscounts();
   };

   const getReservationDiscounts = async () => {
      const tempResDiscount = await discountApi.getDiscountsByReservation(reservationId, 2);
      setReservationDiscounts(tempResDiscount.data);
   };

   const createService = (item: ReturnType<any>) => {
      addService.execute(reservationId, item);
   };

   const reqDiscount = (values: any) => {
      requestDiscount.execute({ ...values, typeId: 2, reservationId: reservationId, itemId: [item] });
      setShowDiscountReqModal(false);
   };

   const deleteReservationService = async (id: number) => {
      setLoading(true);
      serviceApi
         .deleteReservationService(reservationId, id.toString())
         .then((res) => {
            services.setData((prev) => prev?.filter((el) => el.id != id));
         })
         .catch((err) => {
            throw Error(err);
         })
         .finally(() => setLoading(false));
   };

   return (
      <div>
         <Loader loading={addService.loading || loading || requestDiscount.loading} />
         {requestDiscount.show.toast && (
            <Toast
               show={requestDiscount.show.toast}
               handleClose={() => {
                  requestDiscount.close();
                  getReservationDiscounts();
               }}
               message={requestDiscount.message}
            />
         )}

         {addService.show.toast && (
            <Toast
               show={addService.show.toast}
               message={addService.message}
               handleClose={() => {
                  addService.close();
                  // services.execute(reservationId);
               }}
            />
         )}

         <PopupModal
            show={showDiscountReqModal}
            render={() => <RequestDiscountItem handleClose={() => {}} handleSubmit={reqDiscount} />}
            handleClose={() => {
               setShowDiscountReqModal(false);
            }}
         />

         <TitleContainer title={t("services")} />

         <ServiceHeader
            reservation={reservation?.data}
            onBackPress={() => {
               history.push(`/reservation/form/${reservationId}`);
            }}
         />

         <ServiceForm
            handleSubmit={createService}
            discounts={reservationDiscounts || []}
            onTransactionsClick={() => {
               history.push(`/reservation/transactions/${reservationId}`);
            }}
            onReqDiscountClick={() => {
               if (item) {
                  setShowDiscountReqModal(true);
               }
            }}
            onItemSelect={(id) => setItem(id)}
            clearForm={clearInput}
         />

         <ContainerTopBar title={t("listing")}>
            <DataTable data={services.data || []} search={false}>
               <TableColumn headerText="ID" render={(item) => item.id} />

               <TableColumn
                  headerText={t("date")}
                  render={(item) => (
                     <TableItem title={formatDateTime(item.registration)} subtitle={`Created By: ${item.author ? item.author?.name : ""}`} />
                  )}
               />

               <TableColumn
                  headerText={t("description")}
                  render={(item) => (
                     <>
                        <span>{item.name} </span>
                        <span className={globalClasses.tableSmallText}>{item.item.description}</span>
                     </>
                  )}
               />

               <TableColumn headerText={t("quantity")} render={(item) => item.quantity} />

               <TableColumn headerText={t("value")} render={(item) => item.itemValue} />

               <TableColumn headerText={t("discount")} render={(item) => item.discount} />

               <TableColumn headerText={t("vat")} render={(item) => `${item.itemTax}%`} />

               <TableColumn
                  headerText={t("vat")}
                  render={(item) =>
                     !item.transaction && (
                        <IconButton
                           icon={<Delete className={globalClasses.tableSmallIcon} />}
                           color={COLORS.redLabel}
                           onClick={() => {
                              deleteReservationService(item.id);
                           }}
                        />
                     )
                  }
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default ReservationServices;
