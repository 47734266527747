import { Box } from "@material-ui/core";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as bannerApi from "../../../data/api/requests/banners/index";
import { TitleContainer } from "../../../components/molecules";
import { BannerForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { Alert, AlertDailog, Loader, Toast } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { CheckCircleOutline } from "@material-ui/icons";

function AddNewBanner() {
   const globalClasses = globalStyles();

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const { t } = useTranslation(["translations", "users"]);

   const banner = useRequest((params) => bannerApi.getBannerByID(params));
   const newBanner = useRequest((params) => bannerApi.saveBanner(params));
   const updateBanner = useRequest((params) => bannerApi.updateBanner(params, id));

   const clearForm = useRef() as MutableRefObject<() => void>;

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         banner.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const submit = (data: any) => {
      if (id) {
         updateBanner.execute(data);
      } else {
         newBanner.execute(data);
      }
   };
   return (
      <div>
         <Loader loading={banner.loading || newBanner.loading || updateBanner.loading} />

         {updateBanner.show.toast && (
            <Toast
               show={updateBanner.show.toast}
               message={updateBanner.message}
               handleClose={() => {
                  updateBanner.close();
               }}
            />
         )}

         {newBanner.show.modal && (
            <AlertDailog
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               show={newBanner.show.modal}
               title={newBanner.message.message}
               message={newBanner.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  newBanner.close();
                  history.push(ROUTE_LINKS.APP_BANNERS);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  newBanner.close();
                  clearForm.current();
               }}
            />
         )}

         <TitleContainer title={t("translations:new") + t("translations:banner")} />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <BannerForm banner={banner?.data} onSubmit={submit} clearForm={clearForm} />
         </Box>
      </div>
   );
}
export default AddNewBanner;
