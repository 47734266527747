import React, { FC } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import CarDropdown from "../../../data/models/car-dropdown.model";
import Country from "../../../data/models/country.model";
import { countryToFlag } from "../../../utills/helper";

interface DropDownItemProps {
  country?: Country;
  car?: CarDropdown;
  phone?: boolean;
}

const DropdownItem: FC<DropDownItemProps> = ({ country, car, phone }) => {
  const globalClasses = globalStyles();
  return (
    <div>
      {country && <span>{countryToFlag(country?.shortName)}</span>}
      {car && (
        <span className={globalClasses.greyBox}>{car?.licensePlate}</span>
      )}
      {phone && (
        <span className={globalClasses.marginLeft}>{country?.phoneCode}</span>
      )}
      <span className={globalClasses.marginLeft}>
        {country?.name || car?.name}
      </span>
    </div>
  );
};

export default DropdownItem;
