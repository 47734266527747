import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FC } from "react";

interface ToastProps {
  show: boolean;
  message: { message: string; error: boolean };
  handleClose: () => void;
}

const Toast: FC<ToastProps> = ({ show, message, handleClose }) => {
  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={show}
        key={"simple-toast"}
        autoHideDuration={3000}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
        onClose={handleClose}
      >
        <Alert color={message.error ? "error" : "success"}>
          {message.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Toast;
