import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";
const drawerWidth = 220;
export const sidebarStyles = makeStyles((theme: Theme) =>
   createStyles({
      drawer: {
         [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
         },
      },
      toolBar: {
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
         alignItems: "center",
      },
      appBar: {
         height: 50,
         backgroundColor: theme.palette.background.paper,
         [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
         },
      },
      menuButton: {
         marginRight: theme.spacing(2),
         marginLeft: theme.spacing(1),
         [theme.breakpoints.up("sm")]: {
            display: "none",
         },
      },
      // necessary for content to be below app bar
      // toolbar: theme.mixins.toolbar,
      drawerPaper: {
         border: "none",
         position: "fixed",
         top: "0",
         bottom: "0",
         left: "0",
         width: drawerWidth,
         // backgroundColor: "rgba(36,48,63,0.9)",
      },
      userControlContainer: { paddingTop: 5 },
      formControl: {
         minWidth: 80,
      },
      root: {},
      drawerContainer: {
         padding: 8,
         borderRightWidth: 1,
         borderRightColor: COLORS.greyBorder,
         borderRightStyle: "solid",
      },
      activeIcon: { minWidth: 25 },
      icon: { color: COLORS.greyBorder },
      select: {
         backgroundColor: "transparent",
         borderRadius: 3,
         borderWidth: 0,
         color: COLORS.iconInactive,
      },
      active: {
         opacity: 0.7,
         height: 45,
         paddingLeft: 10,
         // paddingRight: 10,
         "&:hover": {
            opacity: 1,
            backgroundColor: COLORS.menuActive,
            borderRadius: 6,
            // margin: 10,
         },
      },
      selectedMenuItem: {
         height: 45,
         paddingLeft: 10,
         opacity: 1,
         backgroundColor: COLORS.menuActive,
         borderRadius: 6,
         fontWeight: "bold",
      },
      subMenu: {
         paddingLeft: 20,
         height: 30,
         marginTop: 3,
         opacity: 0.7,
         "&:hover": {
            opacity: 1,
            backgroundColor: COLORS.menuActive,
            borderRadius: 6,
         },
      },
      selectedSubMenu: {
         paddingLeft: 20,
         height: 30,
         marginTop: 3,
         opacity: 1,
         backgroundColor: COLORS.menuActive,
         borderRadius: 6,
         "&:hover": {
            opacity: 1,
            backgroundColor: COLORS.menuActive,
            borderRadius: 6,
         },
      },
      listItem: {
         color: COLORS.iconInactive,
         fontSize: 20,
         padding: 0,
      },
      text: {
         color: COLORS.iconInactive,
         fontSize: 12,
      },
   })
);
