import { Box, Typography } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { userCardStyles } from "./index.styles";

interface CardItemProps {
   title?: string;
   type?: string;
   expiry?: string;
   cardNumber?: string;
   remove: () => void;
}

const UserCardItem: FC<CardItemProps> = ({ title, remove, type, expiry, cardNumber }) => {
   const globalClasses = globalStyles();
   const classes = userCardStyles();
   const { t } = useTranslation(["gen"]);

   return (
      <Box className={classes.container}>
         <Box className={classes.topLabel}>
            <Typography className={globalClasses.cardTitle}>{title}</Typography>
         </Box>
         <Box className={`${globalClasses.paddingContainer} ${classes.body}`}>
            <Typography className={classes.bodyText}>{type?.toUpperCase()}</Typography>
            <Typography className={classes.bodyText}>{expiry}</Typography>
            <Typography className={classes.bodyText}>{cardNumber}</Typography>
         </Box>
         <Box>
            <span className={classes.removeButton} onClick={remove}>
               <Delete className={globalClasses.errorText} />
               <Typography className={globalClasses.errorText}>{t("gen:remove")}</Typography>
            </span>
         </Box>
      </Box>
   );
};

export default UserCardItem;
