import { FC } from "react";
import { Box, Grid } from "@material-ui/core";
import Carousel from "react-material-ui-carousel";
import { carouselStyles } from "./index.styles";
import { COLORS } from "../../../data/constants/Colors";
import Banner from "../../../data/models/banner.model";
import { useTranslation } from "react-i18next";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { TableDropDown } from "../../atoms";
import moment from "moment";
import { formatDate } from "../../../utills/helper";

interface BannerProps {
   banners: Banner[] | undefined;
   deleteBanner: (item: Banner, index: number) => void;
   editBanner: (item: Banner) => void;
}

const BannersCarousel: FC<BannerProps> = ({ banners, deleteBanner, editBanner }) => {
   const classes = carouselStyles();

   const { t } = useTranslation(["translations", "gen", "vehicles", "users"]);

   const onActionClick = (action: string, item: any, index: number) => {
      switch (action) {
         case t("translations:edit"):
            editBanner(item);
            break;
         case t("gen:remove"):
            deleteBanner(item, index);
            break;
         default:
            break;
      }
   };

   return (
      <Grid container spacing={3}>
         <Grid item xl={5} lg={5} md={12} sm={12} xs={12}>
            <Carousel
               navButtonsProps={{
                  // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                  style: {
                     backgroundColor: COLORS.primary,
                     borderRadius: 30,
                  },
               }}
               indicatorIconButtonProps={{
                  style: {
                     padding: "1px", // 1
                     color: COLORS.greyBorder, // 3
                  },
               }}
               activeIndicatorIconButtonProps={{
                  style: {
                     color: COLORS.greenLabel,
                  },
               }}
               indicatorContainerProps={{
                  style: {
                     marginTop: "5px", // 5
                     textAlign: "center", // 4
                  },
               }}
            >
               {banners?.map((item, i) => (
                  <Box key={i} border={1} borderColor={COLORS.greyBorder} className={classes.carouselContainer}>
                     <img src={item.file} height={"90%"} width={"90%"} />
                  </Box>
               ))}
            </Carousel>
         </Grid>
         <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
            <div style={{ height: "65vh" }}>
               <DataTable data={banners || []} search={false}>
                  <TableColumn headerText="ID" render={(item) => item.id} />
                  <TableColumn headerText={t("vehicles:registration")} render={(item) => formatDate(item.registration)} />
                  <TableColumn headerText={t("users:name")} render={(item) => item.name} />
                  <TableColumn
                     headerText={t("translations:action")}
                     render={(item, index) => (
                        <TableDropDown
                           options={[t("translations:edit"), t("gen:remove")]}
                           onClick={(action) => {
                              onActionClick(action, item, index);
                           }}
                        />
                     )}
                  />
               </DataTable>
            </div>
         </Grid>
      </Grid>
   );
};

export default BannersCarousel;
