import moment from "moment";
import User from "../data/models/user.model";
import { postCodeString, taxIdString } from "./masks";

interface Documents {
   countryId: number;
   value: string;
   expirationDate: string;
   typeId: number;
   issueDate: string;
}

interface ReturnType {
   profileImage: string;
   stateId: number;
   countryId: number;
   cityId: number;
   nationalityId: number;
   phoneCountryId: number;
   name: string;
   email: string;
   phone: string;
   alternateCityName: string;
   taxId: string;
   neighborhood: string;
   address: string;
   birthdate: string;
   postalCode: string;
   disableRequireDocs: boolean;
   documents: {
      countryId: number;
      value: string;
      expirationDate: string;
      typeId: number;
      issueDate: string;
   }[];
}

export const returnUser = (user: User): ReturnType => {
   console.log(user);
   let tempDoc: Documents[] = [];

   if (user.documents.length != 0) {
      user.documents.map((el) => {
         let tmpDoc = {
            countryId: el.country.id || 0,
            value: el.value || "",
            expirationDate: el.expirationDate ? moment(el.expirationDate).format("YYYY-MM-DD") : "",
            typeId: el.typeId,
            issueDate: el.issueDate ? moment(el.issueDate).format("YYYY-MM-DD") : "",
         };
         tempDoc[el.typeId - 1] = tmpDoc;
      });
   } else {
      const nullDoc = {
         countryId: 0,
         value: "",
         expirationDate: "",
         issueDate: "",
      };
      [1, 2, 3].map((el) => tempDoc.push({ ...nullDoc, typeId: el }));
   }

   let tempUser = {
      profileImage: user.profileImage || "",
      stateId: user.state.id || 0,
      countryId: user.country.id || 0,
      cityId: user.city.id || 0,
      nationalityId: user.nationalityId.id || 0,
      phoneCountryId: user.phoneCountry.id || 0,
      name: user.name || "",
      email: user.email || "",
      phone: user.phone || "",
      taxId: user.taxId ? taxIdString(user.taxId) : "",
      neighborhood: user.neighborhood || "",
      address: user.address || "",
      birthdate: user.birthdate ? moment(user.birthdate).format("YYYY-MM-DD") : moment(new Date().getTime()).format("YYYY-MM-DD"),
      postalCode: user.postalCode ? postCodeString(user.postalCode) : "",
      disableRequireDocs: user.disableRequireDocs ? user.disableRequireDocs : false,
      documents: tempDoc,
      alternateCityName: user.alternateCityName || "",
   };
   return tempUser;
};
