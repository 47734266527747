import { Divider, Grid, Slider, Typography } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../../../data/api/wrapper";
import { AutoComplete, CheckBox, CommonButton, InputLabel, TextArea, DropdownItem, TextInput, CurrencyInput } from "../../../atoms";
import ModalComponent from "../../modals/alertModal/AlertModal";
import * as dropdownApi from "../../../../data/api/requests/dropdowns/index";
import { useFormik } from "formik";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import moment from "moment";
import { useValidation } from "../../../../utills/validation";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { currency, normalizeCurrency, putDecimalPoints } from "../../../../utills/masks";
import { calculateDays } from "../../../../utills/calculator";
import { encodeDate } from "../../../../utills/utills";
import Reservation from "../../../../data/models/reservation.model";
import { DisableProps } from "../../../../pages/reservations/newReservation/NewReservation";
import { COLORS } from "../../../../data/constants/Colors";
import { Block, Error, Flag, Save } from "@material-ui/icons";
import { actions, reservation_actions, route_actions } from "../../../../data/constants/button-action";
import { TransferTariff } from "../../../../data/models/transfer-tax.model";
import CarCategory from "../../../../data/models/car-category.model";
import { useCheckUserForRole } from "../../../../data/user-fields/useCheckUserForRole";
import { ConfirmItem, PopupModal } from "../../../molecules";

interface ResFormProps {
   onNavigateClick: (action: string, id: number | null | undefined) => void;
   handleSubmit: (values: any) => void;
   reservation?: Reservation;
   renderInputs?: DisableProps;
   onReservationActionClick: (action: string, id: number | undefined) => void;
   getTranferTariff?: (pickupId: number, returnId: number) => void;
   tranferTariff?: TransferTariff;
   saveDossier: (dossier: string) => void;
}

const NewReservationForm: FC<ResFormProps> = ({
   onNavigateClick,
   handleSubmit,
   reservation,
   renderInputs,
   onReservationActionClick,
   getTranferTariff,
   tranferTariff,
   saveDossier,
}) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "gen", "vehicles"]);

   const [modalActive, setModalActive] = useState<boolean>(false);

   const { reservationSchema } = useValidation();

   const { counters, tariffs, status, billableItems, paymentMethods } = useDropdown();

   const { modalFields, openModal, userTyp, getData, close, resetValue } = useCheckUserForRole();

   const companies = useRequest((params) => dropdownApi.getOrganizations(params));
   const responsible = useRequest((params) => dropdownApi.getUsers(params));
   const mainDriver = useRequest((params) => dropdownApi.getUsers(params));
   const secondDriver = useRequest((params) => dropdownApi.getUsers(params));
   const campaigns = useRequest((params) => dropdownApi.getDropdownCampaigns(params));
   const categories = useRequest((params) => dropdownApi.getVehicleCategories(params));
   const cars = useRequest((params) => dropdownApi.getAllCars(params));

   const [priceWithOutTax, setPriceWithOutTax] = useState<number>(0);
   const [priceWithTax, setPriceWithTax] = useState<number>(0);
   const [carCategory, setCarCategory] = useState({} as CarCategory);
   const [days, setDays] = useState<{ days: number; hours: number }>({ days: 1, hours: 0 });

   const formik = useFormik({
      initialValues: {
         organizationId: !!reservation?.organization ? reservation?.organization.id : null,
         userId: !!reservation?.user ? reservation?.user.id : null,
         mainDriverId: !!reservation?.mainDriver ? reservation?.mainDriver.id : null,
         additionalDriverId: !!reservation?.additionalDriver ? reservation?.additionalDriver.id : null,
         pickupOrganizationId: !!reservation?.pickupOrganization ? reservation?.pickupOrganization.id : 1,
         returnOrganizationId: !!reservation?.returnOrganization ? reservation?.returnOrganization.id : 1,
         startDate: reservation?.startDate ? moment(reservation?.startDate).format("YYYY-MM-DD") : moment(new Date().getTime()).format("YYYY-MM-DD"),
         endDate: reservation?.endDate
            ? moment(reservation?.endDate).format("YYYY-MM-DD")
            : moment(new Date().getTime() + 24 * 60 * 60 * 1000).format("YYYY-MM-DD"),
         startTime: reservation?.startDate ? moment(reservation?.startDate).format("HH:mm") : moment(new Date().getTime()).format("HH:mm"),
         endTime: reservation?.endDate ? moment(reservation?.endDate).format("HH:mm") : moment(new Date().getTime()).format("HH:mm"),
         groupId: reservation?.group ? reservation.group.id : null,
         categoryId: reservation?.category ? reservation.category.id : null,
         carId: reservation?.car ? reservation.car.carId : null,
         statusId: reservation?.status ? reservation.status.id : 1,
         securityDeposit: reservation?.securityDeposit ? putDecimalPoints(reservation.securityDeposit) : `100`,
         paymentMethodId: reservation?.paymentMethod ? reservation.paymentMethod.id : null,
         dossier: reservation?.dossier ? reservation.dossier : "",
         checkOvertime: reservation?.checkOvertime ? reservation.checkOvertime : false,
         campaignId: null,
         note: reservation?.notes ? reservation.notes : "",
         optionals: [],
         carChanged: false,
         fuelLevel: null,
         mileage: 0,
      },
      enableReinitialize: true,
      validationSchema: reservationSchema,
      validateOnBlur: false,
      validateOnMount: false,
      onSubmit: (values) => {
         let newObject: any = {
            ...values,
            securityDeposit: normalizeCurrency(values.securityDeposit),
            startDate: encodeDate(values.startDate, values.startTime),
            endDate: encodeDate(values.endDate, values.endTime),
         };
         if (!values.carChanged) {
            delete newObject["fuelLevel"];
            delete newObject["mileage"];
         }
         delete newObject["carChanged"];
         delete newObject["startTime"];
         delete newObject["endTime"];
         console.log(formik.values);
         handleSubmit(newObject);
      },
   });

   useEffect(() => {
      if (reservation) {
         getDopdownFields();
      }
   }, [reservation]);

   useEffect(() => {
      const { priceWithOutVat, dayDuration, priceWithVat, hours } = calculateDays(
         formik.values.startDate,
         formik.values.startTime,
         formik.values.endDate,
         formik.values.endTime,
         tranferTariff,
         carCategory,
         formik.values.checkOvertime
      );
      setPriceWithOutTax(priceWithOutVat || 0);
      setDays({ days: dayDuration || 0, hours: hours });
      setPriceWithTax(priceWithVat || 0);
   }, [
      formik.values.startDate,
      formik.values.startTime,
      formik.values.endDate,
      formik.values.endTime,
      formik.values.categoryId,
      tranferTariff,
      formik.values.checkOvertime,
   ]);

   useEffect(() => {
      if (formik.values.pickupOrganizationId && formik.values.returnOrganizationId) {
         !!getTranferTariff && getTranferTariff(formik.values.pickupOrganizationId, formik.values.returnOrganizationId);
      }
   }, [formik.values.pickupOrganizationId, formik.values.returnOrganizationId]);

   const checkUserFields = async (id: number | undefined, userField: string) => {
      if (id) {
         await getData(id, userField);
      }
   };

   const checkUserUsage = () => {
      if (resetValue && userTyp === "userId") {
         formik.setFieldValue("userId", null);
      } else if (resetValue && userTyp === "mainDriverId") {
         formik.setFieldValue("mainDriverId", null);
      } else if (resetValue && userTyp === "additionalDriverId") {
         formik.setFieldValue("additionalDriverId", null);
      }
   };

   const getDopdownFields = () => {
      companies.execute(!!reservation?.organization ? reservation?.organization.name : "");
      responsible.execute(!!reservation?.user ? reservation?.user.name : "");
      mainDriver.execute(reservation?.mainDriver ? reservation?.mainDriver.name : "");
      if (reservation?.additionalDriver) {
         secondDriver.execute(reservation?.additionalDriver.name);
      }
      categories.execute(!!reservation?.group ? reservation.group.id : 0);
      cars.execute(!!reservation?.category ? reservation.category.id : 0);
   };

   const labelIconClick = (action: string, value: number | null) => {
      if (!value || value == 0) {
         setModalActive(true);
      } else {
         onNavigateClick(action, value);
      }
   };

   const handleModalButtonClick = (action: string) => {
      close();
      checkUserUsage();
   };

   return (
      <div className={globalClasses.paddingContainer}>
         {openModal && (
            <PopupModal
               show={openModal}
               handleClose={() => {
                  close();
               }}
               render={() => (
                  <ConfirmItem
                     icon={<Error className={globalClasses.modalIcon} />}
                     title={modalFields.title}
                     subtitle={modalFields.body}
                     handleClose={handleModalButtonClick}
                  />
               )}
            />
         )}
         <ModalComponent open={modalActive} handleClose={() => setModalActive(false)} />
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel
                  label={`${t("translations:company")}:`}
                  actionIcon={true}
                  onClick={() => {
                     labelIconClick("translations:company", formik.values.organizationId);
                  }}
               />
               <AutoComplete
                  label=""
                  placeholder=""
                  defaultValue={companies.data?.data?.find((el) => el.id == formik.values.organizationId)}
                  loading={companies.loading}
                  disabled={renderInputs?.all}
                  onInputChange={(text) => {
                     if (text.length > 1) {
                        console.log("kdhaksjdbjksa");
                        companies.execute(text);
                     }
                  }}
                  options={companies.data?.data || []}
                  getOptionLabel={(item) => item.officialName}
                  renderOption={(item) => item.officialName}
                  onChange={(_, item) => {
                     formik.setFieldValue("organizationId", item?.id);
                  }}
               />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel
                  label={`${t("translations:responsible")}:`}
                  actionIcon={true}
                  onClick={() => {
                     labelIconClick(route_actions.USER, formik.values.userId);
                  }}
               />
               <AutoComplete
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  onInputChange={(text) => {
                     if (text.length > 2) {
                        responsible.execute(text);
                     }
                  }}
                  options={responsible.data || []}
                  defaultValue={responsible.data?.find((el) => el.id == formik.values.userId)}
                  getOptionLabel={(item) => `${item.name} - ${item.taxId}`}
                  renderOption={(item) => `${item.name} - ${item.taxId}`}
                  onChange={(_, item) => {
                     formik.setFieldValue("userId", item?.id);
                     // formik.setFieldValue("mainDriverId", item?.id);
                     mainDriver.execute(item?.name);
                     checkUserFields(item?.id, "userId");
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.userId}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel
                  label={`${t("translations:mainDriver")}:`}
                  actionIcon={true}
                  onClick={() => {
                     labelIconClick(route_actions.USER, formik.values.mainDriverId);
                  }}
               />
               <AutoComplete
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  defaultValue={mainDriver.data?.find((item) => item.id === formik.values.mainDriverId)}
                  onInputChange={(text) => {
                     if (text.length > 2) {
                        mainDriver.execute(text);
                     }
                  }}
                  options={mainDriver.data || []}
                  getOptionLabel={(item) => `${item.name} - ${item.taxId}`}
                  renderOption={(item) => `${item.name} - ${item.taxId}`}
                  onChange={(_, item) => {
                     formik.setFieldValue("mainDriverId", item?.id);
                     checkUserFields(item?.id, "mainDriverId");
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.mainDriverId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel
                  label={`${t("translations:secondDriver")}:`}
                  actionIcon={true}
                  onClick={() => {
                     labelIconClick(route_actions.USER, formik.values.additionalDriverId);
                  }}
               />
               <AutoComplete
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  onInputChange={(text) => {
                     if (text.length > 2) {
                        secondDriver.execute(text);
                     }
                  }}
                  options={secondDriver.data || []}
                  getOptionLabel={(item) => `${item.name} - ${item.taxId}`}
                  renderOption={(item) => `${item.name} - ${item.taxId}`}
                  onChange={(_, item) => {
                     formik.setFieldValue("additionalDriverId", item?.id);
                     checkUserFields(item?.id, "additionalDriverId");
                  }}
               />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:pickupIn")}:`} />
               <AutoComplete
                  defaultValue={counters.find((el) => el.id == formik.values.pickupOrganizationId)}
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  onInputChange={(text) => {}}
                  options={counters || []}
                  getOptionLabel={(item) => item.officialName}
                  renderOption={(item) => item.officialName}
                  onChange={(_, item) => {
                     formik.setFieldValue("pickupOrganizationId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.pickupOrganizationId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:pickupDate")}:`} />
                     <TextInput
                        disabled={renderInputs?.pickupDate}
                        title=""
                        id="startDate"
                        type="date"
                        error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                        helperText={formik.touched.startDate && formik.errors.startDate}
                        value={formik.values.startDate}
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:hour")}`} />
                     <TextInput
                        disabled={renderInputs?.pickupDate}
                        title=""
                        id="startTime"
                        type="time"
                        error={formik.touched.startTime && Boolean(formik.errors.startTime)}
                        helperText={formik.touched.startTime && formik.errors.startTime}
                        value={formik.values.startTime}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:deliverIn")}:`} />
               <AutoComplete
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  defaultValue={counters.find((el) => el.id === formik.values.returnOrganizationId)}
                  options={counters || []}
                  getOptionLabel={(item) => item.officialName}
                  renderOption={(item) => item.officialName}
                  onChange={(_, item) => formik.setFieldValue("returnOrganizationId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.returnOrganizationId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:deliveryDate")}:`} />
                     <TextInput
                        disabled={renderInputs?.all}
                        title=""
                        id="endDate"
                        type="date"
                        error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        value={formik.values.endDate}
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={`${t("translations:hour")}`} />
                     <TextInput
                        disabled={renderInputs?.all}
                        title=""
                        id="endTime"
                        type="time"
                        error={formik.touched.endTime && Boolean(formik.errors.endTime)}
                        helperText={formik.touched.endTime && formik.errors.endTime}
                        value={formik.values.endTime}
                        onChange={formik.handleChange}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:teriff")}`} />
               <AutoComplete
                  label=""
                  placeholder=""
                  disabled={renderInputs?.all}
                  defaultValue={tariffs.find((el) => el.id === formik.values.groupId)}
                  options={tariffs || []}
                  getOptionLabel={(item) => item.name}
                  renderOption={(item) => item.name}
                  onChange={(_, item) => {
                     categories.execute(item?.id);
                     formik.setFieldValue("groupId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.groupId}</Typography>
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:category")}`} />
               <AutoComplete
                  label=""
                  placeholder=""
                  loading={categories.loading}
                  defaultValue={categories.data?.data?.find((el) => el.id === formik.values.categoryId)}
                  disabled={renderInputs?.all}
                  options={categories.data?.data || []}
                  getOptionLabel={(item) => `${item.cta} - ${item.name}`}
                  renderOption={(item) => `${item.name} - ${item.cta}`}
                  onChange={(_, item) => {
                     if (item) {
                        setCarCategory(item);
                     }
                     formik.setFieldValue("categoryId", item?.id);
                     formik.setFieldValue("carId", null);
                     campaigns.execute(item?.id);
                     cars.execute(item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.categoryId}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:vehicle")}:`} />
               <AutoComplete
                  label=""
                  placeholder=""
                  defaultValue={cars.data?.data?.find((el) => el.id == formik.values.carId)}
                  loading={cars.loading}
                  disabled={renderInputs?.all}
                  options={cars.data?.data || []}
                  getOptionLabel={(item) => item.name}
                  renderOption={(item) => <DropdownItem car={item} />}
                  onChange={(_, item) => {
                     formik.setFieldValue("carId", item?.id);
                     if (renderInputs?.edit && item?.id !== reservation?.car.carId) {
                        formik.setFieldValue("carChanged", true);
                     }
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.carId}</Typography>
               {renderInputs?.buttons && formik?.values?.carId != reservation?.car?.carId && (
                  <Grid container spacing={3} className={globalClasses.margin}>
                     <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <InputLabel label={t("vehicles:mileage")} />
                        <TextInput
                           title=""
                           disabled={false}
                           id="mileage"
                           type="number"
                           onChange={formik.handleChange}
                           error={formik.touched.mileage && Boolean(formik.errors.mileage)}
                           helperText={formik.touched.mileage && formik.errors.mileage}
                        />
                     </Grid>
                     <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                        <InputLabel label={t("vehicles:fuelLevel")} />
                        <Slider
                           aria-label="fuel-level"
                           defaultValue={8}
                           // getAriaValueText={valuetext}
                           valueLabelDisplay="auto"
                           step={1}
                           marks
                           min={0}
                           max={8}
                           onChange={(e, value) => {
                              formik.setFieldValue("fuelLevel", value);
                           }}
                        />
                     </Grid>
                  </Grid>
               )}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:optionals")}:`} />
               {renderInputs?.edit && (
                  <>
                     <div>
                        {reservation?.optionals.map((el, index) => (
                           <span className={`${globalClasses.greyBox} ${globalClasses.marginLeft}`} key={`optional-${index}`}>
                              {el}
                           </span>
                        ))}
                     </div>
                     <span className={globalClasses.marginSmall} />
                     <CommonButton
                        color={COLORS.greyText}
                        title={t("gen:change")}
                        onClick={() => {
                           onNavigateClick(route_actions.SERVICES, reservation?.id);
                        }}
                     />
                  </>
               )}
               {!renderInputs?.edit && (
                  <AutoComplete
                     label=""
                     multiple
                     placeholder=""
                     disabled={renderInputs?.all}
                     onInputChange={(text) => {}}
                     options={billableItems || []}
                     getOptionLabel={(item) => item.name}
                     renderOption={(item) => `${item.name} - ${item.value} `}
                     onChange={(_, item) => {
                        let items: number[] = [];
                        item.map((el) => items.push(el.id));
                        formik.setFieldValue("optionals", items);
                     }}
                  />
               )}
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={`${t("translations:frunchiasePaymentMethod")}:`} />
               <AutoComplete
                  label=""
                  placeholder=""
                  defaultValue={paymentMethods.find((el) => el.id == formik.values.paymentMethodId)}
                  disabled={renderInputs?.all}
                  options={paymentMethods || []}
                  getOptionLabel={(item) => item.name}
                  renderOption={(item) => item.name}
                  onChange={(_, item) => formik.setFieldValue("paymentMethodId", item?.id)}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={`${t("translations:frunchaiseValue")}:`} />
               <CurrencyInput
                  disabled={renderInputs?.all}
                  title=""
                  id="securityDeposit"
                  type="text"
                  helperText={""}
                  value={formik.values.securityDeposit}
                  onChange={(value) => formik.setFieldValue("securityDeposit", value)}
               />
               {/* <TextInput
                  disabled={renderInputs?.all}
                  title=""
                  id="securityDeposit"
                  type="text"
                  helperText={""}
                  value={formik.values.securityDeposit}
                  onChange={(e) => formik.setFieldValue("securityDeposit", currency(e).target.value)}
               /> */}
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:reference")}`} />
               <div className={globalClasses.flexRow}>
                  <TextInput
                     disabled={false}
                     title=""
                     id="dossier"
                     type="text"
                     helperText={""}
                     value={formik.values.dossier}
                     onChange={formik.handleChange}
                  />
                  {renderInputs?.edit && (
                     <CommonButton
                        margin={5}
                        icon={<Save />}
                        color={COLORS.greyText}
                        title={t("translations:record")}
                        onClick={() => {
                           saveDossier(formik.values.dossier);
                        }}
                     />
                  )}
               </div>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />

         <CheckBox
            title={`${t("translations:chargeHour")}`}
            checked={formik.values.checkOvertime}
            setChecked={() => formik.setFieldValue("checkOvertime", !formik.values.checkOvertime)}
            disabled={renderInputs?.all}
         />

         <div>
            <InputLabel label={`${t("translations:campaign")}`} />
            <AutoComplete
               label=""
               placeholder=""
               disabled={renderInputs?.all}
               options={campaigns.data || []}
               loading={campaigns.loading}
               getOptionLabel={(item) => item.name}
               renderOption={(item) => item.name}
               onChange={(_, item) => {
                  formik.setFieldValue("campaignId", item?.id);
               }}
            />
         </div>
         <div className={globalClasses.margin}>
            <InputLabel label={`${t("translations:observation")}`} />
            <TextArea id="note" value={formik.values.note} onChange={formik.handleChange} disabled={renderInputs?.all} />
         </div>
         {!renderInputs?.edit && (
            <div className={globalClasses.alertContainer}>
               <Typography className={globalClasses.titleText}>
                  {t("translations:duration")}: {days.days} {t("translations:days")} {t("translations:and")} {days.hours} {t("translations:hours")} (
                  {t("translations:subTotal")}: € {priceWithOutTax})
               </Typography>
               <Typography className={globalClasses.titleText}>
                  {t("translations:vat")}: € {(priceWithTax - priceWithOutTax).toFixed(2)}
               </Typography>
               <Typography className={globalClasses.titleText}>
                  {t("translations:total")} {t("translations:with")} {t("translations:vat")}: € {priceWithTax}
               </Typography>
            </div>
         )}
         <Divider className={globalClasses.dividerMargin} />
         {renderInputs?.edit && (
            <Typography className={globalClasses.tableSmallText}>
               {t("translations:saleman")}: {""}
            </Typography>
         )}
         {!renderInputs?.edit && (
            <Grid container spacing={3}>
               <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <InputLabel label={`${t("translations:status")}`} />
                  <AutoComplete
                     label=""
                     placeholder=""
                     disabled={renderInputs?.all}
                     options={status || []}
                     getOptionLabel={(item) => item.name}
                     renderOption={(item) => item.name}
                     onChange={(_, item) => formik.setFieldValue("note", item?.id)}
                  />
               </Grid>
            </Grid>
         )}
         <Divider className={globalClasses.dividerMargin} />
         {!renderInputs?.edit && (
            <div>
               <CommonButton title={`${t("translations:save")}`} onClick={formik.handleSubmit} />
               <Typography variant="caption" color="secondary" className={globalClasses.marginLeft}>
                  {t("translations:warning")}: {t("translations:reservationWithOutGreenway")}
               </Typography>
            </div>
         )}
         {renderInputs?.buttons && (
            <div className={`${globalClasses.cardTitleCOntainer}`}>
               <span>
                  <CommonButton title={t("gen:saveChanges")} icon={<Save />} onClick={formik.handleSubmit} />
                  <span className={globalClasses.marginLeft} />
                  {!renderInputs?.start ? (
                     <CommonButton
                        color={COLORS.blue}
                        icon={<Flag />}
                        title={t("gen:startRental")}
                        onClick={() => {
                           onReservationActionClick(reservation_actions.START, reservation?.id);
                        }}
                     />
                  ) : (
                     <CommonButton
                        color={COLORS.blue}
                        icon={<Flag />}
                        title={t("gen:finalizeRental")}
                        onClick={() => {
                           onReservationActionClick(reservation_actions.FINISH, reservation?.id);
                        }}
                     />
                  )}
               </span>
               <CommonButton
                  color={COLORS.redLabel}
                  icon={<Block />}
                  title={t("gen:cancelReservation")}
                  onClick={() => handleSubmit(actions.CANCEL)}
               />
            </div>
         )}
      </div>
   );
};

export default NewReservationForm;
