import { Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import moment from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../data/models/reservation.model";
import { CommonButton } from "../../atoms";

interface ServiceProps {
   onBackPress: () => void;
   reservation: Reservation | undefined;
}

const ServiceHeader: FC<ServiceProps> = ({ onBackPress, reservation }) => {
   const { t } = useTranslation("translations");
   const globalClasses = globalStyles();
   return (
      <div className={globalClasses.alertContainer}>
         <CommonButton
            title={`${t("backTo")} ${t("booking")} ${reservation?.id}`}
            icon={<ArrowBack />}
            onClick={onBackPress}
            color={COLORS.primary}
         />
         <div className={globalClasses.margin} />
         <Typography className={globalClasses.smallErrorText}>
            <b>{t("period")}:</b> {moment(reservation?.startDate).format("DD/MM/YYYY HH:mm")} {t("to")}{" "}
            {moment(reservation?.endDate).format("DD/MM/YYYY HH:mm")}
         </Typography>
         <Typography className={globalClasses.smallErrorText}>
            <b>{t("mainDriver")}: </b> {reservation?.mainDriver.name}
         </Typography>
      </div>
   );
};

export default ServiceHeader;
