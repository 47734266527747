import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton, TableItem, StatusLabel, TableDropDown, Toast, Loader } from "../../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../../components/molecules";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import * as discountApi from "../../../../data/api/requests/disscounts/index";
import DataTable from "../../../../components/molecules/tables/datatable/DataTable";
import { useRequest } from "../../../../data/api/wrapper";
import TableColumn from "../../../../components/molecules/tables/table-column/TableColumn";
import { formatDate } from "../../../../utills/helper";
import Status from "../../../../data/models/status.model";
import { putDecimalPoints } from "../../../../utills/masks";

const Campaigns = () => {
   const history = useHistory();
   const { t } = useTranslation(["translations"]);

   const [pagination, setPagination] = useState({ page: 1, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");

   const discountCampaigns = useRequest(() => discountApi.getDiscountCampaign(pagination.page, pagination.size, searchQuery));
   const changeStatus = useRequest((params) => discountApi.changeCampaignStatus(params));

   useEffect(() => {
      discountCampaigns.execute();
   }, [pagination, searchQuery]);

   useEffect(() => {
      if (changeStatus.data) {
         changeCampaignLocally();
      }
   }, [changeStatus.data]);

   const changeCampaignLocally = () => {
      discountCampaigns.setData((prev) => {
         const index = prev?.data.findIndex((el) => el.id == changeStatus.data?.id);
         if (prev) {
            prev.data[index!!] = changeStatus.data!!;
         }
         return prev;
      });
   };

   const handleDropdownClick = (name: string, id: number, status: Status) => {
      switch (name) {
         case t("translations:edit"):
            history.push(`/discounts/campaign/form/${id}`);
            break;
         case t("translations:changeStatus"):
            changeStatus.execute(id);
            break;
         default:
            break;
      }
   };

   console.log(discountCampaigns.data);

   return (
      <div>
         <Loader loading={changeStatus.loading} />
         {changeStatus.show.toast && (
            <Toast
               show={changeStatus.show.toast}
               message={changeStatus.message}
               handleClose={() => {
                  changeStatus.close();
               }}
            />
         )}
         <TitleContainer title={t("translations:campaigns")} />

         <CommonButton
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_DISCOUNT_CAMPAIGN);
            }}
            icon={<AddCircle />}
         />

         <ContainerTopBar title={t("translations:listing")} activity={discountCampaigns.loading}>
            <DataTable
               data={discountCampaigns.data?.data || []}
               total={discountCampaigns.data?.total}
               pagination
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               onSearching={(query) => setSearchQuery(query)}
            >
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn
                  headerText={t("translations:nameDescription")}
                  render={(item) => <TableItem title={item.name} subtitle={item.description} />}
               />
               <TableColumn
                  headerText={t("translations:discountItem")}
                  render={(item) => (
                     <TableItem
                        title={`${putDecimalPoints(item.discount)}${item.isPercentage ? "%" : "€"} ${t("translations:in")}`}
                        subtitle={item.item.name}
                     />
                  )}
               />
               <TableColumn
                  headerText={t("translations:startEnd")}
                  render={(item) => <TableItem title={formatDate(item.registration)} subtitle={formatDate(item.endDate)} />}
               />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:status")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(name) => {
                           handleDropdownClick(name, item.id, item.status);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Campaigns;
