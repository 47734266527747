import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const messageStyles = makeStyles((theme: Theme) =>
  createStyles({
    //payment Cards
    title: { fontWeight: "bold", fontSize: 16, marginBottom: 10 },
    smallText: { fontSize: 10, marginTop: 15 },

    //New Message
    textArea: {
      borderColor: COLORS.greyBorder,
      fontSize: 17,
      fontWeight: "lighter",
      letterSpacing: 1,
      width: "100%",
    },
    checkboxText: {
      fontWeight: "lighter",
    },
    checkbox: { marginLeft: "10%" },
  })
);
