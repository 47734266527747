import { Box } from "@material-ui/core";
import { FC } from "react";
import Car from "../../../data/models/car.model";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import FuelLevel from "../../atoms/fuel-level/FuelLevel";

interface VehicleInParkProps {
   data: Car[] | undefined;
   onCarPlatePress: (id: number) => void;
}
const ParkedCars: FC<VehicleInParkProps> = ({ data, onCarPlatePress }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "vehicles"]);
   return (
      <Box className={globalClasses.container}>
         <DataTable data={data || []} search={false}>
            <TableColumn
               headerText={t("translations:vehicle")}
               render={(item) => (
                  <span className={`${globalClasses.greyBox} ${globalClasses.hoverPointer}`} onClick={() => onCarPlatePress(item.id)}>
                     {item.licensePlate}
                  </span>
               )}
            />
            <TableColumn headerText={t("vehicles:year")} render={(item) => item.year} />
            <TableColumn headerText={t("vehicles:model")} render={(item) => item.name} />
            <TableColumn headerText={t("vehicles:fuel")} render={(item) => <FuelLevel level={item.fuelLevel} />} />
         </DataTable>
      </Box>
   );
};

export default ParkedCars;
