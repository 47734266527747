import { makeStyles, Theme } from "@material-ui/core";

interface styleProps {
   color: string;
}

export const buttonStyles = makeStyles<Theme, styleProps>((props) => ({
   container: {
      padding: 6,
      background: ({ color }) => color,
      borderRadius: "0.2rem",
      "&:hover": {
         cursor: "pointer",
      },
   },
}));
