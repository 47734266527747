import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Check, Close, Refresh, Remove } from "@material-ui/icons";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, InputLabel, RadioButton, TextArea, TextInput } from "..";
import { invoice_modal_actions } from "../../../data/constants/button-action";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Service from "../../../data/models/reservation-service.model";
import { putDecimalPoints } from "../../../utills/masks";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import CommonButton from "../buttons/commonButton/CommonButton";

interface CreditProps {
   onClick: (action: string, reason?: { reason: string; type: string }) => void;
   items?: Service[];
   onRemove: (item: Service) => void;
   onChange: (item: Service, event: React.ChangeEvent<HTMLInputElement>) => void;
   loading?: boolean;
}

interface Props {
   text: string;
}

const CreditNoteItem: FC<CreditProps> = ({ onClick, items, onChange, onRemove, loading }) => {
   const globalClasses = globalStyles();
   const [reason, setReason] = useState({ reason: "", type: "canceling" });

   const { t } = useTranslation(["invoice", "transaction", "translations", "gen", "vehicles"]);

   const SmallText: FC<Props> = ({ text }) => {
      return <span className={globalClasses.tableSmallText}>{text}</span>;
   };

   const calculateTotal = () => {
      const tax = items?.reduce((a, item) => (a += (item.itemValue - item.discount) * item.quantity * (item.itemTax / 100 + 1)), 0);
      return tax;
   };
   if (items?.length == 0)
      return (
         <>
            <Box className={`${globalClasses.cardTitleCOntainer}`}>
               <Typography className={globalClasses.cardTitle}>{t("transaction:itemsToBill")}</Typography>
               <Close
                  className={globalClasses.tableIcon}
                  onClick={() => {
                     onClick(invoice_modal_actions.cancel);
                  }}
               />
            </Box>
            <Divider className={globalClasses.dividerMargin} />
            <div className={globalClasses.paddingContainer}>
               <Box className={globalClasses.alertContainer}>{t("invoice:noItems")}</Box>
            </div>
            <Divider className={globalClasses.dividerMargin} />
            <Box className={`${globalClasses.flexEnd} ${globalClasses.paddingContainer}`}>
               <CommonButton
                  color={COLORS.greyText}
                  title={t("gen:cancel")}
                  onClick={() => {
                     onClick(invoice_modal_actions.cancel);
                  }}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  icon={<Refresh className={globalClasses.tableIcon} />}
                  title={t("transaction:refresh")}
                  onClick={() => onClick(invoice_modal_actions.refresh)}
               />
            </Box>
         </>
      );
   return (
      <>
         <Box className={`${globalClasses.cardTitleCOntainer}`}>
            <Typography className={globalClasses.cardTitle}>{t("transaction:itemsToBill")}</Typography>
            <Close
               className={globalClasses.tableIcon}
               onClick={() => {
                  onClick(invoice_modal_actions.cancel);
               }}
            />
         </Box>
         <Divider className={globalClasses.dividerMargin} />
         <Box className={globalClasses.paddingContainer}>
            <Typography className={globalClasses.cardTitle}>{t("invoice:noteBehaviour")}</Typography>
            <RadioButton
               options={[
                  { label: t("invoice:cancellation"), value: "canceling" },
                  { label: t("invoice:correction"), value: "fixing" },
               ]}
               subText={[t("invoice:cancelSubMessage"), t("invoice:correctionSub")]}
               onChange={(value) => setReason({ ...reason, type: value })}
            />
            <Divider className={globalClasses.margin} />
            <Typography className={globalClasses.cardTitle}>{t("transaction:itemsToBill")}</Typography>
            <DataTable data={items || []} search={false}>
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <IconButton
                        icon={<Remove className={globalClasses.tableSmallIcon} />}
                        onClick={() => {
                           onRemove(item);
                        }}
                        color={COLORS.redLabel}
                     />
                  )}
               />
               <TableColumn headerText={t("translations:item")} render={(item) => <SmallText text={item.item.name} />} />
               <TableColumn headerText={t("translations:value")} render={(item) => <SmallText text={putDecimalPoints(item.itemValue)} />} />
               <TableColumn headerText={t("translations:discount")} render={(item) => <SmallText text={putDecimalPoints(item.discount)} />} />
               <TableColumn headerText={t("translations:vat")} render={(item) => <SmallText text={item.itemTax + "%"} />} />
               <TableColumn
                  headerText={t("translations:amount")}
                  render={(item) => (
                     <TextInput
                        disabled={false}
                        title=""
                        value={item.quantity}
                        id="quantity"
                        helperText=""
                        type={"number"}
                        onChange={(event) => {
                           onChange(item, event);
                        }}
                     />
                  )}
               />
            </DataTable>
            <Typography className={`${globalClasses.margin} ${globalClasses.cardTitle}`}>
               {t("invoice:refund")}: {putDecimalPoints(calculateTotal())} €
            </Typography>
            <InputLabel label={t("invoice:motive")} />
            <TextArea onChange={(e) => setReason({ ...reason, reason: e.target.value })} />
            <Divider className={globalClasses.dividerMargin} />
            <Box className={globalClasses.flexEnd}>
               <CommonButton
                  color={COLORS.greyText}
                  title={t("gen:cancel")}
                  onClick={() => {
                     onClick(invoice_modal_actions.cancel);
                  }}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  icon={<Check className={globalClasses.tableIcon} />}
                  title={t("gen:confirm")}
                  onClick={() => onClick(invoice_modal_actions.confirm, reason)}
                  loading={loading}
                  disable={loading}
               />
            </Box>
         </Box>
      </>
   );
};

export default CreditNoteItem;
