import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const newVehFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: "100%",
    },

    media: {
      height: 0,
      paddingTop: "70.25%", // 16:9
    },
  })
);
