import { CreditCard, Note, TouchApp } from "@material-ui/icons";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Transaction from "../../../data/models/transaction.model";
import { formatDate } from "../../../utills/helper";
import { currencyNumber } from "../../../utills/masks";
import { CommonButton, StatusLabel, TableButton } from "../../atoms";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";

interface InvoiceItems {
   data: Transaction[];
   generateInvoice: (item: Transaction) => void;
   showInvoice: (item: Transaction) => void;
}

const InvoiceItems: FC<InvoiceItems> = ({ data, generateInvoice, showInvoice }) => {
   const { t } = useTranslation(["translations", "transaction"]);

   console.log(data);

   const globalClasses = globalStyles();

   const getIcon = (id: number, item: Transaction): ReactNode => {
      if (!item?.invoice?.externalId) {
         return (
            <CommonButton
               color={COLORS.darkText}
               icon={<Note className={globalClasses.tableSmallIcon} />}
               title={t("translations:generateInvoice")}
               onClick={() => generateInvoice(item)}
            />
         );
      } else {
         return (
            <CommonButton
               icon={<TouchApp className={globalClasses.tableSmallIcon} />}
               title={t("transaction:openInvoice")}
               onClick={() => {
                  showInvoice(item);
               }}
            />
         );
      }
   };

   return (
      <>
         <DataTable data={data} search={false}>
            <TableColumn headerText={"ID"} render={(item) => item.id} />

            <TableColumn headerText={t("translations:dueDate")} render={(item) => formatDate(item.dueDate)} />

            <TableColumn
               headerText={t("translations:method")}
               render={(item) => (
                  <>
                     <div>{item.method.name}</div>
                     {item.externalReceiptURL && (
                        <div
                           className={`${globalClasses.linkText} ${globalClasses.flexRowCenter} `}
                           onClick={() => {
                              window.open(item.externalRecepitURL, "_blank");
                           }}
                        >
                           <CreditCard className={globalClasses.smallIcon} /> <span>{t("transaction:receipt")}</span>
                        </div>
                     )}
                  </>
               )}
            />

            <TableColumn headerText={t("translations:value")} render={(item) => currencyNumber(item.netAmount)} />

            <TableColumn headerText={t("translations:discount")} render={(item) => "0,00"} />

            <TableColumn headerText={t("translations:finalValue")} render={(item) => currencyNumber(item.netAmount)} />

            <TableColumn headerText={t("translations:vat")} render={(item) => "11, 63"} />

            <TableColumn headerText={t("translations:subTotal")} render={(item) => currencyNumber(item.amount)} />

            <TableColumn headerText={t("translations:invoice")} render={(item) => item?.invoice?.fullIdentifier} />

            <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />

            <TableColumn headerText={t("translations:action")} render={(item) => (item.amount ? getIcon(item.invoice?.id, item) : null)} />
         </DataTable>
      </>
   );
};

export default InvoiceItems;
