import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { FC } from "react";
import { alertStyles, historyModalBoxSx } from "./index.styles";
import { Box, Typography } from "@material-ui/core";
import { CheckCircleOutlined, ErrorOutlined } from "@material-ui/icons";
import { COLORS } from "../../../data/constants/Colors";
import { CommonButton } from "..";

interface AlertProps {
   show: boolean;
   handleClose: () => void;
   message: { message: string; error: boolean };
}

const Alert: FC<AlertProps> = ({ show, handleClose, message }) => {
   const classes = alertStyles();

   console.log(message);

   return (
      <div>
         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={show}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
               timeout: 500,
            }}
         >
            <Fade in={show}>
               <Box sx={historyModalBoxSx}>
                  <Box className={classes.container}>
                     {message.error ? (
                        <ErrorOutlined style={{ fontSize: 45, color: COLORS.redLabel }} />
                     ) : (
                        <CheckCircleOutlined style={{ fontSize: 45, color: COLORS.greenLabel }} />
                     )}
                     <div className={classes.messageText}>
                        <Typography>{message.message}</Typography>
                     </div>
                     <CommonButton title="OK" onClick={handleClose} />
                  </Box>
               </Box>
            </Fade>
         </Modal>
      </div>
   );
};

export default Alert;
