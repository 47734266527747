import { Box } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton, Loader, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as tariffApi from "../../../data/api/requests/tariffs/index";
import Tariff from "../../../data/models/tariff.model";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
function ListTerrifs() {
   const globalClasses = globalStyles();
   const history = useHistory();
   const { t } = useTranslation(["translations", "users"]);
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");
   const tariffs = useRequest(() => tariffApi.getTariffs(pagination.page, pagination.size, searchQuery));
   const changeTariffStatus = useRequest((params) => tariffApi.changeTariffStatus(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         tariffs.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchQuery]);

   useEffect(() => {
      if (changeTariffStatus.data) {
         tariffs.setData((prev) => {
            const index = prev?.data.findIndex((el) => el.id == changeTariffStatus.data?.id);
            if (prev) {
               prev.data[index!!] = changeTariffStatus.data!!;
            }
            return prev;
         });
      }
   }, [changeTariffStatus.data]);

   const handleDropdownClick = (action: string, item: Tariff) => {
      switch (action) {
         case t("translations:edit"):
            history.push(`/tarrifs/form/${item.id}`);
            break;
         case t("translations:changeStatus"):
            changeTariffStatus.execute(item.id);
            break;
         default:
            break;
      }
   };

   return (
      <div>
         <Loader loading={changeTariffStatus.loading} />

         {changeTariffStatus.show.toast && (
            <Toast
               show={changeTariffStatus.show.toast}
               message={changeTariffStatus.message}
               handleClose={() => {
                  changeTariffStatus.close();
               }}
            />
         )}

         <TitleContainer title={t("translations:teriffs")} />

         <CommonButton
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_TERRIF);
            }}
            icon={<AddCircle />}
         />
         <ContainerTopBar title={t("translations:listing")} activity={tariffs.loading}>
            <DataTable
               data={tariffs.data?.data || []}
               pagination
               total={tariffs.data?.total}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               onSearching={(query) => setSearchQuery(query)}
            >
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn headerText={t("users:name")} render={(item) => item.name} />
               <TableColumn headerText={t("translations:description")} render={(item) => item.description} />
               <TableColumn headerText={t("translations:type")} render={(item) => item.type.name} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(action) => handleDropdownClick(action, item)}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
}

export default ListTerrifs;
