import { Box, Typography } from "@material-ui/core";
import { CreditCard } from "@material-ui/icons";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NewOrganizationForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import City from "../../../data/models/city.model";
import CountryState from "../../../data/models/state.model";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import * as organizationApi from "../../../data/api/requests/organizations/index";
import { TitleContainer } from "../../../components/molecules";
import { useHistory, useParams } from "react-router";
import { Alert, Loader, Toast, CommonButton, AlertDailog } from "../../../components/atoms";
import { useRequest } from "../../../data/api/wrapper";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

function AddNewOrganization() {
   const globalClasses = globalStyles();

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const { t } = useTranslation(["translations", "users", "organization", "vehicles"]);

   const clearForm = useRef() as MutableRefObject<() => void>;

   const [cities, setCities] = useState([] as City[]);
   const [states, setStates] = useState([] as CountryState[]);

   const getOrganization = useRequest((params) => organizationApi.getOrganizationById(params));
   const updateOrganization = useRequest((params) => organizationApi.updateOrganization(params, id));
   const newOrganization = useRequest((params) => organizationApi.addNewOrganization(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         getOrganization.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);
   useEffect(() => {
      if (getOrganization.data) {
         _getState(getOrganization.data?.country.id);
         _getCity(getOrganization.data.state.id);
      }
   }, [getOrganization.data]);

   const _getCity = (id: number | undefined) => {
      dropdownApi
         .getCities(id)
         .then((res) => {
            setCities(res.data);
         })
         .catch((err) => console.log(err));
   };

   const _getState = (id: number | undefined) => {
      dropdownApi
         .getStates(id)
         .then((res) => setStates(res.data))
         .catch((err) => console.log(err));
   };

   const _submit = (value: any) => {
      if (id) {
         updateOrganization.execute(value);
      } else {
         newOrganization.execute(value);
      }
   };

   return (
      <div>
         <Loader loading={updateOrganization.loading || getOrganization.loading || newOrganization.loading} />

         {updateOrganization.show.toast && (
            <Toast show={updateOrganization.show.toast} message={updateOrganization.message} handleClose={() => updateOrganization.close()} />
         )}

         {newOrganization.show.modal && (
            <AlertDailog
               show={newOrganization.show.modal}
               title={newOrganization.message.description}
               message={newOrganization.message.message}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => history.push(ROUTE_LINKS.ORGANIZATIONS)}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  newOrganization.close();
                  clearForm.current();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:organization") : t("translations:new") + " " + t("translations:organization")}
         />

         {id && (
            <CommonButton
               icon={<CreditCard className={globalClasses.tableIcon} />}
               title={t("users:creditCards")}
               onClick={() => {
                  history.push(`/payment-cards/${id}`, { typeId: 2, name: getOrganization.data?.officialName });
               }}
            />
         )}

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Typography className={globalClasses.cardTitle}>{t("organization:local")}:</Typography>
            <NewOrganizationForm
               counter={false}
               getCity={_getCity}
               getState={_getState}
               states={states}
               cities={cities}
               onSubmit={_submit}
               values={getOrganization.data}
               clearForm={clearForm}
            />
         </Box>
      </div>
   );
}

export default AddNewOrganization;
