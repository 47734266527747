import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const routerStyles = makeStyles((theme: Theme) =>
   createStyles({
      root: {
         display: "flex",
      },
      content: {
         flexGrow: 1,
         marginTop: theme.spacing(6),
         padding: theme.spacing(3),
      },
   })
);
