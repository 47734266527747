import  { useEffect, useRef } from "react";

export const useEffectAfterMount = (callBack: () => void, dependency: any) => {
   const componentJustMounted = useRef(true);
   useEffect(() => {
      if (!componentJustMounted.current) {
         return callBack();
      }
      componentJustMounted.current = false;
   }, [dependency]);
};
