import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS, VALUES } from "../../../../data/constants/Colors";

export const modalStyles = makeStyles((theme: Theme) =>
   createStyles({
      topBar: {
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
         paddingLeft: VALUES.margin_small,
         paddingRight: VALUES.margin_small,
      },
      icon: {
         "&:hover": {
            cursor: "pointer",
         },
      },
      head: {
         display: "flex",
         justifyContent: "space-between",
         paddingLeft: VALUES.padding_large,
         paddingRight: VALUES.padding_large,
         borderBottomWidth: 1,
         borderBottomColor: COLORS.borders,
         borderBottomStyle: "solid",
      },
      bodySimple: {
         display: "flex",
         justifyContent: "space-between",
         paddingLeft: VALUES.padding_large,
         paddingRight: VALUES.padding_large,
         paddingTop: VALUES.margin_small,
         paddingBottom: VALUES.margin_small,
      },
      bodyShaded: {
         display: "flex",
         justifyContent: "space-between",
         paddingLeft: VALUES.padding_large,
         paddingRight: VALUES.padding_large,
         backgroundColor: COLORS.lightGrey,
         paddingTop: VALUES.margin_small,
         paddingBottom: VALUES.margin_small,
      },
      greenBox: {
         marginTop: 10,
         marginBottom: 10,
         fontSize: 13,
         fontWeight: "bold",
         color: theme.palette.background.paper,
         backgroundColor: theme.palette.secondary.main,
         borderRadius: 6,
         padding: "2px 15px 2px 15px",
      },
      orangeBox: {
         padding: "2px 15px 2px 15px",
         marginTop: 10,
         marginBottom: 10,
         fontSize: 13,
         fontWeight: "bold",
         color: theme.palette.background.paper,
         backgroundColor: theme.palette.primary.main,
         borderRadius: 6,
      },
      bottomContainer: {
         display: "flex",
         flexDirection: "row",
         justifyContent: "flex-end",
         paddingRight: VALUES.margin_large,
      },
   })
);

export const modalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 450,
   bgcolor: "background.paper",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   textAlign: "center",
   p: 4,
};

export const historyModalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 650,
   bgcolor: "background.paper",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   padding: "20px 0 20px 0",
};
