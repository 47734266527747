import { Button, Menu, MenuItem } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TableDropdownProps } from "./index.types";

const TableDropDown: FC<TableDropdownProps> = ({ options, onClick, title }) => {
   const { t } = useTranslation("translations");
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };
   const handleChange = (option: string) => {
      setAnchorEl(null);
      onClick(option);
   };

   return (
      <div>
         <Button
            variant="contained"
            color="primary"
            disableElevation
            size="small"
            style={{ textTransform: "none", fontWeight: "normal" }}
            aria-controls="table-dropdown-menu"
            aria-haspopup="true"
            onClick={handleClick}
            endIcon={<KeyboardArrowDown />}
         >
            {title ? title : t("action")}
         </Button>
         <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {options?.map((option, index) => (
               <MenuItem key={`table-dropdown-option-${index}`} value={10} onClick={() => handleChange(option)}>
                  {option}
               </MenuItem>
            ))}
         </Menu>
      </div>
   );
};

export default TableDropDown;
