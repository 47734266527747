import { FC } from "react";
import { Box, Divider, Typography, RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import { actions } from "../../../data/constants/button-action";
import DataTable from "../tables/datatable/DataTable";
import TableColumn from "../tables/table-column/TableColumn";
import Service from "../../../data/models/reservation-service.model";
import { currency, putDecimalPoints } from "../../../utills/masks";
import { CheckBox, CommonButton, InputLabel, TextArea, TextInput } from "../../atoms";
import { useFormik } from "formik";
import { COLORS } from "../../../data/constants/Colors";
import * as yup from "yup";

interface DiscountProps {
   handleClose: (action: string) => void;
   data?: Service[];
   isTransaction?: boolean;
   handleSubmit: (values: any) => void;
}

const ReqDiscountItem: FC<DiscountProps> = ({ handleClose, data, handleSubmit, isTransaction }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["transaction", "users", "translations", "gen", "vehicles"]);

   const formik = useFormik({
      initialValues: { discount: "", isPercent: false, note: "", itemId: [0], isTransaction: isTransaction },
      validationSchema: yup.object({
         discount: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         itemId: yup.array().when("isTransaction", {
            is: true,
            then: yup.array().min(2, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         }),
         note: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      validateOnMount: false,
      validateOnBlur: false,
      onSubmit: (values) => {
         let finalData: any = { ...values };
         delete finalData.isTransaction;
         handleSubmit(finalData);
      },
   });

   const addItem = (id: number) => {
      let items: number[] = [...formik.values.itemId];
      let alreadyAdded = items.find((el) => el == id);
      if (alreadyAdded) {
         items = items.filter((el) => el != id);
         return formik.setFieldValue("itemId", items);
      }
      items.push(id);
      return formik.setFieldValue("itemId", items);
   };

   const checkStatus = (id: number) => {
      const found = formik.values.itemId.find((el) => el == id);
      if (found) return true;
      return false;
   };

   const changeValue = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      if (value === "true") {
         formik.setFieldValue("isPercent", true);
      } else {
         formik.setFieldValue("isPercent", false);
      }
   };

   return (
      <>
         <Box className={globalClasses.cardTitleCOntainer}>
            <Typography className={globalClasses.cardTitle}>{t("transaction:requestDiscount")}</Typography>
            <Close
               className={globalClasses.tableIcon}
               onClick={() => {
                  handleClose(actions.CANCEL);
               }}
            />
         </Box>
         <Divider />
         <Box className={globalClasses.paddingContainer}>
            {data && (
               <DataTable data={data || []} search={false}>
                  <TableColumn
                     headerText={t("translations:add")}
                     render={(item) => (
                        <CheckBox
                           title={""}
                           checked={checkStatus(item?.item?.itemId)}
                           setChecked={() => {
                              addItem(item?.item?.itemId);
                           }}
                        />
                     )}
                  />
                  <TableColumn headerText="ID" render={(item) => item.id} />
                  <TableColumn headerText={t("users:name")} render={(item) => item?.item?.name} />
                  <TableColumn headerText={t("translations:discount")} render={(item) => putDecimalPoints(item.discount)} />
                  <TableColumn headerText={t("translations:total")} render={(item) => putDecimalPoints(item.total)} />
               </DataTable>
            )}
            <Typography className={globalClasses.errorText}>{formik.errors.itemId}</Typography>
            <Box className={globalClasses.margin} />
            <InputLabel label={t("translations:discount")} />
            <TextInput
               title="0"
               error={formik.touched.discount && Boolean(formik.errors.discount)}
               helperText={formik.touched.discount && formik.errors.discount}
               id="discount"
               type="number"
               onChange={formik.handleChange}
            />
            <Box className={globalClasses.margin} />
            <RadioGroup row aria-label="percentage" name="row-radio-buttons-group" onChange={changeValue} value={String(formik.values.isPercent)}>
               <FormControlLabel value={"false"} control={<Radio color="primary" />} label="€" />
               <FormControlLabel value={"true"} control={<Radio color="primary" />} label="%" />
            </RadioGroup>
            <Box className={globalClasses.margin} />
            <InputLabel label={t("gen:notes")} />
            <TextArea id="note" onChange={formik.handleChange} />
            <Typography className={globalClasses.errorText}>{formik.errors.note}</Typography>
         </Box>
         <Divider />
         <Box className={`${globalClasses.flexEnd} ${globalClasses.paddingContainer}`}>
            <CommonButton title={t("transaction:requestDiscount")} onClick={formik.handleSubmit} />
            <span className={globalClasses.marginLeft} />
            <CommonButton
               color={COLORS.greyText}
               title={t("gen:cancel")}
               onClick={() => {
                  handleClose(actions.CANCEL);
               }}
            />
         </Box>
      </>
   );
};

export default ReqDiscountItem;
