import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { VALUES } from "../../../../data/constants/Colors";

export const inputLabelStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      fontSize: 14,
      marginLeft: VALUES.margin_small,
      "&:hover": {
        cursor: "pointer",
      },
    },
  })
);
