import { createContext, FC, useContext, useState } from "react";
import { DropdownContextProps } from "./index.types";
import Country from "../../models/country.model";
import * as dropdownApi from "../../api/requests/dropdowns/index";
import * as tariffApi from "../../api/requests/tariffs/index";
import { FeatureType } from "../../models/feature-type.model";
import CarDropdown from "../../models/car-dropdown.model";
import Tariff from "../../models/tariff.model";
import DiscountBillable from "../../models/billable-discount.modal";
import CarCategory from "../../models/car-category.model";
import Status from "../../models/status.model";
import ReservationType from "../../models/reservation-type.model";
import { FeatureInputs } from "../../models/car-inputs";
import Organization from "../../models/organization.model";
import { Counter } from "../../models/counters.model";
export const DropdownContext = createContext<DropdownContextProps>({} as DropdownContextProps);

export const DropdownProvider: FC = ({ children }) => {
   const [countries, setCountries] = useState([] as Country[]);
   const [counters, setCounters] = useState([] as Counter[]);
   const [featureTypes, setFeatureTypes] = useState([] as FeatureType[]);
   const [cars, setCars] = useState([] as CarDropdown[]);
   const [maintenanceTypes, setMaintenanceTypes] = useState([] as Tariff[]);
   const [groupTypes, setGroupTypes] = useState([] as Tariff[]);
   const [companies, setCompanies] = useState([] as Organization[]);
   const [billableItems, setBillableItems] = useState([] as DiscountBillable[]);
   const [vehicleCategories, setVehicleCategories] = useState([] as CarCategory[]);
   const [documentTypes, setDocumentTypes] = useState([] as Status[]);
   const [tariffs, setTariffs] = useState([] as Tariff[]);
   const [campaigns, setCampaigns] = useState([] as ReservationType[]);
   const [status, setStatus] = useState([] as ReservationType[]);
   const [colors, setColors] = useState([] as ReservationType[]);
   const [paymentMethods, setPaymentMethods] = useState([] as ReservationType[]);
   const [fleets, setFleets] = useState([] as ReservationType[]);
   const [fuels, setFuels] = useState([] as ReservationType[]);
   const [brands, setBrands] = useState([] as ReservationType[]);
   const [subjectTypes, setSubjectTypes] = useState([] as ReservationType[]);
   const [receptivities, setReceptivities] = useState([] as ReservationType[]);
   const [modalTypes, setModalTypes] = useState([] as ReservationType[]);
   const [carFeatures, setCarFeatures] = useState({} as FeatureInputs);
   const [tollStatus, setTollStatus] = useState([] as ReservationType[]);
   const [account, setAccount] = useState([] as ReservationType[]);
   const [discountTypes, setDiscountType] = useState([] as ReservationType[]);

   const _getDropdowns = async () => {
      try {
         let [res1, res2, res3, res4, res5, res6, res7, res8, res9, res10] = await Promise.all([
            dropdownApi.getCountries(),
            dropdownApi.getDropdownCounters(),
            dropdownApi.getFeatureTypes(),
            dropdownApi.getAllCars(),
            dropdownApi.getCarMaintenanceTypes(),
            dropdownApi.getGroupTypes(),
            dropdownApi.getOrganizations(),
            dropdownApi.getBillableItems(1),
            dropdownApi.getVehicleCategories(),
            tariffApi.getTariffs(1, 1000, ""),
         ]);
         setCountries(res1.data.data);
         setCounters(res2.data.data);
         setFeatureTypes(res3.data);
         setCars(res4.data.data);
         setMaintenanceTypes(res5.data);
         setGroupTypes(res6.data);
         setCompanies(res7.data.data);
         setBillableItems(res8.data.data);
         setVehicleCategories(res9.data.data);
         setTariffs(res10.data.data);

         let [res11, res12, res13, res14, res15, res16, res17, res18, res19] = await Promise.all([
            dropdownApi.getDocumentTypes(),
            dropdownApi.getPaymentMethods(),
            // dropdownApi.getDropdownCampaigns(),
            dropdownApi.getColors(),
            dropdownApi.getCarFleets(),
            dropdownApi.getFuels(),
            dropdownApi.getBrands(),
            dropdownApi.getSubjectTypes(),
            dropdownApi.getReceptivities(),
            dropdownApi.getModalTypes(),
            // dropdownApi.carFeatures(), // AFter FIX CHANGE IT AGAIN
         ]);
         setDocumentTypes(res11.data);
         setPaymentMethods(res12.data);
         // setCampaigns(res13.data);
         setColors(res13.data);
         setFleets(res14.data);
         setFuels(res15.data);
         setBrands(res16.data.data);
         setSubjectTypes(res17.data);
         setReceptivities(res18.data);
         setModalTypes(res19.data);
         // setCarFeatures(res20);
         let [res21, res22, res23, res24] = await Promise.all([
            dropdownApi.tollsStatusTypes(),
            dropdownApi.accountTypes(),
            dropdownApi.reservationStatus(),
            dropdownApi.getDiscountTypes(),
         ]);
         setTollStatus(res21.data);
         setAccount(res22.data);
         setStatus(res23.data);
         setDiscountType(res24.data);
      } catch (error) {
         throw error;
      }
   };
   const value = {
      countries,
      counters,
      featureTypes,
      cars,
      maintenanceTypes,
      groupTypes,
      companies,
      billableItems,
      vehicleCategories,
      tariffs,
      documentTypes,
      campaigns,
      status,
      paymentMethods,
      colors,
      fleets,
      fuels,
      brands,
      subjectTypes,
      receptivities,
      modalTypes,
      carFeatures,
      tollStatus,
      account,
      discountTypes,
      _getDropdowns,
   };
   return <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>;
};

export default function useDropdown() {
   return useContext(DropdownContext);
}
