import { ArrowBack, Cancel } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { invoice_action } from "../../../data/constants/button-action";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";

interface InvoiceButtonProps {
   onClick: (action: string) => void;
}

const InvoiceButtons: FC<InvoiceButtonProps> = ({ onClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "dashboardStrings", "invoice"]);
   return (
      <>
         <CommonButton
            title={t("translations:backButton")}
            icon={<ArrowBack />}
            onClick={() => {
               onClick(invoice_action.BACK);
            }}
         />
         <span className={globalClasses.marginLeft} />
         <CommonButton
            title={t("dashboardStrings:reservation")}
            onClick={() => {
               onClick(invoice_action.RESERVATION);
            }}
         />
         <span className={globalClasses.marginLeft} />
         <CommonButton
            icon={<Cancel />}
            title={t("invoice:cancelInvoice")}
            onClick={() => {
               onClick(invoice_action.CANCEL_INVOICE);
            }}
            color={COLORS.redLabel}
         />
      </>
   );
};

export default InvoiceButtons;
