import { Divider, Grid, Input, Typography } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { ImagePicker, InputLabel, TableButton, TextInput } from "../../atoms";
import { FileDetailsProps } from "./index.types";

const FileDetails: FC<FileDetailsProps> = ({ files, onChange, values, error }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "gen"]);
   return (
      <>
         <Typography className={globalClasses.titleText}>{t("translations:attachment")}</Typography>
         <ImagePicker
            onChange={(e) => {
               onChange("fileName", e.target.files[0]);
            }}
         />
         <InputLabel label={t("translations:description")} />
         <TextInput
            id="fileDescription"
            value={values.desc}
            disabled={false}
            error={Boolean(error)}
            helperText={error}
            title=""
            type="text"
            onChange={(e) => {
               onChange("fileDescription", e.target.value);
            }}
         />
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={4}>
               <Typography className={globalClasses.cardTitle}>{t("gen:file")}</Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4}>
               <Typography className={globalClasses.cardTitle}>{t("translations:description")}</Typography>
            </Grid>
         </Grid>
         <Divider />
         {files.map((item, index) => (
            <Grid key={item.fileName} container spacing={3} className={globalClasses.marginSmall}>
               <Grid item xl={4} lg={4} md={4} sm={4}>
                  <TableButton
                     icon={<CloudDownload className={globalClasses.tableSmallIcon} />}
                     title={t("gen:download")}
                     onClick={() => {
                        window.open(item.fileName, "_blank");
                     }}
                  />
               </Grid>
               <Grid item xl={4} lg={4} md={4} sm={4}>
                  <Typography className={globalClasses.tableText}>{item.description}</Typography>
               </Grid>
            </Grid>
         ))}
      </>
   );
};

export default FileDetails;
