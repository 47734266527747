import { useEffect, useState } from "react";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as tollApi from "../../../data/api/requests/tolls/index";
import { useTranslation } from "react-i18next";
import { TableButton, TableItem } from "../../../components/atoms";
import { useHistory } from "react-router";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { formatDateTime } from "../../../utills/helper";
import { putDecimalPoints } from "../../../utills/masks";
import { IconButton, Tooltip } from "@material-ui/core";
import { Error, ErrorOutline } from "@material-ui/icons";
import { COLORS } from "../../../data/constants/Colors";

const NotBilled = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "gen", "dashboardStrings", "tolls"]);

   const [pagination, setPagination] = useState({ page: 0, size: 10 });

   const noBilledTolls = useRequest(() => tollApi.getUnbilledTolls(pagination.page, pagination.size));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         noBilledTolls.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination]);

   const onReservationClick = (id: number) => {
      history.push(`/reservation/transactions/${id}`);
   };
   console.log(noBilledTolls.data?.data);

   return (
      <div>
         <TitleContainer title={t("translations:tolls")} />
         <ContainerTopBar title={t("translations:listing")}>
            <DataTable
               data={noBilledTolls.data?.data || []}
               pagination
               total={noBilledTolls.data?.total}
               search={false}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               stripedRows
            >
               <TableColumn headerText="Cod." render={(item) => `# ${item.transaction?.transactionId || t("gen:noInformation")}`} />
               <TableColumn
                  headerText={t("gen:enrollDev")}
                  render={(item) => (
                     <>
                        <span className={globalClasses.greyBox}>{item.transaction?.carPlate || t("gen:noInformation")}</span>
                        <div className={globalClasses.tableSmallText}>{item.transaction?.obenumber || t("gen:noInformation")}</div>
                     </>
                  )}
               />
               <TableColumn
                  headerText={t("gen:exit")}
                  render={(item) => (
                     <TableItem
                        title={item.transaction?.outTollName}
                        subtitle={item.transaction?.outDate ? formatDateTime(item.transaction?.outDate) : t("gen:noInformation")}
                     />
                  )}
               />
               <TableColumn
                  headerText={t("translations:value")}
                  render={(item) => `${item.itemValue ? putDecimalPoints(item.itemValue) : t("gen:noInformation")}`}
               />
               <TableColumn
                  headerText={t("dashboardStrings:reservation") + "/" + "IMPRO"}
                  headerOptions={{ align: "right" }}
                  align="right"
                  render={(item) => (
                     <>
                        {item.reservation?.status?.id == 3 && (
                           <Tooltip title={t("tolls:alreadyFinished")} placement="top" arrow>
                              <IconButton>
                                 <ErrorOutline color="error" />
                              </IconButton>
                           </Tooltip>
                        )}
                        {item.reservation.type.id == 2 ? (
                           <TableButton
                              title={`IMPRO # ${item.reservationId}`}
                              color={COLORS.greyText}
                              onClick={() => {
                                 onReservationClick(item.reservationId);
                              }}
                           />
                        ) : (
                           <TableButton
                              title={`${t("translations:reservation")} # ${item.reservationId}`}
                              onClick={() => {
                                 onReservationClick(item.reservationId);
                              }}
                           />
                        )}
                     </>
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default NotBilled;
