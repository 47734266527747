import { Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { AutoComplete, InputLabel, TextInput } from "../../../atoms";
import NewTariffFormProps from "./index.types";

const NewTariffForm: FC<NewTariffFormProps> = (props) => {
   const { value, onChange, onMultiSelect, errors } = props;
   const { t } = useTranslation(["translations", "users"]);
   const globalClasses = globalStyles();
   const { counters, groupTypes } = useDropdown();
   return (
      <div>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
               <InputLabel label={t("users:name")} />
               <TextInput
                  id="name"
                  title={""}
                  error={!!errors.nameError}
                  helperText={errors.nameError}
                  value={value.name}
                  disabled={false}
                  type="text"
                  onChange={(event) => onChange("name", event.target.value)}
               />
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
               <InputLabel label={t("translations:company")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={counters}
                  multiple
                  // defaultValue={value.organizations.find((item: number) => counters.find((el) => el.id === item))}
                  renderOption={(item) => item.officialName}
                  getOptionLabel={(item) => item.officialName}
                  onChange={(_, item) => {
                     onMultiSelect(item);
                  }}
               />
               <Typography className={globalClasses.errorText}>{errors.organizationsError}</Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={groupTypes}
                  defaultValue={groupTypes.find((el) => value.typeId === el.id)}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => onChange("typeId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{errors.typeIdError}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={8} lg={8} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:description")} />
               <TextInput
                  id="description"
                  error={!!errors.descriptionError}
                  helperText={errors.descriptionError}
                  title={""}
                  value={value.description}
                  disabled={false}
                  type="text"
                  onChange={(event) => onChange("description", event.target.value)}
               />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:creditDays")} />
               <TextInput
                  id="days"
                  helperText={errors.daysError}
                  error={!!errors.daysError}
                  title={""}
                  value={value.days}
                  disabled={false}
                  type="number"
                  onChange={(event) => onChange("days", event.target.value)}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={4} sm={4} xs={12}>
               <InputLabel label={`${t("translations:creditDays")} (%)`} />
               <TextInput
                  id="commissionRate"
                  error={!!errors.commissionRateError}
                  helperText={errors.commissionRateError}
                  title={""}
                  value={value.commissionRate}
                  disabled={false}
                  type="number"
                  onChange={(event) => onChange("commissionRate", event.target.value)}
               />
            </Grid>
         </Grid>
      </div>
   );
};

export default NewTariffForm;
