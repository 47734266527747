import { StorageKeys } from "../data/constants/StorageKeys";

export const emailValidator = (email: string) => {
   const re = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
   );
   if (!re.test(email)) return "por favor digite um email válido";
   return "";
};

export const passwordValidator = (password: string) => {
   if (password.length === 0) {
      return "Por favor insira uma senha";
   }
   return "";
};
export const saveLanguage = (language: string) => {
   try {
      return localStorage.setItem(StorageKeys.LANGUAGE, language);
   } catch (error) {
      throw error;
   }
};
export const getLanguage = () => {
   try {
      return localStorage.getItem(StorageKeys.LANGUAGE);
   } catch (error) {
      throw error;
   }
};
export const saveToken = async (token: string) => {
   try {
      return localStorage.setItem(StorageKeys.TOKEN, token);
   } catch (error) {
      throw error;
   }
};

export const getToken = async () => {
   try {
      return localStorage.getItem(StorageKeys.TOKEN);
   } catch (error) {
      throw error;
   }
};

export const saveUser = async (user: any) => {
   try {
      return localStorage.setItem(StorageKeys.USER, JSON.stringify(user));
   } catch (error) {
      throw error;
   }
};

export const getUser = async () => {
   try {
      const user = localStorage.getItem(StorageKeys.USER);
      if (user) {
         return JSON.parse(user);
      } else {
         return null;
      }
   } catch (error) {
      throw error;
   }
};

export const saveCounter = async (id: string) => {
   try {
      return localStorage.setItem(StorageKeys.COUNTER, id);
   } catch (error) {
      throw error;
   }
};

export const getCounterFromLocalStorage = async () => {
   try {
      return localStorage.getItem(StorageKeys.COUNTER);
   } catch (error) {
      throw error;
   }
};

export const saveRefreshToken = async (token: string) => {
   try {
      return localStorage.setItem(StorageKeys.REFRESH_TOKEN, token);
   } catch (error) {
      throw error;
   }
};

export const getRefreshToken = async () => {
   try {
      return localStorage.getItem(StorageKeys.REFRESH_TOKEN);
   } catch (error) {
      throw error;
   }
};

export const clearSessionStorage = async () => {
   try {
      localStorage.removeItem(StorageKeys.TOKEN);
      localStorage.removeItem(StorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(StorageKeys.USER);
   } catch (error) {
      throw error;
   }
};

export const emptyStringValidator = (string: string, length: number) => {
   if (string.length <= length) {
      return { start: "emptyErrorStart", end: "emptyErrorEnd" };
   }
   return "";
};

export const countryToFlag = (isoCode: string) => {
   return typeof String.fromCodePoint !== "undefined"
      ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
};

export const StringLengthValidator = (string: string | undefined) => {
   if (!string) {
      return "";
   }
   if (string.length === 0) {
      return "Required Field";
   }
   return "";
};
export const NumberValidator = (number: number) => {
   if (number === 0) {
      return true;
   }
   return false;
};

export const FormValidator = (data: object): string[] => {
   const errors: string[] = [];

   Object.values(data).map((el) => {
      if (typeof el === "string") {
         if (el.length === 0) {
            errors.push("Required Field");
         } else {
            errors.push("");
         }
      } else if (typeof el === "number") {
         if (el === 0) {
            errors.push("Required Field");
         } else {
            errors.push("");
         }
      } else if (typeof el === "boolean") {
         errors.push("");
      } else {
         if (el.length === 1) {
            errors.push("Required Field");
         } else {
            errors.push("");
         }
      }
   });
   return errors;
};

export const encodeDate = (date: string, time: string) => {
   const newDate = new Date(date + " " + time);
   return newDate.toISOString();
};

export const removeComma = (query: string) => {
   return query.replaceAll(",", "");
};

export const validateNif = (value: string) => {
   value = value.replace(/-/g, "");
   if (!value || typeof value !== "string" || value.length !== 9) return false;

   let sumAux = 0;
   for (let i = 9; i >= 2; i--) {
      sumAux += i * (parseInt(value[value.length - i]) || 0);
   }

   const module = sumAux % 11;

   // Get the eight first numbers
   const NIFwithoutLastDigit = value.slice(0, value.length - 1);

   if (module === 0 || module === 1) {
      return `${NIFwithoutLastDigit}0` === value;
   } else {
      return `${NIFwithoutLastDigit}${11 - module}` === value;
   }
};
