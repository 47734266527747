import { TextField, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton } from "../..";
import { COLORS } from "../../../../data/constants/Colors";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../../data/models/reservation.model";

interface LinkItemProps {
   onSearchClick: (id: string | number | undefined) => void;
   onCancel: () => void;
   onConfirm: (reservationId: number) => void;
   reservation: Reservation | undefined;
   loading: boolean;
}

const LinkItem: FC<LinkItemProps> = ({ onSearchClick, onCancel, onConfirm, reservation, loading }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["gen", "translations"]);
   const [code, setCode] = useState<number | string>();

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCode(event.target.value);
   };

   return (
      <>
         {!reservation ? (
            <>
               <Typography className={`${globalClasses.textAlign} ${globalClasses.title}`}>{t("gen:bookingCode")}</Typography>
               <TextField variant={"filled"} className={globalClasses.alignCenter} type="number" onChange={handleChange} />
               <div className={`${globalClasses.flexRow} ${globalClasses.alignCenter}`}>
                  <CommonButton title={t("translations:search")} onClick={() => onSearchClick(code)} color={COLORS.blue} loading={loading} />
                  <span className={globalClasses.marginLeft} />
                  <CommonButton title={t("gen:cancel")} onClick={onCancel} color={COLORS.greyText} />
               </div>
            </>
         ) : (
            <>
               <Typography className={`${globalClasses.textAlign} ${globalClasses.title}`}>{t("gen:confirmBooking")}</Typography>
               <div className={globalClasses.alignCenter}>
                  <span className={globalClasses.greyBox}>{reservation?.car?.licensePlate}</span>
                  <span className={globalClasses.marginLeft} />
                  <span className={globalClasses.cardTitle}>{reservation?.user?.name}</span>
               </div>
               <div className={`${globalClasses.flexRow} ${globalClasses.alignCenter}`}>
                  <CommonButton title={t("gen:confirm")} onClick={() => onConfirm(reservation.id)} color={COLORS.blue} />
                  <span className={globalClasses.marginLeft} />
                  <CommonButton title={t("gen:cancel")} onClick={onCancel} color={COLORS.greyText} />
               </div>
            </>
         )}
      </>
   );
};

export default LinkItem;
