import { Box, Grid, Typography } from "@material-ui/core";
import { loadStripe, SetupIntent, SetupIntentResult, StripeError } from "@stripe/stripe-js";
import { CardElement, Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import { payStyles } from "./index.style";
import { CommonButton, Loader, Toast } from "../../../components/atoms";
import { TitleContainer, UserCardItem } from "../../../components/molecules";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useRequest } from "../../../data/api/wrapper";
import * as cardApi from "../../../data/api/requests/payment-cards/index";
import { useLocation, useParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserCards } from "../../../data/api/requests/users";

const stripeKey =
   process.env.NODE_ENV === "production"
      ? "production_key"
      : "pk_test_51HD6LpDMiQWG2BfFNiWiIrQRAR8MEz0EF7FJqYtJJdvu1GFO9DwjmUQi6Hw84QI5hJRMJgbDEpel4bU0wnksRq7H00BJNTY9eE";

const stripePromise = loadStripe(stripeKey);

function PaymentCards() {
   const globalClasses = globalStyles();
   const classes = payStyles();

   const { id } = useParams<{ id: string }>();

   const { state } = useLocation<{ typeId: number; name: string }>();

   const { t } = useTranslation(["payment", "vehicles"]);

   const userCards = useRequest((params) => cardApi.getPaymentCards(params));
   const removeCard = useRequest((param) => cardApi.deletePaymentCards(param));

   const [loading, setLoading] = useState(false);
   const [stripeResponse, setStripeResponse] = useState(null as SetupIntent | null);
   const [stripeError, setStripeError] = useState(null as StripeError | null);

   const [showToast, setShowToast] = useState(false);
   const [toastData, setToastData] = useState({ message: "", error: false });

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      checkResponse();
   }, [stripeResponse, stripeError]);

   const checkResponse = () => {
      if (stripeError) {
         setShowToast(true);
         setToastData({ message: stripeError.message || "", error: true });
      } else if (stripeResponse) {
         setShowToast(true);
         setToastData({ message: stripeResponse.status, error: false });
         getData();
      }
   };

   const getData = () => {
      userCards.execute({ clientId: id, typeId: state.typeId });
   };

   const deleteCard = (cardId: string) => {
      removeCard.execute({ cardId: cardId, clientId: id, clientType: state.typeId });
   };

   return (
      <>
         <Loader loading={removeCard.loading || loading} />

         {showToast && <Toast show={showToast} handleClose={() => setShowToast(false)} message={toastData} />}

         {removeCard.show.toast && (
            <Toast
               show={removeCard.show.toast}
               message={removeCard.message}
               handleClose={() => {
                  removeCard.close();
                  userCards.execute({ clientId: id, typeId: state.typeId });
               }}
            />
         )}

         <TitleContainer title={t("payment:paymentCards")} />

         <Box className={globalClasses.instructionContainer}>
            <Typography className={classes.title}>{t("payment:minCharge")}</Typography>
            <Typography className={globalClasses.labelText}>{t("payment:minChargeDescription")}</Typography>
         </Box>
         <Box className={globalClasses.instructionContainer}>
            <Typography className={classes.title}>{t("payment:paymentMethods")}</Typography>
            {userCards.data?.methods?.length == 0 && <Typography className={globalClasses.labelText}>{t("payment:noPaymentMethods")}</Typography>}
            <Grid container spacing={3}>
               {userCards.data?.methods.map((el, index) => (
                  <Grid item xl={3} lg={3} md={4} sm={12} xs={12} key={`credit-card-${index}`}>
                     <UserCardItem
                        title={el.name}
                        type={`${el.brand} ${el.funding} ${el.country}`}
                        expiry={el.fullExpiry}
                        cardNumber={`**** **** **** ${el.last4}`}
                        remove={() => {
                           deleteCard(el.id);
                        }}
                     />
                  </Grid>
               ))}
            </Grid>
         </Box>

         <Box className={globalClasses.instructionContainer}>
            <Typography className={classes.title}>{t("payment:addNewCard")}</Typography>
            <Typography className={globalClasses.labelText}>{t("payment:cardData")}</Typography>
            <div className={globalClasses.margin}>
               <Elements stripe={stripePromise}>
                  <CheckoutForm
                     clientSecret={userCards.data?.intent.clientSecret}
                     name={state.name}
                     setLoading={setLoading}
                     setStripeError={setStripeError}
                     setStripeResponse={setStripeResponse}
                  />
               </Elements>
            </div>
         </Box>
      </>
   );
}

interface CheckoutProps {
   clientSecret: string | undefined;
   name: string;
   setLoading: React.Dispatch<React.SetStateAction<boolean>>;
   setStripeResponse: React.Dispatch<React.SetStateAction<SetupIntent | null>>;
   setStripeError: React.Dispatch<React.SetStateAction<StripeError | null>>;
}

const CheckoutForm: FC<CheckoutProps> = ({ clientSecret, name, setLoading, setStripeResponse, setStripeError }) => {
   const stripe = useStripe();
   const elements = useElements();
   const classes = payStyles();
   const { t } = useTranslation(["payment"]);

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (elements == null) {
         return;
      }
      if (clientSecret) {
         const cardElement = elements.getElement(CardElement);
         if (cardElement) {
            setLoading(true);
            stripe
               ?.confirmCardSetup(clientSecret, { payment_method: { card: cardElement, billing_details: { name: name } } })
               .then((res: SetupIntentResult) => {
                  if (res.setupIntent) {
                     setStripeError(null);
                     setStripeResponse(res.setupIntent);
                     cardElement.clear();
                  } else if (res.error) {
                     setStripeError(res.error);
                     setStripeResponse(null);
                  }
               })
               .catch(({ error }: { error: StripeError }) => {
                  setStripeError(error);
               })
               .finally(() => {
                  setLoading(false);
               });
         }
      }
   };

   return (
      <form onSubmit={handleSubmit}>
         <CardElement />
         <Typography className={classes.smallText}>{t("payment:newCardNote")}</Typography>
         <CommonButton type="submit" title="Incluir" onClick={() => {}} />
      </form>
   );
};

export default PaymentCards;
