import { AppBar, Grid, IconButton, Toolbar } from "@material-ui/core";
import { FC } from "react";
import { navBarStyles } from "./index.styles";
import { AppBarProps } from "./index.types";
import MenuIcon from "@material-ui/icons/Menu";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CounterDropdown } from "../../atoms";
import useSetting from "../../../data/context/setting-context/SettingContext";

const NavigationBar: FC<AppBarProps> = ({ onClick, user }) => {
   const { i18n } = useTranslation();
   const classes = navBarStyles();
   const globalClasses = globalStyles();
   const { changeCounter, counter } = useSetting();

   return (
      <AppBar position="fixed" className={classes.appBar} color={"default"} elevation={0}>
         <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={onClick} className={classes.menuButton}>
            <MenuIcon />
         </IconButton>
         <div />
         <CounterDropdown options={user?.counters || []} counter={counter} onSelect={(id) => changeCounter(id)} />
      </AppBar>
   );
};

export default NavigationBar;
