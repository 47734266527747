import { FormControlLabel, Checkbox, Typography } from "@material-ui/core";
import { FC } from "react";
import { CheckboxProps } from "./index.types";
import { checkStyles } from "./index.styles";
import { StarCheckbox } from "../..";

const CheckBox: FC<CheckboxProps> = ({ title, checked, setChecked, setHighlight, highlight, highlighted, disabled }) => {
   const classes = checkStyles();

   return (
      <div className={classes.container}>
         <Checkbox checked={checked} onChange={setChecked} color={"primary"} disabled={disabled} />
         <Typography className={classes.checkboxText}>{title}</Typography>
         {highlight && setHighlight && <StarCheckbox active={highlighted} onClick={() => setHighlight(!highlighted)} />}
      </div>
   );
};

export default CheckBox;
