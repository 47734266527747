import { makeStyles, Theme } from "@material-ui/core";
import { VALUES } from "../../../data/constants/Colors";

export const alertStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-around",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: theme.spacing(1),
    padding: theme.spacing(2, 4, 3),
  },
  messageText: {
    textAlign: "center",
  },
}));

export const historyModalBoxSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: VALUES.borderRadius,
  boxShadow: 24,
  padding: "20px 0 20px 0",
};
