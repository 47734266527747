import { Fade, Modal, Box } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { render } from "@testing-library/react";

import React, { FC, ReactNode } from "react";
import { VALUES } from "../../../data/constants/Colors";

interface PopupProps {
   show: boolean;
   handleClose: () => void;
   render: () => ReactNode;
}

const PopupModal: FC<PopupProps> = ({ show, handleClose, render }) => {
   return (
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         open={show}
         onClose={handleClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
      >
         <Fade in={show}>
            <Box sx={historyModalBoxSx}>{render()}</Box>
         </Fade>
      </Modal>
   );
};
const historyModalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 490,
   bgcolor: "background.paper",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   padding: "20px 0 20px 0",
};
export default PopupModal;
