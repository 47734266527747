import { makeStyles, Theme } from "@material-ui/core";

export const loaderStyles = makeStyles((theme: Theme) => ({
   backdrop: {
      position: "absolute",
      height: "100vh",
      width: "100%",
      zIndex: 1400,
      color: theme.palette.primary.main,
   },
}));
