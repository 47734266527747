import { makeStyles, Theme } from "@material-ui/core";

export const checkStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: theme.palette.primary.main,
    fontSize: 25,
    marginLeft: 10,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
