import React, { FC } from "react";
import { CommonButton, TextInput } from "../../../atoms";
import { loginFormStyles } from "./index.styles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import { Box } from "@mui/material";

export type LoginFormProps = {
   onSubmit: (values: any) => void;
};

const LoginForm: FC<LoginFormProps> = ({ onSubmit }) => {
   const classes = loginFormStyles();
   const { t } = useTranslation("translations");
   const { loginSchema } = useValidation();
   const formik = useFormik({
      initialValues: {
         email: "",
         password: "",
      },
      validationSchema: loginSchema,
      onSubmit: (values) => {
         onSubmit(values);
      },
   });
   return (
      <div className={classes.paper}>
         <form className={classes.form} noValidate>
            <TextInput
               title={t("email")}
               disabled={false}
               id="email"
               error={formik.touched.email && Boolean(formik.errors.email)}
               helperText={formik.touched.email && formik.errors.email}
               value={formik.values.email}
               type="string"
               onChange={formik.handleChange}
            />
            <Box mt={1} />
            <TextInput
               title={t("password")}
               disabled={false}
               type="password"
               id="password"
               error={formik.touched.password && Boolean(formik.errors.password)}
               helperText={formik.touched.password && formik.errors.password}
               value={formik.values.password}
               onChange={formik.handleChange}
            />
            <CommonButton title={t("authenticate")} type="submit" onClick={formik.handleSubmit} fullWidth={true} />
         </form>
      </div>
   );
};

export default LoginForm;
