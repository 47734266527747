import { VALUES } from "../../../../data/constants/Colors";

export const ConfirmModalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 450,
   bgcolor: "background.paper",
   display: "flex",
   flexDirection: "column",
   alignItems: "center",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   textAlign: "center",
   p: 4,
};
