import { createContext, FC, useContext, useState } from "react";

export interface Data {
   message: string;
   error: boolean;
}

export interface FeedbackContextProps {
   data: Data | null;
   removeData: () => void;
   addData: (params: Data) => void;
}

export const FeedbackContext = createContext<FeedbackContextProps>({} as FeedbackContextProps);

export const FeedbackProvider: FC = ({ children }) => {
   const [data, setData] = useState<Data | null>(null);

   const removeData = () => setData(null);

   const addData = (param: Data) => setData(param);

   const value = {
      data,
      removeData,
      addData,
   };

   return <FeedbackContext.Provider value={value}>{children}</FeedbackContext.Provider>;
};

export default function useAPIFeedback() {
   const { data, addData, removeData } = useContext(FeedbackContext);
   return { data, addData, removeData };
}
