import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { AutoComplete, CommonButton, CurrencyInput, InputLabel, TextInput } from "../../../atoms";
import { AddCircle, ArrowForward } from "@material-ui/icons";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import * as dropdownApi from "../../../../data/api/requests/dropdowns/index";
import { useRequest } from "../../../../data/api/wrapper";
import { currency, normalizeCurrency, putDecimalPoints } from "../../../../utills/masks";
import { Type } from "../../../../data/models/type.modal";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import DiscountBillable from "../../../../data/models/billable-discount.modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ServiceFormProps {
   handleSubmit: (values: any) => void;
   discounts: DiscountBillable[];
   onReqDiscountClick: () => void;
   onTransactionsClick: () => void;
   onItemSelect: (id: number) => void;
   clearForm: MutableRefObject<() => void>;
}

const ServiceForm: FC<ServiceFormProps> = ({ handleSubmit, onTransactionsClick, discounts, onReqDiscountClick, onItemSelect, clearForm }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "gen", "transaction"]);

   const billableItems = useRequest((params) => dropdownApi.getBillableItems(params));
   const { discountTypes } = useDropdown();

   const { serviceSchema } = useValidation();

   const [type, setType] = useState<number | undefined>(0);

   const formik = useFormik({
      initialValues: {
         type: null,
         billableItemId: null,
         value: "",
         quantity: 1,
         discountRequestId: null,
      },
      enableReinitialize: true,
      validationSchema: serviceSchema,
      validateOnMount: false,
      validateOnBlur: false,
      onSubmit: (values) => {
         let finalValue: any = {
            ...values,
            value: normalizeCurrency(values.value),
         };
         delete finalValue.type;
         handleSubmit(finalValue);
      },
   });

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   const getBillableItems = (item: Type | null) => {
      if (item?.id) {
         setType(item.id);
         billableItems.execute(item.id);
      }
   };

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={discountTypes}
                  getOptionLabel={(item) => item.name}
                  renderOption={(item) => (
                     <div>
                        <i className={`fa ${item.icon}`}></i>
                        <span className={globalClasses.marginLeft}>{item.name}</span>
                     </div>
                  )}
                  defaultValue={discountTypes.find((item) => item.id === type)}
                  onChange={(_, item) => {
                     formik.setFieldValue("type", item?.id);
                     getBillableItems(item);
                  }}
               />
            </Grid>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:item")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={billableItems?.data?.data || []}
                  loading={billableItems.loading}
                  getOptionLabel={(item) => item.name}
                  renderOption={(item) => item.name}
                  defaultValue={billableItems?.data?.data?.find((el) => el.id === formik.values.billableItemId)}
                  onChange={(_, item) => {
                     formik.setFieldValue("billableItemId", item?.id);
                     formik.setFieldValue("value", putDecimalPoints(item?.value));
                     if (item?.id) {
                        onItemSelect(item?.id);
                     }
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.billableItemId}</Typography>
            </Grid>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:quantity")} />
               <TextInput
                  title=""
                  value={formik.values.quantity}
                  maxLength={3}
                  type="number"
                  error={formik.touched.quantity && Boolean(formik.errors.quantity)}
                  helperText={formik.touched.quantity && formik.errors.quantity}
                  disabled={false}
                  id="quantity"
                  onChange={(e) => {
                     e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 3);
                     formik.setFieldValue("quantity", parseInt(e.target.value));
                  }}
               />
            </Grid>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:unitary")} (${t("translations:withOut")} ${t("translations:vat")})`} />
               <CurrencyInput
                  title=""
                  type="text"
                  disabled={formik.values.type == 1}
                  id="value"
                  value={formik.values.value}
                  error={formik.touched.value && Boolean(formik.errors.value)}
                  helperText={formik.touched.value && formik.errors.value}
                  onChange={(value) => formik.setFieldValue("value", value)}
                  clear={clearForm}
               />
            </Grid>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <InputLabel label="Desconto" />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={discounts}
                  getOptionLabel={(item) => item.note}
                  renderOption={(item) => item.note}
                  onChange={(_, item) => formik.setFieldValue("discountRequestId", item?.discountRequestId)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.discountRequestId}</Typography>
            </Grid>
            <Grid item lg={2} xl={2} md={6} sm={12} xs={12}>
               <CommonButton title={t("transaction:requestDiscount")} onClick={onReqDiscountClick} margin={25} />
            </Grid>
         </Grid>
         <Box className={globalClasses.cardTitleCOntainer}>
            <CommonButton title={t("translations:addService")} onClick={formik.handleSubmit} icon={<AddCircle />} />
            <CommonButton title={t("translations:goToTransactions")} onClick={onTransactionsClick} icon={<ArrowForward />} variant="text" />
         </Box>
      </Box>
   );
};

export default ServiceForm;
