import { Box, Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CommonButton, InputLabel } from "../../../atoms";
import * as dropdownApi from "../../../../data/api/requests/dropdowns/index";
import { useRequest } from "../../../../data/api/wrapper";
import { useFormik } from "formik";
import * as yup from "yup";
import { FC, useEffect } from "react";
import { MutableRefObject } from "react";

interface ProcessProps {
   submit: (values: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const ProcessToll: FC<ProcessProps> = ({ submit, clearForm }) => {
   const globalClasses = globalStyles();

   const users = useRequest((params) => dropdownApi.getUsers(params));

   const { tollStatus } = useDropdown();

   const { t } = useTranslation(["translations", "vehicles"]);

   const getUsers = (text: string) => {
      if (text.length > 2) {
         users.execute(text);
      }
   };

   const formik = useFormik({
      initialValues: {
         StatusId: null,
         DriverId: 0,
      },
      validationSchema: yup.object({
         StatusId: yup.number().min(0, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         DriverId: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      onSubmit: (values) => {
         submit(values);
      },
   });

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <Box className={`${globalClasses.container}`}>
         <Grid container spacing={3}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:selectAnAction")}:`} />
               <AutoComplete
                  options={tollStatus || []}
                  label=""
                  placeholder=""
                  defaultValue={tollStatus.find((el) => el.id == formik.values.StatusId)}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     console.log(item?.id);
                     formik.setFieldValue("StatusId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.StatusId}</Typography>
            </Grid>
            <Grid item xl={7} lg={7} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("translations:selectACustumer")}:`} />
               <AutoComplete
                  options={users.data || []}
                  label=""
                  defaultValue={users.data?.find((el) => el.id == formik.values.DriverId)}
                  loading={users.loading}
                  placeholder=""
                  renderOption={(item) => `${item.name} - ${item.taxId || ""}`}
                  getOptionLabel={(item) => item.name}
                  onInputChange={getUsers}
                  onChange={(_, item) => {
                     formik.setFieldValue("DriverId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.DriverId}</Typography>
            </Grid>
         </Grid>
         <CommonButton title={t("translations:process")} onClick={formik.handleSubmit} />
      </Box>
   );
};

export default ProcessToll;
