import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { CommonButton, Toast } from "../../../components/atoms";
import { BannersCarousel, ConfirmModal } from "../../../components/organisms";
import { useTranslation } from "react-i18next";
import Banner from "../../../data/models/banner.model";
import * as bannerApi from "../../../data/api/requests/banners/index";
import { useRequest } from "../../../data/api/wrapper";
import Pagination from "../../../data/models/pagination.model";

function AppBanners() {
   const [bannerToDelete, setBannerToDelete] = useState<number>();

   const { t } = useTranslation(["translations"]);

   const history = useHistory();

   const [showCinfirmModal, setShowConfirmModal] = useState(false);

   const [pagination, setPagination] = useState({ page: 0, size: 10 });

   const [searchQuery, setSearchQuery] = useState("");

   const banners = useRequest(() => bannerApi.getBanners(pagination.page, pagination.size, searchQuery));
   const deleteBanner = useRequest((params) => bannerApi.deleteBannerByID(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         banners.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (deleteBanner.message && bannerToDelete) {
         banners.setData((prev) => {
            const index = prev?.data.findIndex((el) => el.id == bannerToDelete);
            let tempBanners: Pagination<Banner[]> = prev!!;
            const dumped = tempBanners.data.splice(index!!, 1);
            tempBanners.total -= 1;
            return tempBanners;
         });
      }
   }, [deleteBanner.message]);

   const _deleteBanner = () => {
      if (bannerToDelete) {
         deleteBanner.execute(bannerToDelete);
      }
   };

   return (
      <div>
         {deleteBanner.show.toast && (
            <Toast
               show={deleteBanner.show.toast}
               message={deleteBanner.message}
               handleClose={() => {
                  deleteBanner.close();
               }}
            />
         )}

         <ConfirmModal
            open={showCinfirmModal}
            handleClose={(item) => {
               setShowConfirmModal(false);
               if (item === "confirm") {
                  _deleteBanner();
               }
            }}
         />

         <TitleContainer title={t("translations:banners")} />

         <CommonButton
            title={`${t("translations:new")} ${t("translations:banner")}`}
            icon={<AddCircle />}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_BANNER);
            }}
         />

         <ContainerTopBar title={t("translations:listing")}>
            <BannersCarousel
               banners={banners.data?.data || []}
               editBanner={(item) => {
                  history.push(`/app-banners/form/${item.id}`);
               }}
               deleteBanner={(item: Banner, index) => {
                  setBannerToDelete(item.id);
                  setShowConfirmModal(true);
               }}
            />
         </ContainerTopBar>
      </div>
   );
}

export default AppBanners;
