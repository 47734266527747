import { makeStyles, Theme } from "@material-ui/core";

export const receiptStyles = makeStyles((theme: Theme) => ({
   container: {
      background: theme.palette.background.paper,
      height: "70vh",
      padding: 15,
      marginTop: 20,
   },
}));
