import { Backdrop, Box, Fade, Modal, Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import React from "react";
import useAPIError from "../../../data/context/error";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";
import { errorModalBoxSx } from "./index.styles";

const ErrorModal = () => {
   const { error, removeError } = useAPIError();

   const globalClasses = globalStyles();

   return (
      <Modal
         aria-labelledby="transition-modal-title"
         aria-describedby="transition-modal-description"
         open={!!error}
         onClose={removeError}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
      >
         {/* <Fade> */}
         <Box sx={errorModalBoxSx} className={`${globalClasses.modalAlign} ${globalClasses.paddingContainer}`}>
            <ErrorOutline className={globalClasses.bigErrorIcon} />
            <Typography className={globalClasses.title}>{error?.title}</Typography>
            <Typography className={`${globalClasses.textAlign}`}>{error?.message}</Typography>
            <CommonButton title="OK" onClick={removeError}></CommonButton>
         </Box>
         {/* </Fade> */}
      </Modal>
   );
};

export default ErrorModal;
