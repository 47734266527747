import ApiResponse from "../../../models/api-response.model";
import { CommunicationLog } from "../../../models/communication-log.modal";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export const getCommLogsByRes = async (reservationId: number): Promise<ApiResponse<CommunicationLog[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.communicationLogByRes.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not found");
      }
      const data: ApiResponse<CommunicationLog[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
export const getCommLogsByUser = async (userId: number): Promise<ApiResponse<CommunicationLog[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.communicationLogsByUser.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Not found");
      }
      const data: ApiResponse<CommunicationLog[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const addNewCommLog = async (payload: any): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.communicationLogs,
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("Not found");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
