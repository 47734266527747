import { CreditCard, LockOpen, Message } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { user_buttons_actions } from "../../../data/constants/button-action";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";

interface UserButtonProps {
   onClick: (action: string) => void;
}

const UserButtons: FC<UserButtonProps> = (props) => {
   const { onClick } = props;
   const { t } = useTranslation("users");
   const globalClasses = globalStyles();

   return (
      <div>
         <CommonButton
            icon={<LockOpen />}
            title={t("resetPassword")}
            onClick={() => {
               onClick(user_buttons_actions.reset_password);
            }}
         />
         <span className={globalClasses.marginLeft} />
         <CommonButton
            icon={<CreditCard />}
            title={t("creditCards")}
            onClick={() => {
               onClick(user_buttons_actions.credit_cards);
            }}
         />
         <span className={globalClasses.marginLeft} />
         <CommonButton
            icon={<Message />}
            title={"CRM"}
            onClick={() => {
               onClick(user_buttons_actions.crm);
            }}
         />
      </div>
   );
};

export default UserButtons;
