import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton, Loader, StatusLabel, TableDropDown, Toast } from "../../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../../components/molecules";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import * as featureApi from "../../../../data/api/requests/feature/index";
import { useRequest } from "../../../../data/api/wrapper";
import DataTable from "../../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../../components/molecules/tables/table-column/TableColumn";
import { AddCircle } from "@material-ui/icons";

const Characteristics = () => {
   const history = useHistory();

   const { t } = useTranslation(["translations", "users", "vehicles"]);

   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchString, setSearchString] = useState("");

   const feature = useRequest(() => featureApi.getFeatures(pagination.page, pagination.size, searchString));
   const changeFeatureStatus = useRequest((params) => featureApi.changeFeatureStatus(params));

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         feature.execute();
      }
      return () => {
         unmounted = true;
      };
   }, [pagination]);

   useEffect(() => {
      if (changeFeatureStatus.data) {
         feature.setData((prev) => {
            const index = prev?.data.findIndex((el) => el.id == changeFeatureStatus.data?.id);
            if (prev) {
               prev.data[index!!] = changeFeatureStatus.data!!;
            }
            return prev;
         });
      }
   }, [changeFeatureStatus.data]);

   const _handleClick = (name: string, id: number) => {
      switch (name) {
         case t("translations:edit"):
            history.push(`/vehiclemanagment/characteristics/form/${id}`);
            break;
         case t("translations:changeStatus"):
            _changeStatus(id);
            break;
         default:
            break;
      }
   };

   const _changeStatus = (id: number) => {
      changeFeatureStatus.execute(id);
   };

   return (
      <div>
         <Loader loading={changeFeatureStatus.loading} />

         <TitleContainer title={t("translations:characteristics")} />

         {changeFeatureStatus.show.toast && (
            <Toast
               show={changeFeatureStatus.show.toast}
               message={changeFeatureStatus.message}
               handleClose={() => {
                  changeFeatureStatus.close();
               }}
            />
         )}

         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_VEHICLE_CHARACTERISTICS);
            }}
         />

         <ContainerTopBar title={t("translations:listing")} activity={feature.loading}>
            <DataTable
               data={feature.data?.data || []}
               pagination
               onSearching={(query) => setSearchString(query)}
               onChange={(page, rowsPerPage) => {
                  setPagination({ page: page, size: rowsPerPage });
               }}
               total={feature.data?.total}
            >
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn headerText={t("vehicles:featur")} render={(item) => item.name} />
               <TableColumn headerText={t("translations:groups")} render={(item) => item.type.name} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(name) => _handleClick(name, item.id)}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Characteristics;
