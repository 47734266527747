import { ArrowBack, Replay } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { CommonButton, Toast } from "../../../components/atoms";
import { Popup, TicketDescription } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../../components/molecules";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import { useRequest } from "../../../data/api/wrapper";
import { Box } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";
import useAPIFeedback from "../../../data/context/feedback";

const EditTicket = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "vehicles"]);
   const { addData } = useAPIFeedback();

   const { id } = useParams<{ id: string }>();

   const addMessage = useRequest((params) => reservationApi.addTicketMessage(params));
   const ticket = useRequest((params) => reservationApi.getTicketById(params));

   const [historyModal, setHistoryModal] = useState(false);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         ticket.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const _handleSubmit = (values: any) => {
      const payload = { ...values, id: id };
      addMessage.execute(payload);
   };

   const handleStatusChange = (newStatusId: number) => {
      reservationApi
         .changeTicketStatus(id, newStatusId)
         .then((res) => {
            addData({ message: res.message, error: false });
            ticket.setData(res.data);
         })
         .catch((err) => {
            addData({ message: err.message, error: true });
         });
   };

   console.log(ticket.data);

   const getTicketButtons = () => {
      switch (ticket.data?.status.id) {
         case 1:
            return (
               <Box className={`${globalClasses.cardTitleCOntainer} `}>
                  <CommonButton
                     color={COLORS.blue}
                     title="iniciar analize"
                     onClick={() => {
                        handleStatusChange(2);
                     }}
                  />
                  <CommonButton
                     color={COLORS.redLabel}
                     title="cancelar"
                     onClick={() => {
                        handleStatusChange(4);
                     }}
                  />
               </Box>
            );
         case 2:
            return (
               <Box className={`${globalClasses.cardTitleCOntainer} `}>
                  <CommonButton
                     color={COLORS.blue}
                     title="marcar como resolvido"
                     onClick={() => {
                        handleStatusChange(3);
                     }}
                  />{" "}
                  <CommonButton
                     color={COLORS.redLabel}
                     title="cancelar"
                     onClick={() => {
                        handleStatusChange(4);
                     }}
                  />
               </Box>
            );
            break;
         default:
            break;
      }
   };

   return (
      <div>
         <Toast
            show={addMessage.show.toast}
            message={addMessage.message}
            handleClose={() => {
               addMessage.close();
               ticket.execute(id);
            }}
         />
         <Popup
            data={ticket.data?.statusHistory || []}
            open={historyModal}
            handleClose={() => {
               setHistoryModal(false);
            }}
         />
         <TitleContainer title={`Ticket # ${id}`} />
         <div>
            <CommonButton
               title={t("translations:backButton")}
               icon={<ArrowBack />}
               onClick={() => {
                  history.goBack();
               }}
            />
            <span className={globalClasses.marginLeft} />
            <CommonButton
               title={t("translations:historic")}
               icon={<Replay />}
               onClick={() => {
                  setHistoryModal(true);
               }}
            />
         </div>
         {getTicketButtons()}
         <TicketDescription
            data={ticket.data}
            onSubmit={_handleSubmit}
            loading={addMessage.loading}
            onReservationClick={() => {
               history.push(`/reservation/form/${ticket.data?.reservation.id}`);
            }}
         />
      </div>
   );
};

export default EditTicket;
