import { Divider } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ContainerTopBar, TicketSearch, TitleContainer } from "../../../components/molecules";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import moment from "moment";
import { EditOutlined } from "@material-ui/icons";
import { Margin, StatusLabel, TableDropDown } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { padId } from "../../../utills/masks";
const Tickets = () => {
   const globalClasses = globalStyles();
   const history = useHistory();
   const { t } = useTranslation(["translations", "users", "waybills"]);
   const [pagination, setPagination] = useState({
      page: 0,
      size: 10,
   });
   const [searchQuery, setSearchQuery] = useState("");
   const [counterId, setCounterId] = useState([] as number[]);
   const getTickets = useRequest(() => reservationApi.getTickets(pagination.page, pagination.size, counterId, searchQuery));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getTickets.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchQuery]);

   return (
      <div>
         <TitleContainer title={t("translations:tickets")} />
         <ContainerTopBar title={t("translations:listing")} activity={getTickets.loading}>
            <TicketSearch
               onChange={(item) => {
                  setCounterId(item);
               }}
               search={() => {
                  getTickets.execute();
               }}
               counterId={counterId}
            />
            <Margin />
            <DataTable
               data={getTickets.data?.data || []}
               total={getTickets.data?.total}
               pagination
               search
               onChange={(page, size) => setPagination({ page: page, size: size })}
               onSearching={(item) => setSearchQuery(item)}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("translations:user")} render={(item) => item.user.name} />
               <TableColumn headerText={t("translations:subject")} render={(item) => item.subject.name} />
               <TableColumn headerText={t("translations:message")} render={(item) => <div className={globalClasses.tableText}>{item.note}</div>} />
               <TableColumn headerText={t("translations:registeredIn")} render={(item) => moment(item.registration).format("MM/DD/YYYY HH:mm")} />
               <TableColumn
                  headerText={t("translations:details")}
                  render={(item) => (
                     <>
                        <div className={globalClasses.tableSmallText}>{item.reservation.pickupOrganization}</div>
                        <div className={globalClasses.tableSmallText}>{item.reservation.returnOrganization}</div>
                        <div
                           className={`${globalClasses.tableSmallText} ${globalClasses.linkText}`}
                           onClick={() => {
                              history.push(`/reservation/form/${item.reservation.id}`);
                           }}
                        >
                           <EditOutlined className={globalClasses.smallIcon} /> {padId(item.reservation.id, 5)}
                        </div>
                     </>
                  )}
               />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  align="right"
                  render={(item) => (
                     <TableDropDown options={[t("translations:edit")]} onClick={() => history.push(`/reservation/tickets/edit/${item.id}`)} />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Tickets;
