import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const loginFormStyles = makeStyles((theme: Theme) =>
   createStyles({
      paper: {
         marginTop: theme.spacing(2),
         display: "flex",
         flexDirection: "column",
         alignItems: "center",
      },
      form: {
         width: "100%", // Fix IE 11 issue.
         marginTop: theme.spacing(0),
      },
   })
);
