import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const carouselStyles = makeStyles((theme: Theme) =>
  createStyles({
    carouselContainer: {
      paddingTop: 15,
      paddingBottom: 15,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);
