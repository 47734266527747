import { Box, Typography } from "@material-ui/core";
import { ArrowBack, Autorenew } from "@material-ui/icons";
import React, { useState } from "react";
import { CommonButton } from "../../../components/atoms";
import { ContainerTopBar } from "../../../components/molecules";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

const GenerateBilling = () => {
   const globalClasses = globalStyles();
   const [invoices, setInvoices] = useState([
      {
         id: "16",
         dueData: "10/28/2020",
         method: "Cheque",
         value: 88.0,
         discounts: 0.0,
         Subtotal: 88.0,
         vat: 20.24,
         finalValue: 108.24,
         invoice: "FT 2020A21/72",
         status: "Pendente",
         actions: "Gerar fatura",
      },
      {
         id: "6",
         dueData: "10/18/2020",
         method: "MultiBanco",
         value: 88.0,
         discounts: 0.0,
         Subtotal: 88.0,
         vat: 20.24,
         finalValue: 108.24,
         invoice: "",
         status: "pago",
         actions: "Abrir fatura",
      },
   ]);
   return (
      <div>
         <Box className={globalClasses.title}>Transações</Box>
         <div className={globalClasses.alertContainer}>
            <CommonButton color={COLORS.blue} onClick={() => {}} title="Voltar para reserva 1" icon={<ArrowBack />} />
            <div className={globalClasses.margin} />
            <Typography className={globalClasses.smallErrorText}>
               <b>Período:</b> 26/10/2020 17:14 à 30/11/-0001 00:00
            </Typography>
            <Typography className={globalClasses.smallErrorText}>
               <b>Condutor principal: </b> LUIZ ALBERTO BARBOSA MESQUITA
            </Typography>
         </div>
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Typography className={globalClasses.titleText}>Gerar cobrança</Typography>
            <Typography className={globalClasses.cardTitle}>
               Não existem itens a serem cobrados. Adicione novos serviços para criar transações
            </Typography>
            <CommonButton title="Actualizar" onClick={() => {}} icon={<Autorenew />} />
            <ContainerTopBar title="Listagem">
               {/* <SimpleTable
            data={invoices}
            tableBody={[
              "id",
              "dueData",
              "method",
              "value",
              "discounts",
              "Subtotal",
              "vat",
              "finalValue",
              "invoice",
              "status",
              "actions",
            ]}
            headData={[
              "ID",
              "Vencimento",
              "Método",
              "Valor",
              "Descontos",
              "Subtotal",
              "IVA",
              "Valor final",
              "Fatura",
              "Status",
              "Açoes",
            ]}
            subMenu={false}
            dropDownOptions={[]}
            onClick={() => {}}
          /> */}
            </ContainerTopBar>
         </Box>
      </div>
   );
};

export default GenerateBilling;
