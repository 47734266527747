import { Box } from "@material-ui/core";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { TitleContainer } from "../../../../components/molecules";
import { FeatureForm } from "../../../../components/organisms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import * as featureApi from "../../../../data/api/requests/feature/index";
import { useRequest } from "../../../../data/api/wrapper";
import { Alert, AlertDailog, Loader, Toast } from "../../../../components/atoms";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import { CheckCircleOutline } from "@material-ui/icons";

const NewCharacteristic = () => {
   const globalClasses = globalStyles();

   const { id } = useParams<{ id: string }>();
   const history = useHistory();

   const { t } = useTranslation(["translations", "vehicles"]);

   const clearForm = useRef() as MutableRefObject<() => void>;

   const newFeature = useRequest((params) => featureApi.newFeature(params));
   const feature = useRequest((params) => featureApi.getFeatureById(params));
   const updateFeature = useRequest((params) => featureApi.updateFeature(params, id));

   useEffect(() => {
      let unsubscribe = false;

      if (id && !unsubscribe) {
         feature.execute(id);
      }

      return () => {
         unsubscribe = false;
      };
   }, []);

   const submit = (feature: any) => {
      if (id) {
         updateFeature.execute(feature);
      } else {
         newFeature.execute(feature);
      }
   };

   return (
      <div>
         <Loader loading={newFeature.loading || updateFeature.loading} />

         {newFeature.show.modal && (
            <AlertDailog
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               show={newFeature.show.modal}
               title={newFeature.message.message}
               message={newFeature.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  newFeature.close();
                  history.push(ROUTE_LINKS.VEHICLE_CHARACTERISTICS);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  newFeature.close();
                  clearForm.current();
               }}
            />
         )}

         {updateFeature.show.toast && (
            <Toast show={updateFeature.show.toast} message={updateFeature.message} handleClose={() => updateFeature.close()} />
         )}

         <TitleContainer
            title={
               id ? t("translations:edit") + " " + t("translations:characteristic") : t("translations:new") + " " + t("translations:characteristic")
            }
         />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <FeatureForm submit={submit} feature={feature.data} clearForm={clearForm} />
         </Box>
      </div>
   );
};

export default NewCharacteristic;
