import { Box, Checkbox, Divider, Grid, Typography } from "@material-ui/core";
import { FC, MutableRefObject, useEffect, useRef, useState } from "react";
import { COLORS, VALUES } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { InputLabel, CommonButton, ImagePicker, AutoComplete, DropdownItem, TextInput, MaskedInput, AlertDailog, Margin } from "../../atoms";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import { newUserFormStyles } from "./index.styles";
import { useFormik } from "formik";
import { useValidation } from "../../../utills/validation";
import { getOptionLabel } from "../../../utills/helper";
import { useRequest } from "../../../data/api/wrapper";
import { normalize, putDecimalPoints } from "../../../utills/masks";
import { encodeDate, validateNif } from "../../../utills/utills";
import moment from "moment";
import User from "../../../data/models/user.model";
import { returnUser } from "../../../utills/user";
import { ErrorOutline, FiberManualRecord } from "@material-ui/icons";
import { UserPendingFinancials } from "../../../data/models/financials.model";

interface UserFormProps {
   handleSubmit: (values: any, image: File | undefined) => void;
   data: User | undefined;
   pendingTransactions?: UserPendingFinancials[];
   clearForm: MutableRefObject<() => void>;
   getUserByTaxId: (taxId: string) => Promise<string>;
   onReservationClick?: (id: number) => void;
}

const NewUserForm: FC<UserFormProps> = ({ handleSubmit, data, clearForm, getUserByTaxId, pendingTransactions, onReservationClick }) => {
   const classes = newUserFormStyles();
   const globalClasses = globalStyles();
   const { countries } = useDropdown();
   const { t } = useTranslation(["users", "translations"]);
   const { userSchema } = useValidation();
   const [image, setImage] = useState<File>();
   const getState = useRequest((params) => dropdownApi.getStates(params));
   const getCity = useRequest((params) => dropdownApi.getCities(params));

   const [modalText, setModalText] = useState({ message: "", error: true, title: "" });
   const [modal, setModal] = useState(false);

   const imageRef = useRef() as MutableRefObject<HTMLInputElement>;

   const formik = useFormik({
      initialValues: {
         profileImage: "",
         stateId: 0,
         countryId: 0,
         cityId: 0,
         nationalityId: 0,
         phoneCountryId: 0,
         name: "",
         email: "",
         phone: "",
         taxId: "",
         neighborhood: "",
         alternateCityName: "",
         address: "",
         birthdate: moment(new Date(new Date().setFullYear(new Date().getFullYear() - 18))).format("YYYY-MM-DD"),
         postalCode: "",
         disableRequireDocs: false,
         documents: [
            {
               countryId: 0,
               value: "",
               expirationDate: "",
               typeId: 1,
               issueDate: "",
            },
            {
               countryId: 0,
               value: "",
               expirationDate: "",
               typeId: 2,
               issueDate: "",
            },
            {
               countryId: 0,
               value: "",
               expirationDate: "",
               typeId: 3,
               issueDate: "",
            },
         ],
      },
      validationSchema: userSchema,
      enableReinitialize: true,
      validateOnBlur: false,
      validateOnChange: false,
      validateOnMount: false,
      onSubmit: (values) => {
         let newData: any = {
            ...values,
            taxId: normalize(values.taxId),
            postalCode: normalize(values.postalCode),
            birthdate: encodeDate(values.birthdate, ""),
         };
         if (!values.disableRequireDocs) {
            let da = newData.documents.map((el: any, index: number) => {
               if (index < 2) {
                  if (el.countryId !== 0 && el.value !== "" && el.expirationDate !== "" && el.issueDate !== "") {
                     const newObject = {
                        ...el,
                        expirationDate: encodeDate(el.expirationDate, ""),
                        issueDate: encodeDate(el.issueDate, ""),
                     };
                     return newObject;
                  }
               } else {
                  const newObject = {
                     ...el,
                     expirationDate: encodeDate(el.expirationDate, ""),
                     issueDate: encodeDate(el.issueDate, ""),
                  };
                  return newObject;
               }
            });
            da = da.filter((el: any) => el);
            const filteredData = {
               ...newData,
               documents: [...da],
            };
            handleSubmit(filteredData, image);
         } else {
            delete newData["documents"];
            handleSubmit(newData, image);
         }
      },
   });

   useEffect(() => {
      clearForm.current = resetFormik;
   }, []);

   const resetFormik = () => {
      formik.resetForm({});
      imageRef.current.value = "";
   };

   useEffect(() => {
      if (formik.values.taxId.length === 9) {
         validateUserNif();
      }
   }, [formik.values.taxId, formik.values.countryId]);

   useEffect(() => {
      if (data) {
         getUser(data);
      }
   }, [data]);

   const validateUserNif = async () => {
      if (formik.values.countryId === 1) {
         if (!validateNif(formik.values.taxId)) {
            setModalText({ ...modalText, message: t("users:invalidNif"), title: "" });
            setModal(true);
         }
      }
      const name = await getUserByTaxId(normalize(formik.values.taxId));
      if (name) {
         setModalText({ ...modalText, message: t("users:duplicateMessage") + " '" + name + "'", title: t("users:duplicateTitle") });
         setModal(true);
      }
   };

   const getUser = async (user: User) => {
      let tempUser = returnUser(user);
      getState.execute(tempUser.countryId);
      getCity.execute(tempUser.stateId);
      formik.setValues(tempUser);
   };

   return (
      <Box className={globalClasses.title}>
         <AlertDailog
            show={modal}
            icon={<ErrorOutline className={globalClasses.bigErrorIcon} />}
            title={modalText.title}
            message={modalText.message}
            primaryButtonHandle={() => {
               setModal(false);
               formik.values.taxId = "";
            }}
         />
         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
               {pendingTransactions && pendingTransactions.length > 0 && (
                  <Box className={globalClasses.alertWarningSevere}>
                     <Typography>{t("users:unpaidTransactionWarning")}</Typography>
                     <Box className={globalClasses.marginLeft}>
                        {pendingTransactions.map((transaction, index) => (
                           <Box className={`${globalClasses.flexRow} ${globalClasses.marginSmall}`}>
                              <FiberManualRecord className={globalClasses.smallIcon} />
                              <span
                                 className={`${globalClasses.greyBox} ${globalClasses.hoverPointer} ${globalClasses.marginLeft}`}
                                 onClick={() => onReservationClick && onReservationClick(transaction.reservationId)}
                              >
                                 #{transaction.reservationId}
                              </span>
                              <span className={`${globalClasses.tableText}`}>
                                 {t("translations:value")}: {putDecimalPoints(transaction.amount)}
                              </span>
                           </Box>
                        ))}
                     </Box>
                  </Box>
               )}
               <Margin />
               <Box className={classes.pickImage} border={1}>
                  <img
                     src={formik.values.profileImage ? formik.values.profileImage : "https://test.ajrent.pt/ws/box/image-placeholder.png"}
                     height={100}
                     width={100}
                     className={classes.cameraIcon}
                     onClick={() => {}}
                     alt="User"
                  />
               </Box>
               <Box />
               <ImagePicker
                  imageRef={imageRef}
                  onChange={(event) => {
                     formik.setFieldValue("profileImage", URL.createObjectURL(event.target.files[0]));
                     setImage(event.target.files[0]);
                  }}
               />
               <Typography variant={"h5"} className={globalClasses.margin}>
                  {t("translations:contact")}
               </Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={t("users:nationality")} />
               <AutoComplete
                  options={countries}
                  label={""}
                  defaultValue={countries.find((el) => el.id === formik.values.nationalityId)}
                  placeholder={t("users:nationality")}
                  renderOption={(item) => <DropdownItem country={item} />}
                  getOptionLabel={(item) => getOptionLabel(item)}
                  onChange={(event, item) => formik.setFieldValue("nationalityId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.nationalityId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={t("users:name")} />
               <TextInput
                  title=""
                  type="text"
                  value={formik.values.name}
                  id="name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={t("users:birth")} />
               <TextInput
                  title=""
                  type="date"
                  value={formik.values.birthdate}
                  id="birthdate"
                  error={formik.touched.birthdate && Boolean(formik.errors.birthdate)}
                  helperText={formik.touched.birthdate && formik.errors.birthdate}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
               <InputLabel label={t("users:countryCode")} />
               <AutoComplete
                  options={countries}
                  label={""}
                  defaultValue={countries.find((el) => el.id === formik.values.phoneCountryId)}
                  placeholder={t("users:countryCode")}
                  renderOption={(item) => <DropdownItem country={item} phone />}
                  getOptionLabel={(item) => item?.phoneCode || ""}
                  onChange={(event, item) => formik.setFieldValue("phoneCountryId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.phoneCountryId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2} xl={2}>
               <InputLabel label={t("users:telephone")} />
               <MaskedInput
                  format={formik.values.phoneCountryId == 1 ? "### ### ###" : "##############"}
                  value={formik.values.phone}
                  onValueChange={(values) => formik.setFieldValue("phone", values.value)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.phone}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={t("translations:email")} />
               <TextInput
                  title=""
                  type="text"
                  value={formik.values.email}
                  id="email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Typography variant="h5">{t("users:taxAddress")}</Typography>
         <ul>
            <li className={classes.listItem}>{t("users:taxAddressReq")}</li>
         </ul>
         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={t("users:parents")} />
               <AutoComplete
                  options={countries}
                  label={""}
                  defaultValue={countries.find((el) => el.id === formik.values.countryId)}
                  placeholder={t("users:parents")}
                  renderOption={(item) => <DropdownItem country={item} />}
                  getOptionLabel={(item) => getOptionLabel(item)}
                  onChange={(event, item) => {
                     formik.setFieldValue("countryId", item?.id);
                     getState.execute(item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.countryId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={`${t("users:state")}/${t("users:district")}`} />

               <AutoComplete
                  options={getState?.data || []}
                  loading={getState?.loading}
                  label={""}
                  defaultValue={getState.data?.find((el) => el.id === formik.values.stateId)}
                  placeholder={t("users:state")}
                  renderOption={(item) => item?.name}
                  getOptionLabel={(item) => getOptionLabel(item)}
                  onChange={(event, item) => {
                     formik.setFieldValue("stateId", item?.id);
                     getCity.execute(item?.id);
                  }}
               />

               <Typography className={globalClasses.errorText}>{formik.errors.stateId}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={`${t("users:city")}/${t("users:muncipality")}`} />
               {/* 
                  if the country Id is 1 render a dropdown if not 1 render text input
               */}
               {formik.values.countryId === 1 ? (
                  <>
                     {" "}
                     <AutoComplete
                        options={getCity.data || []}
                        label={""}
                        defaultValue={getCity.data?.find((el) => el.id === formik.values.cityId)}
                        placeholder={t("users:city")}
                        renderOption={(item) => item?.name}
                        getOptionLabel={(item) => getOptionLabel(item)}
                        onChange={(event, item) => {
                           formik.setFieldValue("cityId", item?.id);
                           formik.setFieldValue("alternateCityName", "");
                        }}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.cityId}</Typography>{" "}
                  </>
               ) : (
                  <TextInput
                     title=""
                     type="text"
                     value={formik.values.alternateCityName}
                     id="alternateCityName"
                     error={formik.touched.alternateCityName && Boolean(formik.errors.alternateCityName)}
                     helperText={formik.touched.alternateCityName && formik.errors.alternateCityName}
                     disabled={false}
                     onChange={(event) => {
                        formik.setFieldValue("alternateCityName", event.target.value);
                        formik.setFieldValue("cityId", 0);
                        formik.setFieldValue("stateId", 0);
                     }}
                  />
               )}
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
               <InputLabel label={`${t("users:address")}/${t("users:houseHold")}`} />
               <TextInput
                  title=""
                  type="text"
                  value={formik.values.address}
                  id="address"
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
               <InputLabel label={`${t("users:neighborhood")}/${t("users:perish")}/${t("users:locality")}`} />
               <TextInput
                  title=""
                  type="text"
                  value={formik.values.neighborhood}
                  id="neighborhood"
                  error={formik.touched.neighborhood && Boolean(formik.errors.neighborhood)}
                  helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
               <InputLabel label={t("users:postalCode")} />
               <MaskedInput
                  format={formik.values.countryId == 1 ? "####-###" : "###############"}
                  value={formik.values.postalCode}
                  onValueChange={(values) => formik.setFieldValue("postalCode", values.value)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.postalCode}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
               <InputLabel label={`${t("users:nif")}/${t("users:taxId")}`} />
               <MaskedInput
                  format={formik.values.countryId == 1 ? "########-#" : "###############"}
                  value={formik.values.taxId}
                  onValueChange={(values) => formik.setFieldValue("taxId", values.value)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.taxId}</Typography>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Typography variant="h5">{t("users:documents")}</Typography>
         <div className={classes.checkboxContainer}>
            <Checkbox
               checked={formik.values.disableRequireDocs}
               onChange={(e) => {
                  formik.setFieldValue("disableRequireDocs", !formik.values.disableRequireDocs);
               }}
               color="primary"
               inputProps={{ "aria-label": "secondary checkbox" }}
            />
            <Typography
               style={{
                  color: !formik.values.disableRequireDocs ? COLORS.greenLabel : COLORS.lightText,
                  fontSize: 13,
               }}
            >
               {t("users:documentRequirementDismiss")}
            </Typography>
         </div>
         {!formik.values.disableRequireDocs && (
            <ul>
               <li className={classes.listItemOrange}>{t("users:clientNoUsageAsDriver")}</li>
            </ul>
         )}
         {!formik.values.disableRequireDocs && (
            <div>
               <ul>
                  <li className={classes.listItem}>{t("users:citizenNumberOrPassport")}</li>
                  <li className={classes.listItem}>{t("users:drivingLicenceMandatory")}</li>
               </ul>
               <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} md={3}>
                     <InputLabel label={t("users:parents")} />
                     <AutoComplete
                        options={countries}
                        label={""}
                        defaultValue={countries.find((el) => el.id === formik.values.documents[0].countryId)}
                        placeholder={t("users:countryCode")}
                        renderOption={(item) => <DropdownItem country={item} phone />}
                        getOptionLabel={(item) => item?.phoneCode || ""}
                        onChange={(event, item) => {
                           formik.setFieldValue("documents[0].countryId", item?.id);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[0]?.countryId}
                     </Typography>
                     <div style={{ marginTop: VALUES.margin_small }} />
                     <InputLabel label={t("users:parents")} />
                     <AutoComplete
                        options={countries}
                        label={""}
                        defaultValue={countries.find((el) => el.id === formik.values.documents[1].countryId)}
                        placeholder={t("users:countryCode")}
                        renderOption={(item) => <DropdownItem country={item} phone />}
                        getOptionLabel={(item) => item?.phoneCode || ""}
                        onChange={(event, item) => {
                           formik.setFieldValue("documents[1].countryId", item?.id);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[1]?.countryId}
                     </Typography>
                     <div style={{ marginTop: VALUES.margin_small }} />
                     <InputLabel label={t("users:parents")} />
                     <AutoComplete
                        options={countries}
                        label={""}
                        defaultValue={countries.find((el) => el.id === formik.values.documents[2].countryId)}
                        placeholder={t("users:countryCode")}
                        renderOption={(item) => <DropdownItem country={item} phone />}
                        getOptionLabel={(item) => item?.phoneCode || ""}
                        onChange={(event, item) => {
                           formik.setFieldValue("documents[2].countryId", item?.id);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[2]?.countryId}
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                     <InputLabel label={t("users:citizenNumber")} />
                     <TextInput
                        title=""
                        type="text"
                        value={formik.values.documents[0].value}
                        id="citizenValue"
                        helperText={""}
                        disabled={false}
                        onChange={(e) => formik.setFieldValue("documents[0].value", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[0]?.value}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:passport")} />
                     <TextInput
                        title=""
                        type="text"
                        value={formik.values.documents[1].value}
                        id="passportValue"
                        helperText=""
                        disabled={false}
                        onChange={(e) => formik.setFieldValue("documents[1].value", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[1]?.value}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:driversLicence")} />
                     <TextInput
                        title=""
                        type="text"
                        value={formik.values.documents[2].value}
                        id="licenseValue"
                        helperText={""}
                        disabled={false}
                        onChange={(e) => formik.setFieldValue("documents[2].value", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[2]?.value}
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                     <InputLabel label={t("users:issue")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[0].issueDate}
                        id="citizenIssueDate"
                        helperText={""}
                        disabled={false}
                        max={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[0].issueDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[0]?.issueDate}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:issue")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[1].issueDate}
                        error={false}
                        helperText={""}
                        id="passportIssueDate"
                        disabled={false}
                        max={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[1].issueDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[1]?.issueDate}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:issue")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[2].issueDate}
                        id="licenseIssueDate"
                        error={false}
                        helperText={""}
                        disabled={false}
                        max={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[2].issueDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[2]?.issueDate}
                     </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                     <InputLabel label={t("users:validity")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[0].expirationDate}
                        id="citizenExpirationDate"
                        error={false}
                        helperText={""}
                        disabled={false}
                        min={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[0].expirationDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[0]?.expirationDate}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:validity")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[1].expirationDate}
                        id="passportExpirationDate"
                        error={false}
                        helperText={""}
                        disabled={false}
                        min={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[1].expirationDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[1]?.expirationDate}
                     </Typography>
                     <div className={globalClasses.margin} />
                     <InputLabel label={t("users:validity")} />
                     <TextInput
                        title=""
                        type="date"
                        value={formik.values.documents[2].expirationDate}
                        id="licenseExpirationDate"
                        error={false}
                        helperText={""}
                        disabled={false}
                        min={moment(new Date().getTime()).format("YYYY-MM-DD")}
                        onChange={(e) => formik.setFieldValue("documents[2].expirationDate", e.target.value)}
                     />
                     <Typography className={globalClasses.errorText}>
                        {formik.errors?.documents &&
                           //@ts-ignore
                           formik.errors?.documents[2]?.expirationDate}
                     </Typography>
                  </Grid>
               </Grid>
            </div>
         )}
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title="Criar" onClick={formik.handleSubmit} />
      </Box>
   );
};

export default NewUserForm;
