import { Box, Typography, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import DataTable from "../tables/datatable/DataTable";
import TableColumn from "../tables/table-column/TableColumn";
import { CommonButton } from "../../atoms";
import { COLORS } from "../../../data/constants/Colors";
import CarHistory from "../../../data/models/car-history";
import { FC } from "react";
import { formatDate } from "../../../utills/helper";

interface VehicleItemProps {
   data?: CarHistory[];
   close: () => void;
}

const VehicleHistoryItem: FC<VehicleItemProps> = ({ data, close }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["tolls", "gen", "translations", "waybills", "transaction", "vehicles"]);

   return (
      <>
         <Box className={globalClasses.cardTitleCOntainer}>
            <Typography className={globalClasses.cardTitle}>{t("translations:vehicleHistory")}</Typography>
            <span>
               <Close className={globalClasses.tableIcon} />
            </span>
         </Box>
         <Divider className={globalClasses.margin} />
         <Box className={globalClasses.paddingContainer}>
            <DataTable data={data || []} search={false}>
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("vehicles:registration")} render={(item) => formatDate(item.registration)} />
               <TableColumn headerText={t("translations:startEnd")} render={(item) => formatDate(item.startDate)} />
               <TableColumn headerText={t("gen:producer")} render={(item) => item.user.name} />
            </DataTable>
         </Box>
         <Divider />
         <Box className={`${globalClasses.paddingContainer} ${globalClasses.flexEnd}`}>
            <CommonButton color={COLORS.greyText} title={t("transaction:close")} onClick={close} />
         </Box>
      </>
   );
};

export default VehicleHistoryItem;
