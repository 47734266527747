import { Typography, Box, Grid } from "@material-ui/core";
import { BarChart, Edit, List, Settings, Timeline } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContainerTopBar } from "..";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Car from "../../../data/models/car.model";
import { CommonButton } from "../../atoms";
import LocationLabel from "../../atoms/location-label/LocationLabel";
import TextPair from "../../atoms/text-with-value/TextPair";

interface VehicleProps {
   car?: Car;
   onNavigate: (route: string) => void;
}

const VehicleCard: FC<VehicleProps> = ({ car, onNavigate }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["vehicles", "vehicleDetail", "translations", "gen"]);
   return (
      <ContainerTopBar title={t("vehicles:vehicleDetail")}>
         <img src={car?.image} alt="vehicle" height="300px" />
         <Box className={`${globalClasses.paddingContainer} ${globalClasses.margin}`}>
            <Typography className={globalClasses.cardTitle}>{`${car?.brand} ${car?.name}`}</Typography>
            <LocationLabel title="Location" value={car?.office.name || ""} />
            <Grid container spacing={3}>
               <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                  <TextPair title={t("vehicleDetail:enrollment")} value={car?.licensePlate || ""} />
                  <TextPair title={t("vehicles:chassis")} value={car?.chassis || ""} />
                  <TextPair title={t("vehicles:year")} value={car?.year || ""} />
               </Grid>
               <Grid item lg={6} xl={6} md={6} sm={12} xs={12}>
                  <TextPair title={t("vehicles:category")} value={car?.categories[0]?.name || ""} />
                  <TextPair title={t("vehicles:fuel")} value={car?.fuelType.name || ""} />
                  <TextPair title={t("vehicles:mileage")} value={`${car?.mileage} km`} />
               </Grid>
            </Grid>
            <Grid container spacing={3}>
               <Grid item lg={4} xl={4} md={5} sm={12} xs={12}>
                  <BarChart />
                  <Typography className={globalClasses.cardValue}>{`${car?.rents} ${t("gen:rental")}`}</Typography>
               </Grid>
               <Grid item lg={8} xl={8} md={7} sm={12} xs={12}>
                  <Timeline />
                  <Typography className={globalClasses.cardValue}>{`${car?.maintenances} ${t("translations:maintenance")}`}</Typography>
               </Grid>
            </Grid>
            <Grid container spacing={3}>
               <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                  <CommonButton
                     title={t("translations:edit")}
                     onClick={() => {
                        onNavigate("edit");
                     }}
                     icon={<Edit />}
                     fullWidth
                  />
               </Grid>
               <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                  <CommonButton title={t("translations:maintenance")} onClick={() => {}} icon={<Settings />} fullWidth />
               </Grid>
               <Grid item lg={4} xl={4} md={4} sm={12} xs={12}>
                  <CommonButton
                     title={t("translations:fines")}
                     onClick={() => {
                        onNavigate("fines");
                     }}
                     fullWidth
                     icon={<List />}
                  />
               </Grid>
            </Grid>
         </Box>
      </ContainerTopBar>
   );
};

export default VehicleCard;
