import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";

export const tableButtonStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         color: theme.palette.background.paper,
         fontSize: 11,
         border: "0px",
         padding: 5,
         borderRadius: 5,
         minWidth: 50,
      },
   })
);
