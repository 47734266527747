import { Box, Divider, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { CommonButton } from "../../../components/atoms";
import { TitleContainer } from "../../../components/molecules";
import { AlertModal, NewTariffForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { FormValidator } from "../../../utills/utills";
import { Errors } from "./index.types";
import * as tariffApi from "../../../data/api/requests/tariffs/index";
import { useParams } from "react-router-dom";

const NewTerrif = () => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "users"]);
   const { state } = useLocation<number>();

   const { id } = useParams<{ id: string }>();
   console.log(id);

   const history = useHistory();
   const [newTariff, setNewTariff] = useState({
      name: "",
      description: "",
      days: 0,
      typeId: 0,
      commissionRate: 0,
      organizations: ["0", 0, undefined],
   });
   const [errors, setErrors] = useState<Errors>({
      nameError: "",
      descriptionError: "",
      daysError: "",
      typeIdError: "",
      commissionRateError: "",
      organizationsError: "",
   });
   const [loading, setLoading] = useState(false);
   const [modalData, setModalData] = useState({ title: "", message: "" });
   const [showModal, setShowModal] = useState(false);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         _getTariff();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const _getTariff = () => {
      tariffApi
         .getTariffById(id)
         .then((res) => {
            console.log(res.data);
            setNewTariff({
               name: res.data.name,
               description: res.data.description,
               days: res.data.days,
               typeId: res.data.type.id,
               commissionRate: res.data.commissionRate,
               organizations: res.data.organizations.map((el) => el.id),
            });
         })
         .catch((err) => {
            console.log(err);
         });
   };

   const _onChange = (key: string, value: string | number | undefined) => {
      setNewTariff({ ...newTariff, [key]: value });
      const errorKey = key + "Error";
      setErrors({ ...errors, [errorKey]: "" });
   };
   const _submit = () => {
      const tempErrors = FormValidator(newTariff);
      let errorObject = Object.assign(errors);
      Object.keys(errors).map((el, index) => (errorObject = { ...errorObject, [el]: tempErrors[index] }));
      setErrors(errorObject);
      let err = false;
      tempErrors.map((el) => {
         if (el) {
            err = true;
         }
      });
      console.log(tempErrors);
      if (err) {
         return;
      }
      setLoading(true);
      if (id) {
         return _updateTariff();
      }
      tariffApi
         .addNewTariff(newTariff)
         .then((res) => {
            setModalData({ title: res.title, message: res.message });
            setShowModal(true);
         })
         .catch((Err) => setLoading(false))
         .finally(() => setLoading(false));
   };
   const _updateTariff = () => {
      tariffApi
         .updateTariffById(newTariff, id)
         .then((res) => {
            setModalData({ title: res.title, message: res.message });
            setShowModal(true);
         })
         .catch((err) => {
            setLoading(false);
         })
         .finally(() => {
            setLoading(false);
         });
   };

   return (
      <div>
         <AlertModal
            open={showModal}
            changeStatus={modalData}
            handleClose={() => {
               setShowModal(false);
               history.goBack();
            }}
         />
         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:teriff") : t("translations:new") + " " + t("translations:teriff")}
            // status={id && }
         />
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <NewTariffForm
               onChange={_onChange}
               errors={errors}
               value={newTariff}
               onMultiSelect={(item) => {
                  let temp: number[] = [];
                  item?.map((el) => {
                     if(el.id){ 
                        temp.push(el.id)
                     }
                  });
                  setNewTariff({ ...newTariff, organizations: temp });
                  setErrors({ ...errors, organizationsError: "" });
               }}
            />
            <Divider className={globalClasses.dividerMargin} />
            <CommonButton title={t("translations:save")} onClick={_submit} loading={loading} disable={loading} />
         </Box>
      </div>
   );
};

export default NewTerrif;
