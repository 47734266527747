import { Backdrop, CircularProgress } from "@material-ui/core";
import { FC } from "react";
import { loaderStyles } from "./index.styles";

interface LoaderProps {
   loading: boolean;
}

const Loader: FC<LoaderProps> = ({ loading }) => {
   const classes = loaderStyles();
   return (
      <div>
         <Backdrop className={classes.backdrop} open={loading}>
            <CircularProgress color="inherit" />
         </Backdrop>
      </div>
   );
};

export default Loader;
