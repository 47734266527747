import { Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import { AutoComplete, CommonButton, InputLabel, Margin } from "../../atoms";

interface TicketSearchProps {
   onChange: (ids: number[]) => void;
   search: () => void;
   counterId: number[];
}

const TicketSearch: FC<TicketSearchProps> = ({ onChange, search, counterId }) => {
   const { counters } = useDropdown();
   const { t } = useTranslation("translations");
   return (
      <Grid container spacing={3}>
         <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
            <InputLabel label={t("counters")} />
            <AutoComplete
               options={counters}
               renderOption={(item) => item?.officialName}
               getOptionLabel={(item) => item?.officialName}
               multiple
               label=""
               placeholder=""
               defaultValue={counters.filter((el) => counterId.find((id) => id === el.id))}
               onChange={(_, item) => {
                  let temp: number[] = [];
                  item?.map((item) => {
                     if(item.id){
                        temp.push(item?.id);
                     }
                  });
                  onChange(temp);
               }}
            />
         </Grid>
         <Grid item xl={6} lg={6} md={4} sm={12} xs={12}>
            <Margin />
            <CommonButton title={t("search")} icon={<Search />} onClick={search} />
         </Grid>
      </Grid>
   );
};

export default TicketSearch;
