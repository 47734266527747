import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { InputLabel, CommonButton, TextArea, ImagePicker, StatusLabel, TextInput } from "../../atoms";
import { ReservationLink } from "../../molecules";
import { useTranslation } from "react-i18next";
import { TicketDescriptionProps } from "./index.types";
import moment from "moment";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { useFormik } from "formik";
import { useValidation } from "../../../utills/validation";
import useAuth from "../../../data/context/authContext/AuthContext";
import { padId } from "../../../utills/masks";
const TicketDescription: FC<TicketDescriptionProps> = ({ data, onReservationClick, onSubmit, loading }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation("translations");
   const { ticketSchema } = useValidation();
   const formik = useFormik({
      initialValues: {
         message: "",
         fileAttachment: File,
         fileAttachmentDescription: "",
         fileString: "",
      },
      validationSchema: ticketSchema,
      onSubmit: (values) => {
         let uploadJson: any = { ...values };
         delete uploadJson["fileString"];
         onSubmit(uploadJson);
      },
   });
   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("user")}:`} font={true} />
               <InputLabel label={data?.user.name || ""} />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("subject")}:`} font={true} />
               <InputLabel label={data?.subject.name || ""} />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("status")}:`} font={true} />
               <StatusLabel status={data?.status} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("contact")}:`} font={true} />
               <div className={globalClasses.flexRow}>
                  <Typography className={globalClasses.linkText}>
                     <a href={`tel:${data?.user?.phoneCountry.phoneCode || "" + data?.user?.phone || ""}`}>
                        {data?.user?.phoneCountry.phoneCode || ""} {data?.user?.phone || ""}
                     </a>
                  </Typography>
                  /
                  <Typography className={globalClasses.linkText}>
                     <a href={`mailto:${data?.user?.email || ""}`}>{data?.user?.email || ""}</a>
                  </Typography>
               </div>
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={`${t("registeredIn")}:`} font={true} />
               {data && <InputLabel label={moment(data.registration).format("DD/MM/YYYY HH:mm")} />}
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <Typography className={globalClasses.smallerText}>
                  {t("withDrawl")}: {data?.reservation.pickupOrganization}
               </Typography>
               <Typography className={globalClasses.smallerText}>
                  {t("deliverIn")}: {data?.reservation.returnOrganization}
               </Typography>
               <ReservationLink id={data?.reservation.id && padId(data?.reservation.id, 4)} onClick={onReservationClick} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={8} lg={8} md={10} sm={12} xs={12}>
               <InputLabel label={`${t("message")}:`} font={true} />
               <InputLabel label={data?.note || ""} />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <div>
            <InputLabel label={t("newMessage")} />
            <TextArea
               onChange={(event) => {
                  formik.setFieldValue("message", event.target.value);
               }}
            />
            <Typography className={globalClasses.errorText}>{formik.errors.message}</Typography>
         </div>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
               <Typography className={`${globalClasses.titleText} ${globalClasses.margin}`}>{t("attachment")}</Typography>
               <ImagePicker
                  onChange={(event) => {
                     formik.setFieldValue("fileAttachment", event.target.files[0]);
                     formik.setFieldValue("fileString", "files");
                  }}
               />
               <TextInput
                  title={t("writeDescription")}
                  id="fileAttachmentDescription"
                  disabled={false}
                  type="text"
                  error={formik.touched.fileAttachmentDescription && Boolean(formik.errors.fileAttachmentDescription)}
                  helperText={formik.touched.fileAttachmentDescription && formik.errors.fileAttachmentDescription}
                  onChange={formik.handleChange}
               />
               <CommonButton title={t("add")} onClick={formik.handleSubmit} loading={loading} disable={loading} />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <div className={globalClasses.tableTitleBox}>
            <Typography className={globalClasses.cardTitle}></Typography>
         </div>
         <div className={globalClasses.marginLeft}>
            <DataTable data={data?.messages || []}>
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("message")} render={(item) => item.note} />
               <TableColumn headerText={t("attachment")} render={(item) => ""} />
               <TableColumn
                  headerText={t("registeredIn")}
                  render={(item) => (
                     <>
                        <div>{moment(item.registration).format("DD/MM/YYYY HH:mm")}</div>
                        <div className={globalClasses.tableSmallText}>
                           {t("by")}: {item.user.name}
                        </div>
                     </>
                  )}
               />
            </DataTable>
         </div>
      </Box>
   );
};

export default TicketDescription;
