import { Box, Typography, Divider } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import DataTable from "../tables/datatable/DataTable";
import TableColumn from "../tables/table-column/TableColumn";
import { CommonButton } from "../../atoms";
import { COLORS } from "../../../data/constants/Colors";
import Transaction from "../../../data/models/transaction.model";
import { FC } from "react";
import { formatDate } from "../../../utills/helper";

interface TollItemProps {
   data?: Transaction[];
   close: () => void;
}

const TollModalItem: FC<TollItemProps> = ({ data, close }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["tolls", "gen", "translations", "waybills", "transaction"]);

   return (
      <>
         <Box className={globalClasses.cardTitleCOntainer}>
            <Typography className={globalClasses.cardTitle}>{t("tolls:tollRegister")}</Typography>
            <span onClick={close}>
               <Close className={globalClasses.tableIcon} />
            </span>
         </Box>
         <Divider className={globalClasses.margin} />
         <Box className={globalClasses.paddingContainer}>
            <DataTable data={data || []} search={false}>
               <TableColumn headerText={t("tolls:cod")} render={(item) => item.transactionId} />
               <TableColumn headerText={t("gen:enrollDev")} render={(item) => formatDate(item.registration)} />
               <TableColumn headerText={t("gen:location") + "/" + t("translations:date")} render={(item) => item.outTollName} />
               <TableColumn headerText={t("translations:value") + "/" + t("translations:invoice")} render={(item) => ""} />
            </DataTable>
         </Box>
         <Divider />
         <Box className={`${globalClasses.paddingContainer} ${globalClasses.flexEnd}`}>
            <CommonButton
               title={t("waybills:print")}
               onClick={() => {
                  window.print();
               }}
            />
            <span className={globalClasses.marginLeft} />
            <CommonButton color={COLORS.greyText} title={t("transaction:close")} onClick={close} />
         </Box>
      </>
   );
};

export default TollModalItem;
