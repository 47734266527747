import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Bookings, TitleContainer, VehicleCard } from "../../../components/molecules";
import { useRequest } from "../../../data/api/wrapper";
import * as carApi from "../../../data/api/requests/cars/index";
import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { FreePeriods, ReservationSearch } from "../../../components/atoms";

interface paramProps {
   id: string;
}

const VehicleDetail = () => {
   const { id } = useParams<paramProps>();

   const { t } = useTranslation(["vehicles", "translations"]);

   const car = useRequest(() => carApi.getCarByID(id));
   const recentReservations = useRequest(() => carApi.getCarRecentReservations(id));
   const searchResByDate = useRequest((params) => carApi.carReservationByDate(params, id));
   const freePeriods = useRequest(() => carApi.freePeriods(id));

   const history = useHistory();

   const [date, setDate] = useState({ date: "", time: "" });
   const [dateError, setDateError] = useState("");

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = false;
      };
   }, []);

   const getData = () => {
      car.execute();
      recentReservations.execute();
      freePeriods.execute();
   };

   const handleNavigate = (route: string) => {
      switch (route) {
         case "fines":
            history.push(`/vehiclemanagment/fines/${car.data?.id}`, `${car.data?.licensePlate} - ${car.data?.name}`);
            break;
         case "edit":
            history.push(`/vehiclemanagment/vehicles/form/${car.data?.id}`, `${car.data?.licensePlate} - ${car.data?.name}`);
            break;

         default:
            break;
      }
   };

   const onReservationDetailClick = (id: number) => {
      history.push(`/reservation/form/${id}`);
   };

   const seeAllReservations = () => {};

   const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setDateError("");
      setDate({ ...date, [e.target.id]: e.target.value });
   };

   const onReservationSearch = () => {
      if (!date.date) {
         return setDateError(t("vehicles:emptyField"));
      }
      searchResByDate.execute(date);
   };

   console.log(car.data);

   return (
      <>
         <TitleContainer title={t("vehicles:vehicleDetail")} />

         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <VehicleCard car={car?.data} onNavigate={handleNavigate} />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <Bookings reservations={recentReservations.data} onClick={onReservationDetailClick} onSeeAllClick={seeAllReservations} />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <ReservationSearch
                  onChange={onChange}
                  onReservationSearch={onReservationSearch}
                  reservation={searchResByDate?.data && searchResByDate.data[0]}
                  loading={searchResByDate.loading}
                  error={dateError}
                  onResClick={onReservationDetailClick}
               />

               <FreePeriods data={freePeriods.data} />
            </Grid>
         </Grid>
      </>
   );
};

export default VehicleDetail;
