import { Box } from "@material-ui/core";
import { Add, AddCircle } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { listUserStyles } from "./indes.styles";
import { CommonButton, Loader, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { ContainerTopBar } from "../../../components/molecules";
import * as userApi from "../../../data/api/requests/users/index";
import { useEffect, useState } from "react";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import Pagination from "../../../data/models/pagination.model";
import User from "../../../data/models/user.model";

const ListAllUsers = () => {
   const classes = listUserStyles();
   const globalClasses = globalStyles();
   const history = useHistory();
   const { t } = useTranslation(["translations", "users"]);
   const [pagination, setPagination] = useState({
      page: 0,
      size: 10,
   });
   const [searchString, setSearchString] = useState("");
   const getUsers = useRequest(() => userApi.getAllUsers(pagination.size, pagination.page, searchString));
   const promoteToAdmin = useRequest((params) => userApi.promoteUserToAdmin(params));
   const changeStatus = useRequest((params) => userApi.changeUserStatus(params));
   const resetPassword = useRequest((params) => userApi.resetUserPassword(params));

   useEffect(() => {
      if (changeStatus.data) {
         const index = getUsers.data?.data.findIndex((el) => el.id == changeStatus?.data?.id);
         const { data } = getUsers;
         let users: Pagination<User[]> = Object.assign(data!!);
         users.data[index!!] = changeStatus.data;
         getUsers.setData(users);
      }
   }, [changeStatus.data]);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getUsers.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchString]);

   const onTableDropdownClick = (name: string, item: ReturnType<any>) => {
      switch (name) {
         case t("translations:edit"):
            history.push(`/users/form/${item.id}`);
            break;
         case t("translations:changeStatus"):
            changeStatus.execute(item.id);
            break;
         case t("users:promoteAdm"):
            promoteToAdmin.execute(item.id);
            break;
         case t("users:resetPassword"):
            resetPassword.execute(item.id);
            break;
         case t("users:creditCards"):
            history.push(`/payment-cards/${item.id}`, { typeId: 1, name: item.name });
            break;
         case t("users:sendMessage"):
            history.push(ROUTE_LINKS.NEW_MESSAGE, { user: item.id });
            break;
         default:
            break;
      }
   };

   return (
      <Box>
         <Loader loading={promoteToAdmin.loading || resetPassword.loading || changeStatus.loading} />

         {promoteToAdmin.show.toast && (
            <Toast
               show={promoteToAdmin.show.toast}
               message={promoteToAdmin.message}
               handleClose={() => {
                  promoteToAdmin.close();
               }}
            />
         )}
         {resetPassword.show.toast && (
            <Toast show={resetPassword.show.toast} message={resetPassword.message} handleClose={() => resetPassword.close()} />
         )}
         {changeStatus.show.toast && (
            <Toast
               show={changeStatus.show.toast}
               message={changeStatus.message}
               handleClose={() => {
                  changeStatus.close();
               }}
            />
         )}
         <Box className={globalClasses.title}>{t("translations:users")}</Box>
         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.CREATE_USER);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={getUsers.loading}>
            <DataTable
               data={getUsers?.data?.data || []}
               pagination
               search
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               total={getUsers.data?.total}
               onSearching={(query) => setSearchString(query)}
               stripedRows
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("users:taxId")} render={(item) => item.taxId} />
               <TableColumn headerText={t("users:name")} render={(item) => item.name} />
               <TableColumn headerText={t("translations:email")} render={(item) => item.email} />
               <TableColumn headerText={t("users:telephone")} render={(item) => item.phone} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[
                           t("translations:edit"),
                           t("translations:changeStatus"),
                           t("users:promoteAdm"),
                           t("users:resetPassword"),
                           t("users:creditCards"),
                           t("users:sendMessage"),
                        ]}
                        onClick={(action) => {
                           onTableDropdownClick(action, item);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </Box>
   );
};
export default ListAllUsers;
