import { TextareaAutosize } from "@material-ui/core";
import { FC } from "react";
import { textAreaStyle } from "./index.styles";
import { TextAreaProps } from "./index.types";

const TextArea: FC<TextAreaProps> = ({ onChange, value, error, numRows, id, disabled }) => {
   const classes = textAreaStyle();
   return (
      <TextareaAutosize
         minRows={5.5}
         disabled={disabled}
         maxRows={numRows ? numRows : 7}
         aria-label="new message"
         id={id}
         className={classes.textArea}
         value={value}
         onChange={(e) => {
            onChange(e);
         }}
      ></TextareaAutosize>
   );
};

export default TextArea;
