import { Box, Typography } from "@material-ui/core";
import { MutableRefObject, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Alert, AlertDailog, Loader, Toast } from "../../../components/atoms";
import { TitleContainer } from "../../../components/molecules";
import { NewOrganizationForm } from "../../../components/organisms";
import * as counterApi from "../../../data/api/requests/counters/index";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

function AddnewCounter() {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "organization"]);

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const counters = useRequest(() => counterApi.getCounterById(id));

   const getStates = useRequest((params) => dropdownApi.getStates(params));
   const getCities = useRequest((params) => dropdownApi.getCities(params));
   const updateCounter = useRequest((params) => counterApi.updateCounterById(params, id));
   const newCounter = useRequest((params) => counterApi.createCounter(params));

   useEffect(() => {
      let unsubscribe = false;
      if (id && !unsubscribe) {
         counters.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (counters.data) {
         getStates.execute(counters.data.country.id);
         getCities.execute(counters.data.state.id);
      }
   }, [counters.data]);

   const _submit = (value: any) => {
      if (id) {
         updateCounter.execute(value);
      } else {
         newCounter.execute(value);
      }
   };

   return (
      <div>
         <Loader loading={newCounter.loading || updateCounter.loading || counters.loading} />

         {newCounter.show.toast && (
            <Toast
               show={newCounter.show.toast}
               message={newCounter.message}
               handleClose={() => {
                  newCounter.close();
               }}
            />
         )}
         {updateCounter.show.toast && (
            <Toast
               show={updateCounter.show.toast}
               message={updateCounter.message}
               handleClose={() => {
                  updateCounter.close();
               }}
            />
         )}

         {newCounter.show.modal && (
            <AlertDailog
               show={newCounter.show.toast}
               title={newCounter.message.message}
               message={newCounter.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => history.push(ROUTE_LINKS.LIST_COUNTERS)}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  newCounter.close();
                  clearForm.current();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("organization:counter") : t("translations:new") + " " + t("organization:counter")}
         />

         <Box className={globalClasses.container} style={{ padding: 15 }}>
            <Typography className={globalClasses.cardTitle}>Local:</Typography>
            <NewOrganizationForm
               counter={false}
               states={getStates.data || []}
               cities={getCities.data || []}
               values={counters.data}
               getCity={(id) => {
                  getCities.execute(id);
               }}
               getState={(id) => {
                  getStates.execute(id);
               }}
               onSubmit={_submit}
               clearForm={clearForm}
            />
         </Box>
      </div>
   );
}

export default AddnewCounter;
