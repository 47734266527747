import { Box, Divider, Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { AlertDailog, CommonButton, InputLabel, Loader, TextInput, Toast } from "../../../../components/atoms";
import { TitleContainer } from "../../../../components/molecules";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import Brand from "../../../../data/models/brand.model";
import * as brandApi from "../../../../data/api/requests/brands/index";
import { useRequest } from "../../../../data/api/wrapper";
import { CheckCircleOutline } from "@material-ui/icons";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
function NewBrand() {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "users", "vehicles"]);
   const { state } = useLocation<Brand>();
   const { id } = useParams<{ id: string }>();

   const history = useHistory();
   const [brand, setBrand] = useState<string>("");
   const [error, setError] = useState("");

   const addBrand = useRequest((params) => brandApi.saveBrand(params));
   const updateBrand = useRequest((params) => brandApi.updateBrand(id, params));

   useEffect(() => {
      if (id) {
         getBrand();
      }
   }, []);

   const getBrand = async () => {
      brandApi
         .getBrandByID(id)
         .then((res) => setBrand(res.data.name))
         .catch((err) => {
            throw err;
         });
   };

   const _handleClick = () => {
      if (brand === "") {
         return setError(t("vehicles:emptyField"));
      }
      if (id) {
         updateBrand.execute(brand);
      } else {
         addBrand.execute(brand);
      }
   };

   return (
      <div>
         <Loader loading={addBrand.loading || updateBrand.loading} />

         {updateBrand.show.toast && (
            <Toast
               show={updateBrand.show.toast}
               message={updateBrand.message}
               handleClose={() => {
                  updateBrand.close();
               }}
            />
         )}

         {addBrand.show.modal && (
            <AlertDailog
               show={addBrand.show.modal}
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               title={addBrand.message.message}
               message={addBrand.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  addBrand.close();
                  history.push(ROUTE_LINKS.VEHICLE_BRANDS);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  setBrand("");
                  addBrand.close();
               }}
            />
         )}
         <TitleContainer title={id ? t("translations:edit") + " " + t("vehicles:brand") : t("translations:new") + " " + t("vehicles:brand")} />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Grid container spacing={3}>
               <Grid item xl={10} lg={10} md={12} xs={12}>
                  <InputLabel label={t("users:name")} />
                  <TextInput
                     title=""
                     helperText={error}
                     id="name"
                     error={!!error}
                     disabled={false}
                     type="text"
                     value={brand !== "" ? brand : ""}
                     onChange={(event) => {
                        setBrand(event.target.value);
                        setError("");
                     }}
                  />
               </Grid>
            </Grid>
            <Divider className={globalClasses.dividerMargin} />
            <CommonButton title={t("translations:save")} onClick={_handleClick} />
         </Box>
      </div>
   );
}

export default NewBrand;
