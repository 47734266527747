import { Box } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton, Loader, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as countersApi from "../../../data/api/requests/counters/index";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import Pagination from "../../../data/models/pagination.model";
import Organization from "../../../data/models/organization.model";
import { AddCircle } from "@material-ui/icons";
function ListCounters() {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "users"]);

   const history = useHistory();

   const counter = useRequest(() => countersApi.getCounters(pagination.page, pagination.size, searchString));
   const changeCounterStatus = useRequest((params) => countersApi.changeCounterStatus(params));

   const [pagination, setPagination] = useState({ size: 10, page: 0 });
   const [searchString, setSearchString] = useState("");

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         counter.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination]);

   useEffect(() => {
      if (changeCounterStatus.data) {
         const index = counter.data?.data.findIndex((el) => el.id == changeCounterStatus?.data?.id);
         const { data } = counter;
         let countersss: Pagination<Organization[]> = Object.assign(data!!);
         countersss.data[index!!] = changeCounterStatus.data;
         counter.setData(countersss);
      }
   }, [changeCounterStatus.data]);

   const handleDropdownClick = (action: string, id: number) => {
      switch (action) {
         case t("translations:changeStatus"):
            changeCounterStatus.execute(id);
            break;
         case t("translations:edit"):
            history.push(`/counters/form/${id}`);
            break;
         default:
            break;
      }
   };

   return (
      <div>
         {changeCounterStatus.show.toast && (
            <Toast
               show={changeCounterStatus.show.toast}
               message={changeCounterStatus.message}
               handleClose={() => {
                  changeCounterStatus.close();
                  // counter.execute();
               }}
            />
         )}

         <Loader loading={changeCounterStatus.loading} />

         <TitleContainer title={t("translations:counters")} />

         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_COUNTER);
            }}
         />

         <ContainerTopBar title={t("translations:listing")} activity={counter.loading}>
            <DataTable
               data={counter.data?.data || []}
               onChange={(page, rowsPerPage) => {
                  setPagination({ page: page, size: rowsPerPage });
               }}
               onSearching={(query) => setSearchString(query)}
               pagination
               total={counter.data?.total}
               stripedRows
            >
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn headerText={t("users:nif")} render={(item) => item.taxId} />
               <TableColumn headerText={t("users:name")} render={(item) => item.officialName} />
               <TableColumn headerText={t("translations:email")} render={(item) => item.email} />
               <TableColumn headerText={t("users:telephone")} render={(item) => item.phone} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:status")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(action) => {
                           handleDropdownClick(action, item.id);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
}

export default ListCounters;
