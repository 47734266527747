import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../data/constants/Colors";

export const splashStyle = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         color: theme.palette.background.paper,
         position: "absolute",
         top: 0,
         bottom: 0,
         left: 0,
         right: 0,
         display: "flex",
         flexWrap: "wrap",
         flexDirection: "column",
         alignItems: "center",
         justifyContent: "center",
      },

      label: {
         color: COLORS.darkText,
      },
   })
);
