import { CircularProgress } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

const ActivityIndicator = () => {
  const globalClasses = globalStyles();
  return (
    <div className={`${globalClasses.alignCenter}`}>
      <CircularProgress size={20} />
    </div>
  );
};

export default ActivityIndicator;
