import { encodeDate, getCounterFromLocalStorage } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import Car from "../../../models/car.model";
import { Invoice } from "../../../models/invoice.model";
import { Movement } from "../../../models/Movement.model";
import Reservation from "../../../models/reservation.model";
import { API_ROUTES } from "../../apiRoutes";
import { request } from "../../wrapper";

export async function getPendingReservations(): Promise<ApiResponse<Reservation[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getPendingReservations,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Reservation[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getParkedVehicles(): Promise<ApiResponse<Car[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getParkedCars,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Car[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDelayedDeliveries(): Promise<ApiResponse<Reservation[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getDelayedDeliveries,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Reservation[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getPendingInvoices(): Promise<ApiResponse<Invoice[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getUngeneratedInvoices,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Invoice[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDayMovements(date: string): Promise<ApiResponse<Movement>> {
   const id = await getCounterFromLocalStorage(); //TODO remove from here, already send in headers
   date = encodeDate(date, "");
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getReservationSummary + `?organizationId=${id}&date=${date}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Movement> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
