import React, { FC, useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import Autocomplete from "../../atoms/autocomplete/Autocomplete";
import { AutoComplete, CommonButton, InputLabel, TableItem, TextInput } from "../../atoms";
import DataTable from "../tables/datatable/DataTable";
import TableColumn from "../tables/table-column/TableColumn";
import Service from "../../../data/models/reservation-service.model";
import { formatDate, formatTime } from "../../../utills/helper";
import { putDecimalPoints } from "../../../utills/masks";
import { IconButton } from "../../atoms";
import { COLORS } from "../../../data/constants/Colors";
import { AddCircle, ArrowForward, ArrowRight, Close, CreditCard, Refresh } from "@material-ui/icons";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import User from "../../../data/models/user.model";
import { useFormik } from "formik";
import useAuth from "../../../data/context/authContext/AuthContext";
import ReservationType from "../../../data/models/reservation-type.model";
import { useRequest } from "../../../data/api/wrapper";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import DiscountBillable from "../../../data/models/billable-discount.modal";
import moment from "moment";
import { useValidation } from "../../../utills/validation";
import UserDropdownItem from "../../atoms/userDropdownItem/UserDropdownItem";
import { useEffectAfterMount } from "../../../data/useEffectAfterMount";
import useAPIError from "../../../data/context/error";

interface BillingProps {
   data: Service[];
   onRemove: (id: number) => void;
   createBilling: (values: any) => void;
   refreshList: () => void;
   customers: User[] | undefined;
   accountTypes: ReservationType[] | undefined;
   showDiscountDailog: () => void;
   discounts?: DiscountBillable[];
   navigateToServices: () => void;
}

const BillingItem: FC<BillingProps> = ({
   data,
   onRemove,
   createBilling,
   refreshList,
   customers,
   accountTypes,
   showDiscountDailog,
   discounts,
   navigateToServices,
}) => {
   const globalClasses = globalStyles();

   useEffect(() => {
      calculateSubTotal();
   }, []);

   const { t } = useTranslation(["transaction", "translations", "gen"]);

   const { paymentMethods, discountTypes } = useDropdown();

   const { billingSchema } = useValidation();

   const { addError } = useAPIError();

   const billableItems = useRequest((params) => dropdownApi.getBillableItems(params));

   const formik = useFormik({
      initialValues: {
         dueDate: moment(new Date().getTime()).format("YYYY-MM-DD"),
         paymentMethodId: 0,
         clientId: 0,
         accountId: 0,
         clientType: 0,
         discountRequestId: null,
      },
      validationSchema: billingSchema,
      validateOnMount: false,
      validateOnBlur: false,
      onSubmit: (values) => {
         const userHasCard = checkPaymentMethod();
         console.log();
         if (!userHasCard) {
            return addError({ title: t("transaction:cardErrorTitle"), message: t("transaction:carErrorMessage") });
         }
         createBilling(values);
      },
   });

   const checkPaymentMethod = () => {
      if (formik.values.paymentMethodId === 6) {
         const user = customers?.find((el) => el.id == formik.values.clientId);
         if (!user?.hasCard) {
            return false;
         }
         return true;
      }
      return true;
   };

   const calculateSubTotal = () => {
      const totalValue = data.reduce((a, el) => (el.itemValue - el.discount) * el.quantity + a, 0);
      const tax = data.reduce((a, el) => (el.itemValue - el.discount) * el.quantity * (el.itemTax / 100) + a, 0);
      return (
         <Typography className={globalClasses.margin}>
            {t("translations:total")}: {putDecimalPoints(totalValue.toFixed(2))}€ + {t("translations:vat")}
            {putDecimalPoints(tax.toFixed(2))}€ = <span className={globalClasses.redText}>{putDecimalPoints((totalValue + tax).toFixed(2))}€</span>
         </Typography>
      );
   };

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Typography className={globalClasses.titleText}>{t("transaction:generate")}</Typography>
         {data?.length > 0 && (
            <>
               <Grid container spacing={3}>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                     <InputLabel label={t("translations:client")} />
                     <Autocomplete
                        options={customers || []}
                        renderOption={(item) => <UserDropdownItem user={item} />}
                        label=""
                        placeholder=""
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => {
                           formik.setFieldValue("clientId", item?.id); // TODO Confirm the type and set type here
                           console.log(item);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.clientId}</Typography>
                  </Grid>
                  <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                     <InputLabel label={t("transaction:formOfPayment")} />
                     <Autocomplete
                        options={paymentMethods || []}
                        renderOption={(item) => item.name}
                        label=""
                        placeholder=""
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => {
                           formik.setFieldValue("paymentMethodId", item?.id);
                        }}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.paymentMethodId}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={3}>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                     <InputLabel label={t("gen:account")} />
                     <Autocomplete
                        options={accountTypes || []}
                        renderOption={(item) => item.name}
                        label=""
                        placeholder=""
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("accountId", item?.id)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.accountId}</Typography>
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("translations:dueDate")} />
                     <TextInput
                        title=""
                        disabled={false}
                        id="dueDate"
                        type="date"
                        error={formik.touched.dueDate && Boolean(formik.errors.dueDate)}
                        helperText={formik.touched.dueDate && formik.errors.dueDate}
                        value={formik.values.dueDate}
                        onChange={formik.handleChange}
                     />
                  </Grid>
                  <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
                     <InputLabel label={t("translations:discount")} />
                     <AutoComplete
                        label=""
                        placeholder=""
                        options={discounts || []}
                        getOptionLabel={(item) => item.note}
                        renderOption={(item) => item.note}
                        onChange={(_, item) => formik.setFieldValue("discountRequestId", item?.discountRequestId)}
                     />
                     <Typography className={globalClasses.errorText}>{formik.errors.discountRequestId}</Typography>
                  </Grid>
                  <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
                     <CommonButton
                        margin={25}
                        title={t("transaction:requestDiscount")}
                        onClick={() => {
                           showDiscountDailog();
                        }}
                     />
                  </Grid>
               </Grid>
               <Divider className={globalClasses.dividerMargin} />
               <Typography className={globalClasses.cardTitle}>{t("transaction:itemsToBill")}</Typography>
               <DataTable data={data} search={false}>
                  <TableColumn headerText="ID" render={(item) => item.id} />
                  <TableColumn
                     headerText={t("translations:date")}
                     render={(item) => <TableItem title={formatDate(item.registration)} subtitle={formatTime(item.registration)} />}
                  />
                  <TableColumn
                     headerText={t("translations:description")}
                     render={(item) => <TableItem title={item?.item?.name} subtitle={item?.item?.description} />}
                  />
                  <TableColumn headerText={t("transaction:quantity")} render={(item) => item.quantity} />
                  <TableColumn headerText={t("translations:value")} render={(item) => putDecimalPoints(item.itemValue)} />
                  <TableColumn headerText={t("translations:discount")} render={(item) => putDecimalPoints(item.discount)} />
                  <TableColumn headerText={t("translations:vat")} render={(item) => putDecimalPoints(item.itemTax)} />
                  <TableColumn headerText={t("translations:subTotal")} render={(item) => item.total} />
                  <TableColumn
                     render={(item) => (
                        <IconButton
                           color={COLORS.redLabel}
                           onClick={() => {
                              onRemove(item.id);
                           }}
                           icon={<Close className={globalClasses.tableSmallIcon} />}
                        />
                     )}
                  />
               </DataTable>
               {calculateSubTotal()}
            </>
         )}
         <Box className={globalClasses.cardTitleCOntainer}>
            <span>
               {data?.length > 0 && (
                  <>
                     <CommonButton icon={<AddCircle />} title={t("transaction:createBilling")} onClick={formik.handleSubmit} />
                     <span className={globalClasses.marginLeft} />
                  </>
               )}
               <CommonButton icon={<Refresh />} title={t("transaction:refresh")} onClick={refreshList} />
            </span>
            <CommonButton icon={<ArrowForward />} title={t("transaction:goToServices")} onClick={navigateToServices} variant="text" />
         </Box>
      </Box>
   );
};

export default BillingItem;
