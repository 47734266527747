import { ArrowBack } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton, TableButton, TableItem, Toast, UpdateStatus } from "../../../components/atoms";
import { ContainerTopBar, InvoiceItemType, PopupModal, TitleContainer } from "../../../components/molecules";
import { OrphanToll } from "../../../data/models/orphan-tolls";
import * as tollApi from "../../../data/api/requests/tolls/index";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import { AlertModal } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import moment from "moment";
import { putDecimalPoints } from "../../../utills/masks";
import { COLORS } from "../../../data/constants/Colors";
import LinkItem from "../../../components/atoms/modal-items/link/LinkItem";
import Transaction from "../../../data/models/transaction.model";
const NoBonds = () => {
   const history = useHistory();
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "gen", "tolls", "dashboardStrings"]);
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");

   const orphanTolls = useRequest(() => tollApi.getOrphanTolls(pagination.page, pagination.size, searchQuery));
   const registerPayment = useRequest((params) => tollApi.registerPayment(params));
   const reservation = useRequest((params) => reservationApi.getReservationById(params));
   const linkToll = useRequest((params) => tollApi.linkToll(params));
   const changeStatus = useRequest((params) => tollApi.changeTollStatus(params));

   const [openModal, setModalOpen] = useState(false);
   const [modalOptions, setModalOptions] = useState({
      updateStatus: false,
      link: false,
      registerPayment: false,
   });
   const [item, setItem] = useState<Transaction>();
   const [open, setOpen] = useState(false);
   const [type, setType] = useState(0);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         orphanTolls.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchQuery]);

   const refreshList = () => {
      registerPayment.close();

      if (!registerPayment.message.error) {
         orphanTolls.execute();
      }
   };

   const _registerPayment = () => {
      if (item) {
         registerPayment.execute(item.transactionId);
      }
   };

   const getReservation = (id: string | number | undefined) => {
      reservation.execute(id);
   };

   const confirmBooking = (id: number) => {
      const data = { transactionId: item?.transactionId, reservationId: id };
      linkToll.execute(data);
      setOpen(false);
      reservation.setData(undefined);
   };

   const changeTollStatus = (payload: any) => {
      payload = { ...payload, transactionId: item?.transactionId };
      changeStatus.execute(payload);
      setOpen(false);
   };

   const getComponent = () => {
      if (type == 0) {
         return (
            <LinkItem
               reservation={reservation.data}
               loading={reservation.loading}
               onConfirm={confirmBooking}
               onSearchClick={getReservation}
               onCancel={() => {
                  setOpen(false);
                  reservation.setData(undefined);
               }}
            />
         );
      } else if (type == 3) {
         return <InvoiceItemType handleClose={() => {}} handleSubmit={() => {}} />;
         // <UpdateStatus changeStatus={changeTollStatus} close={() => setOpen(false)} />;
      }
   };

   return (
      <div>
         <TitleContainer title={t("translations:tollsWithoutReservations")} />
         {registerPayment.show.toast && <Toast show={registerPayment.show.toast} message={registerPayment.message} handleClose={refreshList} />}

         {linkToll.show.toast && (
            <Toast
               show={linkToll.show.toast}
               message={linkToll.message}
               handleClose={() => {
                  linkToll.close();
                  orphanTolls.execute();
               }}
            />
         )}

         {changeStatus.show.toast && (
            <Toast
               show={changeStatus.show.toast}
               message={changeStatus.message}
               handleClose={() => {
                  changeStatus.close();
                  orphanTolls.execute();
               }}
            />
         )}

         <PopupModal
            show={open}
            handleClose={() => {
               setOpen(false);
            }}
            render={getComponent}
         />
         <AlertModal
            open={openModal}
            handleClose={() => {
               setModalOpen(false);
               setModalOptions({
                  updateStatus: false,
                  link: false,
                  registerPayment: false,
               });
            }}
            modalOptions={modalOptions}
            action={(name) => {
               setModalOpen(false);
               if (name === "register") {
                  _registerPayment();
               }
            }}
         />
         <CommonButton title={t("translations:backButton")} onClick={() => history.goBack()} icon={<ArrowBack />} />
         <ContainerTopBar title={`${t("translations:value")}`} activity={orphanTolls.loading}>
            <DataTable
               data={orphanTolls.data?.data || []}
               pagination
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               onSearching={(query) => setSearchQuery(query)}
               total={orphanTolls.data?.total}
               stripedRows
            >
               <TableColumn headerText={"Cod."} render={(item) => item.transactionId} />
               <TableColumn
                  headerText={t("gen:enrollDev")}
                  render={(item) => (
                     <>
                        <span className={globalClasses.greyBox}>{item.carPlate}</span>
                        <div className={globalClasses.tableSmallText}>{item.obenumber}</div>
                     </>
                  )}
               />
               <TableColumn
                  headerText={t("gen:exit")}
                  render={(item) => <TableItem title={item.outTollName} subtitle={moment(item.outDate).format("DD/MM/YYYY HH:mm")} />}
               />
               <TableColumn headerText={t("translations:value")} render={(item) => `€ ${putDecimalPoints(item.fare)}`} />
               <TableColumn headerText={t("translations:status")} render={(item) => item?.status?.name} />
               <TableColumn
                  align='right'
                  headerOptions={{ align: "right" }}
                  headerText={t("dashboardStrings:reservation")}
                  render={(item) => (
                     <>
                        <TableButton
                           title={t("tolls:update")}
                           onClick={() => {
                              setItem(item);
                              setOpen(true);
                              setType(3);
                           }}
                        />
                        <span className={globalClasses.marginLeft} />
                        <TableButton
                           title={t("tolls:link")}
                           onClick={() => {
                              setItem(item);
                              setOpen(true);
                              setType(0);
                           }}
                           color={COLORS.greenLabel}
                        />
                        <span className={globalClasses.marginLeft} />
                        <TableButton
                           title={t("tolls:register")}
                           color={COLORS.darkText}
                           onClick={() => {
                              setItem(item);
                              setModalOpen(true);
                              setModalOptions({
                                 updateStatus: false,
                                 link: false,
                                 registerPayment: true,
                              });
                           }}
                        />
                     </>
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default NoBonds;
