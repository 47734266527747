import moment from "moment";
import ApiResponse from "../../../models/api-response.model";
import { UserPendingFinancials } from "../../../models/financials.model";
import Pagination from "../../../models/pagination.model";
import Card from "../../../models/Payment-Card.model";
import ReservationType from "../../../models/reservation-type.model";
import { Message } from "../../../models/send-message.modal";
import { UserCards } from "../../../models/user-cards.model";
import User from "../../../models/user.model";
import { UserForUpload } from "../../../models/user.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export async function getAllUsers(size: number, page: number, search: string | undefined): Promise<ApiResponse<Pagination<User[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.baseUrl + API_ROUTES.users + `?size=${size}&page=${page}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<User[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUserById(userId: string): Promise<ApiResponse<User>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.users + `/${userId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<User> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUserPendingFields(userId: number): Promise<ApiResponse<string[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.pendingFields.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<string[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUserPendingDocuments(userId: number): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.pendingDocuments.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<ReservationType[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getUserPendingFinancials(userId: number): Promise<ApiResponse<UserPendingFinancials[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.userPendingFinancials.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<UserPendingFinancials[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addUser(data: { user: UserForUpload; file: File }): Promise<ApiResponse<void>> {
   let downlink = "";
   if (data.file?.size) {
      const image = new FormData();
      image.append("file", data.file);
      const res = await uploadFile(image);
      downlink = res.data.file;
   }
   const dataToUpload = {
      ...data.user,
      profileImage: downlink,
      cityId: data.user.cityId === 0 ? null : data.user.cityId,
      stateId: data.user.stateId === 0 ? null : data.user.stateId,
   };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.users,
         data: { ...dataToUpload },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateUser(
   data: {
      user: UserForUpload;
      file: File;
   },
   id: string
): Promise<ApiResponse<void>> {
   let downlink = "";
   if (data.file?.size) {
      const image = new FormData();
      image.append("file", data.file);
      const res = await uploadFile(image);
      downlink = res.data.file;
   } else {
      downlink = data.user.profileImage;
   }
   const dataToUpload = {
      ...data.user,
      profileImage: downlink,
      cityId: data.user.cityId === 0 ? null : data.user.cityId,
      stateId: data.user.stateId === 0 ? null : data.user.stateId,
   };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.updateUsers.format(id),
         data: { ...dataToUpload },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeUserStatus(userId: number): Promise<ApiResponse<User>> {
   try {
      const resp = await request({
         method: "POST",
         url: API_ROUTES.changeUserStatus.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<User> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function promoteUserToAdmin(userId: number): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.promoteAdm,
         data: { userId },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function sendMessageToUser(datatosend: Message): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.sendMessage.format(datatosend.userId),
         data: datatosend,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function resetUserPassword(userId: number): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.resetUserPassword.format(userId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUserByFiscalNumber(taxId: string): Promise<ApiResponse<User>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.getUserByTaxId,
         data: { taxId },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<User> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUserCards(id: string): Promise<ApiResponse<UserCards>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.userCards.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<UserCards> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
