import { AxiosError, AxiosResponse } from "axios";
import { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { clearSessionStorage, getRefreshToken, getToken, getUser, saveCounter, saveRefreshToken, saveToken, saveUser } from "../../../utills/utills";
import { loginUser, logoutUser, refreshToken } from "../../api/requests/auth";
import User from "../../models/user.model";
import { Auth_Context_Props } from "./index.types";

export const AuthContext = createContext<Auth_Context_Props>({} as Auth_Context_Props);

export const AuthProvider: FC = ({ children }) => {
   const [user, setCurrentUser] = useState<User>();
   const [error, setError] = useState<any>();
   const [loading, setLoading] = useState<boolean>(false);
   const [modalOpen, setModalOpen] = useState<boolean>(false);
   const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
   const history = useHistory();

   const login = (email: string, password: string) => {
      setLoading(true);
      loginUser(email, password)
         .then(async (user) => {
            saveToken(user.data.authenticationToken);
            saveRefreshToken(user.data.refreshToken);
            saveUser(user.data);
            setCurrentUser(user.data);
            saveCounter(user.data?.counters[0]?.organizationId?.toString()!!);
         })
         .catch((error: AxiosError) => {
            setModalOpen(true);
            setError(error.response?.data.message);
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const requestNewToken = async () => {
      try {
         let token = await getRefreshToken();
         let localUser = await getUser();
         if (token) {
            refreshToken(token)
               .then((user) => {
                  saveToken(user.data.authenticationToken);
                  setCurrentUser(localUser);
               })
               .catch((error: AxiosError) => {
                  // setError(error.response?.data.message);
               });
         }
      } catch (error) {
         throw error;
      }
   };

   const closeModal = () => {
      setModalOpen(false);
   };
   const value = useMemo(
      () => ({
         user,
         loading,
         error,
         login,
         requestNewToken,
         modalOpen,
         closeModal,
      }),
      [user, loading, error]
   );

   return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default function useAuth() {
   return useContext(AuthContext);
}
