import { Box, createStyles, Grid, makeStyles, TextField, Theme, Typography, Divider } from "@material-ui/core";
import moment from "moment";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { DayMovementTable } from "..";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { Movement } from "../../../data/models/Movement.model";
import { getToday } from "../../../utills/helper";
import { CommonButton, TextInput } from "../../atoms";

interface DayMovementsProps {
   data: Movement | undefined;
   onClick: (date: string) => void;
   onReservationClick: (id: number) => void;
   loading?: boolean;
}
const DayMovements: FC<DayMovementsProps> = ({ data, onClick, onReservationClick, loading }) => {
   const globalClasses = globalStyles();
   const { t, i18n } = useTranslation(["translations", "dashboardStrings"]);
   const [date, setDate] = useState<string>(moment(new Date().getTime()).format("YYYY-MM-DD"));

   const getDayFromDate = () => {
      const selectedDate = moment(date);
      const today = moment(getToday());
      const result = today.diff(selectedDate, "days");
      if (result == 0) return t("dashboardStrings:today");
      if (result == 1) return t("dashboardStrings:yesterday");
      if (result == -1) return t("dashboardStrings:tomorrow");
      if (result > 1 || result < -1) return date;
   };

   return (
      <Box>
         <Divider className={globalClasses.dividerMargin} />
         <Typography variant="caption" color="textSecondary">
            {t("dashboardStrings:movementsText")}
         </Typography>
         <Grid container spacing={3} className={globalClasses.margin}>
            <Grid item xs={12} sm={12} md={6}>
               <TextInput
                  title=""
                  helperText=""
                  id="date"
                  type="date"
                  value={date}
                  onChange={(e) => {
                     setDate(e.target.value);
                  }}
               />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
               <CommonButton
                  title={t("dashboardStrings:view")}
                  onClick={() => {
                     onClick(date);
                  }}
                  margin={7}
                  loading={loading}
                  disable={loading}
               />
            </Grid>
            <DayMovementTable
               title={t("dashboardStrings:comming") + " " + `(${getDayFromDate()})`}
               data={data?.toArrive}
               onReservationClick={onReservationClick}
            />
            <DayMovementTable
               title={t("dashboardStrings:leaving") + " " + `(${getDayFromDate()})`}
               data={data?.toLeave}
               onReservationClick={onReservationClick}
            />
            <DayMovementTable
               title={t("dashboardStrings:initiates") + " " + `(${getDayFromDate()})`}
               data={data?.started}
               onReservationClick={onReservationClick}
            />
            <DayMovementTable
               title={t("dashboardStrings:closed") + " " + `(${getDayFromDate()})`}
               data={data?.finished}
               onReservationClick={onReservationClick}
            />
         </Grid>
      </Box>
   );
};

export default DayMovements;
