import { Box } from "@material-ui/core";
import { ArrowBack, CheckCircleOutline, Toll } from "@material-ui/icons";
import { MutableRefObject, useEffect, useRef } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import CommonButton from "../../../components/atoms/buttons/commonButton/CommonButton";
import { AddnewWaybillForm } from "../../../components/organisms/index";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../../components/molecules";
import * as waybillApi from "../../../data/api/requests/waybills/index";
import { useRequest } from "../../../data/api/wrapper";
import { Alert, AlertDailog, Loader, Toast } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

const NewWayBill = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations"]);

   const { id } = useParams<{ id: string }>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const waybill = useRequest(() => waybillApi.getWaybillById(id));
   const newWaybill = useRequest((params) => waybillApi.addNewWaybill(params));
   const updateWaybill = useRequest((params) => waybillApi.updateWaybill(params, id));

   useEffect(() => {
      if (id) {
         waybill.execute();
      }
   }, []);

   const _Submit = (item: any) => {
      if (id) {
         updateWaybill.execute(item);
      } else {
         newWaybill.execute(item);
      }
   };

   return (
      <div>
         <Loader loading={newWaybill.loading || updateWaybill.loading} />

         {updateWaybill.show.toast && (
            <Toast show={updateWaybill.show.toast} message={updateWaybill.message} handleClose={() => updateWaybill.close()} />
         )}

         {newWaybill.show.modal && (
            <AlertDailog
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               show={newWaybill.show.modal}
               title={newWaybill.message.message}
               message={newWaybill.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => history.push(ROUTE_LINKS.LIST_ALL_TRANSPORT_GUIDES)}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  clearForm.current();
                  newWaybill.close();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:wayBills") : t("translations:new") + " " + t("translations:wayBills")}
         />
         {id ? (
            <CommonButton title={t("translations:tolls")} onClick={() => history.goBack()} icon={<Toll />} />
         ) : (
            <CommonButton title={t("translations:backtolistofguides")} onClick={() => history.goBack()} icon={<ArrowBack />} />
         )}
         <Box className={`${globalClasses.container}`}>
            <AddnewWaybillForm
               handleSubmit={(item) => {
                  _Submit(item);
               }}
               clearForm={clearForm}
               data={waybill.data}
               edit={!waybill.data || waybill.data.status.id == 1 || waybill.data.status.id == 2}
            />
         </Box>
      </div>
   );
};

export default NewWayBill;
