import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import CarFreePeriods from "../../../data/models/free-periods.model";
import { ContainerTopBar } from "../../molecules";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";

interface FreePeriodProps {
   data: CarFreePeriods[] | undefined;
}

const FreePeriods: FC<FreePeriodProps> = ({ data }) => {
   const { t } = useTranslation(["vehicleDetail", "translations"]);
   return (
      <ContainerTopBar title={t("vehicleDetail:freePeriods")}>
         <DataTable data={data || []} search={false}>
            <TableColumn headerText={t("translations:start")} render={(item) => item.start} />
            <TableColumn headerText={t("translations:end")} render={(item) => item.end} />
            <TableColumn headerText={t("vehicleDetail:quantityDays")} render={(item) => item.daysFree} />
         </DataTable>
      </ContainerTopBar>
   );
};

export default FreePeriods;
