import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const newUserFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    pickImage: {
      height: 110,
      width: 110,
      marginBottom: 10,
    },
    cameraIcon: { margin: 4 },
    listItem: {
      color: COLORS.lightText,
      fontSize: 13,
    },
    listItemOrange: {
      color: COLORS.orangeText,
      fontSize: 13,
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
  })
);
