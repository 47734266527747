import React, { JSXElementConstructor, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import useAuth from "../../data/context/authContext/AuthContext";
import useDropdown from "../../data/context/dropDown/DropdownContext";
import useSetting from "../../data/context/setting-context/SettingContext";
import { getLanguage } from "../../utills/utills";
import animationData from "./../../lotties/splash-loading.json";
import { splashStyle } from "./index.styles";

const LoadingMessage: React.FC = () => {
   const { t } = useTranslation("splash");
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice",
      },
   };
   const classes = splashStyle();
   return (
      <div className={classes.container}>
         <Lottie options={defaultOptions} height={200} width={400} />
         <p className={classes.label}>{t("splash")}</p>
      </div>
   );
};

interface SplashState {
   loading: boolean;
}

const withSplashScreen = (WrappedComponent: JSXElementConstructor<any>) => {
   return function (props: any) {
      const [loading, setLoading] = useState<boolean>(true);
      const { i18n } = useTranslation();
      const { requestNewToken, user } = useAuth();
      const { _getDropdowns } = useDropdown();
      const { getCounter } = useSetting();
      useLayoutEffect(() => {
         regreshToken();
      }, []);

      const regreshToken = async () => {
         try {
            await requestNewToken();
            await _getDropdowns();
            await getCounter();
            const language = getLanguage();
            if (language) {
               i18n.changeLanguage(language);
            }
            setLoading(false);
         } catch (err) {
            console.log(err);
            setLoading(false);
         }
      };

      return (
         <div>
            {loading && <LoadingMessage />}
            {!loading && <WrappedComponent {...props} />}
         </div>
      );
   };
};

export default withSplashScreen;
