import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const defStyles = makeStyles((theme: Theme) =>
   createStyles({
      flexRow: { marginTop: 3, display: "flex", flexDirection: "row" },
      smallGreyBox: {
         height: 45,
         width: 80,
         backgroundColor: COLORS.lightGrey,
         borderTopLeftRadius: 3,
         borderBottomLeftRadius: 3,
         paddingLeft: 10,
      },
      largeGreyBox: {
         height: 45,
         width: 130,
         backgroundColor: COLORS.lightGrey,
         borderTopLeftRadius: 3,
         borderBottomLeftRadius: 3,
         paddingLeft: 10,
      },
      boxText: {
         fontsize: "1rem",
         fontWeight: "normal",
      },
   })
);
