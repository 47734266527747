import { AxiosError } from "axios";
import { useState } from "react";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import DiscountBillable from "../../../models/billable-discount.modal";
import Pagination from "../../../models/pagination.model";
import { Waybill } from "../../../models/waybill.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getWayBills(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Waybill[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.waybills + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Waybill[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getWaybillById(id: number | string): Promise<ApiResponse<Waybill>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.waybills + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      console.log(resp.data);
      const data: ApiResponse<Waybill> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewWaybill(waybill: any): Promise<ApiResponse<void>> {
   let options = [] as number[];
   if (waybill.options.length > 0) {
      options = waybill.options.map((el: DiscountBillable) => el.id);
   }
   let dataToUpload = {
      ...waybill,
      startDate: encodeDate(waybill.startDate, waybill.startTime),
      endDate: encodeDate(waybill.endDate, waybill.endTime),
      options: options,
   };
   delete dataToUpload["startTime"];
   delete dataToUpload["endTime"];
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.waybills,
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateWaybill(waybill: any, id: string): Promise<ApiResponse<void>> {
   let options = [] as number[];
   if (waybill.options.length > 0) {
      options = waybill.options.map((el: DiscountBillable) => el.id);
   }
   const dataToUpload = {
      ...waybill,
      startDate: encodeDate(waybill.startDate, waybill.startTime),
      endDate: encodeDate(waybill.endDate, waybill.endTime),
      options: options,
   };
   delete dataToUpload["startTime"];
   delete dataToUpload["endTime"];
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.updateWaybill.format(id), // to change
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
