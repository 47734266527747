import { Divider, Grid, Typography } from "@material-ui/core";
import { FC, useEffect } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { VALUES } from "../../../../data/constants/Colors";
import { CommonButton, InputLabel, TextInput, DropdownItem, MaskedInput } from "../../../atoms";
import { useTranslation } from "react-i18next";
import { OrganizatioFormProps } from "./index.types";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import {
   latitude,
   longitude,
   normalize,
   normalizeCurrency,
   postCode,
   postCodeString,
   putDecimalPoints,
   taxId,
   taxIdString,
} from "../../../../utills/masks";
import Autocomplete from "../../../atoms/autocomplete/Autocomplete";

const establishment = [
   { name: "0", id: 0 },
   { name: "1", id: 1 },
   { name: "2", id: 2 },
   { name: "3", id: 3 },
   { name: "4", id: 4 },
   { name: "5", id: 5 },
   { name: "6", id: 6 },
   { name: "7", id: 7 },
   { name: "8", id: 8 },
   { name: "9", id: 9 },
   { name: "10", id: 10 },
];

const NewOrganizationForm: FC<OrganizatioFormProps> = (props) => {
   const { getState, getCity, cities, states, onSubmit, values, clearForm } = props;

   const globalClasses = globalStyles();

   const { countries } = useDropdown();

   const { t } = useTranslation(["translations", "users", "organization"]);

   const { organizationSchema } = useValidation();

   const formik = useFormik({
      initialValues: {
         taxId: values?.taxId ? taxIdString(values?.taxId) : "",
         phone: values?.phone ? values?.phone : "",
         phoneCountryId: values?.phoneCountry ? values.phoneCountry.id : 0,
         email: values?.email ? values.email : "",
         address: values?.address ? values.address : "",
         addressComplement: values?.addressComplement ? values.addressComplement : "",
         postalCode: values?.postalCode ? postCodeString(values.postalCode) : "",
         neighborhood: values?.neighborhood ? values.neighborhood : "",
         fantasyName: values?.fantasyName ? values.fantasyName : "",
         officialName: values?.officialName ? values.officialName : "",
         cityId: values?.city ? values.city.id : 0,
         stateId: values?.state ? values.state.id : 0,
         countryId: values?.country ? values.country.id : 0,
         establishmentNumber: values?.establishmentNumber ? values.establishmentNumber : 0,
         latitude: values?.latitude ? putDecimalPoints(values.latitude) : "",
         longitude: values?.longitude ? putDecimalPoints(values.longitude) : "",
      },
      validationSchema: organizationSchema,
      enableReinitialize: true,
      validateOnMount: false,
      validateOnBlur: false,
      validateOnChange: false,
      onSubmit: (values) => {
         let finalValues = {
            ...values,
            taxId: normalize(values.taxId),
            postalCode: normalize(values.postalCode),
            latitude: normalizeCurrency(values.latitude),
            longitude: normalizeCurrency(values.longitude),
         };
         onSubmit(finalValues);
      },
   });
   // useEffect(() => {
   //    if (values && states && cities) {
   //       console.log(values);
   //       formik.values.taxId = taxIdString(values?.taxId);
   //       formik.values.phone = values.phone;
   //       formik.values.phoneCountryId = values.phoneCountry.id;
   //       formik.values.email = values.email;
   //       formik.values.address = values.address;
   //       formik.values.addressComplement = values.addressComplement;
   //       formik.values.postalCode = postCodeString(values.postalCode);
   //       formik.values.neighborhood = values.neighborhood;
   //       formik.values.fantasyName = values.fantasyName;
   //       formik.values.officialName = values.officialName;
   //       formik.values.cityId = values.city.id;
   //       formik.values.stateId = values.state.id;
   //       formik.values.countryId = values.country.id;
   //       formik.values.establishmentNumber = values.establishmentNumber;
   //       // formik.values.latitude = values.latit
   //    }
   // }, [values]);

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <div>
         <Grid container spacing={3} style={{ marginTop: VALUES.margin_large }}>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("users:parents")} />
               <Autocomplete
                  options={countries}
                  defaultValue={countries.find((el) => formik.values.countryId === el.id)}
                  label=""
                  placeholder=""
                  renderOption={(item) => <DropdownItem country={item} />}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     getState(item?.id);
                     formik.setFieldValue("countryId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.countryId}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("users:district")} />
               <Autocomplete
                  options={states || []}
                  defaultValue={states?.find((el) => formik.values.stateId === el.id)}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     getCity(item?.id);
                     formik.setFieldValue("stateId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.stateId}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("users:city")} />
               <Autocomplete
                  options={cities || []}
                  defaultValue={cities?.find((el) => el.id === formik.values.cityId)}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     formik.setFieldValue("cityId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.cityId}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={3} xl={4}>
               <InputLabel label={t("users:houseHold")} />
               <TextInput
                  title=""
                  id="address"
                  error={formik.touched.address && Boolean(formik.errors.address)}
                  helperText={formik.touched.address && formik.errors.address}
                  disabled={false}
                  type="text"
                  value={formik.values.address}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={3}>
               <InputLabel label={`${t("users:neighborhood")}/${t("users:perish")}`} />
               <TextInput
                  title=""
                  id="neighborhood"
                  error={formik.touched.neighborhood && Boolean(formik.errors.neighborhood)}
                  helperText={formik.touched.neighborhood && formik.errors.neighborhood}
                  disabled={false}
                  type="text"
                  value={formik.values.neighborhood}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={3}>
               <InputLabel label={t("organization:complement")} />
               <TextInput
                  title=""
                  id="addressComplement"
                  error={formik.touched.addressComplement && Boolean(formik.errors.addressComplement)}
                  helperText={formik.touched.addressComplement && formik.errors.addressComplement}
                  disabled={false}
                  type="text"
                  value={formik.values.addressComplement}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={2}>
               <InputLabel label={t("users:postalCode")} />
               <TextInput
                  title=""
                  id="postalCode"
                  error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                  helperText={formik.touched.postalCode && formik.errors.postalCode}
                  disabled={false}
                  type="text"
                  value={formik.values.postalCode}
                  onChange={(e) => formik.handleChange(postCode(e))}
               />
            </Grid>
         </Grid>

         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={3} xl={4}>
               <InputLabel label={t("organization:latitude")} />
               <TextInput
                  title=""
                  id="latitude"
                  error={formik.touched.latitude && Boolean(formik.errors.latitude)}
                  helperText={formik.touched.latitude && formik.errors.latitude}
                  disabled={false}
                  type="text"
                  value={formik.values.latitude}
                  onChange={(e) => formik.handleChange(latitude(e))}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={4}>
               <InputLabel label={t("organization:longitude")} />
               <TextInput
                  title=""
                  id="longitude"
                  error={formik.touched.longitude && Boolean(formik.errors.longitude)}
                  helperText={formik.touched.longitude && formik.errors.longitude}
                  disabled={false}
                  type="text"
                  value={formik.values.longitude}
                  onChange={(e) => formik.setFieldValue("longitude", longitude(e).target.value)}
               />
            </Grid>
            <Grid item sm={12} xs={12} md={3} xl={4}>
               <InputLabel label={t("organization:externalId")} />
               <TextInput title="" value={values?.externalId} disabled={true} helperText="" id="externalId" type="string" />
            </Grid>
         </Grid>

         <Divider className={globalClasses.dividerMargin} />
         <Typography className={globalClasses.cardTitle}>{t("organization:companyData")}:</Typography>
         <Grid container spacing={3} style={{ marginTop: VALUES.margin_large }}>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={`${t("users:name")} ${t("organization:official")}`} />
               <TextInput
                  title=""
                  id="officialName"
                  error={formik.touched.officialName && Boolean(formik.errors.officialName)}
                  helperText={formik.touched.officialName && formik.errors.officialName}
                  disabled={false}
                  type="text"
                  value={formik.values.officialName}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={`${t("users:name")} ${t("organization:comercial", "fantasyName")}`} />
               <TextInput
                  title=""
                  id="fantasyName"
                  error={formik.touched.fantasyName && Boolean(formik.errors.fantasyName)}
                  helperText={formik.touched.fantasyName && formik.errors.fantasyName}
                  disabled={false}
                  type="text"
                  value={formik.values.fantasyName}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("organization:taxpayerNo")} />
               <TextInput
                  title=""
                  id="taxId"
                  error={formik.touched.taxId && Boolean(formik.errors.taxId)}
                  helperText={formik.touched.taxId && formik.errors.taxId}
                  disabled={false}
                  type="text"
                  value={formik.values.taxId}
                  onChange={(e) => formik.handleChange(taxId(e))}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={3} xl={3}>
               <InputLabel label={`${t("organization:number")} ${t("organization:establishment")}`} />
               <Autocomplete
                  options={establishment}
                  defaultValue={establishment.find((el) => el.id === formik.values.establishmentNumber)}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     formik.setFieldValue("establishmentNumber", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.establishmentNumber}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={3}>
               <InputLabel label={t("translations:email")} />
               <TextInput
                  title=""
                  id="email"
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  disabled={false}
                  type="text"
                  value={formik.values.email}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={3}>
               <InputLabel label={t("users:countryCode")} />
               <Autocomplete
                  options={countries}
                  defaultValue={countries.find((el) => formik.values.phoneCountryId === el.id)}
                  label=""
                  placeholder=""
                  renderOption={(item) => <DropdownItem country={item} />}
                  getOptionLabel={(item) => item.phoneCode}
                  onChange={(_, item) => {
                     formik.setFieldValue("phoneCountryId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.phoneCountryId}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={3} xl={2}>
               <InputLabel label={t("organization:contactNumber")} />
               <MaskedInput
                  value={formik.values.phone}
                  format={formik.values.phoneCountryId == 1 ? "### ### ###" : "##############"}
                  onValueChange={(values) => {
                     formik.setFieldValue("phone", values.value);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.phone}</Typography>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:save")} onClick={formik.handleSubmit} />
      </div>
   );
};
export default NewOrganizationForm;
