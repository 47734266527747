import { FC, ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../data/constants/Colors";
import { actions } from "../../../data/constants/button-action";
interface ConfirmProps {
   icon: ReactNode;
   title: string;
   subtitle?: string;
   dialogTypeId?: string;
   handleClose: (action: string, type?: string) => void;
}

const ConfirmItem: FC<ConfirmProps> = ({ dialogTypeId, icon, title, subtitle, handleClose }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "gen"]);

   return (
      <>
         <Box className={`${globalClasses.paddingContainer} ${globalClasses.modalAlign}`}>
            {icon}
            <Typography className={`${globalClasses.title} ${globalClasses.textAlign}`}>{title}</Typography>
            <Typography className={`${globalClasses.tableText} ${globalClasses.alignCenter}`}>{subtitle}</Typography>
            <Box className={globalClasses.flexRow}>
               <CommonButton
                  title={t("gen:confirm")}
                  color={COLORS.blue}
                  onClick={() => {
                     handleClose(actions.CONFIRM, dialogTypeId);
                  }}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  title={t("gen:cancel")}
                  color={COLORS.greyText}
                  onClick={() => {
                     handleClose(actions.CANCEL);
                  }}
               />
            </Box>
         </Box>
      </>
   );
};

export default ConfirmItem;
