import React, { FC, MutableRefObject, useEffect } from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { AutoComplete, CommonButton, DropdownItem, InputLabel, TextInput } from "../../../atoms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import Question from "../../../../data/models/question.modal";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";
import { language } from "../../../../pages/definitions/Definitions";

interface QuestionProps {
   data?: Question;
   onSubmit: (values: any) => void;
   clearForm: MutableRefObject<() => void>;
}

const QuestionForm: FC<QuestionProps> = ({ data, onSubmit, clearForm }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "gen"]);
   const { questionSchema } = useValidation();
   const formik = useFormik({
      initialValues: {
         question: data?.question || "",
         answer: data?.answer || "",
         languageId: data?.language.id || null,
      },
      enableReinitialize: true,
      validationSchema: questionSchema,
      onSubmit: (values) => {
         onSubmit(values);
      },
   });

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   const clearFormik = () => {
      formik.resetForm({});
   };

   return (
      <Box className={globalClasses.container} style={{ padding: 20 }}>
         <div>
            <Typography variant="caption" color="textPrimary">
               {t("translations:questions")}
            </Typography>

            <TextInput
               title={t("translations:question")}
               disabled={false}
               type="text"
               id="question"
               error={formik.touched.question && Boolean(formik.errors.question)}
               helperText={formik.touched.question && formik.errors.question}
               value={formik.values.question}
               onChange={formik.handleChange}
            />
         </div>
         <div className={globalClasses.margin}>
            <Typography variant="caption" color="textPrimary">
               {t("translations:answer")}
            </Typography>

            <TextInput
               title={t("translations:answer")}
               disabled={false}
               type="text"
               id="answer"
               error={formik.touched.answer && Boolean(formik.errors.answer)}
               helperText={formik.touched.answer && formik.errors.answer}
               value={formik.values.answer}
               onChange={formik.handleChange}
            />
         </div>
         <div className={globalClasses.margin}>
            <Grid container spacing={3}>
               <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <InputLabel label={t("translations:language")} />
                  <div className={globalClasses.margin}>
                     <AutoComplete
                        options={language}
                        label={""}
                        defaultValue={language.find((el) => el.id == formik.values.languageId)}
                        placeholder={t("translations:language")}
                        renderOption={(item) => <DropdownItem country={item} />}
                        getOptionLabel={(item) => item.name}
                        onChange={(_, item) => formik.setFieldValue("languageId", item?.id)}
                     />
                     <Typography className={`${globalClasses.errorText} ${globalClasses.marginLeft} ${globalClasses.margin}`}>
                        {formik.errors.languageId}
                     </Typography>
                  </div>
               </Grid>
            </Grid>
         </div>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={!data ? t("translations:create") : t("gen:update")} onClick={formik.handleSubmit} />
      </Box>
   );
};

export default QuestionForm;
