import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { KeyboardArrowDown } from "@material-ui/icons";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import React, { FC } from "react";
import { navItemStyle } from "./index.styles";
import { NavigationItemProps } from "./index.types";

const NavigationItem: FC<NavigationItemProps> = ({ routeItem, pathName, onClick }) => {
   const classes = navItemStyle();

   return (
      <ListItem
         className={pathName === routeItem.link || pathName.startsWith(routeItem.link) ? classes.selectedMenuItem : classes.active}
         button
         onClick={onClick}
      >
         <ListItemIcon className={classes.activeIcon}>{routeItem.icon}</ListItemIcon>
         <ListItemText primary={routeItem.route} classes={{ primary: classes.text }} />
         {routeItem.children && (
            <ListItemIcon className={classes.activeIcon}>
               {routeItem.active ? <KeyboardArrowDown className={classes.listItem} /> : <KeyboardArrowLeft className={classes.listItem} />}
            </ListItemIcon>
         )}
      </ListItem>
   );
};

export default NavigationItem;
