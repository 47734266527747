import { Box, Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ContainerTopBar } from "../../../components/molecules";
import { CommAdjSearchForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as reportsApi from "../../../data/api/requests/reports/index";
import { Loader, TableButton } from "../../../components/atoms";
import { Invoice } from "../../../data/models/invoice.model";
import moment from "moment";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { formatDate } from "../../../utills/helper";
import { COLORS } from "../../../data/constants/Colors";

const CommissionAdjustment = () => {
   const globalClasses = globalStyles();

   const [commissionAdjustments, setCommissionAdjustment] = useState([] as Invoice[]);
   const [diffTotal, setDiffTotal] = useState(0);
   const [data, setData] = useState({
      startDate: moment(new Date().getTime()).format("YYYY-MM-DD"),
      endDate: moment(new Date().getTime()).format("YYYY-MM-DD"),
      counterId: 0,
   });
   const [loading, setLoading] = useState(false);
   const { t } = useTranslation(["translations", "invoice", "gen", "dashboardStrings"]);
   const history = useHistory();

   const _getCommissionAdjustment = () => {
      setLoading(true);
      reportsApi
         .getCommissionAdjustmentReport(data.startDate, data.endDate, data.counterId)
         .then((res) => {
            let temp = [] as Invoice[];
            res.data.invoices.map((el) => temp.push({ ...el, difference: el.amount - el.oldAmount }));
            console.log(temp);
            setCommissionAdjustment(temp);
            setDiffTotal(res.data.diffSum);
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   };
   return (
      <div>
         <Loader loading={loading} />
         <Box className={globalClasses.title}>{t("translations:diverCreditNotes")}</Box>
         <CommAdjSearchForm
            handleChange={(value, key) => {
               setData({ ...data, [key]: value });
            }}
            handleClick={() => {
               _getCommissionAdjustment();
            }}
            data={data}
         />
         <ContainerTopBar title={t("translations:listing")}>
            <DataTable data={commissionAdjustments || []}>
               <TableColumn headerText="ID" render={(item) => item.invoiceId} />
               <TableColumn headerText={t("translations:record")} render={(item) => formatDate(item.registration)} />
               <TableColumn
                  headerText={t("dashboardStrings:reservation")}
                  render={(item) => (
                     <TableButton
                        title={`#${item.reservationId}`}
                        onClick={() => {
                           history.push(`/reservations/form/${item.reservationId}`);
                        }}
                     />
                  )}
               />
               <TableColumn headerText={t("invoice:previousValue")} render={(item) => item.oldAmount} />
               <TableColumn headerText={t("invoice:correctedValue")} render={(item) => item.amount} />
               <TableColumn headerText={t("invoice:difference")} render={(item) => item.difference} />
               <TableColumn
                  headerText={t("translations:invoice")}
                  render={(item) => (
                     <div
                        className={globalClasses.linkText}
                        onClick={() =>
                           history.push(ROUTE_LINKS.INVOICES, {
                              id: item.invoiceId,
                              invoice: true,
                           })
                        }
                     >
                        {item.fullIdentifier && "#" + item.fullIdentifier}
                     </div>
                  )}
               />
            </DataTable>
            {commissionAdjustments.length == 0 && (
               <Typography className={`${globalClasses.title} ${globalClasses.textAlign}`}>{t("gen:noRecords")}</Typography>
            )}
            <div className={globalClasses.margin} />
            <Grid container spacing={3}>
               <Grid item xl={2} lg={2} md={3} sm={8} xs={8}>
                  <div style={{ backgroundColor: COLORS.redLabel, padding: 10, color: COLORS.cardBackground }}>
                     {t("gen:totalDifference")}: {diffTotal.toString().replace(".", ",").concat("0")}
                  </div>
               </Grid>
            </Grid>
         </ContainerTopBar>
      </div>
   );
};

export default CommissionAdjustment;
