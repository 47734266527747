import { Box } from "@material-ui/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../data/models/reservation.model";
import { formatDate } from "../../../utills/helper";
import { padId } from "../../../utills/masks";
import { TableButton } from "../../atoms";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";

interface PendingReservationProps {
   pendingReservations: Reservation[] | undefined;
   handleClick: (item: number) => void;
}
const PendingReservations: FC<PendingReservationProps> = ({ pendingReservations, handleClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["dashboardStrings", "translations"]);
   return (
      <Box className={globalClasses.container}>
         <DataTable data={pendingReservations || []} search={false}>
            <TableColumn
               headerText={t("dashboardStrings:reservation")}
               render={(item) => (
                  <TableButton
                     title={padId(item.id, 5)}
                     onClick={() => {
                        handleClick(item.id);
                     }}
                  />
               )}
            />
            <TableColumn headerText={t("dashboardStrings:enrollment")} render={(item) => ""} />
            <TableColumn headerText={t("dashboardStrings:driver")} render={(item) => item.user} />
            <TableColumn headerText={t("translations:start")} render={(item) => formatDate(item.startDate)} />
         </DataTable>
      </Box>
   );
};

export default PendingReservations;
