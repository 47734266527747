import { createTheme } from "@material-ui/core/styles";
import { COLORS, VALUES } from "../data/constants/Colors";

export const theme = createTheme({
   palette: {
      // type: "dark",
      primary: {
         main: "#FBAD19",
         dark: "#E29400",
         light: "#FFC733",
         contrastText: "#fff",
      },
      secondary: {
         main: "#00c0c0",
         light: "#1ADADA",
         dark: "#00A7A7",
         contrastText: "#000",
      },
      success: {
         main: "#00c0c0",
         light: "#c9e9ee",
         dark: "#00A7A7",
         contrastText: "rgba(0, 0, 0, 0.7)",
      },
      background: {
         // default: "#000000",
         paper: "#FFFFFF",
      },
   },
   typography: {},
   overrides: {
      // MuiInputLabel: {
      //    root: {
      //       fontSize: "1rem",
      //    },
      // },
      MuiOutlinedInput: {
         root: {
            fontSize: 14,
         },
      },
   },
});
