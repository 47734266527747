import { Box } from "@material-ui/core";
import { FC } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { StatusLabel } from "../../../atoms";
import { titleStyles } from "./index.styles";
import { TitleContainerProps } from "./index.types";

const TitleContainer: FC<TitleContainerProps> = ({ title, status }) => {
   const classes = titleStyles();
   const globalClasses = globalStyles();

   return (
      <div className={classes.container}>
         <Box className={classes.title}>{title}</Box>
         {status && (
            <span className={` ${globalClasses.marginLeft}`}>
               <StatusLabel status={status} />
            </span>
         )}
      </div>
   );
};

export default TitleContainer;
