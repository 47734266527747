import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { useFormik } from "formik";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { AutoComplete, CommonButton, InputLabel, TextArea, TextInput } from "..";
import { invoice_modal_actions } from "../../../data/constants/button-action";
import { COLORS } from "../../../data/constants/Colors";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { currency } from "../../../utills/masks";
import * as yup from "yup";

interface ReceiptItemProps {
   onClick: (values: any, action: string) => void;
   loading?: boolean;
}

const ReceiptItem: FC<ReceiptItemProps> = ({ onClick, loading }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["invoice", "transaction", "translations", "gen", "vehicles"]);

   const { paymentMethods, account } = useDropdown();

   const formik = useFormik({
      initialValues: {
         paymentMethodId: 0,
         accountId: 0,
         value: "",
         date: moment(new Date().getTime()).format("YYYY-MM-DD"),
         note: "",
      },
      validationSchema: yup.object({
         paymentMethodId: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         accountId: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         value: yup.string().min(3, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         date: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      onSubmit: (values) => {
         onClick(values, invoice_modal_actions.confirm);
      },
   });

   return (
      <>
         <Box className={`${globalClasses.cardTitleCOntainer}`}>
            <Typography className={globalClasses.cardTitle}>
               {t("translations:new")} {t("transaction:receipt")}
            </Typography>
            <Close className={globalClasses.tableIcon} onClick={() => onClick(null, invoice_modal_actions.cancel)} />
         </Box>
         <Divider />
         <Box className={`${globalClasses.paddingContainer} ${globalClasses.margin}`}>
            <InputLabel label={t("transaction:formOfPayment")} />
            <AutoComplete
               label=""
               placeholder=""
               options={paymentMethods}
               renderOption={(item) => item.name}
               getOptionLabel={(item) => item.name}
               onChange={(_, item) => {
                  formik.setFieldValue("paymentMethodId", item?.id);
               }}
            />
            <Typography className={globalClasses.errorText}>{formik.errors.paymentMethodId}</Typography>
            <InputLabel label={t("gen:account")} />
            <AutoComplete
               label=""
               placeholder=""
               options={account}
               renderOption={(item) => item.name}
               getOptionLabel={(item) => item.name}
               onChange={(_, item) => {
                  formik.setFieldValue("accountId", item?.id);
               }}
            />
            <Typography className={globalClasses.errorText}>{formik.errors.accountId}</Typography>
            <Grid container spacing={3}>
               <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <InputLabel label={t("translations:value")} />
                  <TextInput
                     disabled={false}
                     title=""
                     id="value"
                     type="text"
                     error={formik.touched.value && Boolean(formik.errors.value)}
                     helperText={formik.touched.value && formik.errors.value}
                     value={formik.values.value}
                     onChange={(e) => formik.setFieldValue("value", currency(e).target.value)}
                  />
               </Grid>
               <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                  <InputLabel label={t("translations:date")} />
                  <TextInput
                     disabled={false}
                     value={formik.values.date}
                     title=""
                     id="date"
                     type="date"
                     error={formik.touched.date && Boolean(formik.errors.date)}
                     helperText={formik.touched.date && formik.errors.date}
                     onChange={formik.handleChange}
                  />
               </Grid>
            </Grid>
            <InputLabel label={t("translations:description")} />
            <TextArea
               onChange={(e) => {
                  formik.setFieldValue("note", e.target.value);
               }}
            />
            <Divider className={globalClasses.dividerMargin} />
            <Box className={globalClasses.flexEnd}>
               <CommonButton
                  color={COLORS.greyText}
                  title={t("gen:cancel")}
                  onClick={() => {
                     onClick(null, invoice_modal_actions.cancel);
                  }}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  icon={<Check className={globalClasses.tableIcon} />}
                  title={t("gen:confirm")}
                  onClick={formik.handleSubmit}
                  loading={loading}
                  disable={loading}
               />
            </Box>
         </Box>
      </>
   );
};

export default ReceiptItem;
