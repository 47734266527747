import { Box } from "@material-ui/core";
import Lottie from "react-lottie";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import animationData from "./../../lotties/not-found.json";
const NotFound = () => {
   const globalClasses = globalStyles();
   const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
         preserveAspectRatio: "xMidYMid slice",
      },
   };
   return (
      <div style={{ height: "100%", width: "100%" }}>
         <Box className={globalClasses.container}>
            <Lottie options={defaultOptions} height={"60vh"} width={"90vh"} />
         </Box>
      </div>
   );
};

export default NotFound;
