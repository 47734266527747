import { CheckCircleOutline, ErrorOutline, List } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Alert, AlertDailog, CommonButton, Loader, Toast } from "../../../../components/atoms";
import { TitleContainer } from "../../../../components/molecules";
import { NewFineForm } from "../../../../components/organisms";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import * as carFineApi from "../../../../data/api/requests/car-fine/index";
import { useRequest } from "../../../../data/api/wrapper";
import { MutableRefObject, useEffect, useRef } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";

const NewFine = () => {
   const { t } = useTranslation(["translations"]);

   const globalClasses = globalStyles();

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const saveCarFine = useRequest((params, file, note) => carFineApi.saveCarFine(params, file, note));

   const getCarFine = useRequest(() => carFineApi.getCarFineByID(id));

   const updateCarFine = useRequest((params, file, note) => carFineApi.updateCarFine(params, file, note));

   useEffect(() => {
      if (id) {
         getCarFine.execute();
      }
   }, []);

   useEffect(() => {
      if (updateCarFine.data) {
         getCarFine.setData(updateCarFine.data);
      }
   }, [updateCarFine.data]);

   const submit = (values: any) => {
      let file = values.file;
      let fileNote = values.fileNote;
      let finalValue: any = { ...values };
      delete finalValue["file"];
      delete finalValue["fileNote"];
      if (id) {
         const updateValue = { ...finalValue, carFineId: id };
         updateCarFine.execute(updateValue, file, fileNote);
      } else {
         saveCarFine.execute(finalValue, file, fileNote);
      }
   };

   return (
      <div>
         <Loader loading={saveCarFine.loading || updateCarFine.loading} />
         {updateCarFine.show.toast && (
            <Toast show={updateCarFine.show.toast} message={updateCarFine.message} handleClose={() => updateCarFine.close()} />
         )}
         {saveCarFine.show.modal && (
            <AlertDailog
               show={saveCarFine.show.modal}
               title={saveCarFine.message.message}
               message={saveCarFine.message.description}
               icon={<CheckCircleOutline className={globalClasses.bigSuccessIcon} />}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  saveCarFine.close();
                  history.push(ROUTE_LINKS.VEHICLE_FINES);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  saveCarFine.close();
                  clearForm.current();
               }}
            />
         )}
         <TitleContainer title={id ? t("translations:edit") + " " + t("translations:fine") : t("translations:new") + " " + t("translations:fine")} />
         <CommonButton
            title="Listar multas"
            onClick={() => {
               history.push(ROUTE_LINKS.VEHICLE_FINES);
            }}
            icon={<List />}
         />
         <NewFineForm handleSubmit={submit} data={getCarFine.data} clearForm={clearForm} />
      </div>
   );
};

export default NewFine;
