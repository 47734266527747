import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const userCardStyles = makeStyles((theme: Theme) => ({
   container: {
      marginTop: 10,
      border: 1,
      borderColor: COLORS.greyBorder,
      borderStyle: "solid",
      borderRadius: 5,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
   },
   topLabel: {
      height: 40,
      borderTop: 1,
      borderTopColor: COLORS.greyBorder,
      borderTopStyle: "solid",
      backgroundColor: COLORS.lightGrey,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 20,
      paddingRight: 20,
   },
   body: { paddingTop: 10 },
   bodyText: { fontSize: 12, fontWeight: "bold", color: COLORS.greyText },
   removeButton: {
      width: 60,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginLeft: 15,
      marginBottom: 10,
      "&:hover": {
         cursor: "pointer",
      },
   },
}));
