import { Divider, Grid, Typography, Box } from "@material-ui/core";
import { DoubleArrow, Search } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { CommonButton, TableButton, TextInput } from "..";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../data/models/reservation.model";
import { ContainerTopBar } from "../../molecules";

interface ReservationSearchProps {
   onReservationSearch: () => void;
   onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
   reservation?: Reservation;
   loading?: boolean;
   error?: string;
   onResClick: (id: number) => void;
}

const ReservationSearch: FC<ReservationSearchProps> = ({ onChange, onReservationSearch, reservation, loading, error, onResClick }) => {
   const { t } = useTranslation(["vehicleDetail", "translations", "vehicles", "gen"]);
   const globalClasses = globalStyles();

   return (
      <ContainerTopBar title={t("vehicleDetail:reservationSearch")}>
         <Typography className={`${globalClasses.cardValue} ${globalClasses.margin}`}>{t("vehicleDetail:dateTimeText")}</Typography>
         <Grid container spacing={3}>
            <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
               <TextInput type={"date"} id="date" helperText={""} disabled={false} title="" onChange={onChange} />
               <Typography className={globalClasses.errorText}>{error}</Typography>
            </Grid>
            <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
               <TextInput type={"time"} id="time" helperText={""} disabled={false} title="" onChange={onChange} />
            </Grid>
         </Grid>
         <CommonButton icon={<Search />} title={t("translations:search")} onClick={onReservationSearch} loading={loading} disable={loading} />
         <Divider className={globalClasses.dividerMargin} />
         {reservation && (
            <Box className={globalClasses.alertContainer}>
               <Typography className={globalClasses.tableText}>
                  {t("vehicles:dateSearchString")} #{reservation.id}
               </Typography>
               <TableButton
                  icon={<DoubleArrow className={globalClasses.tableSmallIcon} />}
                  title={t("gen:acess")}
                  onClick={() => onResClick(reservation.id)}
               />
            </Box>
         )}
         <Divider className={globalClasses.dividerMargin} />
      </ContainerTopBar>
   );
};

export default ReservationSearch;
