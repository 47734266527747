import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

interface RadioProps {
   options: { label: string; value: string }[];
   subText: string[];
   onChange: (value: string) => void;
}

const RadioButton: FC<RadioProps> = ({ options, subText, onChange }) => {
   const { t } = useTranslation("invoice");

   const globalClasses = globalStyles();

   return (
      <>
         <FormControl component="fieldset">
            <RadioGroup aria-label="gender" defaultValue={options[0].value} name="radio-buttons-group" onChange={(_, value) => onChange(value)}>
               {options.map((el, index) => (
                  <>
                     <FormControlLabel value={el.value} control={<Radio />} label={el.label} key={`radio-${index}`} />
                     <Typography className={globalClasses.tableSmallText}>{subText[index]}</Typography>
                  </>
               ))}
            </RadioGroup>
         </FormControl>
      </>
   );
};

export default RadioButton;
