import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { FC } from "react";
import { pdfStyles } from "./PdfViewer.styles";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton, TableButton } from "../../atoms";
import InvoiceCostumer from "../../../data/models/InvoiceCostumer.model";
import { InvoiceCard, SendByEmail } from "../../molecules";
import { useTranslation } from "react-i18next";
import { invoice_create } from "../../../data/constants/button-action";
import { Invoice } from "../../../data/models/invoice.model";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { formatDateTime } from "../../../utills/helper";
import { putDecimalPoints } from "../../../utills/masks";
import Receipt from "../../../data/models/receipt.modal";
import { COLORS } from "../../../data/constants/Colors";

interface pdfProps {
   data: InvoiceCostumer | undefined;
   onCreateClick: (action: string) => void;
   onSelectUser: (email: string) => void;
   onSendEmail: () => void;
   loading?: boolean;
   receipts?: { receipts: Receipt[]; total: string };
   creditMemo?: Invoice[];
   onGenerateInvoice: (item: Receipt) => void;
   onShowInvoice: (item: Receipt) => void;
}

const PdfViewer: FC<pdfProps> = ({
   data,
   onCreateClick,
   onSelectUser,
   onSendEmail,
   loading,
   receipts,
   creditMemo,
   onGenerateInvoice,
   onShowInvoice,
}) => {
   const globalClasses = globalStyles();
   const classes = pdfStyles();
   const { t } = useTranslation(["invoice", "transaction", "translations", "gen"]);

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
               <div className={classes.pdfContainer}>
                  {data?.invoice.pdfFileBase64 && (
                     <embed
                        src={`data:application/pdf;base64,${data?.invoice.pdfFileBase64}`}
                        type="application/pdf;base64,base64encodedpdf"
                        width="100%"
                        height="100%"
                     />
                  )}
               </div>
            </Grid>
            <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
               {(data?.invoice.type.id == 1 || data?.invoice.type.id || data?.invoice.isCanceled) && (
                  <InvoiceCard
                     title={t("transaction:receipt")}
                     render={() => {
                        if (receipts?.receipts?.length == 0 || !receipts)
                           return <Typography className={globalClasses.tableText}>{t("invoice:receiptDescription")}</Typography>;
                        return (
                           <DataTable search={false} data={receipts.receipts || []}>
                              <TableColumn headerText={"ID"} render={(item) => item.id} />
                              <TableColumn headerText={t("translations:date")} render={(item) => formatDateTime(item.dueDate)} />
                              <TableColumn headerText={t("translations:value")} render={(item) => putDecimalPoints(item.amount)} />
                              <TableColumn
                                 render={(item) =>
                                    !item.externalId ? (
                                       <CommonButton
                                          title={t("transaction:generate")}
                                          onClick={() => {
                                             onGenerateInvoice(item);
                                          }}
                                          color={COLORS.borders}
                                       />
                                    ) : (
                                       <CommonButton
                                          title={t("transaction:openInvoice")}
                                          onClick={() => {
                                             onShowInvoice(item);
                                          }}
                                       />
                                    )
                                 }
                              />
                           </DataTable>
                        );
                     }}
                     priceText={`${t("invoice:received")} ${putDecimalPoints(receipts?.total)} €`}
                     onCreateClick={() => {
                        onCreateClick(invoice_create.receipt);
                     }}
                  />
               )}

               <Divider className={globalClasses.dividerMargin} />

               <InvoiceCard
                  title={t("invoice:creditNotes")}
                  render={() => {
                     if (creditMemo?.length == 0) return <Typography className={globalClasses.tableText}>{t("invoice:creditMemo")}</Typography>;
                     return (
                        <DataTable search={false} data={receipts?.receipts || []}>
                           <TableColumn headerText={"ID"} render={(item) => item.id} />
                           <TableColumn headerText={t("translations:date")} render={(item) => formatDateTime(item.dueDate)} />
                           <TableColumn headerText={t("translations:value")} render={(item) => putDecimalPoints(item.amount)} />
                           <TableColumn render={(item) => <TableButton title={t("transaction:generate")} onClick={() => {}} />} />
                        </DataTable>
                     );
                  }}
                  priceText={`${t("invoice:refunded")}`}
                  onCreateClick={() => {
                     onCreateClick(invoice_create.credit_notes);
                  }}
               />

               {data?.invoice?.amount!! - parseFloat(receipts?.total!!) > 0 ? (
                  <Typography className={`${globalClasses.errorText}`}>
                     {t("invoice:pendingPayment") + (data?.invoice?.amount!! - parseFloat(receipts?.total!!)).toFixed(2) + "€"}
                  </Typography>
               ) : (
                  <Typography className={`${globalClasses.cardTitle}`}>{t("invoice:noPending")}</Typography>
               )}

               <div className={globalClasses.dividerMargin} />

               <SendByEmail
                  onSelectUser={onSelectUser}
                  onSendEmail={onSendEmail}
                  loading={loading}
                  users={data?.customers || []}
                  title={t("gen:sendToUserEmail")}
                  buttonTitle={t("gen:emailButtonTitle")}
               />
            </Grid>
         </Grid>
      </Box>
   );
};

export default PdfViewer;
