import { Box, Typography } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router";
import { ActivityIndicator, CommonButton, Loader, Toast } from "../../../components/atoms";
import { ContainerTopBar, DiscountItem, TitleContainer } from "../../../components/molecules";
import { EditDiscountsForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as discountApi from "../../../data/api/requests/disscounts/index";
import { ItemRelatedToDiscount } from "../../../data/models/item-discount-response.model";
import { EditDiscountProps } from "./index.types";
import { Item } from "../../../data/models/discount-item.model";
import { AlertModal } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";

const EditDiscount = () => {
   const globalClasses = globalStyles();
   const history = useHistory();
   const { state } = useLocation<string>();
   const { id } = useParams<{ id: string }>();
   const { t } = useTranslation(["gen", "translations", "vehicles"]);

   const reset = useRef() as MutableRefObject<() => void>;

   const addBillableItem = useRequest((params) => discountApi.addNewBillableItem(id, params));
   const deleteBillableItem = useRequest((params) => discountApi.deleteBillableDiscount(id, params));
   const [billableItems, setBillableItem] = useState<ItemRelatedToDiscount<Item[]>>();

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const getData = () => {
      discountApi
         .getAllItemsOfDiscount(id)
         .then((res) => setBillableItem(res.data))
         .catch((err) => {
            throw err;
         });
   };

   useEffect(() => {
      if (addBillableItem.data) {
         reset.current();
         getData();
      }
   }, [addBillableItem.data]);

   const _onDeleteClick = (discountId: number, index: number) => {
      discountApi
         .deleteBillableDiscount(id, discountId)
         .then((res) => {
            let tempItems = { ...billableItems!! };
            const dumped = tempItems?.items?.splice(index, 1);
            setBillableItem(tempItems!!);
         })
         .catch((err) => {});
   };
   const _onAddClick = (values: any) => {
      addBillableItem.execute(values);
   };

   return (
      <div>
         <Loader loading={addBillableItem.loading || deleteBillableItem.loading} />

         {addBillableItem.show.toast && (
            <Toast
               show={addBillableItem.show.toast}
               message={addBillableItem.message}
               handleClose={() => {
                  addBillableItem.close();
               }}
            />
         )}

         {deleteBillableItem.show.toast && (
            <Toast
               show={deleteBillableItem.show.toast}
               message={deleteBillableItem.message}
               handleClose={() => {
                  deleteBillableItem.close();
               }}
            />
         )}

         <TitleContainer title={t("translations:discountFor") + " " + state} />

         <CommonButton
            title="Voltar"
            onClick={() => {
               history.goBack();
            }}
            icon={<ArrowBack />}
         />

         <ContainerTopBar title={`${t("gen:addDiscountItem")}${state}`}>
            <EditDiscountsForm onSubmit={_onAddClick} clearForm={reset} />
            {billableItems &&
               billableItems?.items.length > 0 &&
               billableItems?.items.map((item, index) => (
                  <div>
                     {billableItems?.items[index - 1]?.category?.tariff?.id != item.category.tariff.id && (
                        <Typography className={globalClasses.discountTitle}>{item.category.tariff.name}</Typography>
                     )}
                     <DiscountItem item={item} key={item.itemDiscountId} onDeleteClick={(discountId) => _onDeleteClick(discountId, index)} />
                  </div>
               ))}
         </ContainerTopBar>
      </div>
   );
};

export default EditDiscount;
