import { ThemeProvider } from "@material-ui/styles";
import * as locales from "@material-ui/core/locale";
import "./i18n";
import { AuthProvider } from "./data/context/authContext/AuthContext";
import Main from "./Main";
import { DropdownProvider } from "./data/context/dropDown/DropdownContext";
import { SettingProvider } from "./data/context/setting-context/SettingContext";
import { ErrorProvider } from "./data/context/error";
import {} from "./utills/Extensions";
import ErrorModal from "./components/organisms/global-error";
import i18next from "i18next";
import { theme } from "./theme/theme";
import { useTranslation } from "react-i18next";
import { CssBaseline } from "@material-ui/core";
import { Feedback } from "./components/atoms";
import { FeedbackProvider } from "./data/context/feedback";

const language = i18next.language === "en" ? locales.enUS : locales.ptPT;

function App() {
   const { t } = useTranslation(["translations"]);
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <ErrorProvider>
            <FeedbackProvider>
               <ErrorModal />
               <Feedback />
               <AuthProvider>
                  <SettingProvider>
                     <DropdownProvider>
                        <Main />
                     </DropdownProvider>
                  </SettingProvider>
               </AuthProvider>
            </FeedbackProvider>
         </ErrorProvider>
      </ThemeProvider>
   );
}

export default App;
