import { FC, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { ContainerTopBarProps } from "./index.types";
import { containerStyles } from "./index.styles";
import { COLORS } from "../../../../data/constants/Colors";
import { ActivityIndicator } from "../../../atoms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";

const ContainerTopBar: FC<ContainerTopBarProps> = ({ title, children, badgeColor, badgeNumber, hidden, activity, seeAll, onSeeAllClick }) => {
   const classes = containerStyles();

   const { t } = useTranslation("gen");

   const [menuActive, setMenuActive] = useState<boolean>(hidden ? false : true);
   const showContent = () => {
      setMenuActive(!menuActive);
   };

   return (
      <Box className={classes.container} borderTop={1} borderColor={COLORS.greyBorder}>
         <Box
            className={classes.cardTitleCOntainer}
            style={{
               borderBottomColor: COLORS.greyBorder,
               borderBottomWidth: 1,
               borderBottomStyle: "solid",
            }}
         >
            <div className={classes.label}>
               <Typography className={classes.cardTitle}>{title}</Typography>
               <div className={classes.redLabel} style={{ backgroundColor: badgeColor }}>
                  {badgeNumber}
               </div>
               {activity && <ActivityIndicator />}
            </div>
            <div>
               {seeAll && (
                  <span className={classes.box} onClick={onSeeAllClick}>
                     {t("seeAll")}
                  </span>
               )}
               {!menuActive ? (
                  <KeyboardArrowDown color="disabled" className={classes.icon} onClick={showContent} />
               ) : (
                  <KeyboardArrowUp color="disabled" className={classes.icon} onClick={showContent} />
               )}
            </div>
         </Box>
         {menuActive && <Box className={classes.contentContainer}>{children}</Box>}
      </Box>
   );
};

export default ContainerTopBar;
