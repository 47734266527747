import { useEffect } from "react";
import { DayMovements, DelayedDeliveries, NotGenInvoices, ParkedCars, PendingReservations } from "../../components/organisms";
import { COLORS } from "../../data/constants/Colors";
import { ContainerTopBar, TitleContainer } from "../../components/molecules";
import { useTranslation } from "react-i18next";
import * as dashboardApi from "../../data/api/requests/dashboard";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../routes/routeLinks";
import { useRequest } from "../../data/api/wrapper";
import useDropdown from "../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import { getToday } from "../../utills/helper";
import { CurrencyInput } from "../../components/atoms";

function Dashboard() {
   const history = useHistory();
   const globalClasses = globalStyles();
   const { _getDropdowns } = useDropdown();
   const { t } = useTranslation(["translations", "dashboardStrings"]);
   const pendingReservation = useRequest(() => dashboardApi.getPendingReservations());
   const parkedVehicles = useRequest(() => dashboardApi.getParkedVehicles());
   const delayedDeliveries = useRequest(() => dashboardApi.getDelayedDeliveries());
   const pendingInvoices = useRequest(() => dashboardApi.getPendingInvoices());
   const daymovements = useRequest((params) => dashboardApi.getDayMovements(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         _getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const _getData = async () => {
      _getDropdowns();
      Promise.all([
         pendingReservation.execute(),
         parkedVehicles.execute(),
         delayedDeliveries.execute(),
         pendingInvoices.execute(),
         daymovements.execute(getToday()),
      ]);
   };

   const handleNavigateToReservation = (id: number, type?: number) => {
      if (type === 1 || !type) {
         history.push(`/reservation/form/${id}`);
      } else {
         history.push(`/way-bills/form/${id}`);
      }
   };

   const handleInvoiceClick = (id: number) => {
      history.push(`/reservation/transactions/${id}`);
   };

   const handleCarPlatePress = (id: number) => {
      history.push(ROUTE_LINKS.VEHICLE_DETAIL.formatMap({ id: id }));
   };

   return (
      <div>
         <TitleContainer title={t("translations:dashboard")} />
         <>
            <ContainerTopBar
               title={t("dashboardStrings:pending") + " (APP)"}
               badgeColor={COLORS.redLabel}
               badgeNumber={pendingReservation?.data?.length}
               hidden
               activity={pendingReservation.loading}
            >
               <PendingReservations pendingReservations={pendingReservation?.data} handleClick={handleNavigateToReservation} />
            </ContainerTopBar>
            <ContainerTopBar
               title={t("dashboardStrings:carsInPark")}
               badgeNumber={parkedVehicles.data?.length}
               badgeColor={COLORS.greenLabel}
               hidden
               activity={parkedVehicles.loading}
            >
               <ParkedCars data={parkedVehicles.data} onCarPlatePress={handleCarPlatePress} />
            </ContainerTopBar>
            <ContainerTopBar
               title={t("dashboardStrings:delayedDeliveries")}
               badgeColor={COLORS.redLabel}
               badgeNumber={delayedDeliveries.data?.length}
               hidden
               activity={delayedDeliveries.loading}
            >
               <DelayedDeliveries
                  data={delayedDeliveries.data}
                  onReservationClick={handleNavigateToReservation}
                  onCarPlateClick={handleCarPlatePress}
               />
            </ContainerTopBar>
            <ContainerTopBar
               title={t("dashboardStrings:invoicesNotGenerated")}
               badgeColor={COLORS.redLabel}
               badgeNumber={pendingInvoices?.data?.length}
               hidden
               activity={pendingInvoices.loading}
            >
               <NotGenInvoices data={pendingInvoices.data} onReservationClick={handleNavigateToReservation} onInvoiceClick={handleInvoiceClick} />
            </ContainerTopBar>
         </>
         <DayMovements
            data={daymovements.data}
            onClick={(date) => {
               daymovements.execute(date);
            }}
            onReservationClick={handleNavigateToReservation}
            loading={daymovements.loading}
         />
      </div>
   );
}

export default Dashboard;
