import { useEffect, useState } from "react";
import ContainerTopBar from "../../../components/molecules/containers/container/ContainerTopBar";
import CommonButton from "../../../components/atoms/buttons/commonButton/CommonButton";
import * as organizationApi from "../../../data/api/requests/organizations/index";
import { useTranslation } from "react-i18next";
import { Loader, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { TitleContainer } from "../../../components/molecules";
import { AddCircle } from "@material-ui/icons";
import Pagination from "../../../data/models/pagination.model";
import { Administrator } from "../../../data/models/administrator.model";
import Organization from "../../../data/models/organization.model";

function ListOrganizations() {
   const { t } = useTranslation(["translations", "users"]);
   const history = useHistory();
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchRes, setSearchRes] = useState("");
   const organizations = useRequest(() => organizationApi.getOrganizations(pagination.page, pagination.size, searchRes));
   const changeOrganizationStatus = useRequest((params) => organizationApi.changeOrganizationStatus(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         organizations.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchRes]);

   useEffect(() => {
      if (changeOrganizationStatus.data) {
         const index = organizations.data?.data.findIndex((el) => el.id === changeOrganizationStatus?.data?.id);
         console.log(index);
         const { data } = organizations;
         let users: Pagination<Organization[]> = Object.assign(data!!);
         users.data[index!!] = changeOrganizationStatus.data;
         organizations.setData(users);
      }
   }, [changeOrganizationStatus.data]);

   const onActionClick = (action: string, id: any) => {
      switch (action) {
         case t("translations:edit"):
            history.push(`/organizations/form/${id}`);
            break;
         case t("translations:changeStatus"):
            changeOrganizationStatus.execute(id);
            break;
         default:
            break;
      }
   };
   return (
      <div>
         <Loader loading={changeOrganizationStatus.loading} />
         {changeOrganizationStatus.show.toast && (
            <Toast
               show={changeOrganizationStatus.show.toast}
               message={changeOrganizationStatus.message}
               handleClose={() => {
                  changeOrganizationStatus.close();
                  //organizations.execute();
               }}
            />
         )}
         <TitleContainer title={t("translations:organizations")} />
         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_ORGANIZATION);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={organizations.loading}>
            <DataTable
               data={organizations.data?.data || []}
               onSearching={(query) => setSearchRes(query)}
               pagination
               total={organizations.data?.total}
               stripedRows
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("users:nif")} render={(item) => item.taxId} />
               <TableColumn headerText={t("users:name")} render={(item) => item.officialName} />
               <TableColumn headerText={t("translations:email")} render={(item) => item.email} />
               <TableColumn headerText={t("users:telephone")} render={(item) => item.phone} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(action) => {
                           onActionClick(action, item.id);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
}

export default ListOrganizations;
