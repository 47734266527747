import { VALUES } from "../../../data/constants/Colors";

export const errorModalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 400,
   bgcolor: "background.paper",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   padding: "20px 0 20px 0",
};
