import ApiResponse from "../../../models/api-response.model";
import Pagination from "../../../models/pagination.model";
import Tariff from "../../../models/tariff.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getTariffs(page: number, size: number, searchQuery: string): Promise<ApiResponse<Pagination<Tariff[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.tariffs + `?page=${page}&size=${size}&search=${searchQuery}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Tariff[]>> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getTariffById(id: number | string): Promise<ApiResponse<Tariff>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.tariffs + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Tariff> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeTariffStatus(id: number): Promise<ApiResponse<Tariff>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeTariffsStatus.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Tariff> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewTariff(tariff: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.tariffs,
         data: tariff,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateTariffById(tariff: any, tariffId: number | string): Promise<ApiResponse<void>> {
   const tariffToUpload = { ...tariff, tariffId: tariffId };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.tariffs + `/${tariffId}`,
         data: tariffToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
