import ApiResponse from "../../../models/api-response.model";
import User from "../../../models/user.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { $axios, request } from "../../wrapper";

export const loginUser = async (email: string, password: string): Promise<ApiResponse<User>> => {
   try {
      const resp = await $axios({
         method: API_METHODS.POST,
         url: API_ROUTES.login,
         data: { email, password },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<User> = await resp.data;
      console.log(data);
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const changePassword = async (password: any): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changePassword,
         data: {
            currentPassword: password.password,
            newPassword: password.newPassword,
         },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const refreshToken = async (refreshToken: string): Promise<ApiResponse<User>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.refreshToken,
         data: { refreshToken },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<User> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const logoutUser = async (): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.logout,
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};
