import React, { FC } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Car from "../../../data/models/car.model";

interface Props {
  car: Car;
}

const CarItem: FC<Props> = ({ car }) => {
  const globalClasses = globalStyles();
  return (
    <>
      <span className={globalClasses.greyBox}>{car.licensePlate}</span>
      <div>{car.name}</div>
    </>
  );
};

export default CarItem;
