import ApiResponse from "../../../models/api-response.model";
import CarFine from "../../../models/car-fine.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export const getFines = async (carId: number): Promise<ApiResponse<CarFine[]>> => {
   const query = carId ? `?carId=${carId}` : "";
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.carFines + query,
         data: {},
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<CarFine[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const saveCarFine = async (
   carFine: CarFine, //TODO refactor when grouping car properties
   file: File,
   fileNote: File
): Promise<ApiResponse<void>> => {
   let downlink = "";
   if (file?.size) {
      const image = new FormData();
      image.append("file", file);
      const res = await uploadFile(image);
      downlink = res.data.file;
   }
   try {
      const resp = await request({
         method: API_METHODS.POST, //TODO change to POST after API refactor
         url: API_ROUTES.carFines,
         data: { ...carFine, file: downlink, fileNote },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCarFineByID = async (id: string): Promise<ApiResponse<CarFine>> => {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.carFines + `/${id}`,
         data: {},
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<CarFine> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const updateCarFine = async (
   carFine: CarFine, //TODO refactor when grouping car properties
   file: File,
   fileNote: string
): Promise<ApiResponse<void>> => {
   try {
      if (carFine.carFineId == undefined) {
         throw new Error("Invalid Param");
      }
      let downlink = "";
      if (file?.size) {
         const image = new FormData();
         image.append("file", file);
         const res = await uploadFile(image);
         downlink = res.data.file;
         console.log(downlink);
      }
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.carFines + `/${carFine.carFineId}`,
         data: { ...carFine, file: downlink, fileNote },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;

      return data;
   } catch (error) {
      throw error;
   }
};
