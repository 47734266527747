import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { ButtonContainer, TitleContainer, PopupModal, ConfirmItem, TollModalItem, VehicleHistoryItem } from "../../../components/molecules";
import { NewReservationForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import { useEffect, useLayoutEffect, useState } from "react";
import { actions, reservation_actions, route_actions } from "../../../data/constants/button-action";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { Alert, Loader, Toast } from "../../../components/atoms";
import { Help } from "@material-ui/icons";
import { getReservationDisabledOptions } from "../../../utills/helper";

export interface DisableProps {
   edit: boolean;
   all: boolean;
   pickupDate: boolean;
   start: boolean;
   buttons: boolean;
}

const NewReservation = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "gen"]);

   const { id } = useParams<{ id: string }>();

   const reservation = useRequest((params) => reservationApi.getReservationById(params));
   const addReservation = useRequest((params) => reservationApi.addNewReservation(params));
   const cancelReservation = useRequest((params) => reservationApi.cancelReservation(params));
   const approveReservation = useRequest((params) => reservationApi.approveReservation(params));
   const startReservation = useRequest((params) => reservationApi.startReservation(params));
   const transferTariff = useRequest((params) => reservationApi.transferTariff(params));
   const saveReservaDossier = useRequest((params) => reservationApi.saveReservationDossier(id, params));
   const updateReservation = useRequest((params) => reservationApi.updateReservationById(id, params));
   const carHistory = useRequest(() => reservationApi.carHistory(id));
   const tolls = useRequest(() => reservationApi.reservationTolls(id));

   const [showCancelModal, setShowCancelModal] = useState(false);
   const [showApproveModal, setShowApproveModal] = useState(false);
   const [showModal, setShowModal] = useState(false);
   const [modalType, setModalType] = useState(0);
   const [disabledInputs, setDisabledInputs] = useState({} as DisableProps);

   useLayoutEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const getData = () => {
      reservation.execute(id);
      carHistory.execute();
      tolls.execute();
   };

   useEffect(() => {
      if (reservation.data) {
         getReservationDisabledOptions(reservation.data.status, setDisabledInputs);
      }
   }, [reservation.data]);

   const getReservationProof = () => {
      reservationApi
         .reservationProof(id)
         .then((res) => {
            let file = new Blob([res], { type: "application/pdf" });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
         })
         .catch((err) => {
            throw Error(err);
         });
   };

   const handleButtonClick = (action: string) => {
      switch (action) {
         case actions.NEW_USER:
            history.push(ROUTE_LINKS.CREATE_USER);
            break;

         case actions.NEW_COMPANY:
            history.push(ROUTE_LINKS.ADD_NEW_ORGANIZATION);
            break;

         case actions.SERVICES:
            history.push(`/reservation/services/${id}`);
            break;

         case actions.TRANSACTIONS:
            history.push(ROUTE_LINKS.RESERVATION_TRANSACTIONS, id);
            break;

         case actions.CRM:
            history.push(ROUTE_LINKS.crm, {
               id: id,
               userId: reservation.data?.user.id,
            });
            break;
         case actions.PROOF:
            getReservationProof();
            break;
         case actions.CANCEL:
            setModalType(2);
            setShowModal(true);
            break;
         case actions.CONTRACT:
            history.push(`/contract/${id}`);
            break;
         case actions.APPROVE:
            setModalType(1);
            setShowModal(true);
            break;
         case actions.TOLLS:
            setModalType(3);
            setShowModal(true);
            break;
         case actions.VEHICLE_HISTORY:
            setModalType(4);
            setShowModal(true);
            break;
         default:
            break;
      }
   };

   const handleNavigate = (action: string, id: number | null | undefined) => {
      switch (action) {
         case route_actions.USER:
            history.push(`/users/form/${id}`);
            break;
         case route_actions.COMPANY:
            history.push(`/organizations/form/${id}`);
            break;
         case route_actions.SERVICES:
            history.push(`/reservation/services/${id}`);
            break;
         default:
            break;
      }
   };

   const handleCloseModal = (action: string, type: string | undefined) => {
      switch (action) {
         case actions.CONFIRM:
            if (type == actions.APPROVE) {
               approveReservation.execute(id);
            } else {
               cancelReservation.execute(id);
            }
            setShowCancelModal(false);
            setShowApproveModal(false);
            break;
         case actions.CANCEL:
            setShowCancelModal(false);
            setShowApproveModal(false);
            break;
         default:
            break;
      }
   };

   const handleSubmit = (payload: any) => {
      if (id) {
         updateReservation.execute(payload);
      } else {
         addReservation.execute(payload);
      }
   };

   const reservationActions = (action: string, id: number | undefined) => {
      switch (action) {
         case reservation_actions.START:
            startReservation.execute(id);
            break;
         case reservation_actions.FINISH:
            history.push(`/reservations/finalize/${id}`);
            break;
         default:
            break;
      }
   };

   const getTransferTariff = (pickupOrganization: number, returnOrganization: number) => {
      const payload = { pickupOrganizationId: pickupOrganization, returnOrganizationId: returnOrganization };
      transferTariff.execute(payload);
   };

   const saveDossier = (dossier: string) => {
      saveReservaDossier.execute(dossier);
   };

   const modalContent = () => {
      if (modalType == 1)
         return (
            <ConfirmItem
               icon={<Help className={globalClasses.modalIcon} />}
               title={t("gen:approveModalTitle")}
               subtitle={t("gen:approveModalSubTitle")}
               handleClose={handleCloseModal}
            />
         );
      if (modalType == 2)
         return (
            <ConfirmItem
               icon={<Help className={globalClasses.modalIcon} />}
               title={t("gen:modalCancelQuestion")}
               subtitle={t("gen:modalCancelSub")}
               handleClose={handleCloseModal}
            />
         );
      if (modalType == 3) return <TollModalItem data={tolls.data?.data} close={() => setShowModal(false)} />;
      if (modalType == 4) return <VehicleHistoryItem data={carHistory.data} close={() => setShowModal(false)} />;
   };

   return (
      <div>
         <Loader
            loading={
               addReservation.loading ||
               cancelReservation.loading ||
               approveReservation.loading ||
               startReservation.loading ||
               updateReservation.loading
            }
         />

         {/* <PopupModal
            show={showCancelModal}
            handleClose={() => setShowCancelModal(false)}
            render={() => (
               <ConfirmItem
                  icon={<Help className={globalClasses.modalIcon} />}
                  title={t("gen:modalCancelQuestion")}
                  subtitle={t("gen:modalCancelSub")}
                  handleClose={handleCloseModal}
               />
            )}
         /> */}
         <PopupModal show={showModal} handleClose={() => setShowModal(false)} render={modalContent} />

         {/* <PopupModal
            show={showApproveModal}
            handleClose={() => setShowApproveModal(false)}
            render={() => (
               <ConfirmItem
                  icon={<Help className={globalClasses.modalIcon} />}
                  title={t("gen:approveModalTitle")}
                  subtitle={t("gen:approveModalSubTitle")}
                  dialogTypeId={actions.APPROVE}
                  handleClose={handleCloseModal}
               />
            )}
         /> */}

         {cancelReservation.show.toast && (
            <Toast
               show={cancelReservation.show.toast}
               message={cancelReservation.message}
               handleClose={() => {
                  cancelReservation.close();
                  reservation.execute(id);
               }}
            />
         )}

         {saveReservaDossier.show.toast && (
            <Toast show={saveReservaDossier.show.toast} message={saveReservaDossier.message} handleClose={() => saveReservaDossier.close()} />
         )}

         {addReservation.show.toast && (
            <Toast
               show={addReservation.show.toast}
               message={addReservation.message}
               handleClose={() => {
                  addReservation.close();
               }}
            />
         )}
         {updateReservation.show.toast && (
            <Toast show={updateReservation.show.toast} message={updateReservation.message} handleClose={() => updateReservation.close()} />
         )}

         {approveReservation.show.toast && (
            <Toast
               show={approveReservation.show.toast}
               message={approveReservation.message}
               handleClose={() => {
                  approveReservation.close();
                  reservation.execute(id);
               }}
            />
         )}

         {addReservation.show.modal && (
            <Alert
               show={addReservation.show.modal}
               message={addReservation.message}
               handleClose={() => {
                  addReservation.close();
                  history.goBack();
               }}
            />
         )}

         {startReservation.show.toast && (
            <Toast
               show={startReservation.show.toast}
               message={startReservation.message}
               handleClose={() => {
                  startReservation.close();
                  reservation.execute(id);
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:reservation") : t("translations:new") + " " + t("translations:reservation")}
            status={reservation.data?.status}
         />

         <ButtonContainer reservation={reservation.data} onClick={handleButtonClick} />

         <Box className={globalClasses.container}>
            <NewReservationForm
               onNavigateClick={handleNavigate}
               handleSubmit={handleSubmit}
               reservation={reservation.data}
               renderInputs={disabledInputs}
               onReservationActionClick={reservationActions}
               getTranferTariff={getTransferTariff}
               tranferTariff={transferTariff.data}
               saveDossier={saveDossier}
            />
         </Box>
      </div>
   );
};

export default NewReservation;
