import { useHistory, useParams } from "react-router";
import { InvoiceButtons, PdfViewer } from "../../components/organisms";
import { useRequest } from "../../data/api/wrapper";
import * as invoiceApi from "../../data/api/requests/invoice/index";
import * as serviceApi from "../../data/api/requests/reservation-services/index";
import React, { useEffect, useState } from "react";
import { invoice_action, invoice_create, invoice_modal_actions, modal_action } from "../../data/constants/button-action";
import { PopupModal, TitleContainer } from "../../components/molecules";
import { useTranslation } from "react-i18next";
import { CancelInvoiceItem, CreditNoteItem, Loader, ReceiptItem, Toast } from "../../components/atoms";
import Service from "../../data/models/reservation-service.model";
import Receipt from "../../data/models/receipt.modal";

const Index = () => {
   const { t } = useTranslation(["translations"]);

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const [showModal, setShowModal] = useState(false);
   const [modalType, setModalType] = useState(0);
   const [email, setEmail] = useState("");

   const getInvoice = useRequest((params) => invoiceApi.getInvoiceById(params));
   const cancelInvoice = useRequest((params) => invoiceApi.cancelInvoice(params));
   const sendInvoice = useRequest(() => invoiceApi.sendInvoiceByEmail(id, email));
   const creditMemo = useRequest((params) => invoiceApi.getCreditMemo(params));
   const findReceipt = useRequest((params) => invoiceApi.getReceipt(params));
   const receipt = useRequest((params) => invoiceApi.createReceipt(id, params));
   const refund = useRequest((params) => invoiceApi.refundInvoice(params, id));
   const reservationItems = useRequest((params) => serviceApi.getReservationService(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (getInvoice.data) {
         reservationItems.execute(getInvoice.data.invoice.reservationId);
      }
   }, [getInvoice.data]);

   const getData = () => {
      getInvoice.execute(id);
      creditMemo.execute(id);
      findReceipt.execute(id);
   };

   const handleButtonClick = (action: string) => {
      switch (action) {
         case invoice_action.BACK:
            history.goBack();
            break;
         case invoice_action.RESERVATION:
            history.push(`/reservation/form/${getInvoice.data?.invoice.reservationId}`);
            break;
         case invoice_action.CANCEL_INVOICE:
            setModalType(1);
            setShowModal(true);
            break;
         default:
            break;
      }
   };

   const onModalButtonClick = (action: string, reason: string | undefined) => {
      switch (action) {
         case modal_action.CONFIRM:
            setShowModal(false);
            cancelInvoice.execute({ invoiceId: id, reason: reason });
            break;
         case modal_action.CANCEL:
            setShowModal(false);
            break;
         default:
            break;
      }
   };

   const sendByEmail = () => {
      sendInvoice.execute();
   };

   const onCreateClick = (type: string) => {
      switch (type) {
         case invoice_create.receipt:
            setModalType(2);
            setShowModal(true);
            break;
         case invoice_create.credit_notes:
            setModalType(3);
            setShowModal(true);
            break;
         default:
            break;
      }
   };

   const createReceipt = (values: any, action: string) => {
      switch (action) {
         case invoice_modal_actions.cancel:
            setShowModal(false);
            break;
         case invoice_modal_actions.confirm:
            receipt.execute(values);
            break;
         default:
            break;
      }
   };

   const onItemRemove = (item: Service) => {
      reservationItems.setData((prev) => prev?.filter((el) => el.id != item.id));
   };

   const onItemChange = (item: Service, event: React.ChangeEvent<HTMLInputElement>) => {
      reservationItems.setData((prev) =>
         prev?.map((el) => {
            el.quantity = parseInt(event.target.value);
            return el;
         })
      );
   };

   const refundInvoice = (reason?: { reason: string; type: string }) => {
      let data = { ...reason, items: reservationItems.data };
      refund.execute(data);
   };

   const handleCreditNotesClick = (action: string, reason?: { reason: string; type: string }) => {
      switch (action) {
         case invoice_modal_actions.cancel:
            setShowModal(false);
            break;
         case invoice_modal_actions.confirm:
            refundInvoice(reason);
            // setShowModal(false);
            break;
         case invoice_modal_actions.refresh:
            reservationItems.execute(getInvoice?.data?.invoice.reservationId);
            break;
         default:
            break;
      }
   };

   const generateInvoice = (item: Receipt) => {
      console.log(creditMemo.data);
   };

   const onShowInvoice = (item: Receipt) => {
      history.push(`/receipts/${item.id}`);
   };

   return (
      <div>
         <Loader loading={cancelInvoice.loading} />

         {sendInvoice.show.toast && <Toast show={sendInvoice.show.toast} handleClose={() => sendInvoice.close()} message={sendInvoice.message} />}

         {cancelInvoice.show.toast && (
            <Toast
               show={cancelInvoice.show.toast}
               handleClose={() => {
                  cancelInvoice.close();
               }}
               message={cancelInvoice.message}
            />
         )}

         <PopupModal
            show={showModal}
            handleClose={() => setShowModal(false)}
            render={() => {
               if (modalType == 1) return <CancelInvoiceItem onClick={onModalButtonClick} />;
               if (modalType == 2) return <ReceiptItem onClick={createReceipt} loading={receipt.loading} />;
               if (modalType == 3)
                  return (
                     <CreditNoteItem
                        onClick={handleCreditNotesClick}
                        items={reservationItems.data}
                        onChange={onItemChange}
                        onRemove={onItemRemove}
                        loading={refund.loading}
                     />
                  );
            }}
         />

         <TitleContainer title={t("translations:invoice")} />

         <InvoiceButtons onClick={handleButtonClick} />

         <PdfViewer
            data={getInvoice.data}
            onCreateClick={onCreateClick}
            onSelectUser={(mail) => setEmail(mail)}
            onSendEmail={sendByEmail}
            loading={sendInvoice.loading}
            creditMemo={creditMemo.data}
            receipts={findReceipt?.data}
            onGenerateInvoice={generateInvoice}
            onShowInvoice={onShowInvoice}
         />
      </div>
   );
};

export default Index;
