import { Divider, Grid, Slider, Typography } from "@material-ui/core";
import { Flag } from "@material-ui/icons";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../../data/models/reservation.model";
import { useValidation } from "../../../../utills/validation";
import { useFormik } from "formik";
import { AutoComplete, CheckBox, CommonButton, InputLabel, TextArea, TextInput } from "../../../atoms";
import moment from "moment";
import { getCarByID } from "../../../../data/api/requests/cars";
import { useRequest } from "../../../../data/api/wrapper";

interface FinalizeProps {
   reservation?: Reservation;
   handleSubmit: (item: any) => void;
}

const FinalizeForm: FC<FinalizeProps> = ({ reservation, handleSubmit }) => {
   const { t } = useTranslation(["translations", "vehicles", "gen"]);

   const { counters } = useDropdown();

   const globalClasses = globalStyles();
   console.log(reservation);

   const carApi = useRequest((params) => getCarByID(params));

   const { finalizeResevationSchema } = useValidation();
   const formik = useFormik({
      initialValues: {
         car: "",
         brand: "",
         model: "",
         startDate: "",
         startTime: "",
         endDate: "",
         endTime: "",
         pickupOrganisation: "",
         returnOrganisation: "",
         client: "",
         startFuel: 0,
         endFuel: 8,
         startKilometer: "",
         notes: "",
         checked: false,
         returnOrgnizationId: 0,
      },
      validationSchema: finalizeResevationSchema,
      validateOnChange: false,
      validateOnBlur: false,
      validateOnMount: false,
      onSubmit: (values) => {
         handleSubmit({
            reservationId: reservation?.id,
            notes: values.notes,
            returnOrganizationId: values.returnOrgnizationId,
            fuelLevel: values.endFuel,
            endDate: reservation?.endDate,
         });
      },
   });

   useEffect(() => {
      if (reservation) {
         var isReturningEarlierThanExpected = moment(reservation.endDate).isAfter();

         formik.values.startDate = moment(reservation.startDate).format("YYYY-MM-DD");
         formik.values.startTime = moment(reservation.startDate).format("HH:mm");
         formik.values.endDate = isReturningEarlierThanExpected ? moment().format("YYYY-MM-DD") : moment(reservation.endDate).format("YYYY-MM-DD");
         formik.values.endTime = isReturningEarlierThanExpected ? moment().format("HH:mm") : moment(reservation.endDate).format("HH:mm");
         formik.values.pickupOrganisation = reservation.pickupOrganization.name;
         formik.values.returnOrganisation = reservation.returnOrganization.name;
         formik.values.client = reservation.user.name;
         formik.values.returnOrgnizationId = reservation.returnOrganization.id;
         formik.values.endFuel = 8;

         if (!carApi.data) {
            carApi.execute(reservation?.car.carId);
         }
      }
   }, [reservation]);

   useEffect(() => {
      if (carApi) {
         var car = carApi.data;
         console.log(car);
         formik.values.car = car?.licensePlate ?? "";
         formik.values.brand = car?.name ?? "";
         formik.values.model = car?.carType.name ?? "";
         formik.values.startFuel = car?.fuelLevel ?? 0;
      }
   }, [carApi]);

   return (
      <div className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={2} md={4} sm={12} xs={12}>
               <img src={"https://test.ajrent.pt/ws/box/image-placeholder.png"} height="100%" width="100%" />
            </Grid>
            <Grid item xl={9} lg={10} md={8} sm={12} xs={12}>
               <Grid container spacing={3}>
                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                     <InputLabel label={t("translations:vehicle")} />
                     <TextInput helperText="" id="" type="text" disabled={true} title={`${formik.values.car}`} />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:brand")} />
                     <TextInput helperText="" id="" type="text" disabled={true} title={`${formik.values.brand}`} />
                  </Grid>
                  <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                     <InputLabel label={t("vehicles:model")} />
                     <TextInput title="" helperText="" id="" type="text" disabled={true} />
                  </Grid>
               </Grid>
               <InputLabel label={t("translations:client")} />
               <TextInput title={`${formik.values.client}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:survey")} />
               <TextInput title={`${formik.values.pickupOrganisation}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:withDrawlDate")} />
               <TextInput title={`${formik.values.startDate}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:hour")} />
               <TextInput title={`${formik.values.startTime}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={5} lg={5} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:returnCounter")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  defaultValue={counters.find((el) => el.id == reservation?.returnOrganization.id)}
                  options={counters || []}
                  renderOption={(item) => item.officialName}
                  getOptionLabel={(item) => item.officialName}
                  onChange={(_, item) => formik.setFieldValue("returnOr", item?.id)}
               />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:returnDate")} />
               <TextInput title={`${formik.values.endDate}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:hour")} />
               <TextInput title={`${formik.values.endTime}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:initialKm")} />
               <TextInput title={`${formik.values.startKilometer}`} helperText="" id="" type="text" disabled={true} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:finalKm")} />
               <TextInput title={``} helperText="" id="" type="text" disabled={false} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:initialFuel")} />
               <Slider
                  aria-label="fuel-level"
                  defaultValue={8}
                  // getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={8}
                  disabled={true}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("gen:finalFuel")} />
               <Slider
                  aria-label="fuel-level"
                  defaultValue={8}
                  // getAriaValueText={valuetext}
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={0}
                  max={8}
                  onChange={(e, value) => formik.setFieldValue("endFuel", value)}
               />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <Grid container spacing={3}>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
               <InputLabel label={t("gen:notes")} />
               <TextArea error={formik.errors.notes} id="notes" onChange={formik.handleChange} />
               <Typography className={globalClasses.errorText}> {formik.errors.notes} </Typography>
            </Grid>
         </Grid>
         <CheckBox
            checked={formik.values.checked}
            setChecked={() => {
               formik.setFieldValue("checked", !formik.values.checked);
            }}
            title={t("translations:finalizeAffirmation")}
         />
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton disable={!formik.values.checked} title={t("gen:finalizeRental")} icon={<Flag />} onClick={formik.handleSubmit} />
      </div>
   );
};

export default FinalizeForm;
