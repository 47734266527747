import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const titleStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 15,
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontSize: 25,
      fontWeight: "lighter",
    },
  })
);
