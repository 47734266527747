import moment from "moment";
import * as yup from "yup";
import { FeatureInputs } from "../data/models/car-inputs";
import Status from "../data/models/status.model";
import { DisableProps } from "../pages/reservations/newReservation/NewReservation";
import { normalizeCurrency } from "./masks";

export const countryToFlag = (isoCode: string) => {
   return typeof String.fromCodePoint !== "undefined"
      ? isoCode.toUpperCase().replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
};

export const capitalizeFirstLetter = (string: string) => {
   return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getOptionLabel = (item: any) => {
   if (item) {
      return item.name;
   } else {
      return "";
   }
};

export const formatDate = (date: string | undefined) => {
   if (!date) {
      return "";
   }
   return moment(date).format("DD/MM/YYYY");
};

export const formatDateForInput = (date: string | null | undefined) => {
   if (!date) {
      return "";
   }
   return moment(date).format("YYYY-MM-DD");
};

export const getToday = () => {
   const today = new Date().getTime();
   return moment(today).format("YYYY-MM-DD");
};

export const formatTime = (date: string | undefined) => {
   if (!date) {
      return "";
   }
   return moment(date).format("HH:mm");
};

export const formatDateTime = (date: string) => {
   return moment(date).format("DD/MM/YYYY HH:mm");
};

export const validateAtleastOne = (context: yup.TestContext<any>) => {
   const data: any = context;
   const documents = data.from[1].value.documents;
   const docObject = data.parent;
   const currentField = data.path.split(".")[1];
   if (docObject.typeId === 3) {
      if (docObject[currentField]) {
         return true;
      }
      return false;
   }
   if (docObject.typeId === 1) {
      if (documents[1][currentField]) {
         return true;
      }
      if (docObject[currentField]) {
         return true;
      }
      return false;
   }
   if (docObject.typeId === 2) {
      if (documents[0][currentField]) {
         return true;
      }
      if (docObject[currentField]) {
         return true;
      }
      return false;
   }
   return false;
};

export const validateVehicleHighlights = (context: any[], count: number) => {
   const highlighted = context.filter((el) => el.highlight);
   if (highlighted.length >= count) {
      return true;
   }
   return false;
};

export const validateLatitude = (context: any, maxValue: number) => {
   let value = context.originalValue;
   if (!value) {
      return false;
   }
   value = value.replace(",", ".");
   value = value.replace(" ", "");
   value = parseFloat(value);
   if (value > maxValue) {
      return false;
   }
   return true;
};

export const getFeatureInputs = (carFeatures: FeatureInputs) => {
   let features: {
      value: number | null;
      highlight: boolean;
      featureId: number;
      name: string;
   }[] = [];
   carFeatures.inputs.map((el) =>
      el.features.map((temp) => {
         features.push({ value: 0, highlight: true, featureId: temp.id, name: temp.name });
      })
   );
   return features;
};

export const getReservationDisabledOptions = (status: Status, setDisabledInputs: React.Dispatch<React.SetStateAction<DisableProps>>) => {
   if (status.id == 1) {
      setDisabledInputs({
         edit: true,
         all: false,
         pickupDate: false,
         start: false,
         buttons: true,
      });
   } else if (status.id == 2) {
      setDisabledInputs({
         edit: true,
         all: false,
         pickupDate: true,
         start: true,
         buttons: true,
      });
   } else if (status.id == 3) {
      setDisabledInputs({
         edit: true,
         all: true,
         pickupDate: true,
         start: false,
         buttons: false,
      });
   } else if (status.id == 4) {
      setDisabledInputs({
         edit: true,
         all: false,
         pickupDate: true,
         start: false,
         buttons: false,
      });
   } else if (status.id == 5) {
      setDisabledInputs({
         edit: true,
         all: false,
         start: false,
         pickupDate: true,
         buttons: false,
      });
   }
};
