import { Box, Grid } from "@material-ui/core";
import { Edit, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { AutoComplete, CommonButton, InputLabel, TableButton } from "../../../components/atoms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as reportsApi from "../../../data/api/requests/reports/index";
import UngeneratedInvoice from "../../../data/models/ungenerated-invoices.model";
import useDropdown from "../../../data/context/dropDown/DropdownContext";
import { ContainerTopBar } from "../../../components/molecules";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import Autocomplete from "../../../components/atoms/autocomplete/Autocomplete";
import { useRequest } from "../../../data/api/wrapper";

const UngeneratedInvoices = () => {
   const globalClasses = globalStyles();
   const history = useHistory();
   const { t } = useTranslation(["translations", "dashboardStrings"]);
   const { counters } = useDropdown();
   const [pagination, setPagination] = useState({ page: 1, size: 10 });
   const [searchUngen, setSearchUngen] = useState("");
   const [selectedCounters, setSelectedCounters] = useState<number[]>([]);

   const getUngeneratedInvoicesRequest = useRequest(() =>
      reportsApi.getUngeneratedInvoices(pagination.page, pagination.size, selectedCounters, searchUngen)
   );

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getUngeneratedInvoicesRequest.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchUngen]);

   return (
      <div>
         <Box className={globalClasses.title}>{t("translations:ungeneratedInvoices")}</Box>
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <InputLabel label={t("translations:counters")} />
            <Grid container spacing={3}>
               <Grid item xl={6} lg={6} md={8} sm={12} xs={12}>
                  <AutoComplete
                     label={""}
                     placeholder="Select one"
                     multiple
                     getOptionLabel={(item) => item.officialName}
                     options={counters}
                     onChange={(event, item) => {
                        let temp: number[] = [];
                        item.map((el) => {
                           if(el.id){
                              temp.push(el.id)
                           }
                        });
                        setSelectedCounters(temp);
                     }}
                  />
               </Grid>
            </Grid>
            <CommonButton
               title={t("translations:search")}
               onClick={() => {
                  getUngeneratedInvoicesRequest.execute();
               }}
               icon={<Search />}
            />
            <ContainerTopBar title={t("translations:listing")} activity={getUngeneratedInvoicesRequest.loading}>
               <DataTable
                  data={getUngeneratedInvoicesRequest.data?.data || []}
                  pagination={true}
                  total={getUngeneratedInvoicesRequest.data?.total || 0}
                  onSearching={(query) => setSearchUngen(query)}
                  onChange={(page, rowsPerPage) => setPagination({ ...pagination, page: page, size: rowsPerPage })}
               >
                  <TableColumn headerText={t("translations:counters")} render={(item) => item.fantasyName} />

                  <TableColumn headerText={t("translations:saleman")} render={(item) => item.sellerName} />

                  <TableColumn
                     headerText={t("translations:invoice")}
                     render={(item) => (
                        <div
                           className={`${globalClasses.tableSmallText} ${globalClasses.primaryText}`}
                           onClick={() => {
                              history.push(`/reservation/transactions/${item.reservationId}`);
                           }}
                        >
                           #{item.id}
                        </div>
                     )}
                  />

                  <TableColumn
                     headerText={t("dashboardStrings:reservation")}
                     headerOptions={{ align: "right" }}
                     align="right"
                     render={(item) => (
                        <TableButton
                           title={`#${item.reservationId}`}
                           onClick={() => {
                              history.push(`/reservation/form/${item.reservationId}`);
                           }}
                           margin={1}
                        />
                     )}
                  />
               </DataTable>
            </ContainerTopBar>
         </Box>
      </div>
   );
};

export default UngeneratedInvoices;
