import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const cardStyles = makeStyles((theme: Theme) => ({
   creditContainer: {
      // height: 200,
      border: 1,
      borderColor: COLORS.greyBorder,
      borderStyle: "solid",
      borderRadius: 7,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
   },
   topTextContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
   },
   bottomLabel: {
      height: 50,
      borderTop: 1,
      borderTopColor: COLORS.greyBorder,
      borderTopStyle: "solid",
      backgroundColor: COLORS.lightGrey,
      borderBottomLeftRadius: 7,
      borderBottomRightRadius: 7,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      paddingLeft: 20,
      paddingRight: 20,
   },
}));
