export const ROUTE_LINKS = {
   DASHBOARD: "/dashboard",
   CHANGE_PASSWORD: "/changepassword",
   NEW_RESERVATION: "/reservation/form",
   EDIT_RESERVATION: "/reservation/form/:id",
   LIST_ALL_RESERVATIONS: "/reservation/listall",
   RESERVATION_SERVICES: "/reservation/services/:reservationId",
   RESERVATION_TRANSACTIONS: "/reservation/transactions/:id",
   RESERVATION_TICKETS: "/reservation/tickets",
   EDIT_RESERVATION_TICKETS: "/reservation/tickets/edit/:id",
   RESERVATION_CONTRACT: "/contract/:reservationId",
   FINALIZE_RESERVATION: "/reservations/finalize/:id",
   crm: "/reservation/crm",
   LIST_ALL_TRANSPORT_GUIDES: "/way-bills/listall",
   ADD_NEW_TRANSPORT_GUIDE: "/way-bills/form",
   EDIT_TRANSPORT_GUIDE: "/way-bills/form/:id",
   CREATE_USER: "/users/form",
   EDIT_USER: "/users/form/:id",
   LIST_USERS: "/users/list",
   PROMOTE_USER_TO_ADMIN: "/users/promote-to-admin",
   NEW_MESSAGE: "/users/new-message",
   ADD_PAYMENT_CARD: "/payment-cards/:id",
   LIST_ADMINISTRATORS: "/administrators",
   ORGANIZATIONS: "/organizations",
   ADD_NEW_ORGANIZATION: "/organizations/form",
   EDIT_ORGANIZATION: "/organizations/form/:id",
   LIST_COUNTERS: "/counters/listing",
   ADD_NEW_COUNTER: "/counters/form",
   EDIT_COUNTER: "/counters/form/:id",
   NEW_MAINTENANCE: "/maintenance/form",
   EDIT_MAINTENANCE: "/maintenance/form/:id",
   MAINTENANCE: "/maintenance",
   REMOVE_FROM_MAINTENANCE: "/maintenance/remove/:id",
   VEHICLES: "/vehiclemanagment/vehicles",
   NEW_VEHICLE: "/vehiclemanagment/vehicles/form",
   EDIT_VEHICLE: "/vehiclemanagment/vehicles/form/:id",
   VEHICLE_DETAIL: "/vehiclemanagment/vehicles/detail/:id",
   VEHICLE_FINES: "/vehiclemanagment/fines",
   VEHICLE_FINES_ID: "/vehiclemanagment/fines/:id",
   NEW_VEHICLE_FINE: "/fines/form",
   EDIT_VEHICLE_FINE: "/fines/form/:id",
   VEHICLE_CATEGORIES: "/vehiclemanagment/categories",
   NEW_VEHICLE_CATREGORY: "/vehiclemanagment/categories/new",
   VEHICLE_BRANDS: "/vehiclemanagment/brands",
   NEW_VEHICLE_BRAND: "/vehiclemanagment/brands/new",
   EDIT_VEHICLE_BRAND: "/vehiclemanagment/brands/edit/:id",
   VEHICLE_CHARACTERISTICS: "/vehiclemanagment/characteristics",
   NEW_VEHICLE_CHARACTERISTICS: "/vehiclemanagment/characteristics/form",
   EDIT_VEHICLE_CHARACTERISTICS: "/vehiclemanagment/characteristics/form/:id",
   COUNTRIES: "/places/countries",
   STATES: "/places/states",
   CITIES: "/places/cities",
   ADD_NEW_TERRIF: "/tarrifs/form",
   EDIT_TERRIF: "/tarrifs/form/:id",
   LIST_ALL_TERRIFS: "/tarrifs/listall",
   DISCOUNT_CAMPAIGN: "/discounts/campaign",
   NEW_DISCOUNT_CAMPAIGN: "/discounts/campaign/form",
   EDIT_DISCOUNT_CAMPAIGN: "/discounts/campaign/form/:id",
   DISCOUNTS_BY_QUANTITY: "/discounts/byquantity",
   EDIT_DISCOUNT_BY_QUANTITY: "/discount/byquantity/edit/:id",
   TOLLS_NO_BONDS: "/tolls/nobonds",
   TOLLS_NOT_BILLED: "/tolls/notbilled",
   TOLLS_PROCESS: "/tolls/process",
   REPORTS_DIVERGENT_CATEGORIES: "/reports/divergent-categories",
   REPORTS_PENDING_FINANCIALS: "/reports/pending-financials",
   REPORTS_MAINTENANCE_COST: "/reports/maintenance-cost",
   REPORTS_TOLL_BOOKING: "/reports/toll-bookings",
   REPORTS_COMMISSION: "/reports/commissions",
   REPORTS_ACCOUNTING: "/reports/accounting",
   REPORTS_UNGENERATED_INVOICES: "/reports/ungenerated-invoices",
   REPORTS_COMMISSION_ADJUSTMENT: "/reports/commission-adjustment",
   REPORTS_TRANSACTIONS_GENERATE_BILLING: "/reports/transactions/generate-billing",
   FAQS: "/faqs",
   ADD_NEW_FAQ: "/faqs/create",
   EDIT_FAQ: "/faq/edit/:id",
   APP_BANNERS: "/app-banners",
   ADD_NEW_BANNER: "/app-banners/form",
   EDIT_BANNER: "/app-banners/form/:id",
   DEFINITIONS: "/definitions",
   INVOICES: "/invoices/:id",
   RECEIPT: "/receipts/:id",
   NOT_FOUND: "*",
};
