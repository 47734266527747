import moment from "moment";
import CarCategory from "../data/models/car-category.model";
import Car from "../data/models/car.model";
import { TransferTariff } from "../data/models/transfer-tax.model";
import { formatDate } from "./helper";
import { encodeDate } from "./utills";

export const calculateDays = (
   startDate: string,
   startTime: string,
   endDate: string,
   endTime: string,
   transferTariff: TransferTariff | undefined,
   carCategory: CarCategory,
   chargeHour: boolean
) => {
   startDate = startDate.replace(/-/g, "/");
   endDate = endDate.replace(/-/g, "/");
   if (startDate && startTime && endDate && endTime && carCategory) {
      const start = moment(encodeDate(startDate, ""));
      const end = moment(encodeDate(endDate, ""));
      let dayDuration = moment.duration(end.diff(start)).asDays();
      const startHours = startTime.split(":")[0];
      const endHours = endTime.split(":")[0];
      let hours = parseInt(endHours) - parseInt(startHours);

      let priceWithOutVat = 0;
      if (chargeHour) {
         const firstDate = moment(encodeDate(startDate, startTime));
         const lastDate = moment(encodeDate(endDate, endTime));
         const duration = moment.duration(lastDate.diff(firstDate));
         hours = duration.asHours();
         priceWithOutVat = hours * carCategory.pricePerHour + (transferTariff?.itemValue || 1);
         dayDuration = Math.floor(hours / 24);
         hours = hours % 24;
      } else {
         priceWithOutVat = dayDuration * carCategory.pricePerDay + (transferTariff?.itemValue || 1);
         if (hours >= 1) {
            dayDuration += 1;
         }
         hours = 0;
      }
      let priceWithVat = priceWithOutVat * 1.23;
      if (dayDuration < 0 && hours < 0) {
         dayDuration = 0;
         hours = 0;
         priceWithOutVat = 0;
         priceWithVat = 0;
      }
      return { priceWithOutVat, dayDuration, priceWithVat, hours };
   }
   const emptyPrice = 0;
   const duration = 0;
   const withTax = 0;
   const hours = 0;
   return { emptyPrice, duration, withTax, hours };
};
