import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { TitleContainer } from "../../../components/molecules";
import { NewVehicleForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as carApi from "../../../data/api/requests/cars/index";
import { MutableRefObject, useEffect, useRef } from "react";
import { AlertDailog, Loader, Toast } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

const NewVehicle = () => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "vehicles"]);

   const { id } = useParams<{ id: string }>();

   const history = useHistory();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const car = useRequest((params) => carApi.getCarByID(params));
   const addCar = useRequest((params) => carApi.saveCar(params));
   const updateCar = useRequest((params) => carApi.updateCar(params, id));

   useEffect(() => {
      let unsubscribe = false;
      if (id && !unsubscribe) {
         car.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const createVehicle = (vehicle: any) => {
      if (id) {
         updateCar.execute(vehicle);
      } else {
         addCar.execute(vehicle);
      }
   };

   return (
      <div>
         <Loader loading={addCar.loading || updateCar.loading} />

         {updateCar.show.toast && <Toast show={updateCar.show.toast} message={updateCar.message} handleClose={() => updateCar.close()} />}

         {addCar.show.modal && (
            <AlertDailog
               show={addCar.show.modal}
               title={addCar.message.message}
               message={addCar.message.description}
               primaryButtonText={t("translations:backButton")}
               secondaryButtonText={t("translations:addNew")}
               primaryButtonHandle={() => {
                  history.push(ROUTE_LINKS.VEHICLES);
               }}
               secondaryButtonHandle={() => {
                  clearForm.current();
                  addCar.close();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:vehicle") : t("translations:new") + " " + t("translations:vehicle")}
            status={id ? car.data?.status : undefined}
         />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <NewVehicleForm car={car.data} createVehicle={createVehicle} clearForm={clearForm} />
         </Box>
      </div>
   );
};

export default NewVehicle;
