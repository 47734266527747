import ApiResponse from "../../../models/api-response.model";
import Tolerance from "../../../models/tolerance.modal";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getTolerance(): Promise<ApiResponse<Tolerance[]>> {
  try {
    const resp = await request({
      method: API_METHODS.GET,
      url: API_ROUTES.tolerance,
    });
    if (resp.status !== 200) {
      throw new Error("Algo deu errado");
    }
    const data: ApiResponse<Tolerance[]> = resp.data;
    return data;
  } catch (error) {
    throw error;
  }
}

export async function updateTolerance(tolerance: {
  toleranceModels: Tolerance[];
}): Promise<ApiResponse<void>> {
  try {
    const resp = await request({
      method: API_METHODS.POST,
      url: API_ROUTES.tolerance,
      data: tolerance,
    });
    if (resp.status !== 200) {
      throw new Error("Algo deu errado");
    }
    const data: ApiResponse<void> = resp.data;
    return data;
  } catch (error) {
    throw error;
  }
}
