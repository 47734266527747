import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";

export const CounterDropdownStyles = makeStyles((theme: Theme) => ({
   button: {
      width: 250,
      padding: 5,
      background: theme.palette.background.paper,
      borderWidth: 1,
      borderColor: COLORS.greyBorder,
      borderStyle: "solid",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      "&:hover": {
         cursor: "pointer",
      },
      borderRadius: 5,
   },
   text: {
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      color: COLORS.greyText,
      marginLeft: 4,
      fontSize: 15,
      padding: 0,
      marginTop: 1,
   },
   icon: { color: COLORS.greyText, fontSize: 16 },
   iconText: { display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" },
}));
