import { Slide, Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import useAPIFeedback from "../../../data/context/feedback";

const Feedback = () => {
   const { data, removeData } = useAPIFeedback();
   return (
      <div>
         <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={!!data}
            key={"simple-toast"}
            autoHideDuration={3000}
            TransitionComponent={(props) => <Slide {...props} direction="up" />}
            onClose={removeData}
         >
            <Alert color={data?.error ? "error" : "success"}>{data?.message}</Alert>
         </Snackbar>
      </div>
   );
};

export default Feedback;
