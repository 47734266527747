import { ArrowBack } from "@material-ui/icons";
import { Box, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { CommonButton, Loader, Toast } from "../../components/atoms";
import { SendByEmail, TitleContainer } from "../../components/molecules";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import { useRequest } from "../../data/api/wrapper";
import * as reservationApi from "../../data/api/requests/reservations/index";
import { useEffect, useLayoutEffect, useState } from "react";
import { contractStyles } from "./index.styles";

const Contract = () => {
   const { t } = useTranslation(["gen", "translations"]);

   const { reservationId } = useParams<{ reservationId: string }>();

   const reservationContract = useRequest((params) => reservationApi.reservationContract(params));
   const reservationCostumers = useRequest((params) => reservationApi.getReservationCustomers(params));
   const sendContract = useRequest((params) => reservationApi.sendContract(reservationId, params));

   const history = useHistory();

   const globalClasses = globalStyles();
   const classes = contractStyles();

   const [userEmail, setUserEmail] = useState("");

   useLayoutEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const getData = () => {
      reservationContract.execute(reservationId);
      reservationCostumers.execute(reservationId);
   };

   const sentToUserEmail = () => {
      if (!userEmail) return;
      sendContract.execute(userEmail);
   };
   return (
      <>
         <Loader loading={sendContract.loading} />

         {sendContract.show.toast && <Toast show={sendContract.show.toast} message={sendContract.message} handleClose={() => sendContract.close()} />}

         {!reservationContract.loading && <TitleContainer title={t("gen:contract") + `# ${reservationContract.data?.id}`} />}

         <CommonButton icon={<ArrowBack />} title={t("translations:backButton")} onClick={() => history.goBack()} />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Grid container spacing={3}>
               <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <Box className={classes.pdfContainer}>
                     {reservationContract?.data?.pdfFileBase64 && (
                        <embed
                           src={`data:application/pdf;base64,${reservationContract?.data?.pdfFileBase64}`}
                           type="application/pdf;base64,base64encodedpdf"
                           width="100%"
                           height="100%"
                        />
                     )}
                  </Box>
               </Grid>
               <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                  <SendByEmail
                     users={reservationCostumers.data || []}
                     onSelectUser={(email) => {
                        setUserEmail(email);
                     }}
                     onSendEmail={sentToUserEmail}
                     loading={false}
                     title={t("gen:sendToUserEmail")}
                     buttonTitle={t("gen:emailButtonTitle")}
                  />
               </Grid>
            </Grid>
         </Box>
      </>
   );
};

export default Contract;
