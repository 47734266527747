import ApiResponse from "../../../models/api-response.model";
import DiscountBillable from "../../../models/billable-discount.modal";
import CarCategory from "../../../models/car-category.model";
import CarDropdown from "../../../models/car-dropdown.model";
import City from "../../../models/city.model";
import { Counter } from "../../../models/counters.model";
import Country from "../../../models/country.model";
import { FeatureType } from "../../../models/feature-type.model";
import ReservationType from "../../../models/reservation-type.model";
import CountryState from "../../../models/state.model";
import Status from "../../../models/status.model";
import Tariff from "../../../models/tariff.model";
import User from "../../../models/user.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import FeatureNames from "../../../models/feature-name.model";
import { FeatureInputs } from "../../../models/car-inputs";
import Pagination from "../../../models/pagination.model";
import Organization from "../../../models/organization.model";

export const getCountries = async (): Promise<ApiResponse<Pagination<Country[]>>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCountries.format(1, 1000),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<Country[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getStates = async (id: number | undefined): Promise<ApiResponse<CountryState[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getStatesByCountryId.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<CountryState[]>> = resp.data;
      const mData = {...data, data: data.data.data}
      return mData;
   } catch (error) {
      throw error;
   }
};

export const getCities = async (id: number | undefined): Promise<ApiResponse<City[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCitiesByStateId.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<City[]>> = resp.data;
      const mData = {...data, data: data.data.data}
      return mData;
   } catch (error) {
      throw error;
   }
};
export const getDropdownBanners = async (): Promise<ApiResponse<string[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.dropdownBanners,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<string[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getDropdownCounters = async (): Promise<ApiResponse<Pagination<Counter[]>>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getDropdownCounters.format(1, 100000),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<Counter[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCarFleets = async (): Promise<ApiResponse<ReservationType[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCarFleets,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: Promise<ApiResponse<ReservationType[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getFuels = async (): Promise<ApiResponse<ReservationType[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getFuelTypes,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: Promise<ApiResponse<ReservationType[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getBrands = async (): Promise<ApiResponse<Pagination<ReservationType[]>>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCarBrands,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<ReservationType[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getFeatureTypes = async (): Promise<ApiResponse<FeatureType[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCarFeatureTypes,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<FeatureType[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getAllCars = async (categoryId?: number): Promise<ApiResponse<Pagination<CarDropdown[]>>> => {
   const query = categoryId ? `&categoryId=${categoryId}` : "";
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.dropdownCars.format(1, 1000000) + query,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      let data: ApiResponse<Pagination<CarDropdown[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCarMaintenanceTypes = async (): Promise<ApiResponse<Tariff[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getDropdownCarMaintenanceTypes,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Tariff[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getGroupTypes = async (): Promise<ApiResponse<Tariff[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getGroupTypes,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Tariff[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export async function getOrganizations(search?: string): Promise<ApiResponse<Pagination<Organization[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getDropdownOrganizations.format(1, 1000000, search || ""),
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Organization[]>> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getUsers(search: string): Promise<ApiResponse<User[]>> {
   console.log("SEARCH", search);
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getDropdownUsers + `?search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<User[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getBillableItems(typeId: number): Promise<ApiResponse<Pagination<DiscountBillable[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getBillableItems.format(1, 1000, "") + `&typeId=${typeId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      let data: ApiResponse<Pagination<DiscountBillable[]>> = await resp.data;
      const index = data.data.data.findIndex((el) => el.reference === "IN0001");
      if (index >= 0) {
         const dumped = data.data.data.splice(index, 1);
      }
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getColors(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getColors,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getPaymentMethods(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getPaymentMethods,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDropdownCampaigns(categoryId?: number): Promise<ApiResponse<ReservationType[]>> {
   const query = categoryId ? `?categoryId=${categoryId}` : "";
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getDropdownCampaigns + query,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getVehicleCategories(tariffId?: number): Promise<ApiResponse<Pagination<CarCategory[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getDropdownCategories.format(1, 10000, "", tariffId),
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      let data: ApiResponse<Pagination<CarCategory[]>> = await resp.data;
      data.data.data = data.data.data.sort((a, b) => a.tariff.name.localeCompare(b.tariff.name));
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDocumentTypes(): Promise<ApiResponse<Status[]>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.getDocumentTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Status[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getSubjectTypes(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getCrmSubjectTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getModalTypes(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getModalTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getReceptivities(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getReceptivities,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function tollsStatusTypes(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getTollStatusTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function carFeatures(): Promise<FeatureInputs> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getDropdownFeatures,
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }

      const rawData: ApiResponse<FeatureNames[]> = await resp.data;

      console.log(rawData);

      let groupedInputs = rawData.data.map((el) => {
         return {
            type: el.type,
            features: el.features.filter((item: any) => !item.isBoolean),
         };
      });

      let groupedCheckboxes = rawData.data.map((el) => {
         return {
            type: el.type,
            features: el.features.filter((item: any) => {
               if (item.isBoolean) {
                  return { ...item, checked: false, highlight: false };
               }
            }),
         };
      });

      console.log(groupedCheckboxes);

      const data: FeatureInputs = {
         inputs: groupedInputs,
         checkboxes: groupedCheckboxes,
      };

      return data;
   } catch (error) {
      throw error;
   }
}

export async function accountTypes(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.accountTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function reservationStatus(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getReservationStatuses,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDiscountTypes(): Promise<ApiResponse<ReservationType[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.discountTypes,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<ReservationType[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
