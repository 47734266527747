import { Box, Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CommonButton, InputLabel, TextInput } from "../../../atoms";
import { CommissionAdjustmentProps } from "./index.types";

const CommAdjSearchForm: FC<CommissionAdjustmentProps> = ({ handleChange, handleClick, data }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations"]);
   const { counters } = useDropdown();
   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer} `}>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:counters")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={counters}
                  defaultValue={counters.find((el) => el.id === data.counterId)}
                  renderOption={(item) => item.officialName}
                  getOptionLabel={(item) => item.officialName}
                  onChange={(_, item) => handleChange(item?.id, "counterId")}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput
                  disabled={false}
                  id="startDate"
                  title=""
                  helperText=""
                  value={data.startDate}
                  type={"date"}
                  onChange={(event) => handleChange(event.target.value, "startDate")}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput
                  disabled={false}
                  id="endDate"
                  title=""
                  helperText=""
                  value={data.endDate}
                  type={"date"}
                  onChange={(event) => handleChange(event.target.value, "endDate")}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={12} xs={12}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={handleClick} icon={<Search />} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default CommAdjSearchForm;
