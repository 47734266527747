import { Box } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { ROUTE_LINKS } from "../../../../routes/routeLinks/index";
import { CommonButton, TableDropDown } from "../../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../../components/molecules";
import * as carFineApi from "../../../../data/api/requests/car-fine/index";
import { useTranslation } from "react-i18next";
import { useRequest } from "../../../../data/api/wrapper";
import DataTable from "../../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../../components/molecules/tables/table-column/TableColumn";
import moment from "moment";
import { putDecimalPoints } from "../../../../utills/masks";
const Fines = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "users", "vehicles", "organization"]);

   const getFine = useRequest((params) => carFineApi.getFines(params));

   const [total, setTotal] = useState(0);

   const { state } = useLocation<string>();

   const { id } = useParams<{ id: string }>();

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getFine.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (getFine.data) {
         let totalAmount = 0;
         getFine.data.map((el) => (totalAmount += el.amount));
         setTotal(totalAmount);
      }
   }, [getFine.data]);

   return (
      <div>
         <TitleContainer title={state ? t("translations:fines") + " " + state : t("translations:fines")} />
         <CommonButton
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_VEHICLE_FINE);
            }}
            icon={<AddCircle />}
         />
         <ContainerTopBar title={t("translations:listing")} activity={getFine.loading}>
            <DataTable data={getFine.data || []} search={false}>
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn
                  headerText={t("vehicles:registration")}
                  render={(item) => (
                     <>
                        <div>
                           <span className={globalClasses.greyBox}>{item.car.licensePlate}</span>
                        </div>
                        <div className={globalClasses.tableSmallText}>{item.car.name}</div>
                     </>
                  )}
               />
               <TableColumn headerText={t("translations:date")} render={(item) => moment(item.date).format("MM/DD/YYYY HH:mm")} />
               <TableColumn headerText={t("organization:local")} render={(item) => item.local} />
               <TableColumn headerText={t("translations:observation")} render={(item) => item.note} />
               <TableColumn headerText={t("translations:value")} render={(item) => `	€${putDecimalPoints(item.amount)}`} />
               <TableColumn
                  align="right"
                  render={(item) => <TableDropDown options={[t("translations:edit")]} onClick={() => history.push(`/fines/form/${item.id}`)} />}
               />
            </DataTable>

            <Box className={globalClasses.title}>
               {t("vehicles:totalExpenses")}: €{total}
            </Box>
         </ContainerTopBar>
      </div>
   );
};

export default Fines;
