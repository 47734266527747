import { makeStyles, Theme } from "@material-ui/core";

export const statusStyles = makeStyles((theme: Theme) => ({
  container: {
    color: theme.palette.background.default,
    paddingLeft: 6,
    paddingRight: 6,
    paddingTop: 4,
    paddingBottom: 4,
    fontSize: 11,
    borderRadius: "0.25rem",
    fontWeight: "bold",
  },
}));
