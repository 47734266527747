import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS, VALUES } from "../../../../data/constants/Colors";

export const textAreaStyle = makeStyles((theme: Theme) =>
   createStyles({
      textArea: {
         borderColor: COLORS.greyBorder,
         borderRadius: 4,
         fontSize: 17,
         fontWeight: "lighter",
         letterSpacing: 1,
         width: "100%",
         marginTop: VALUES.margin_small,
         padding: 20,
      },
   })
);
