import { Grid, Divider, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { formatDateForInput, getToday } from "../../../../utills/helper";
import { useValidation } from "../../../../utills/validation";
import { AutoComplete, CommonButton, CurrencyInput, InputLabel, TextArea, TextInput } from "../../../atoms";
import FileDetails from "../../../molecules/file-details/FileDetails";
import { MaintenanceForm } from "./index.types";

const NewMaintenenceForm: FC<MaintenanceForm> = (props) => {
   const { data, handleSubmit, clearForm } = props;

   const globalClasses = globalStyles();

   const { t } = useTranslation(["maintenance", "translations", "gen"]);

   const { cars, maintenanceTypes, counters } = useDropdown();

   const { maintenanceSchema } = useValidation();

   const formik = useFormik({
      initialValues: {
         description: data?.description ? data.description : "",
         startDate: data?.startDate ? formatDateForInput(data.startDate) : getToday(),
         intendedOutDate: data?.intendedOutDate ? formatDateForInput(data.intendedOutDate) : getToday(),
         carId: data?.car ? data.car.id : 0,
         typeId: data?.type ? data.type.id : 1,
         organizationId: data?.organization ? data.organization.id : 0,
         totalAmount: data?.totalAmount ? data.totalAmount.toString() : "",
         fileName: File,
         fileDescription: "",
      },
      validationSchema: maintenanceSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
         handleSubmit(values);
      },
   });

   const clearFormik = () => {
      formik.resetForm({});
   };

   useEffect(() => {
      clearForm.current = clearFormik;
   }, []);

   return (
      <div>
         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:car")} />
               <AutoComplete
                  options={cars}
                  label=""
                  placeholder=""
                  defaultValue={cars.find((el) => el.id == formik.values.carId)}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => formik.setFieldValue("carId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.carId}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:maintenanceType")} />
               <AutoComplete
                  options={maintenanceTypes}
                  label=""
                  placeholder=""
                  defaultValue={maintenanceTypes.find((el) => el.id === formik.values.typeId)}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => formik.setFieldValue("typeId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.typeId}</Typography>
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:entry")} />
               <TextInput
                  id="startDate"
                  type="date"
                  value={formik.values.startDate}
                  title=""
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:expectedExit")} />
               <TextInput
                  id="intendedOutDate"
                  type="date"
                  error={formik.touched.intendedOutDate && Boolean(formik.errors.intendedOutDate)}
                  helperText={formik.touched.intendedOutDate && formik.errors.intendedOutDate}
                  value={formik.values.intendedOutDate}
                  title=""
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:local")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={counters}
                  defaultValue={counters.find((el) => el.id == formik.values.organizationId)}
                  renderOption={(item) => item.officialName}
                  getOptionLabel={(item) => item.officialName}
                  onChange={(_, item) => formik.setFieldValue("organizationId", item?.id)}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.organizationId}</Typography>
            </Grid>
            <Grid item sm={6} xs={12} md={4}>
               <InputLabel label={t("maintenance:budget")} />
               <CurrencyInput
                  type={"text"}
                  id="totalAmount"
                  value={formik.values.totalAmount}
                  disabled={false}
                  title=""
                  error={formik.touched.totalAmount && Boolean(formik.errors.totalAmount)}
                  helperText={formik.touched.totalAmount && formik.errors.totalAmount}
                  onChange={(value) => formik.setFieldValue("totalAmount", value)}
                  clear={clearForm}
               />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item sm={12} xs={12} md={8}>
               <InputLabel label={t("maintenance:comments")} />
               <TextArea id="description" value={formik.values.description} onChange={formik.handleChange} />
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         {data && (
            <FileDetails
               files={data.attachments}
               onChange={(key, event) => {
                  formik.setFieldValue(key, event);
               }}
               values={{ image: formik.values.fileName.name, desc: formik.values.fileDescription }}
               error={formik.errors.fileDescription}
            />
         )}
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:create")} onClick={formik.handleSubmit} />
      </div>
   );
};

export default NewMaintenenceForm;
