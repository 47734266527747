import { Box } from "@material-ui/core";
import { AddCircle, Build } from "@material-ui/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CarItem, CommonButton, DateLabel, TableDropDown } from "../../../components/atoms";
import { ContainerTopBar } from "../../../components/molecules";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import * as maintenanceApi from "../../../data/api/requests/maintenance/index";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Maintenance from "../../../data/models/maintenance.model";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
function ListMaintenance() {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "users", "vehicles", "maintenance"]);

   const history = useHistory();

   const maintenance = useRequest(() => maintenanceApi.getMaintenances(pagination.page, pagination.size, searchString));

   const [pagination, setPagination] = useState({ page: 0, size: 10 });

   const [searchString, setSearchString] = useState("");

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         maintenance.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchString]);

   const handleDropdownClick = (action: string, item: Maintenance) => {
      switch (action) {
         case t("translations:edit"):
            history.push(`/maintenance/form/${item.id}`);
            break;
         case t("maintenance:remove"):
            history.push(`/maintenance/remove/${item.id}`);
            break;
         default:
            break;
      }
   };

   const getDropdownOptions = (item: Maintenance) => {
      if (item.endDate) {
         return [t("translations:edit")];
      }
      return [t("translations:edit"), t("maintenance:remove")];
   };

   return (
      <div>
         <Box className={globalClasses.title}>{t("translations:maintenance")}</Box>
         <CommonButton
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_MAINTENANCE);
            }}
            icon={<AddCircle />}
         />
         <ContainerTopBar title={t("translations:listing")} activity={maintenance.loading}>
            <DataTable
               data={maintenance.data?.data || []}
               total={maintenance.data?.total}
               pagination
               onSearching={(query) => setSearchString(query)}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("vehicles:registration")} render={(item) => <CarItem car={item.car} />} />
               <TableColumn headerText={t("maintenance:local")} render={(item) => item.organization.name} />
               <TableColumn headerText={t("maintenance:input")} render={(item) => <DateLabel date={item.startDate} />} />
               <TableColumn headerText={t("maintenance:forcast")} render={(item) => <DateLabel date={item.intendedOutDate} />} />
               <TableColumn
                  headerText={t("maintenance:delivered")}
                  render={(item) => (item.endDate ? <DateLabel date={item.endDate} /> : <Build className={globalClasses.errorText} />)}
               />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={getDropdownOptions(item)}
                        onClick={(action) => {
                           handleDropdownClick(action, item);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
}

export default ListMaintenance;
