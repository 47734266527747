import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { clearSessionStorage, getCounterFromLocalStorage, getLanguage, getToken } from "../../../utills/utills";
import useAPIError from "../../context/error";
import ApiResponse from "../../models/api-response.model";
import { API_ROUTES } from "../apiRoutes";

const axiosConfig = {
   baseURL: API_ROUTES.baseUrl,
   timeout: 30000,
};

export const $axios = axios.create(axiosConfig);

export const request = async (config: AxiosRequestConfig) => {
   const token = await getToken();
   const lang = await getLanguage();
   const counterId = await getCounterFromLocalStorage();

   config["headers"] = {
      ...config.headers,
      'Access-Control-Allow-Origin': API_ROUTES.baseUrl,
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
      Lang: `${lang}`,
      counterId: `${counterId}`,
      organizationId: `${counterId}`,
   };

   return await $axios({
      ...config,
   });
};

export const useRequest = <T>(request: (params?: any, file?: any, note?: any) => Promise<ApiResponse<T>>) => {
   const { addError } = useAPIError();

   const { t } = useTranslation("gen");

   const history = useHistory();

   const [loading, setLoading] = useState(false);
   const [data, setData] = useState<T>();
   const [error, setError] = useState("");
   const [show, setShow] = useState({ modal: false, toast: false });
   const [showError, setShowError] = useState(false);
   const [message, setMessage] = useState({ message: "", description: "", error: false });

   const execute = async (params?: any, file?: any, note?: any) => {
      setLoading(true);
      request(params, file, note)
         .then((response) => {
            setData(response.data);
            setMessage({ message: response.title, description: response.message, error: false });
            setShow({ modal: true, toast: true });
         })
         .catch(async (error: AxiosError) => {
            setLoading(false);
            if (error.response?.status == 401) {
               await clearSessionStorage();
               return history.push("");
            }
            addError({
               title: error.response?.data.title || "",
               message: error.response?.data.message,
            });
         })
         .finally(() => {
            setLoading(false);
         });
   };

   const close = () => {
      setShow({ modal: false, toast: false });
      setShowError(false);
   };

   return {
      execute,
      loading,
      data,
      error,
      show,
      showError,
      close,
      message,
      setData,
   };
};
