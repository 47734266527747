import { createContext, FC, useContext, useState } from "react";

export interface Error {
   title: string;
   message: string;
}

export interface ErrorContextProps {
   error: Error | null;
   removeError: () => void;
   addError: (error: Error) => void;
}

export const ErrorContext = createContext<ErrorContextProps>({} as ErrorContextProps);

export const ErrorProvider: FC = ({ children }) => {
   const [error, setError] = useState<Error | null>(null);

   const removeError = () => setError(null);

   const addError = (error: Error) => setError(error);

   const value = {
      error,
      removeError,
      addError,
   };

   return <ErrorContext.Provider value={value}>{children}</ErrorContext.Provider>;
};

export default function useAPIError() {
   const { error, addError, removeError } = useContext(ErrorContext);
   return { error, addError, removeError };
}
