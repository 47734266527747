import { Grid, Typography } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import Tolerance from "../../../../data/models/tolerance.modal";
import { capitalizeFirstLetter } from "../../../../utills/helper";
import { Margin } from "../../../atoms";
import { Label } from "../../../molecules";
import { ToleranceProps } from "./index.types";

const ToleranceForm: FC<ToleranceProps> = ({ data, onChange }) => {
   const { t } = useTranslation(["translations"]);
   const globalClasses = globalStyles();
   return (
      <Grid container spacing={3}>
         {data?.map((item, index) => (
            <>
               <Grid item xl={3} lg={3} md={4} sm={12} xs={12} key={item.weekDayId}>
                  <Typography className={globalClasses.labelText}>{capitalizeFirstLetter(item.weekDay)}</Typography>
                  <Label
                     width="full"
                     value={item.hours}
                     onChange={(e) => {
                        onChange(e, item.weekDayId, "hours");
                     }}
                  />
                  <Label
                     width="half"
                     value={item.minutes}
                     onChange={(e) => {
                        onChange(e, item.weekDayId, "minutes");
                     }}
                  />
               </Grid>
            </>
         ))}
      </Grid>
   );
};

export default ToleranceForm;
