import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const featureFormStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridContainer: {
      marginTop: 15,
    },
    root: {
      maxWidth: "100%",
    },

    media: {
      height: 0,
      paddingTop: "70.25%", // 16:9
    },
    checkboxContainer: { marginLeft: 20 },
  })
);
