import { NewMaintenance } from "../../../../components/organisms/forms/newMaintenanceForm/index.types";
import { normalizeCurrency } from "../../../../utills/masks";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import MaintenanceInfo from "../../../models/maintenance-info.model";
import Maintenance from "../../../models/maintenance.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export async function getMaintenances(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Maintenance[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: `${API_ROUTES.baseUrl}${API_ROUTES.maintenance}?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Maintenance[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getMaintenanceById(id: number | string): Promise<ApiResponse<Maintenance>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.maintenance + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Maintenance> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getMaintenanceInfoById(id: string): Promise<ApiResponse<MaintenanceInfo>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getMaintenanceInfo.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<MaintenanceInfo> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function removeFromMaintenance(id: string, payload: any): Promise<ApiResponse<void>> {
   const dataToUpload = { ...payload, amount: normalizeCurrency(payload.amount) };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.getMaintenanceInfo.format(id),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function createNewMaintenance(newMaintenance: NewMaintenance): Promise<ApiResponse<void>> {
   let maintenanceToUpload: any = {
      ...newMaintenance,
      startDate: encodeDate(newMaintenance.startDate, ""),
      intendedOutDate: encodeDate(newMaintenance.intendedOutDate, ""),
      totalAmount: newMaintenance.totalAmount.replace(",", "."),
   };
   delete maintenanceToUpload.fileName;
   delete maintenanceToUpload.fileDescription;
   console.log(maintenanceToUpload);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.maintenance,
         data: maintenanceToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateMaintenanceById(maintenance: NewMaintenance, maintenanceId: string): Promise<ApiResponse<void>> {
   let downlink = "";
   if (maintenance.fileName.size) {
      const file = new FormData();
      file.append("file", maintenance.fileName);
      const uploadRes = await uploadFile(file);
      downlink = uploadRes.data.file;
   }

   const maintenanceToUpload = {
      ...maintenance,
      startDate: encodeDate(maintenance.startDate, ""),
      intendedOutDate: encodeDate(maintenance.intendedOutDate, ""),
      fileName: downlink,
      totalAmount: maintenance.totalAmount.replace(",", "."),
      maintenanceId: maintenanceId,
   };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.maintenanceById.format(maintenanceId),
         data: maintenanceToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
