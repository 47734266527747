import { MutableRefObject, useEffect, useRef } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as questionApi from "../../../data/api/requests/questions/index";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useParams } from "react-router-dom";
import { TitleContainer } from "../../../components/molecules";
import QuestionForm from "../../../components/organisms/forms/question-form/QuestionForm";
import { useRequest } from "../../../data/api/wrapper";
import { AlertDailog, Loader, Toast } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

function NewQuestion() {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations"]);

   const history = useHistory();

   const { state } = useLocation<number>();

   const { id } = useParams<{ id: string }>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const getQuestion = useRequest(() => questionApi.getQuestionById(id));
   const newQuestion = useRequest((params) => questionApi.addNewQuestion(params));
   const updateQuestion = useRequest((params) => questionApi.updateQuestion(params, id));

   useEffect(() => {
      let unmounted = false;
      if (!unmounted && id) {
         getQuestion.execute();
      }
      return () => {
         unmounted = true;
      };
   }, []);

   const _submit = (value: any) => {
      if (id) {
         updateQuestion.execute(value);
      } else {
         newQuestion.execute(value);
      }
   };

   return (
      <div>
         <Loader loading={getQuestion.loading || newQuestion.loading || updateQuestion.loading} />

         {newQuestion.show.modal && (
            <AlertDailog
               show={newQuestion.show.modal}
               title={newQuestion.message.message}
               message={newQuestion.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => {
                  newQuestion.close();
                  history.push(ROUTE_LINKS.FAQS);
               }}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  newQuestion.close();
                  clearForm.current();
               }}
            />
         )}

         {updateQuestion.show.toast && (
            <Toast
               show={updateQuestion.show.toast}
               message={updateQuestion.message}
               handleClose={() => {
                  updateQuestion.close();
               }}
            />
         )}

         <TitleContainer
            title={id ? t("translations:edit") + " " + t("translations:question") : t("translations:new") + " " + t("translations:question")}
            status={getQuestion.data?.status}
         />
         <QuestionForm onSubmit={_submit} data={getQuestion.data} clearForm={clearForm} />
      </div>
   );
}

export default NewQuestion;
