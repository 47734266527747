import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";

interface InvoiceItemTypeProps {
   handleClose: () => void;
   handleSubmit: (type: string) => void;
}
const InvoiceItemType: FC<InvoiceItemTypeProps> = (props) => {
   const { handleClose, handleSubmit } = props;

   const { t } = useTranslation(["translations", "gen"]);

   const [value, setValue] = useState("Fatura recibo");

   const globalClasses = globalStyles();

   const handleChange = () => {};

   return (
      <>
         <Typography className={`${globalClasses.textAlign} ${globalClasses.title}`}>{t("translations:invoiceType")}</Typography>
         <Box className={globalClasses.paddingContainer}>
            <FormControl fullWidth variant='outlined' size='small'>
               <Select labelId='demo-simple-select-label' id='demo-simple-select' value={"Fatura recibo"} onChange={handleChange}>
                  <MenuItem value={"Fatura recibo"}>{t("translations:receiptInvoice")}</MenuItem>
                  <MenuItem value={"Fatura comum"}>{t("translations:commonInvoice")}</MenuItem>
               </Select>
            </FormControl>
         </Box>
         <div className={`${globalClasses.flexRow} ${globalClasses.alignCenter}`}>
            <CommonButton title={"OK"} onClick={() => {}} color={COLORS.blue} />
            <span className={globalClasses.marginLeft} />
            <CommonButton title={t("gen:cancel")} onClick={handleClose} color={COLORS.greyText} />
         </div>
      </>
   );
};

export default InvoiceItemType;
