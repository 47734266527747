import {
  Box,
  Divider,
  TableHead,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import React, { useState } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

function PromoteToAdm() {
  const globalClasses = globalStyles();
  const [menuActive, setMenuActive] = useState<boolean>(true);
  const showContent = () => {
    setMenuActive(!menuActive);
  };
  return (
    <div>
      <Box className={globalClasses.title}>Dashboard</Box>
      <Box className={globalClasses.container}>
        <Box className={globalClasses.cardTitleCOntainer}>
          <Typography className={globalClasses.cardTitle}>
            Utilizador selecionado
          </Typography>
          {!menuActive ? (
            <KeyboardArrowDown
              color="disabled"
              className={globalClasses.icon}
              onClick={showContent}
            />
          ) : (
            <KeyboardArrowUp
              color="disabled"
              className={globalClasses.icon}
              onClick={showContent}
            />
          )}
        </Box>
        <Divider className={globalClasses.dividerMargin} />
        {menuActive && (
          <>
            <Box style={{ padding: 20 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Telefone</TableCell>
                    <TableCell>Data de Registro</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>17859</TableCell>
                    <TableCell>Azeez Lawwl</TableCell>
                    <TableCell>a66ee66@gmail.com</TableCell>
                    <TableCell>911939188</TableCell>
                    <TableCell>2021-08-19 16:16:56</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
            <div style={{ textAlign: "center" }}>
              <Typography variant="subtitle2" color="textSecondary">
                Deseja transformar o utilizador Azeez Lawwl em administrador?
              </Typography>
              <div style={{ marginTop: 10 }}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  disableElevation
                >
                  Sim
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  disableElevation
                  style={{ marginLeft: 10 }}
                >
                  não
                </Button>
              </div>
            </div>
          </>
        )}
      </Box>
    </div>
  );
}

export default PromoteToAdm;
