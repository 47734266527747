import { Grid, Typography, useTheme } from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import { FC } from "react";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { AutoComplete, InputLabel, TextInput } from "../../atoms";
import { DiscountItemProps } from "./index.types";
import { IconButton } from "../../atoms";
import { useTranslation } from "react-i18next";
import { putDecimalPoints } from "../../../utills/masks";

const DiscountItem: FC<DiscountItemProps> = (props) => {
   const { item, onDeleteClick } = props;
   const { t } = useTranslation(["gen", "translations"]);
   const globalClasses = globalStyles();
   const theme = useTheme();
   return (
      <>
         <Grid container spacing={3}>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("gen:from")} />
               <TextInput id="" helperText="" title={""} value={item.minAmount} disabled={true} type="number" />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("translations:discount")} />
               <TextInput id="" helperText="" title={""} value={putDecimalPoints(item.discount)} disabled={true} type="text" />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  options={["-Sem escolha-", "Percentual", "Absoluto"]}
                  disabled={true}
                  label={item.isPercentage ? "Percentual" : "Absoluto"}
                  placeholder=""
               />
            </Grid>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:categor")} />
               <AutoComplete options={["-Sem escolha-", "Percentual", "Absoluto"]} disabled={true} label={item.category.name} placeholder="" />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={6} xs={12}>
               <br />
               <IconButton
                  color={COLORS.redLabel}
                  onClick={() => {
                     onDeleteClick(item.itemDiscountId);
                  }}
                  icon={<Remove style={{ color: theme.palette.background.paper, fontSize: 18 }} />}
               />
            </Grid>
         </Grid>
      </>
   );
};

export default DiscountItem;
