import ApiResponse from "../../../models/api-response.model";
import Organization from "../../../models/organization.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getCounters(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Organization[]>>> {
   console.log(page, size);
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.counters + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Organization[]>> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getCounterById(id: string): Promise<ApiResponse<Organization>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.counters + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Organization> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateCounterById(counter: any, id: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.counters + `/${id}`,
         data: counter,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function createCounter(counter: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.counters,
         data: counter,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeCounterStatus(counterId: number): Promise<ApiResponse<Organization>> {
   try {
      const resp = await request({
         method: "POST",
         url: API_ROUTES.changeCounterStatus.format(counterId),
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Organization> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
