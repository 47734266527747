import { createContext, FC, useContext, useEffect, useMemo, useState } from "react";
import { getCounterFromLocalStorage, saveCounter } from "../../../utills/utills";
import useAuth from "../authContext/AuthContext";
import { Setting_Context_Props } from "./index.types";

export const SettingContext = createContext<Setting_Context_Props>({} as Setting_Context_Props);

export const SettingProvider: FC = ({ children }) => {
   const { user } = useAuth();

   const [counter, setCounter] = useState<number>(user?.counters[0].organizationId!!);

   useEffect(() => {
      getCounter();
   }, []);

   const changeCounter = async (id: number) => {
      setCounter(id);
      await saveCounter(id.toString());
      window.location.reload();
   };

   const getCounter = async () => {
      try {
         const tempCounter = await getCounterFromLocalStorage();
         if (tempCounter) {
            setCounter(parseInt(tempCounter));
         } else {
            const userCounter = user?.counters[0].organizationId;
            if (userCounter) {
               await changeCounter(userCounter);
            }
         }
      } catch (error) {
         throw error;
      }
   };

   const value = useMemo(() => ({ counter, changeCounter, getCounter }), [counter]);

   return <SettingContext.Provider value={value}>{children}</SettingContext.Provider>;
};

export default function useSetting() {
   return useContext(SettingContext);
}
