import { Box } from "@material-ui/core";
import moment from "moment";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Financial from "../../../data/models/financials.model";
import { putDecimalPoints } from "../../../utills/masks";
import { TableButton } from "../../atoms";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { financialStyles } from "./index.styles";
interface FinancialProps {
   data: Financial[];
   onReservationClick: (id: number) => void;
}

const FinancialContainer: FC<FinancialProps> = ({ data, onReservationClick }) => {
   const classes = financialStyles();
   const { t } = useTranslation(["translations", "transaction", "dashboardStrings"]);
   return (
      <div>
         {data.map((item, index) => (
            <div key={`financial-${index}`}>
               <Box className={classes.greyContainer}>{`${item.customer.name} - ${t("translations:total")}: ${putDecimalPoints(item.total)}`}</Box>
               <DataTable data={item.transactions || []} search={false}>
                  <TableColumn headerText="ID" render={(item) => item.id} />
                  <TableColumn headerText={t("translations:date")} render={(item) => moment(item.dueDate).format("MM/DD/YYYY")} />
                  <TableColumn headerText={t("translations:dueDate")} render={(item) => moment(item.dueDate).format("MM/DD/YYYY")} />
                  <TableColumn headerText={t("translations:value")} render={(item) => item.value} />
                  <TableColumn headerText={t("transaction:formOfPayment")} render={(item) => item.paymentMethod} />
                  <TableColumn
                     headerText={t("dashboardStrings:reservation")}
                     render={(item) => <TableButton title={`#${item.reservationId}`} onClick={() => onReservationClick(item.reservationId)} />}
                  />
               </DataTable>
            </div>
         ))}
      </div>
   );
};

export default FinancialContainer;
