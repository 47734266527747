import React, { FC, useState } from "react";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Theme, withStyles, makeStyles, Checkbox } from "@material-ui/core";
import { COLORS, VALUES } from "../../../../data/constants/Colors";
import MomentUtils from "@date-io/moment";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import { formatDateForInput } from "../../../../utills/helper";

interface DateInputProps {
   disabled?: boolean;
   helperText: string | boolean | undefined;
   error?: boolean;
   id: string;
   type?: string;
   value: string | number;
   onChange: (value?: string | null | undefined) => void;
}

const styles = makeStyles((theme: Theme) => ({
   container: {
      height: 30,
      fontSize: 13,
      marginTop: VALUES.margin_small,
   },
}));
const CustomDateField = withStyles((theme: Theme) => ({
   root: {
      "& .MuiOutlinedInput-root": {
         "& fieldset": {
            borderRadius: 4,
            borderColor: COLORS.greyBorder,
            fontSize: 13,
            height: 36,
            padding: 0,
         },
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: COLORS.primary,
      },
   },
}))(KeyboardDatePicker);

const DateInput: FC<DateInputProps> = (props) => {
   const { disabled, helperText, error, id, value, onChange } = props;
   const classes = styles();
   return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
         <CustomDateField
            disabled={disabled}
            helperText={helperText}
            error={error}
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="DD/MM/YYYY"
            margin="dense"
            id="date-picker-inline"
            label=""
            fullWidth
            value={value}
            onChange={(_, value) => {
               value = formatDateForInput(value);
               onChange(value);
            }}
            className={classes.container}
            style={{ borderColor: COLORS.greyBorder }}
            inputProps={{ style: { height: 10, fontSize: 14, color: COLORS.greyText } }}
            KeyboardButtonProps={{
               "aria-label": "change date",
            }}
         />
      </MuiPickersUtilsProvider>
   );
};

export default DateInput;
