import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as questionApi from "../../../data/api/requests/questions/index";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { AddCircle } from "@material-ui/icons";

const FAQ = () => {
   const history = useHistory();
   const { t } = useTranslation(["translations"]);

   const [search, setSearch] = useState("");
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [selectedIndex, setSelectedIndex] = useState<number>();

   const getQuestions = useRequest(() => questionApi.getQuestions(search, pagination.page, pagination.size));
   const changeQuestionStatus = useRequest((params) => questionApi.changeQuestionStatus(params));

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         getQuestions.execute();
      }
      return () => {
         unmounted = true;
      };
   }, [search, pagination]);

   useEffect(() => {
      if (changeQuestionStatus.data && selectedIndex) {
         getQuestions.setData((prev) => {
            const index = prev?.data.findIndex((el) => el.id == selectedIndex);
            if (prev) {
               prev.data[index!!] = changeQuestionStatus.data!!;
            }
            return prev;
         });
      }
   }, [changeQuestionStatus.data]);

   const handleClick = (name: string, item: ReturnType<any>, index: number) => {
      switch (name) {
         case t("translations:edit"):
            history.push(`/faq/edit/${item.id}`);
            break;
         case t("translations:changeStatus"):
            setSelectedIndex(item.id);
            changeQuestionStatus.execute(item.id);
            break;
         default:
            break;
      }
   };

   return (
      <div>
         {changeQuestionStatus.show.toast && (
            <Toast
               show={changeQuestionStatus.show.toast}
               message={changeQuestionStatus.message}
               handleClose={() => {
                  changeQuestionStatus.close();
               }}
            />
         )}

         <TitleContainer title={t("translations:questions")} />
         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_FAQ);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={getQuestions.loading}>
            <DataTable
               data={getQuestions.data?.data || []}
               total={getQuestions.data?.total}
               pagination
               onSearching={(query) => setSearch(query)}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("translations:question")} render={(item) => item.question} />
               <TableColumn headerText={t("translations:answer")} render={(item) => item.answer} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  render={(item, index) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(name) => handleClick(name, item, index)}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default FAQ;
