import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ContainerTopBar } from "..";
import CarRecReserva from "../../../data/models/car-recent-reservation.model";
import { RecentReservationItem, StatusLabel } from "../../atoms";
import DataTable from "../tables/datatable/DataTable";
import TableColumn from "../tables/table-column/TableColumn";

interface RecentBookingProps {
  reservations: CarRecReserva[] | undefined;
  onClick: (id: number) => void;
  onSeeAllClick: () => void;
}

const Bookings: FC<RecentBookingProps> = ({
  reservations,
  onClick,
  onSeeAllClick,
}) => {
  const { t } = useTranslation(["vehicles", "vehicleDetail"]);

  console.log(reservations);

  return (
    <ContainerTopBar
      title={t("vehicleDetail:latestBooking")}
      seeAll
      onSeeAllClick={onSeeAllClick}
    >
      <DataTable data={reservations || []} search={false}>
        <TableColumn
          align={"left"}
          render={(item) => (
            <RecentReservationItem reservation={item} onClick={onClick} />
          )}
        />
        <TableColumn align={"right"} render={(item) => item.statusName} />
        {/* //Change to Status Object */}
      </DataTable>
    </ContainerTopBar>
  );
};

export default Bookings;
