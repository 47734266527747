import { InputLabel, TextField, Theme } from "@material-ui/core";
import React, { FC, MutableRefObject, useEffect, useState } from "react";
import { withStyles } from "@material-ui/styles";
import { COLORS, VALUES } from "../../../../data/constants/Colors";

export interface CurrencyProps {
   disabled?: boolean;
   title: string;
   helperText: string | boolean | undefined;
   error?: boolean;
   id: string;
   type: string;
   value: string;
   onChange: (value: string) => void;
   clear?: MutableRefObject<() => void>;
}

const CustomTextField = withStyles((theme: Theme) => ({
   root: {
      "& .MuiOutlinedInput-root": {
         "& fieldset": {
            borderColor: COLORS.greyBorder,
            transition: "border-color 0.3s",
         },
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: COLORS.primary,
      },
   },
}))(TextField);

const CurrencyInput: FC<CurrencyProps> = (props) => {
   let { disabled, title, type, value, error, onChange, helperText, id, clear } = props;

   const [state, setState] = useState("");

   useEffect(() => {
      if (clear) {
         clear.current = formatInitialValue;
      }
   }, []);

   useEffect(() => {
      if (value !== state) {
         formatInitialValue();
      }
   }, [value]);

   const formatInitialValue = () => {
      const initailValue = value || "0";
      setState(new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(parseFloat(initailValue)));
   };

   const addSuffix = () => {
      setState((prev) => {
         if (!prev) {
            prev = "0";
         }
         return new Intl.NumberFormat("de-DE", { style: "currency", currency: "EUR" }).format(parseFloat(prev.replace(",", ".")));
      });
   };

   const removeSuffix = () => {
      let tempValue = state.replace("€", "").trim();
      tempValue = tempValue.replaceAll(".", "");
      let valueAfterDecimal = tempValue.split(",");
      if (valueAfterDecimal[1] === "00") {
         tempValue = valueAfterDecimal[0];
         if (valueAfterDecimal[0] === "0") {
            tempValue = "";
         }
      }
      setState(tempValue);
   };

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let tempValue = event.target.value;
      tempValue = tempValue.replace(".", ",");
      tempValue = tempValue.replace(/[^0-9,]/g, "");
      let moreDecimals = tempValue.split(",")[1];
      if (moreDecimals?.length > 2) {
         return;
      }
      setState(tempValue);
      onChange(tempValue);
   };

   return (
      <CustomTextField
         variant="outlined"
         id={id}
         onFocus={removeSuffix}
         onBlur={addSuffix}
         placeholder={title}
         autoComplete="new-password"
         size="small"
         type={type}
         error={error}
         helperText={helperText}
         fullWidth
         value={state}
         disabled={disabled}
         onChange={handleChange}
         style={{ marginTop: VALUES.margin_small }}
      ></CustomTextField>
   );
};

export default CurrencyInput;
