import { Box, Divider, Typography, Grid, TextField } from "@material-ui/core";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import { ContainerTopBar, TitleContainer, UserCardItem } from "../../components/molecules";
import { CommonButton, DropdownItem, AutoComplete, Loader, Toast, InputLabel, Margin } from "../../components/atoms";
import { useTranslation } from "react-i18next";
import ToleranceForm from "../../components/organisms/forms/tolerance/ToleranceForm";
import { useEffect, useState } from "react";
import * as toleranceApi from "../../data/api/requests/tolerance/index";
import Tolerance from "../../data/models/tolerance.modal";
import { getLanguage, saveLanguage } from "../../utills/utills";
import { useRequest } from "../../data/api/wrapper";
import { createTheme } from "@material-ui/core";
export const language = [
   {
      id: 1,
      isoCode: "620",
      name: "Portuguese",
      phoneCode: "+351",
      shortName: "pt",
      language: "pt",
   },
   { id: 2, isoCode: "620", name: "English", phoneCode: "44", shortName: "gb", language: "en" },
];
const Definitions = () => {
   const globalClasses = globalStyles();
   const { t, i18n } = useTranslation(["translations"]);
   const tolerances = useRequest(() => toleranceApi.getTolerance());
   const updateTolerance = useRequest((params) => toleranceApi.updateTolerance(params));
   const [tolerance, setTolerance] = useState<Tolerance[]>();
   const [lang, setLang] = useState<string | null>();

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();

         const tempLang = getLanguage();
         if (!tempLang) {
            setLang("pt");
         } else {
            setLang(tempLang);
         }
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const getData = async () => {
      tolerances.execute();
   };

   const _updateData = (event: React.ChangeEvent<HTMLInputElement>, index: number, time: string) => {
      if (tolerances.data) {
         let temp = [...tolerances.data];
         if (time === "minutes") {
            temp[index - 1].minutes = parseInt(event.target.value);
         } else {
            temp[index - 1].hours = parseInt(event.target.value);
         }
         setTolerance(temp);
      }
   };

   const _updateTolerance = () => {
      if (tolerance) {
         updateTolerance.execute({ toleranceModels: tolerance });
      }
   };

   return (
      <div>
         <Loader loading={tolerances.loading || updateTolerance.loading} />

         <TitleContainer title={t("translations:definitions")} />

         {updateTolerance.show.toast && (
            <Toast
               show={updateTolerance.show.toast}
               message={updateTolerance.message}
               handleClose={() => {
                  updateTolerance.close();
               }}
            />
         )}

         <ContainerTopBar title={t("translations:vehDeliveryTol")}>
            <ToleranceForm data={tolerances.data || []} onChange={_updateData} />
            {<CommonButton title={t("translations:record")} onClick={_updateTolerance} />}
         </ContainerTopBar>

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <Typography className={globalClasses.cardTitle}>{t("translations:language")}</Typography>
            <Divider className={globalClasses.dividerMargin} />
            <Grid container spacing={3}>
               <Grid item xl={4} lg={4} md={5} sm={12} xs={12}>
                  <InputLabel label={t("translations:language")} />
                  <AutoComplete
                     placeholder=""
                     label=""
                     defaultValue={language.find((el) => el.language == lang)}
                     options={language}
                     renderOption={(item) => <DropdownItem country={item} />}
                     getOptionLabel={(item) => item.name}
                     onChange={(_, item) => {
                        if (item?.shortName === "gb") {
                           i18n.changeLanguage("en");
                           saveLanguage("en");
                        } else {
                           i18n.changeLanguage("pt");
                           saveLanguage("pt");
                        }
                     }}
                  />
                  <Margin />
               </Grid>
            </Grid>
            <Margin />
         </Box>
      </div>
   );
};

export default Definitions;
