import { Box, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as reportApi from "../../../data/api/requests/reports/index";
import { TollReport } from "../../../data/models/toll-reports.model";
import { TitleContainer } from "../../../components/molecules";
import { TollBookingSearchForm } from "../../../components/organisms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { formatDate } from "../../../utills/helper";
import { TableButton, TableItem } from "../../../components/atoms";
import { padId } from "../../../utills/masks";

const TollBooking = () => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "gen", "dashboardStrings"]);
   const [searchData, setSearchData] = useState({
      types: [] as number[],
      startDate: "",
      endDate: "",
   });
   const [tollReports, setTollReports] = useState([] as TollReport[]);
   const [loading, setLoading] = useState(false);

   const history = useHistory();
   const search = () => {
      if (!searchData.startDate || !searchData.endDate || searchData.types.length == 0) {
         return;
      }
      const startDate = new Date(searchData.startDate);
      const endDate = new Date(searchData.endDate);
      setLoading(true);
      reportApi
         .getTollReports(startDate.toISOString(), endDate.toISOString(), searchData.types)
         .then((res) => {
            let temp = [] as TollReport[];
            res.data.forEach((el) => {
               if (el.tollsCount > 0) {
                  temp.push(el);
               }
            });
            setTollReports(temp);
         })
         .catch((err) => console.log(err))
         .finally(() => setLoading(false));
   };
   return (
      <div>
         <TitleContainer title={t("translations:tollReservation")} />
         <TollBookingSearchForm
            onChange={(key, value) => {
               setSearchData({ ...searchData, [key]: value });
            }}
            onClick={search}
            value={searchData}
            loading={loading}
         />
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <DataTable data={tollReports || []}>
               <TableColumn
                  headerText={t("gen:survey")}
                  render={(item) => <TableItem title={item.pickupName} subtitle={formatDate(item.startDate)} />}
               />
               <TableColumn
                  headerText={t("gen:delivery")}
                  render={(item) => <TableItem title={item.returnName} subtitle={formatDate(item.endDate)} />}
               />
               <TableColumn headerText={t("gen:tollQuantity")} render={(item) => item.tollsCount} />
               <TableColumn
                  headerText={t("dashboardStrings:reservation")}
                  render={(item) => (
                     <TableButton title={padId(item.reservationId, 4)} onClick={() => history.push(`/reservation/form/${item.reservationId}`)} />
                  )}
               />
            </DataTable>
            {tollReports.length == 0 && <Typography className={`${globalClasses.title} ${globalClasses.textAlign}`}>{t("gen:noRecords")}</Typography>}
         </Box>
      </div>
   );
};

export default TollBooking;
