import { FC } from "react";
import { Form } from "react-bootstrap";
import { ImagePickerProps } from "./index.types";

const ImagePicker: FC<ImagePickerProps> = ({ onChange, imageRef }) => {
   return (
      <Form.Group controlId="formFile" className="mb-3">
         <Form.Control type="file" accept="image/*" onChange={onChange} ref={imageRef} />
      </Form.Group>
   );
};

export default ImagePicker;
