import { NewUserForm } from "../../../components/organisms";
import { newUserStyles } from "./index.styles";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../../components/molecules";
import { useHistory, useLocation, useParams } from "react-router";
import * as userApi from "../../../data/api/requests/users/index";
import { useRequest } from "../../../data/api/wrapper";
import { AlertDailog, AnnouncementUser, Loader, Margin, Toast } from "../../../components/atoms";
import { UserButtons } from "../../../components/molecules";
import { MutableRefObject, useEffect, useRef } from "react";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { user_buttons_actions } from "../../../data/constants/button-action";

function NewUser() {
   const history = useHistory();

   const { id } = useParams<{ id: string }>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const { t } = useTranslation(["translations", "users"]);

   const addUser = useRequest((params) => userApi.addUser(params));
   const getUser = useRequest((params) => userApi.getUserById(params));
   const updateUser = useRequest((params) => userApi.updateUser(params, id));
   const userPendingFinancials = useRequest((params) => userApi.getUserPendingFinancials(params));
   const resetPassword = useRequest((params) => userApi.resetUserPassword(params));

   useEffect(() => {
      if (id) {
         getUser.execute(id);
         userPendingFinancials.execute(id);
      }
   }, []);

   const submit = (values: any, image: File | undefined) => {
      const payload = { user: values, file: image };
      if (id) {
         updateUser.execute(payload);
      } else {
         addUser.execute(payload);
      }
   };

   const getUserByTaxId = async (taxId: string) => {
      let name = "";
      let user = await userApi.getUserByFiscalNumber(taxId);
      if (!user) {
         return "";
      }
      if (getUser.data?.id == user.data.id) {
         return "";
      }
      return user.data.name;
   };

   const handleClick = (action: string) => {
      switch (action) {
         case user_buttons_actions.reset_password:
            resetPassword.execute(getUser.data?.id);
            break;
         case user_buttons_actions.credit_cards:
            history.push(`/payment-cards/${getUser.data?.id}`, { typeId: 1, name: getUser.data?.name });
            break;
         case user_buttons_actions.crm:
            history.push(ROUTE_LINKS.crm, {
               userId: getUser.data?.id,
            });
            break;
         default:
            break;
      }
   };

   const navigateToReservation = (id: number) => {
      history.push(`/reservation/form/${id}`);
   };

   return (
      <div>
         <Loader loading={addUser.loading || updateUser.loading || resetPassword.loading} />

         {updateUser.show.toast && (
            <Toast
               show={updateUser.show.toast}
               message={updateUser.message}
               handleClose={() => {
                  updateUser.close();
               }}
            />
         )}
         {resetPassword.show.toast && (
            <Toast
               show={resetPassword.show.toast}
               message={resetPassword.message}
               handleClose={() => {
                  resetPassword.close();
               }}
            />
         )}

         {addUser.show.modal && (
            <AlertDailog
               show={addUser.show.modal}
               title={addUser.message.message}
               message={addUser.message.description}
               primaryButtonText={t("translations:backButton")}
               primaryButtonHandle={() => history.push(ROUTE_LINKS.LIST_USERS)}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  clearForm.current();
                  addUser.close();
               }}
            />
         )}

         <TitleContainer title={id ? `${t("translations:edit")} ${t("translations:user")}` : `${t("translations:new")} ${t("translations:user")}`} />
         {!id ? <AnnouncementUser /> : <UserButtons onClick={handleClick} />}

         <Margin />

         <NewUserForm
            handleSubmit={submit}
            data={getUser.data}
            clearForm={clearForm}
            getUserByTaxId={getUserByTaxId}
            pendingTransactions={userPendingFinancials.data}
            onReservationClick={navigateToReservation}
         />
      </div>
   );
}

export default NewUser;
