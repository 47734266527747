import { Box, TextField, Typography } from "@material-ui/core";
import { FC } from "react";
import { withStyles } from "@material-ui/styles";
import { defStyles } from "./index.styles";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { DefinitionInputProps } from "./index.types";
import { useTranslation } from "react-i18next";

const CustomTextField = withStyles({
   root: {
      "& .MuiOutlinedInput-root": {
         "& fieldset": {
            borderRadius: `0 3px 3px 0`,
            marginTop: 5,
            borderColor: COLORS.greyBorder,
            transition: "border-color 0.3s",
            height: 45,
         },
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: COLORS.primary,
      },
   },
})(TextField);

const Label: FC<DefinitionInputProps> = ({ width, value, onChange }) => {
   const { t } = useTranslation(["translations"]);
   const classes = defStyles();
   const globalClasses = globalStyles();

   return (
      <div className={classes.flexRow}>
         <Box border={1} borderColor={COLORS.greyBorder} className={classes.largeGreyBox} display="flex" alignItems="center">
            <Typography className={classes.boxText}>{width === "full" ? `${t("translations:hours")}:` : `${t("translations:minutes")}:`}</Typography>
         </Box>
         <CustomTextField
            id="outlined-number"
            size="small"
            label=""
            onChange={onChange}
            type="number"
            value={value}
            InputLabelProps={{
               shrink: false,
            }}
            variant="outlined"
            fullWidth
            classes={{ root: globalClasses.noRadius }}
         />
      </div>
   );
};

export default Label;
