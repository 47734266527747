import { useHistory, useParams } from "react-router";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import * as serviceApi from "../../../data/api/requests/reservation-services/index";
import * as invoiceApi from "../../../data/api/requests/invoice/index";
import * as dropdownApi from "../../../data/api/requests/dropdowns/index";
import * as discountApi from "../../../data/api/requests/disscounts/index";
import { useTranslation } from "react-i18next";
import { BillingItem, ContainerTopBar, PopupModal, TitleContainer, RequestDiscountItem } from "../../../components/molecules";
import { useEffect, useState } from "react";
import { useRequest } from "../../../data/api/wrapper";
import { ServiceHeader } from "../../../components/molecules/index";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { InvoiceItems } from "../../../components/organisms";
import Transaction from "../../../data/models/transaction.model";
import { Loader, Toast } from "../../../components/atoms";
import useAuth from "../../../data/context/authContext/AuthContext";
import DiscountBillable from "../../../data/models/billable-discount.modal";

const ReservationTransactions = () => {
   const { t } = useTranslation("translations");

   const history = useHistory();

   const { id } = useParams<{ id: string }>();

   const { user } = useAuth();

   const [reservationDiscounts, setReservationDiscount] = useState([] as DiscountBillable[]);
   const [showRequestDailog, setShowRequestDailog] = useState(false);
   const [selectedTransaction, setSelectedTransaction] = useState({} as Transaction);
   const [modalType, setModalType] = useState(1);

   const reservation = useRequest((params) => reservationApi.getReservationById(params));
   const transactions = useRequest((params) => reservationApi.getReservationTransactions(params));
   const genInvoice = useRequest((params) => invoiceApi.generateInvoice(params));
   const reservationItems = useRequest((params) => serviceApi.unbilledReservationItems(params));
   const reservationCostumers = useRequest((params) => reservationApi.getReservationCustomers(params));
   const accountTypes = useRequest((params) => dropdownApi.accountTypes());
   const requestDiscount = useRequest((params) => discountApi.requestDiscount(params));
   const createReservationTransaction = useRequest((params) => reservationApi.addReservationTransaction(id, params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   useEffect(() => {
      if (requestDiscount.data) {
         getReservationDiscounts();
      }
   }, [requestDiscount.data]);

   useEffect(() => {
      if (createReservationTransaction.data) {
         transactions.execute(id);
         reservationItems.execute(id);
      }
   }, [createReservationTransaction.data]);

   const getData = () => {
      reservation.execute(id);
      transactions.execute(id);
      reservationItems.execute(id);
      reservationCostumers.execute(id);
      accountTypes.execute();
   };

   const getReservationDiscounts = async () => {
      const tempDiscounts = await discountApi.getDiscountsByReservation(id, 1);
      setReservationDiscount(tempDiscounts.data);
   };

   const removeItem = (itemId: number) => {
      reservationItems.setData((prev) => prev?.filter((el) => el.id !== itemId));
   };

   const refresh = () => {
      reservationItems.execute(id);
   };

   const generateInvoice = (item: Transaction) => {
      if (item.status.id === 1) {
         const payload = { transactionId: item.id, type: item.type.name };
         genInvoice.execute(payload);
      } else {
         setModalType(2);
         setSelectedTransaction(item);
         setShowRequestDailog(true);
      }
   };

   const showInvoice = (item: Transaction) => {
      switch (item.invoice.type.id) {
         case 5:
            history.push(`/receipts/${item.invoice.id}`);
            break;

         default:
            history.push(`/invoices/${item.invoice.id}`, id);
            break;
      }
   };

   const reqDiscount = (values: any) => {
      requestDiscount.execute({ ...values, typeId: 1, reservationId: id });
      setShowRequestDailog(false);
   };

   const createNewTransaction = (values: any) => {
      let items: number[] = [];
      reservationItems.data?.map((el) => items.push(el.id));
      values = { ...values, reservationItems: items, sessionUserId: user?.userId };
      createReservationTransaction.execute(values);
   };

   const getModalContent = () => {
      if (modalType === 1) {
         return (
            <RequestDiscountItem
               handleClose={() => {
                  setShowRequestDailog(false);
               }}
               isTransaction={true}
               data={reservationItems.data}
               handleSubmit={reqDiscount}
            />
         );
      } else {
         return <></>;
      }
   };

   return (
      <div>
         <Loader loading={genInvoice.loading || requestDiscount.loading || createReservationTransaction.loading} />

         {genInvoice.show.toast && (
            <Toast
               show={genInvoice.show.toast}
               message={genInvoice.message}
               handleClose={() => {
                  genInvoice.close();
                  transactions.execute(id);
               }}
            />
         )}

         {createReservationTransaction.show.toast && (
            <Toast
               show={createReservationTransaction.show.toast}
               message={createReservationTransaction.message}
               handleClose={() => {
                  createReservationTransaction.close();
                  transactions.execute(id);
                  reservationItems.execute(id);
               }}
            />
         )}

         <PopupModal
            show={showRequestDailog}
            handleClose={() => {
               setShowRequestDailog(false);
            }}
            render={getModalContent}
         />

         <TitleContainer title={t("transactions")} />

         <ServiceHeader reservation={reservation.data} onBackPress={() => history.push(`/reservation/form/${id}`)} />

         <BillingItem
            data={reservationItems.data || []}
            onRemove={removeItem}
            createBilling={createNewTransaction}
            refreshList={refresh}
            customers={reservationCostumers.data}
            accountTypes={accountTypes.data}
            showDiscountDailog={() => {
               setModalType(1);
               setShowRequestDailog(true);
            }}
            discounts={reservationDiscounts}
            navigateToServices={() => history.push(`/reservation/services/${id}`)}
         />

         <ContainerTopBar title={t("listing")}>
            <InvoiceItems data={transactions.data || []} generateInvoice={generateInvoice} showInvoice={showInvoice} />
         </ContainerTopBar>
      </div>
   );
};

export default ReservationTransactions;
