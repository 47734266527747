import { Administrator } from "../../../models/administrator.model";
import ApiResponse from "../../../models/api-response.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getAdministrators(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Administrator[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getAdmins + `?Page=${page}&Size=${size}&Search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<Administrator[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeAdminStatus(id: number): Promise<ApiResponse<Administrator>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeAdminStatus.format(id),
         data: { adminId: id },
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Administrator> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
