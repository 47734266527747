import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const userDataStyle = makeStyles((theme: Theme) =>
  createStyles({
    userControl: {
      height: 120,
      width: 150,
      textAlign: "center",
      margin: "auto",
    },
    avatar: { margin: "auto" },
    name: { color: COLORS.iconInactive, marginTop: 5 },
    dropdown: {
      height: 30,
      fontSize: 14,
    },
    dropdownItem: {
      fontSize: 13,
      opacity: 0.7,
      "&:hover": {
        backgroundColor: COLORS.menuActive,
        borderRadius: 6,
      },
    },
  })
);
