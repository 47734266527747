import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import ns1 from "./locate/pt/ns1.json";
import ens1 from "./locate/en/ens1.json";
export const defaultNS = "translations";
export const resources = {
  pt: ns1,
  en: ens1,
} as const;

i18n.use(initReactI18next).init({
  lng: "pt",
  fallbackLng: "pt",
  resources,
});
