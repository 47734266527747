import { Box, Modal, Typography } from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { ConfirmModalBoxSx } from "./index.styles";
import { ConfirmModalProps } from "./index.types";

const ConfirmModal: FC<ConfirmModalProps> = ({ open, handleClose }) => {
  const globalClasses = globalStyles();
  const { t } = useTranslation(["gen"]);
  return (
    <Modal
      open={open}
      onClose={() => handleClose("cancel")}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={ConfirmModalBoxSx}>
        <ErrorOutline color="error" style={{ fontSize: 40 }} />
        <div className={globalClasses.dividerMargin} />
        <Typography variant="h5">{t("gen:deleteMessage")}</Typography>
        <Typography variant="h5">{t("gen:deleteDescription")}</Typography>
        <div
          className={`${globalClasses.dividerMargin} ${globalClasses.flexRow} `}
        >
          <span
            className={globalClasses.orangeButton}
            onClick={() => {
              handleClose("confirm");
            }}
          >
            {t("gen:confirm")}
          </span>
          <span
            className={`${globalClasses.greyButton} ${globalClasses.marginLeft}`}
            onClick={() => {
              handleClose("cancel");
            }}
          >
            {t("gen:cancel")}
          </span>
        </div>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
