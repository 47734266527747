import { useEffect } from "react";
import { Route, Switch, BrowserRouter as Router, RouteProps, Redirect } from "react-router-dom";
import { useHistory } from "react-router";
import Sidebar from "../components/organisms/sidebar/Sidebar";
import useAuth from "../data/context/authContext/AuthContext";
import Login from "../pages/login/Login";
import MAIN_ROUTES from "./appRoutes";
import { routerStyles } from "./index.styles";
import { ROUTE_LINKS } from "./routeLinks";
import useSetting from "../data/context/setting-context/SettingContext";
import NotFound from "../pages/notFound/NotFound";

function AuthenticatedRoute({ children, ...props }: RouteProps) {
   const { user } = useAuth();

   return <Route path={"/(.+)"} render={() => (user ? children : <Redirect to="/" />)} />;
}

const AppRouter = () => {
   const classes = routerStyles();
   const { user } = useAuth();
   const { getCounter } = useSetting();
   const history = useHistory();

   useEffect(() => {
      if (user) {
         if (history.location.pathname == "/") {
            getCounter();
            history.push(ROUTE_LINKS.DASHBOARD);
         }
      } else {
         history.push("/");
      }
   }, [user]);

   return (
      <>
         <Switch>
            <AuthenticatedRoute exact path={"/(.+)"}>
               <div className={classes.root}>
                  <Sidebar />
                  <main className={classes.content}>
                     <MAIN_ROUTES />
                  </main>
               </div>
            </AuthenticatedRoute>
            <Route exact path="/" component={Login} />
         </Switch>
      </>
   );
};

export default AppRouter;
