import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { CommonButton, InputLabel, TextInput } from "../../../atoms";
import { DateSelection } from "./index.types";

const DateSelectionForm: FC<DateSelection> = ({ handleChange, handleClick, loading }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations"]);
   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput id="startDate" helperText="" title="" type="date" disabled={false} onChange={(e) => handleChange(e, "startDate")} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput id="endDate" helperText="" title="" type="date" disabled={false} onChange={(e) => handleChange(e, "endDate")} />
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={handleClick} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default DateSelectionForm;
