import ApiResponse from "../../../models/api-response.model";
import CarFeature from "../../../models/car-feature.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getFeatures(page: number, size: number, search: string): Promise<ApiResponse<Pagination<CarFeature[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.features + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<CarFeature[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function changeFeatureStatus(featureId: number): Promise<ApiResponse<CarFeature>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeFeatureStatus.format(featureId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<CarFeature> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getFeatureById(id: number): Promise<ApiResponse<CarFeature>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.features + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<CarFeature> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateFeature(feature: any, featureId: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.features + `/${featureId}`,
         data: feature,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function newFeature(feature: any): Promise<ApiResponse<void>> {
   console.log(feature);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.features,
         data: feature,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
