import { Redirect, Route, Switch } from "react-router-dom";
import Contract from "../../pages/contract/Contract";
import {
   Dashboard,
   ListReservations,
   NewReservation,
   ReservationServices,
   reservationTransaction,
   Tickets,
   EditTicket,
   ListWayBills,
   NewWayBill,
   NewUser,
   NewMessage,
   ListAllUsers,
   PaymentCards,
   Administrators,
   PromoteToAdm,
   AddNewOrganization,
   ListOrganizations,
   AddnewCounter,
   ListCounters,
   NewVehicleMaintenance,
   ListMaintenance,
   Vehicles,
   NewVehicle,
   VehicleDetail,
   Categories,
   NewCategory,
   Characteristics,
   NewCharacteristic,
   NewBrand,
   Brands,
   ListTerrifs,
   NewTerrif,
   EditDiscount,
   DiscountsByQuantity,
   Campaigns,
   NewCampaign,
   Process,
   NoBonds,
   NotBilled,
   UngeneratedInvoices,
   TollBooking,
   MaintenanceCosts,
   PendingFinancials,
   DivergentCategories,
   Commissions,
   CommissionAdjustment,
   FAQ,
   NewQuestion,
   AppBanners,
   AddNewBanner,
   Definitions,
   Fines,
   NewFine,
   Accounting,
   GenerateBilling,
   ChangePassword,
   Invoice,
   CRM,
   FinalizeReservation,
   RemoveFromMaintenance,
} from "../../pages/index";
import NotFound from "../../pages/notFound/NotFound";
import Receipt from "../../pages/receipts/Receipt";
import { ROUTE_LINKS } from "../routeLinks/index";
const MAIN_ROUTES = () => {
   return (
      <>
         <Switch>
            <Route exact path={ROUTE_LINKS.DASHBOARD} component={Dashboard} />
            <Route path={ROUTE_LINKS.CHANGE_PASSWORD} component={ChangePassword} />
            <Route exact path={ROUTE_LINKS.NEW_RESERVATION} component={NewReservation} />
            <Route path={ROUTE_LINKS.crm} component={CRM} />
            <Route exact path={ROUTE_LINKS.EDIT_RESERVATION} component={NewReservation} />
            <Route exact path={ROUTE_LINKS.FINALIZE_RESERVATION} component={FinalizeReservation} />
            <Route path={ROUTE_LINKS.LIST_ALL_RESERVATIONS} component={ListReservations} />
            <Route path={ROUTE_LINKS.RESERVATION_SERVICES} component={ReservationServices} />
            <Route path={ROUTE_LINKS.RESERVATION_TRANSACTIONS} component={reservationTransaction} />
            <Route exact path={ROUTE_LINKS.RESERVATION_TICKETS} component={Tickets} />
            <Route path={ROUTE_LINKS.EDIT_RESERVATION_TICKETS} component={EditTicket} />
            <Route path={ROUTE_LINKS.RESERVATION_CONTRACT} component={Contract} />
            <Route exact path={ROUTE_LINKS.LIST_ALL_TRANSPORT_GUIDES} component={ListWayBills} />
            <Route exact path={ROUTE_LINKS.ADD_NEW_TRANSPORT_GUIDE} component={NewWayBill} />
            <Route path={ROUTE_LINKS.EDIT_TRANSPORT_GUIDE} component={NewWayBill} />
            <Route exact path={ROUTE_LINKS.CREATE_USER} component={NewUser} />
            <Route exact path={ROUTE_LINKS.EDIT_USER} component={NewUser} />
            <Route path={ROUTE_LINKS.LIST_USERS} component={ListAllUsers} />
            <Route path={ROUTE_LINKS.PROMOTE_USER_TO_ADMIN} component={PromoteToAdm} />
            <Route path={ROUTE_LINKS.NEW_MESSAGE} component={NewMessage} />
            <Route exact path={ROUTE_LINKS.ADD_PAYMENT_CARD} component={PaymentCards} />
            <Route path={ROUTE_LINKS.LIST_ADMINISTRATORS} component={Administrators} />
            <Route path={ROUTE_LINKS.ORGANIZATIONS} exact component={ListOrganizations} />
            <Route exact path={ROUTE_LINKS.ADD_NEW_ORGANIZATION} component={AddNewOrganization} />
            <Route exact path={ROUTE_LINKS.EDIT_ORGANIZATION} component={AddNewOrganization} />
            <Route path={ROUTE_LINKS.LIST_COUNTERS} exact component={ListCounters} />
            <Route exact path={ROUTE_LINKS.ADD_NEW_COUNTER} component={AddnewCounter} />
            <Route exact path={ROUTE_LINKS.EDIT_COUNTER} component={AddnewCounter} />
            <Route exact path={ROUTE_LINKS.NEW_MAINTENANCE} component={NewVehicleMaintenance} />
            <Route exact path={ROUTE_LINKS.EDIT_MAINTENANCE} component={NewVehicleMaintenance} />
            <Route path={ROUTE_LINKS.MAINTENANCE} exact component={ListMaintenance} />
            <Route path={ROUTE_LINKS.REMOVE_FROM_MAINTENANCE} component={RemoveFromMaintenance} />
            <Route exact path={ROUTE_LINKS.VEHICLES} component={Vehicles} />
            <Route exact path={ROUTE_LINKS.VEHICLE_DETAIL} component={VehicleDetail} />
            <Route exact path={ROUTE_LINKS.NEW_VEHICLE} component={NewVehicle} />
            <Route exact path={ROUTE_LINKS.EDIT_VEHICLE} component={NewVehicle} />
            <Route exact path={ROUTE_LINKS.VEHICLE_FINES} component={Fines} />
            <Route exact path={ROUTE_LINKS.VEHICLE_FINES_ID} component={Fines} />
            <Route exact path={ROUTE_LINKS.NEW_VEHICLE_FINE} component={NewFine} />
            <Route exact path={ROUTE_LINKS.EDIT_VEHICLE_FINE} component={NewFine} />
            <Route exact path={ROUTE_LINKS.VEHICLE_CATEGORIES} component={Categories} />
            <Route path={ROUTE_LINKS.NEW_VEHICLE_CATREGORY} component={NewCategory} />
            <Route exact path={ROUTE_LINKS.VEHICLE_BRANDS} component={Brands} />
            <Route path={ROUTE_LINKS.NEW_VEHICLE_BRAND} component={NewBrand} />
            <Route path={ROUTE_LINKS.EDIT_VEHICLE_BRAND} component={NewBrand} />
            <Route exact path={ROUTE_LINKS.VEHICLE_CHARACTERISTICS} component={Characteristics} />
            <Route exact path={ROUTE_LINKS.NEW_VEHICLE_CHARACTERISTICS} component={NewCharacteristic} />
            <Route exact path={ROUTE_LINKS.EDIT_VEHICLE_CHARACTERISTICS} component={NewCharacteristic} />
            <Route exact path={ROUTE_LINKS.ADD_NEW_TERRIF} component={NewTerrif} />
            <Route exact path={ROUTE_LINKS.EDIT_TERRIF} component={NewTerrif} />
            <Route path={ROUTE_LINKS.LIST_ALL_TERRIFS} component={ListTerrifs} />
            <Route exact path={ROUTE_LINKS.DISCOUNT_CAMPAIGN} component={Campaigns} />
            <Route exact path={ROUTE_LINKS.NEW_DISCOUNT_CAMPAIGN} component={NewCampaign} />
            <Route exact path={ROUTE_LINKS.EDIT_DISCOUNT_CAMPAIGN} component={NewCampaign} />
            <Route exact path={ROUTE_LINKS.DISCOUNTS_BY_QUANTITY} component={DiscountsByQuantity} />
            <Route path={`${ROUTE_LINKS.EDIT_DISCOUNT_BY_QUANTITY}`} component={EditDiscount} />
            <Route path={ROUTE_LINKS.TOLLS_NO_BONDS} component={NoBonds} />
            <Route path={ROUTE_LINKS.TOLLS_NOT_BILLED} component={NotBilled} />
            <Route path={ROUTE_LINKS.TOLLS_PROCESS} component={Process} />
            <Route path={ROUTE_LINKS.REPORTS_DIVERGENT_CATEGORIES} component={DivergentCategories} />
            <Route path={ROUTE_LINKS.REPORTS_PENDING_FINANCIALS} component={PendingFinancials} />
            <Route path={ROUTE_LINKS.REPORTS_MAINTENANCE_COST} component={MaintenanceCosts} />
            <Route path={ROUTE_LINKS.REPORTS_TOLL_BOOKING} component={TollBooking} />
            <Route path={ROUTE_LINKS.REPORTS_COMMISSION} component={Commissions} />
            <Route path={ROUTE_LINKS.REPORTS_ACCOUNTING} component={Accounting} />
            <Route path={ROUTE_LINKS.REPORTS_UNGENERATED_INVOICES} component={UngeneratedInvoices} />
            <Route path={ROUTE_LINKS.REPORTS_COMMISSION_ADJUSTMENT} component={CommissionAdjustment} />
            <Route path={ROUTE_LINKS.REPORTS_TRANSACTIONS_GENERATE_BILLING} component={GenerateBilling} />
            <Route path={ROUTE_LINKS.FAQS} exact component={FAQ} />
            <Route path={ROUTE_LINKS.ADD_NEW_FAQ} component={NewQuestion} />
            <Route path={ROUTE_LINKS.EDIT_FAQ} component={NewQuestion} />
            <Route path={ROUTE_LINKS.APP_BANNERS} exact component={AppBanners} />
            <Route exact path={ROUTE_LINKS.ADD_NEW_BANNER} component={AddNewBanner} />
            <Route path={ROUTE_LINKS.EDIT_BANNER} component={AddNewBanner} />
            <Route path={ROUTE_LINKS.DEFINITIONS} component={Definitions} />
            <Route path={ROUTE_LINKS.INVOICES} component={Invoice} />
            <Route path={ROUTE_LINKS.RECEIPT} component={Receipt} />
            <Route path="*" component={NotFound} />
         </Switch>
      </>
   );
};

export default MAIN_ROUTES;
