import { makeStyles, Theme } from "@material-ui/core";
import { COLORS } from "../../../data/constants/Colors";

export const cardStyles = makeStyles((theme: Theme) => ({
   bottomCardContainer: {
      height: 150,
      border: 1,
      paddingLeft: 20,
      paddingRight: 20,
      borderColor: COLORS.greyBorder,
      borderStyle: "solid",
      borderRadius: 7,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
   },
}));
