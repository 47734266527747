import ApiResponse from "../../../models/api-response.model";
import Brand from "../../../models/brand.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export const getBrands = async (size: number, page: number, search: string): Promise<ApiResponse<Pagination<Brand[]>>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.brands + `?size=${size}&page=${page}&search=${search}`,
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Brand[]>> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const saveBrand = async (name: string): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST, //TODO change to POST after API refactor
         url: API_ROUTES.brands,
         data: { name },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const getBrandByID = async (id: number | string): Promise<ApiResponse<Brand>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.brands + `/${id}`,
         data: {},
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Brand> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const updateBrand = async (brandId: string, name: string): Promise<ApiResponse<void>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.brands + `/${brandId}`,
         data: { brandId, name },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      if (resp.status !== 200) {
         throw new Error(data.message);
      }
      return data;
   } catch (error) {
      throw error;
   }
};
