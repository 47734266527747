import { AddCircle, Chat, Check, Close, CreditCard, DirectionsCar, Edit, ListAlt, Print, SettingsApplications, Toll } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { actions } from "../../../data/constants/button-action";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../data/models/reservation.model";
import { CommonButton } from "../../atoms";

interface ContainerProps {
   reservation?: Reservation;
   onClick: (action: string) => void;
}

const ButtonContainer: FC<ContainerProps> = ({ reservation, onClick }) => {
   const { t } = useTranslation(["translations", "dashboardStrings", "gen"]);

   const globalClasses = globalStyles();

   return (
      <>
         {!reservation ? (
            <>
               <CommonButton
                  title={t("translations:createNewUser")}
                  onClick={() => {
                     onClick(actions.NEW_USER);
                  }}
                  icon={<AddCircle />}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  title={t("translations:createNewCompany")}
                  onClick={() => {
                     onClick(actions.NEW_COMPANY);
                  }}
                  icon={<AddCircle />}
               />
            </>
         ) : (
            <>
               {reservation.status.id == 1 && (
                  <CommonButton
                     title={t("translations:proof")}
                     onClick={() => {
                        onClick(actions.PROOF);
                     }}
                     icon={<Print />}
                  />
               )}
               {(reservation.status.id == 2 || reservation.status.id == 3) && (
                  <>
                     <CommonButton
                        title={t("gen:contract")}
                        onClick={() => {
                           onClick(actions.CONTRACT);
                        }}
                        icon={<ListAlt />}
                     />
                  </>
               )}

               {reservation.status.id == 5 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("gen:approveRequest")}
                        onClick={() => {
                           onClick(actions.APPROVE);
                        }}
                        icon={<Check />}
                     />
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("gen:cancelReservation")}
                        color={COLORS.redLabel}
                        onClick={() => {
                           onClick(actions.CANCEL);
                        }}
                        icon={<Close />}
                     />
                  </>
               )}

               {reservation.status.id != 5 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("translations:services")}
                        onClick={() => {
                           onClick(actions.SERVICES);
                        }}
                        icon={<SettingsApplications />}
                     />
                  </>
               )}
               {reservation.status.id != 5 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("translations:transactions")}
                        onClick={() => {
                           onClick(actions.TRANSACTIONS);
                        }}
                        icon={<CreditCard />}
                     />
                  </>
               )}
               {reservation.status.id != 4 && reservation.status.id != 5 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("translations:tolls")}
                        onClick={() => {
                           onClick(actions.TOLLS);
                        }}
                        icon={<Toll />}
                     />
                  </>
               )}
               {reservation.status.id != 5 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("translations:vehicleHistory")}
                        onClick={() => {
                           onClick(actions.VEHICLE_HISTORY);
                        }}
                        icon={<DirectionsCar />}
                     />
                  </>
               )}
               {reservation.status.id == 3 && (
                  <>
                     <span className={globalClasses.marginLeft} />
                     <CommonButton
                        title={t("translations:edit") + t("dashboardStrings:reservation")}
                        color={COLORS.blue}
                        onClick={() => {
                           onClick(actions.EDIT_RESERVATION);
                        }}
                        icon={<Edit />}
                     />
                  </>
               )}
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  title={"CRM"}
                  onClick={() => {
                     onClick(actions.CRM);
                  }}
                  icon={<Chat />}
               />
            </>
         )}
      </>
   );
};

export default ButtonContainer;
