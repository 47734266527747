import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DateSelectionForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import * as reportsApi from "../../../data/api/requests/reports/index";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { DateLabel, Loader, TableButton } from "../../../components/atoms";
import { formatDate } from "../../../utills/helper";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

const DivergentCategories = () => {
   const history = useHistory();
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "vehicles", "gen", "dashboardStrings"]);
   const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");
   const reportsData = useRequest(() =>
      reportsApi.getDivergentCategories(dateRange.startDate, dateRange.endDate, pagination.page + 1, pagination.size, searchQuery)
   );
   useEffect(() => {
      if (dateRange.startDate && dateRange.endDate) {
         reportsData.execute();
      }
   }, [pagination, searchQuery]);

   const _handleChange = (e: React.ChangeEvent<HTMLInputElement>, key: string) => {
      const date = new Date(e.target.value);
      setDateRange({ ...dateRange, [key]: date.toISOString() });
   };

   const handleActionButtonClick = (id: number) => {
      history.push(`/reservation/form/${id}`);
   };

   return (
      <div>
         <Loader loading={reportsData.loading} />
         <TitleContainer title={t("translations:divergentCategoriesBooking")} />
         <DateSelectionForm handleChange={_handleChange} handleClick={() => reportsData.execute()} loading={reportsData.loading} />
         <ContainerTopBar title={t("translations:listing")}>
            <DataTable data={reportsData.data?.data || []}>
               <TableColumn
                  headerText={t("vehicles:registration")}
                  render={(item) => <span className={globalClasses.greyBox}>{item.car.licensePlate}</span>}
               />
               <TableColumn headerText={t("vehicles:model")} render={(item) => item.car.name} />
               <TableColumn headerText={t("translations:start")} render={(item) => <DateLabel date={item.startDate} time />} />
               <TableColumn
                  headerText={t("translations:end")}
                  render={(item) => (item.endDate ? <DateLabel date={item.endDate} time /> : t("vehicles:inUse"))}
               />
               <TableColumn headerText={t("vehicles:category")} render={(item) => item.name} />
               <TableColumn
                  headerText={t("dashboardStrings:reservation")}
                  render={(item) => (
                     <TableButton
                        title={`#${item.reservationId}`}
                        onClick={() => {
                           handleActionButtonClick(item.reservationId);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default DivergentCategories;
