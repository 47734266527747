import ApiResponse from "../../../models/api-response.model";
import Service from "../../../models/reservation-service.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getReservationService(reservationId: number): Promise<ApiResponse<Service[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationItems.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      let data: ApiResponse<Service[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function unbilledReservationItems(reservationId: string): Promise<ApiResponse<Service[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.reservationItems.format(reservationId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      let data: ApiResponse<Service[]> = resp.data;
      data.data = data.data.filter((el) => !el.transaction);
      data.data = data.data.map((el) => {
         return { ...el, total: (el.quantity * (el.itemValue - el.discount) * (1 + el.itemTax / 100)).toFixed(2) };
      });
      return data;
   } catch (error) {
      throw error;
   }
}

export async function deleteReservationService(reservationId: string, itemId: string): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.DELETE,
         url: API_ROUTES.deleteReservationItem.format(reservationId, itemId),
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewReservationService(reservationId: number, item: any): Promise<ApiResponse<Service[]>> {
   const dataToUpload = { ...item, reservationId: reservationId };

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.reservationItems.format(reservationId),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("Not Found");
      }
      const data: ApiResponse<Service[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
