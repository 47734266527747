import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import MaintenanceInfo from "../../../../data/models/maintenance-info.model";
import { formatDateForInput } from "../../../../utills/helper";
import { currency, putDecimalPoints } from "../../../../utills/masks";
import { CommonButton, CurrencyInput, InputLabel, TextArea, TextInput } from "../../../atoms";
import * as yup from "yup";

interface RemoveMaintenanceProps {
   data?: MaintenanceInfo;
   handleSubmit: (values: any) => void;
}

const RemoveMaintenanceForm: FC<RemoveMaintenanceProps> = ({ data, handleSubmit }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["maintenance", "translations", "gen", "vehicles"]);

   const formik = useFormik({
      initialValues: { description: data?.description || "", amount: data?.amount ? putDecimalPoints(data?.amount) : "", endDate: "" },
      validationSchema: yup.object({
         description: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         amount: yup.string().min(2, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         endDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      enableReinitialize: true,
      validateOnMount: false,
      validateOnBlur: false,
      onSubmit: (values) => {
         handleSubmit(values);
      },
   });

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("translations:vehicl")} />
               <TextInput
                  id="vehicle"
                  title=""
                  helperText=""
                  type="text"
                  disabled={true}
                  value={data?.carPlate + " " + data?.carName + `(${data?.carYear})`}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("maintenance:local")} />
               <TextInput id="local" title="" helperText="" type="text" disabled={true} value={data?.organizationName} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("maintenance:entry")} />
               <TextInput id="startDate" title="" helperText="" type="date" disabled={true} value={formatDateForInput(data?.startDate)} />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("maintenance:expectedExit")} />
               <TextInput id="intendedExit" title="" helperText="" type="date" disabled={true} value={formatDateForInput(data?.intendedOutDate)} />
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("gen:returnDate")} />
               <TextInput
                  id="endDate"
                  title=""
                  type="date"
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={12} xs={12}>
               <InputLabel label={t("maintenance:budget")} />
               <CurrencyInput
                  id="amount"
                  title=""
                  value={formik.values.amount}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  type="text"
                  onChange={(value) => formik.setFieldValue("amount", value)}
               />
               {/* <TextInput
                  id="amount"
                  title=""
                  value={formik.values.amount}
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  type="text"
                  onChange={(event) => formik.setFieldValue("amount", currency(event).target.value)}
               /> */}
            </Grid>
         </Grid>
         <Grid container spacing={3}>
            <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
               <InputLabel label={t("maintenance:comments")} />
               <TextArea value={formik.values.description} id="description" onChange={formik.handleChange} />
               <Typography className={globalClasses.errorText}>{formik.errors.description}</Typography>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title={t("translations:save")} onClick={formik.handleSubmit} />
      </Box>
   );
};

export default RemoveMaintenanceForm;
