import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { CommonButton, TableDropDown } from "../../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../../components/molecules";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import { useTranslation } from "react-i18next";
import * as brandsApi from "../../../../data/api/requests/brands/index";
import Brand from "../../../../data/models/brand.model";
import DataTable from "../../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../../components/molecules/tables/table-column/TableColumn";
import { AddCircle } from "@material-ui/icons";
const Brands = () => {
   const history = useHistory();
   const { t } = useTranslation(["translations", "users"]);
   const [pagination, setPagination] = useState({ page: 1, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");
   const [brands, setBrands] = useState<Brand[]>();
   const [total, setTotal] = useState<number>(0);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         _getBrands();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchQuery]);
   const _getBrands = () => {
      setLoading(true);
      brandsApi
         .getBrands(pagination.size, pagination.page, searchQuery)
         .then((res) => {
            setBrands(res.data.data);
            setTotal(res.data.total);
         })
         .catch((err) => {
            console.log(err);
         })
         .finally(() => {
            setLoading(false);
         });
   };
   return (
      <div>
         <TitleContainer title={t("translations:brands")} />
         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_VEHICLE_BRAND);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={loading}>
            <DataTable
               data={brands || []}
               pagination
               onSearching={(query) => setSearchQuery(query)}
               onChange={(page, size) => setPagination({ page: page, size: size })}
               total={total}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("users:name")} render={(item) => item.name} />
               <TableColumn
                  headerText={t("translations:action")}
                  headerOptions={{ align: "right" }}
                  align="right"
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit")]}
                        onClick={() => history.push(ROUTE_LINKS.EDIT_VEHICLE_BRAND.formatMap({ id: item.id }))}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Brands;
