import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import { LegacyTolls } from "../../../models/legacy-tolls.model";
import { NotBilledToll } from "../../../models/not-billed-tolls.model";
import { OrphanToll } from "../../../models/orphan-tolls";
import Pagination from "../../../models/pagination.model";
import Transaction from "../../../models/transaction.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getUnbilledTolls(page: number, size: number): Promise<ApiResponse<Pagination<NotBilledToll[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.unbilledTolls + `?size=${size}&page=${page}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<NotBilledToll[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getOrphanTolls(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Transaction[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.orphanTolls + `?size=${size}&page=${page}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<Transaction[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function registerPayment(transactionId: number): Promise<ApiResponse<void>> {
   console.log(transactionId);
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.registerPayment + `?vvTransactionId=${transactionId}`,
         // data: { transactionId },
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function linkToll(payload: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.linkTo + `?reservationId=${payload.reservationId}&vvpId=${payload.transactionId}`,
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeTollStatus(payload: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeTollStatus,
         data: payload,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function legacyTolls(payload: any, page: number, size: number): Promise<ApiResponse<Pagination<LegacyTolls[]>>> {
   const startDate = encodeURIComponent(encodeDate(payload.startDate, ""));
   const endDate = encodeURIComponent(encodeDate(payload.endDate, ""));
   const plate = payload.registration ? `TextPlate=${payload.registration}` : "";
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.legacyTolls + `?StartDate=${startDate}&EndDate=${endDate}&${plate}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Pagination<LegacyTolls[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function processToll(values: any, transactionId: number[]): Promise<ApiResponse<void>> {
   const transactionQuery = `${transactionId.map((el) => "TransactionIds=" + el + "&")}`;
   const finalQuery = transactionQuery.replaceAll(",", "");

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.processTolls + `?StatusId=${values.StatusId}&${finalQuery}DriverId=${values.DriverId}`,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
