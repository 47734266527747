import { Box } from "@material-ui/core";
import { useEffect } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as discountApi from "../../../data/api/requests/disscounts/index";
import { TableButton, TableItem } from "../../../components/atoms";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useRequest } from "../../../data/api/wrapper";
import { ContainerTopBar } from "../../../components/molecules";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { Edit } from "@material-ui/icons";
import DiscountBillable from "../../../data/models/billable-discount.modal";

const DiscountsByQuantity = () => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["users", "translations"]);

   const history = useHistory();

   const getBillable = useRequest(() => discountApi.getBillableDiscounts());

   useEffect(() => {
      getBillable.execute();
   }, []);

   const onEditClick = (item: DiscountBillable) => {
      history.push(`/discount/byquantity/edit/${item.id}`, item.name);
   };

   return (
      <div>
         <Box className={globalClasses.title}>Itens aplicáveis</Box>
         <ContainerTopBar title={t("translations:listing")} activity={getBillable.loading}>
            <DataTable data={getBillable.data?.data || []} search={false}>
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn headerText={t("translations:type")} render={(item) => item.type.name} />
               <TableColumn headerText={t("users:name")} render={(item) => <TableItem title={item.name} subtitle={item.description} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableButton
                        icon={<Edit className={globalClasses.tableSmallIcon} />}
                        title={t("translations:edit")}
                        onClick={() => {
                           onEditClick(item);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default DiscountsByQuantity;
