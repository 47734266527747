import { createStyles, Theme, makeStyles } from "@material-ui/core";
import { COLORS, VALUES } from "../constants/Colors";

export const globalStyles = makeStyles((theme: Theme) =>
   createStyles({
      marginSmall: {
         marginTop: 3,
      },
      redText: {
         color: COLORS.redLabel,
      },
      primaryText: {
         color: theme.palette.primary.main,
         "&:hover": {
            cursor: "pointer",
         },
      },
      blueText: { color: theme.palette.success.main, fontSize: 13 },
      orangeButton: {
         backgroundColor: theme.palette.primary.main,
         padding: "5px 5px 5px 5px",
         margin: "0px 2px 2px 2px",
         borderRadius: 3,
         color: theme.palette.background.default,
         "&:hover": {
            cursor: "pointer",
         },
      },
      flexEnd: {
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "flex-end",
      },
      whiteButton: {
         backgroundColor: theme.palette.success.main,
         padding: "5px 5px 5px 5px",
         margin: "0px 2px 2px 2px",
         borderRadius: 3,
         "&:hover": {
            cursor: "pointer",
         },
      },
      greyButton: {
         backgroundColor: theme.palette.grey[500],
         padding: "5px 5px 5px 5px",
         margin: "0px 2px 2px 2px",
         borderRadius: 3,
         color: theme.palette.background.default,
         "&:hover": {
            cursor: "pointer",
         },
      },
      errorText: {
         color: theme.palette.error.main,
         fontSize: 13,
      },
      title: {
         backgroundColor: "white",
         padding: 15,
         fontSize: 25,
         fontWeight: "lighter",
         color: theme.palette.text.secondary,
      },
      discountTitle: {
         fontSize: 25,
         fontWeight: "lighter",
         color: theme.palette.text.secondary,
         marginTop: 10,
      },
      paddingContainer: { paddingLeft: 20, paddingRight: 20, paddingBottom: 10 },
      titleText: {
         fontSize: 25,
         fontWeight: "lighter",
         color: theme.palette.text.secondary,
      },
      mediumText: {
         fontSize: 17,
         fontWeight: "bold",
         color: COLORS.darkText,
         display: "block",
      },
      smallErrorText: { fontSize: 11 },
      linkText: {
         fontSize: 13,
         color: COLORS.blue,
         "&:hover": {
            cursor: "pointer",
         },
      },
      hoverPointer: {
         "&:hover": {
            cursor: "pointer",
         },
      },
      smallerText: {
         fontSize: 11,
      },
      badge: {
         maxHeight: 18,
         minWidth: 18,
         paddingLeft: 3,
         paddingRight: 3,
         borderRadius: 18 / 2,
         marginLeft: 3,
         color: theme.palette.background.paper,
         fontSize: 10,
         fontWeight: "bold",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      tableTitleBox: {
         borderTopWidth: 2,
         borderTopColor: COLORS.greyBorder,
         borderTopStyle: "solid",
         borderBottomWidth: 2,
         borderBottomColor: COLORS.greyBorder,
         borderBottmStyle: "solid",
         padding: "10px 10px 10px 10px",
      },
      redLabel: {
         backgroundColor: COLORS.redLight,
         color: theme.palette.background.paper,
         padding: 10,
      },
      gridContainer: {
         marginTop: 15,
      },
      alertWarningSevere: {
         color: "#ed5565",
         backgroundColor: "#f8d7da",
         borderColor: "#f5c6cb",
         marginTop: 20,
         padding: 15,
         borderRadius: 8,
      },
      alertWarning: {
         color: "#856404",
         backgroundColor: "#fff3cd",
         borderColor: "#ffeeba",
         marginTop: 20,
         padding: 15,
         borderRadius: 8,
      },
      alertContainer: {
         backgroundColor: theme.palette.success.light,
         marginTop: 20,
         padding: 15,
         borderRadius: 8,
      },
      errorContainer: {
         backgroundColor: theme.palette.error.light,
         marginBottom: 10,
         padding: 15,
         borderRadius: 8,
      },
      greenBox: {
         padding: "5px",
         fontSize: 13,
         fontWeight: "bold",
         color: theme.palette.background.paper,
         backgroundColor: theme.palette.primary.main,
         borderRadius: 6,
         "&:hover": {
            cursor: "pointer",
         },
      },
      outlineGreenBox: {
         padding: "5px",
         fontSize: 13,
         fontWeight: "bold",
         color: theme.palette.primary.main,
         borderRadius: 6,
         borderWidth: 1,
         borderColor: theme.palette.primary.main,
         borderStyle: "solid",
         "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.background.paper,
            cursor: "pointer",
         },
      },
      marginLeft: {
         marginLeft: VALUES.margin_small,
         marginRight: VALUES.margin_small,
      },
      instructionContainer: {
         backgroundColor: "white",
         padding: 15,
         fontWeight: "lighter",
         marginTop: 25,
         color: theme.palette.text.secondary,
      },
      container: {
         paddingTop: 15,
         backgroundColor: theme.palette.background.paper,
         marginTop: 20,
         paddingBottom: 15,
      },
      containerWithNoMargin: {
         paddingTop: 15,
         backgroundColor: theme.palette.background.paper,
         paddingBottom: 15,
      },

      margin: { marginTop: 10 },
      dividerMargin: { marginTop: 20, marginBottom: 20 },
      largeText: {},
      cardTitleCOntainer: {
         paddingLeft: 15,
         paddingRight: 15,
         paddingBottom: 10,
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
      },
      cardTitle: {
         fontSize: 14,
         fontWeight: "bold",
         color: COLORS.darkText,
      },
      cardValue: {
         fontSize: 14,
         color: COLORS.greyText,
      },
      labelText: { fontSize: 13, marginBottom: 5, color: COLORS.darkText },
      tableHeadText: { fontSize: 13, fontWeight: "bold" },
      tableText: {
         fontSize: 13,
         maxLines: 2,
         wordBreak: "break-word",
         textOverflow: "ellipsis",
         overflow: "hidden",
         display: "-webkit-box",
         "-webkit-line-clamp": 2,
         "-webkit-box-orient": "vertical",
         maxWidth: 300,
      },
      tableSmallText: {
         fontSize: 11,
         marginLeft: 1,
         wordBreak: "break-word",
         textOverflow: "ellipsis",
         overflow: "hidden",
         display: "-webkit-box",
         "-webkit-line-clamp": 1,
         "-webkit-box-orient": "vertical",
      },
      tableIcon: { fontSize: 15, fontWeight: "bold", "&:hover": { cursor: "pointer" } },
      tableSmallIcon: {
         fontSize: 13,
         fontWeight: "bold",
         color: theme.palette.background.paper,
      },
      smallIcon: {
         fontSize: 13,
         fontWeight: "bold",
      },
      greyBox: {
         backgroundColor: COLORS.lightGrey,
         paddingLeft: 6,
         paddingRight: 6,
         paddingTop: 4,
         paddingBottom: 4,
         fontSize: 11,
         borderRadius: "0.25rem",
         fontWeight: "bold",
      },
      blueBox: {
         backgroundColor: COLORS.blue,
         color: theme.palette.background.paper,
         paddingLeft: 6,
         paddingRight: 6,
         paddingTop: 4,
         paddingBottom: 4,
         fontSize: 11,
         borderRadius: "0.25rem",
         fontWeight: "bold",
      },
      alignCenter: {
         display: "flex",
         flexDirection: "row",
         justifyContent: "center",
      },
      textAlign: {
         textAlign: "center",
      },
      flexRow: { display: "flex", flexDirection: "row" },
      flexRowCenter: { display: "flex", flexDirection: "row", alignItems: "center" },
      noRadius: { borderRadius: 0 },
      redBox: {
         backgroundColor: COLORS.redLabel,
         color: theme.palette.background.paper,
         paddingLeft: 6,
         paddingRight: 6,
         paddingTop: 4,
         paddingBottom: 4,
         fontSize: 11,
         borderRadius: "0.25rem",
         fontWeight: "bold",
      },
      icon: { "&:hover": { cursor: "pointer" } },
      bigErrorIcon: { fontSize: 35, color: COLORS.redLabel },
      bigSuccessIcon: { fontSize: 35, color: COLORS.greenLabel },
      carouselImage: {},
      inActiveTableLabel: {
         backgroundColor: "red",
         height: 20,
         paddingLeft: 6,
         paddingRight: 6,
         borderRadius: 5,
         color: theme.palette.background.paper,
         fontSize: 11,
         fontWeight: "bolder",
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "center",
      },
      ActiveTableLabel: {
         backgroundColor: "rgb(0,123,187)",
         height: 20,
         paddingLeft: 6,
         paddingRight: 6,
         borderRadius: 5,
         color: theme.palette.background.paper,
         fontSize: 11,
         fontWeight: "bolder",
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "center",
      },
      modalAlign: {
         display: "flex",
         flexDirection: "column",
         justifyContent: "space-around",
         alignItems: "center",
         paddingTop: 20,
         paddingBottom: 20,
      },
      modalIcon: { fontSize: 35 },
      dollarSign: {
         backgroundColor: "red",
         height: 20,
         paddingLeft: 6,
         paddingRight: 6,
         borderRadius: 5,
         color: theme.palette.background.paper,
         fontSize: 14,
         fontWeight: "bolder",
         marginLeft: 5,
         display: "flex",
         flexDirection: "row",
         alignItems: "center",
         justifyContent: "center",
      },
      greyTableBox: {
         backgroundColor: COLORS.lightGrey,
         padding: 10,
         width: "100%",
         borderRadius: 5,
      },
   })
);
