import { Typography } from "@material-ui/core";
import { BorderColor } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";

interface LinkProps {
   id: number | string | undefined;
   onClick: () => void;
}

const ReservationLink: FC<LinkProps> = ({ id, onClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation("dashboardStrings");
   return (
      <div className={`${globalClasses.flexRow} ${globalClasses.hoverPointer}`} onClick={onClick}>
         <BorderColor style={{ fontSize: 12, color: COLORS.blue }} />
         <Typography className={`${globalClasses.smallerText} ${globalClasses.linkText}`}>{` ${t("reservation")} ${id}`}</Typography>
      </div>
   );
};

export default ReservationLink;
