import ApiResponse from "../../../models/api-response.model";
import Card from "../../../models/Payment-Card.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getPaymentCards({ clientId, typeId }: { clientId: string; typeId: number }): Promise<ApiResponse<Card>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.cards + `?clientId=${clientId}&type=${typeId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Card> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function deletePaymentCards({
   cardId,
   clientId,
   clientType,
}: {
   cardId: string;
   clientId: string;
   clientType: string;
}): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.DELETE,
         url: API_ROUTES.cards + `/${cardId}`,
         data: { clientId, clientType },
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
