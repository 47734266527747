import { Box, Divider, Modal, Typography } from "@material-ui/core";
import { CancelOutlined, CheckCircleOutlined, Close, ErrorOutline } from "@material-ui/icons";
import { FC } from "react";
import { historyModalBoxSx, modalBoxSx } from "./index.styles";
import { ModalProps } from "./index.types";
import { modalStyles } from "./index.styles";
import { CommonButton } from "../../../atoms";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { COLORS } from "../../../../data/constants/Colors";

const ModalComponent: FC<ModalProps> = ({ open, handleClose, data, incomplete, error, changeStatus, modalOptions, action }) => {
   const globalClasses = globalStyles();
   const classes = modalStyles();
   const { t } = useTranslation(["translations", "gen"]);
   return (
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
         {data ? (
            <Box sx={historyModalBoxSx}>
               <Box className={classes.topBar}>
                  <Typography className={globalClasses.cardTitle}>Histórico</Typography>
                  <Close onClick={handleClose} className={classes.icon} />
               </Box>
               <Divider className={globalClasses.dividerMargin} />
               <Box className={classes.head}>
                  <Typography>Utilizador</Typography>
                  <Typography>Data</Typography>
                  <Typography>Status</Typography>
               </Box>
               {data.map((item, index) => (
                  <Box className={index % 2 === 0 ? classes.bodyShaded : classes.bodySimple}>
                     <div>
                        <Typography variant="subtitle2">{item.name}</Typography>
                        <Typography variant="caption">{item.email}</Typography>
                     </div>
                     <Typography variant="caption">{item.date}</Typography>
                     <span className={item.status === "Aberto" ? classes.greenBox : classes.orangeBox}>{item.status}</span>
                  </Box>
               ))}
               <Box className={classes.bottomContainer}>
                  <CommonButton title="Fechar" onClick={handleClose} color={COLORS.greyBorder} />
               </Box>
            </Box>
         ) : changeStatus ? (
            <Box sx={modalBoxSx}>
               <CheckCircleOutlined style={{ fontSize: 45, color: COLORS.greenLabel }} />
               <Typography className={globalClasses.titleText}>{changeStatus.title}</Typography>
               <Typography>{changeStatus.message}</Typography>
               <CommonButton title="OK" onClick={handleClose} />
            </Box>
         ) : modalOptions ? (
            modalOptions.registerPayment ? (
               <Box sx={modalBoxSx}>
                  <Typography variant="h6">{t("gen:confirmMessage")}</Typography>
                  <div className={`${globalClasses.dividerMargin} ${globalClasses.flexRow} `}>
                     <span
                        className={globalClasses.orangeButton}
                        onClick={() => {
                           if (action) {
                              action("register");
                           }
                        }}
                     >
                        {t("gen:confirm")}
                     </span>
                     <span className={`${globalClasses.greyButton} ${globalClasses.marginLeft}`} onClick={handleClose}>
                        {t("gen:cancel")}
                     </span>
                  </div>
               </Box>
            ) : modalOptions.link ? (
               <Box sx={modalBoxSx}>Link</Box>
            ) : (
               <Box sx={modalBoxSx}>Update Status</Box>
            )
         ) : (
            <Box sx={modalBoxSx}>
               {error ? <CancelOutlined color="error" style={{ fontSize: 45 }} /> : <ErrorOutline color="primary" style={{ fontSize: 45 }} />}
               <div className={globalClasses.margin} />
               {error ? (
                  <Typography className={globalClasses.titleText}>{error}</Typography>
               ) : (
                  <Typography className={incomplete ? globalClasses.titleText : ""}>
                     {incomplete ? "Campos incompletos" : "Selecione uma opção da lista para poder acessar este recurso."}
                  </Typography>
               )}

               {incomplete ? (
                  <>
                     <div className={globalClasses.margin}></div>
                     <Typography>É necessário preencher todos os campos</Typography>
                  </>
               ) : null}
               <div className={globalClasses.margin} />
               <CommonButton title="OK" onClick={handleClose} />
            </Box>
         )}
      </Modal>
   );
};

export default ModalComponent;
