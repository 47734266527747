import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as carsApi from "../../../data/api/requests/cars/index";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { CommonButton, StatusLabel, TableDropDown, Toast } from "../../../components/atoms";
import Car from "../../../data/models/car.model";
import Pagination from "../../../data/models/pagination.model";

const Vehicles = () => {
   const { t } = useTranslation(["translations", "vehicles"]);

   const history = useHistory();

   const [pagination, setPagination] = useState({ size: 10, page: 0 });
   const [searchString, setSearchString] = useState("");

   const cars = useRequest(() => carsApi.getCars(pagination.page, pagination.size, searchString));

   const changeCarStatus = useRequest((params) => carsApi.changeCarStatus(params));

   useEffect(() => {
      if (changeCarStatus.data) {
         const index = cars.data?.data.findIndex((el) => el.id == changeCarStatus?.data?.id);
         console.log(index);
         const { data } = cars;
         let users: Pagination<Car[]> = Object.assign(data!!);
         users.data[index!!] = changeCarStatus.data;
         cars.setData(users);
      }
   }, [changeCarStatus.data]);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         cars.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchString]);

   const _changeCarStatus = (carId: number) => {
      changeCarStatus.execute(carId);
   };

   const handleDropdownClick = (action: string, item: Car) => {
      console.log("edit");
      switch (action) {
         case t("translations:details"):
            history.push(`/vehiclemanagment/vehicles/detail/${item.id}`);
            break;
         case t("translations:edit"):
            history.push(`/vehiclemanagment/vehicles/form/${item.id}`);
            break;
         case t("translations:fines"):
            break;
         case t("vehicles:locate"):
            break;
         case t("translations:changeStatus"):
            _changeCarStatus(item.id);
            break;
         default:
            break;
      }
   };

   return (
      <div>
         <TitleContainer title={t("translations:vehicles")} />

         {changeCarStatus.show.toast && (
            <Toast
               show={changeCarStatus.show.toast}
               message={changeCarStatus.message}
               handleClose={() => {
                  changeCarStatus.close();
                  //organizations.execute();
               }}
            />
         )}

         <CommonButton
            title={t("translations:new")}
            icon={<AddCircle />}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_VEHICLE);
            }}
         />

         <ContainerTopBar title={t("translations:listing")} activity={cars.loading}>
            <DataTable
               data={cars.data?.data || []}
               pagination
               onSearching={(query) => setSearchString(query)}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               total={cars.data?.total}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={"Info"} render={(item) => ""} />
               <TableColumn headerText={t("vehicles:model")} render={(item) => item.name} />
               <TableColumn headerText={t("translations:shortName")} render={(item) => ""} />
               <TableColumn headerText={t("vehicles:enrollmentDate")} render={(item) => item.licensePlate} />
               <TableColumn headerText={t("vehicles:chassis")} render={(item) => item.chassis} />
               <TableColumn headerText={t("vehicles:km")} render={(item) => `${item.mileage} Km`} />
               <TableColumn
                  headerText={t("vehicles:category")}
                  render={(item) => (
                     <>
                        {item.categories.map((item: any, index: any) => (
                           <>
                              {index === 0 && <div key={item.id}>{item.name}</div>}
                              {index === 0 && <div key={item.id}>{item.cta}</div>}
                           </>
                        ))}
                     </>
                  )}
               />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[
                           t("translations:details"),
                           t("translations:edit"),
                           t("translations:fines"),
                           t("vehicles:locate"),
                           t("translations:changeStatus"),
                        ]}
                        onClick={(action) => handleDropdownClick(action, item)}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Vehicles;
