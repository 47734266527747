import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";

export const NavChildItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    selectedSubMenu: {
      paddingLeft: 20,
      height: 30,
      marginTop: 3,
      opacity: 1,
      backgroundColor: COLORS.menuActive,
      borderRadius: 6,
      "&:hover": {
        opacity: 1,
        backgroundColor: COLORS.menuActive,
        borderRadius: 6,
      },
    },
    subMenu: {
      paddingLeft: 20,
      height: 30,
      marginTop: 3,
      opacity: 0.7,
      "&:hover": {
        opacity: 1,
        backgroundColor: COLORS.menuActive,
        borderRadius: 6,
      },
    },
    activeIcon: { minWidth: 25 },
    text: {
      color: COLORS.iconInactive,
      fontSize: 12,
    },
  })
);
