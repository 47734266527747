import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { TitleContainer } from "../../../components/molecules";
import { FinancialContainer, FinancialForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import * as reportsApi from "../../../data/api/requests/reports/index";
import { useHistory } from "react-router";
import { Loader } from "../../../components/atoms";

const PendingFinancials = () => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["translations", "users"]);

   const history = useHistory();

   const getFinancials = useRequest((params) => reportsApi.getPendingFinancials(params));

   const search = (data: any) => {
      getFinancials.execute(data);
   };

   const navigateToReservation = (id: number) => {
      history.push(`/reservation/form/${id}`);
   };

   return (
      <div>
         <Loader loading={getFinancials.loading} />

         <TitleContainer title={t("translations:pendingTransactions")} />

         <FinancialForm onSubmit={search} />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <FinancialContainer data={getFinancials.data || []} onReservationClick={navigateToReservation} />
         </Box>
      </div>
   );
};

export default PendingFinancials;
