import moment from "moment";
import { FeatureInputs } from "../data/models/car-inputs";
import Car from "../data/models/car.model";
import { putDecimalPoints } from "./masks";

export const getVehicleData = (car: Car, carFeatures: FeatureInputs) => {
   let tempFeatures: {
      value: number;
      highlight: boolean;
      featureId: number;
   }[] = [];

   let tempVehicleFeatures: {
      value: number;
      highlight: boolean;
      featureId: number;
   }[] = [];

   let tempContractFeatures: {
      value: number;
      highlight: boolean;
      featureId: number;
   }[] = [];

   let emptyFeature = {
      featureId: 0,
      highlight: true,
      name: "",
      value: 0,
   };

   console.log("CAR FEATURES", carFeatures);

   carFeatures.inputs.map((el) =>
      el.features.map((item) => {
         car.features.find((feat) => {
            if (feat.featureId == item.id) {
               return tempFeatures.push(feat);
            }
         });
      })
   );

   if (car.features.length == 0) {
      carFeatures.inputs.map((el) =>
         el.features.map((item) => {
            console.log("ITEMS", item);
            tempFeatures.push({ ...emptyFeature, featureId: item.id });
         })
      );
   }

   carFeatures.checkboxes[0].features.map((el) =>
      car.features.find((item) => {
         if (el.id == item.featureId) {
            tempVehicleFeatures.push(item);
         }
      })
   );

   carFeatures.checkboxes[1].features.map((el) =>
      car.features.find((item) => {
         if (el.id == item.featureId) {
            tempContractFeatures.push(item);
         }
      })
   );
   console.log(car);

   let data = {
      image: File,
      coverImage: File,
      imageUrl: car.image,
      coverImageUrl: car.coverImage,
      fuelTypeId: car.fuelType.id,
      fuelLevel: car.fuelLevel || 0,
      licenseDate: car.licenseDate ? moment(car.licenseDate).format("YYYY-MM-DD") : "",
      cubicCapacity: car.cubicCapacity.toString(),
      firstInspection: car.firstInspection,
      inspectionFrequency: car.inspectionFrequency,
      colorId: car.color.id,
      supplierId: car.supplier.id,
      purchasePrice: putDecimalPoints(car.purchasePrice),
      officeId: car.office.id,
      licensePlate: car.licensePlate,
      carTypeId: car.carType.id,
      fleetId: car.fleet.id,
      year: car.year,
      mileage: car.mileage,
      chassis: car.chassis,
      brandId: car.manufactory.id,
      name: car.name,
      shortName: "",
      categories: car.categories.map((el) => el.id),
      vehicleFeatures: tempVehicleFeatures,
      contractFeatures: tempContractFeatures,
      features: tempFeatures,
   };
   console.log(data);
   return data;
};
