import { Mail } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import User from "../../../data/models/user.model";
import { AutoComplete, CommonButton, InputLabel } from "../../atoms";
import { cardStyles } from "./index.styles";

export interface CardProps {
   onSelectUser: (email: string) => void;
   onSendEmail: () => void;
   users: User[];
   loading?: boolean;
   title?: string;
   buttonTitle?: string;
}

const SendByEmail: FC<CardProps> = ({ onSelectUser, onSendEmail, users, loading, title, buttonTitle }) => {
   const classes = cardStyles();

   return (
      <div className={classes.bottomCardContainer}>
         <InputLabel label={title || ""} />
         <AutoComplete
            options={users || []}
            renderOption={(item) => item.name}
            getOptionLabel={(item) => item.name}
            label={""}
            placeholder=""
            onChange={(_, item) => {
               if (item?.email) {
                  onSelectUser(item.email);
               }
            }}
         />
         <div>
            <CommonButton title={buttonTitle || ""} onClick={onSendEmail} icon={<Mail />} loading={loading} disable={loading} />
         </div>
      </div>
   );
};

export default SendByEmail;
