import { makeStyles, Theme } from "@material-ui/core";

export const levelStyles = makeStyles((theme: Theme) => ({
  outerContainer: {
    maxWidth: 300,
    height: 10,
    backgroundColor: theme.palette.grey[300],
    borderRadius: 5,
  },
}));
