import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Loader, TableButton, TableItem } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import { AccountingForm } from "../../../components/organisms";
import * as reportApi from "../../../data/api/requests/reports/index";
import { useRequest } from "../../../data/api/wrapper";
import { formatDate, formatDateTime } from "../../../utills/helper";
import {  padId, putDecimalPoints } from "../../../utills/masks";
const Accounting = () => {
   const { t } = useTranslation(["translations", "gen"]);
   const history = useHistory();
   const [pagination, setPagination] = useState({ page: 1, size: 10 });
   const [searchQuery, setSearchQuery] = useState("");
   const [data, setData] = useState({
      startDate: "",
      endDate: "",
      reservation: "",
      Pickups: [] as number[],
      documentType: 0,
      billNumber: "",
      year: "",
      series: "",
      nifId: "",
   });
   const getInvoices = useRequest((params) => reportApi.getInvoices({ ...pagination, searchQuery }, params));

   useEffect(() => {
      getInvoices.execute(data);
   }, [pagination, searchQuery]);

   const onChange = (e: string | number | number[] | undefined, key: string) => {
      setData({ ...data, [key]: e });
   };

   const onShowInvoiceClick = (item: any) => {
      switch (item.invoiceType.id) {
         case 4:
            history.push(`/receipts/${item.id}`);

            break;
         default:
            history.push(`/invoices/${item.id}`, item.reservationId);
            break;
      }
   };

   return (
      <div>
         <Loader loading={getInvoices.loading} />
         <TitleContainer title={t("translations:invoice")} />
         <AccountingForm
            onChange={onChange}
            submit={() => {
               getInvoices.execute(data);
            }}
            data={data}
         />
         <ContainerTopBar title={t("translations:invoice")}>
            <DataTable
               data={getInvoices.data?.invoices.data || []}
               total={getInvoices.data?.invoices.total}
               pagination
               onChange={(page, size) => setPagination({ page, size })}
               onSearching={(e) => setSearchQuery(e)}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn headerText={t("gen:coloborator")} render={(item) => item.salespeople} />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("translations:date")} subtitle={t("gen:expiration")} />,
                  }}
                  render={(item) => <TableItem title={formatDateTime(item.registration)} subtitle={formatDate(item.dueDate)} />}
               />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("translations:reservation")} subtitle={t("translations:client")} />,
                  }}
                  render={(item) => (
                     <TableItem
                        title={padId(item.reservationId, 6)}
                        subtitle={item.clientTaxId}
                        onClick={() => history.push(`/reservation/form/${item.reservationId}`)}
                        link
                     />
                  )}
               />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("translations:value")} subtitle={t("translations:discount")} />,
                  }}
                  render={(item) => <TableItem title={putDecimalPoints(item.amount)} subtitle={putDecimalPoints(item.discount)} />}
               />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("gen:paymentMethod")} subtitle={t("gen:account")} />,
                  }}
                  render={(item) => <TableItem title={item.paymentMethod.name} subtitle={item.accountName} />}
               />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("gen:serialName")} subtitle={t("gen:invoiceType")} />,
                  }}
                  render={(item) => <TableItem title={item.serialName} subtitle={item.invoiceType.name} />}
               />
               <TableColumn
                  headerOptions={{
                     render: () => <TableItem title={t("translations:invoice")} subtitle={" "} />,
                  }}
                  render={(item) =>
                     item.fullIdentifier && (
                        <TableButton
                           title={item.fullIdentifier}
                           onClick={() => {
                              onShowInvoiceClick(item);
                           }}
                        />
                     )
                  }
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Accounting;
