import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { FC } from "react";
import { NavChildItemStyles } from "./index.styles";
import { NavChildItemProps } from "./index.types";

const NavChildItem: FC<NavChildItemProps> = ({ children, pathName, onClick }) => {
   const classes = NavChildItemStyles();
   return (
      <ListItem
         button
         onClick={onClick}
         className={pathName === children.route || pathName.startsWith(children.route) ? classes.selectedSubMenu : classes.subMenu}
      >
         <ListItemIcon className={classes.activeIcon}>{children.icon}</ListItemIcon>
         <ListItemText primary={children.title} classes={{ primary: classes.text }} />
      </ListItem>
   );
};

export default NavChildItem;
