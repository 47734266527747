import { Grid } from "@material-ui/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { CommonButton, InputLabel, TextInput } from "../../../atoms";

interface TransactionProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>, key: string) => void;
  onClick: () => void;
}

const TransactionSearchForm: FC<TransactionProps> = ({ onChange, onClick }) => {
  const globalClasses = globalStyles();
  const { t } = useTranslation(["translations", "vehicles"]);
  return (
    <div className={globalClasses.marginLeft}>
      <Grid container spacing={3}>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <InputLabel label={t("vehicles:registration")} />
          <TextInput
            title=""
            disabled={false}
            type="string"
            helperText=""
            id="licensePlate"
            onChange={(event) => onChange(event, "registration")}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <InputLabel label={t("translations:deviceNo")} />
          <TextInput
            title=""
            disabled={false}
            type="number"
            helperText=""
            id="deviceNo"
            onChange={(event) => onChange(event, "deviceNo")}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <InputLabel label={t("translations:startDate")} />
          <TextInput
            title=""
            disabled={false}
            type="date"
            helperText=""
            id="startDate"
            onChange={(event) => onChange(event, "startDate")}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <InputLabel label={t("translations:endDate")} />
          <TextInput
            title=""
            disabled={false}
            type="date"
            helperText=""
            id="endDate"
            onChange={(event) => onChange(event, "endDate")}
          />
        </Grid>
        <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
          <CommonButton
            title={t("translations:search")}
            onClick={onClick}
            margin={25}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TransactionSearchForm;
