import { normalizeCurrency, replaceComma } from "../../../../utills/masks";
import ApiResponse from "../../../models/api-response.model";
import Category from "../../../models/category.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export const getCategories = async (page: number, size: number, search: string): Promise<ApiResponse<Pagination<Category[]>>> => {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.categories + `?page=${page}&size=${size}&search=${search}`,
         data: {},
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Category[]>> = await resp.data;
      if (resp.status !== 200) {
         throw new Error("data.message");
      }
      return data;
   } catch (error) {
      throw error;
   }
};

export const saveCategory = async (category: Category): Promise<ApiResponse<void>> => {
   let coverImageLink = "";
   let imageLink = "";
   if (category.image || category.coverImage) {
      const image = new FormData();
      const coverImage = new FormData();
      image.append("file", category.image);
      coverImage.append("file", category.coverImage);
      if (category.coverImage) {
         const res = await uploadFile(coverImage);
         coverImageLink = res.data.file;
      }
      if (category.image) {
         const resp = await uploadFile(image);
         imageLink = resp.data.file;
      }
   }
   const dataToUpload = {
      name: category.name,
      cta: category.cta,
      pricePerDay: replaceComma(category.pricePerDay),
      pricePerHour: replaceComma(category.pricePerHour),
      image: imageLink,
      coverImage: coverImageLink,
      tariffId: category.tariffId,
   };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.categories,
         data: dataToUpload,
      });

      if (resp.status !== 200) {
         console.log(resp.status);
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCategoryByID = async (id: number): Promise<ApiResponse<Category>> => {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.categories + `/${id}`,
         data: {},
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Category> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const updateCategory = async (category: Category, categoryId: number): Promise<ApiResponse<void>> => {
   let imageLink = category.imageLink || "";
   let coverImageLink = category.coverLink || "";
   if (category.image || category.coverImage) {
      const image = new FormData();
      const coverImage = new FormData();
      image.append("file", category.image);
      coverImage.append("file", category.coverImage);
      if (category.coverImage) {
         const res = await uploadFile(coverImage);
         coverImageLink = res.data.file;
      }
      if (category.image) {
         const resp = await uploadFile(image);
         imageLink = resp.data.file;
      }
   }
   const dataToUpload = {
      categoryId: categoryId,
      name: category.name,
      cta: category.cta,
      pricePerDay: replaceComma(category.pricePerDay),
      pricePerHour: replaceComma(category.pricePerHour),
      image: imageLink,
      coverImage: coverImageLink,
      tariffId: category.tariffId,
   };
   try {
      const resp = await request({
         method: "POST", //TODO change to POST after API refactor
         url: API_ROUTES.updateCategory.format(categoryId),
         data: dataToUpload,
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const updateCategoryStatus = async (id: number): Promise<ApiResponse<Category>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST, //TODO change to POST after API refactor
         url: API_ROUTES.changeCategoryStatus.format(id) + "?questionId=1",
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Category> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
