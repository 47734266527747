import { AddCircle } from "@material-ui/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules/index";
import { CarItem, CommonButton, StatusLabel, TableDropDown, TableItem } from "../../../components/atoms/index";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { useTranslation } from "react-i18next";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import { useRequest } from "../../../data/api/wrapper";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import moment from "moment";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import Reservation from "../../../data/models/reservation.model";

const ListReservations = () => {
   const globalClasses = globalStyles();

   const history = useHistory();

   const { t } = useTranslation(["translations", "gen"]);

   const reservations = useRequest(() => reservationApi.getAllReservations(pagination.page, pagination.size, searchRes));
   const contract = useRequest((params) => reservationApi.reservationContract(params));

   const [pagination, setPagination] = useState({ size: 10, page: 0 });
   const [searchRes, setSearchRes] = useState("");

   useEffect(() => {
      let unmounted = false;
      if (!unmounted) {
         reservations.execute();
      }
      return () => {
         unmounted = true;
      };
   }, [pagination, searchRes]);

   useEffect(() => {
      if (contract.data) {
         openContract();
      }
   }, [contract.data]);

   const getContract = (id: string) => {
      contract.execute(id);
   };

   const openContract = () => {
      if (contract.data?.pdfFileBase64) {
         // let file = new Blob([`${contract?.data?.pdfFileBase64}`], { type: "application/pdf" });
         // let fileURL = URL.createObjectURL(file);
         // window.open(fileURL);
         var w = window.open("about:blank");
         setTimeout(function () {
            //FireFox seems to require a setTimeout for this to work.
            if (w) {
               w.document.body.appendChild(
                  w.document.createElement("embed")
               ).src = `data:application/octet-stream;base64,${contract?.data?.pdfFileBase64}`;
            }
         }, 0);
      }
   };

   const getProof = (id: string) => {
      reservationApi
         .reservationProof(id)
         .then((res) => {
            let file = new Blob([res], { type: "application/pdf" });
            let fileURL = URL.createObjectURL(file);
            window.open(fileURL);
         })
         .catch((err) => {
            throw Error(err);
         });
   };

   const handleDropdownClick = (action: string, id: number) => {
      switch (action) {
         case t("translations:edit"):
            history.push(`/reservation/form/${id}`);
            break;
         case t("gen:contract"):
            history.push(`/contract/${id}`);
            break;
         case t("translations:proof"):
            getProof(id.toString());
            break;
         case t("translations:services"):
            history.push(`/reservation/services/${id}`);
            break;
         case t("translations:transactions"):
            history.push(`/reservation/transactions/${id}`);
            break;
         default:
            break;
      }
   };
   const getDropdownOptions = (item: Reservation): string[] => {
      if (item.status.id == 2 || item.status.id == 2) {
         return [t("translations:edit"), t("gen:contract"), t("translations:services"), t("translations:transactions")];
      }
      return [t("translations:edit"), t("translations:proof"), t("translations:services"), t("translations:transactions")];
   };
   return (
      <div>
         <TitleContainer title={t("translations:reservation")} />
         <CommonButton title={t("translations:new")} onClick={() => history.push(ROUTE_LINKS.NEW_RESERVATION)} icon={<AddCircle />} />
         <ContainerTopBar title={t("translations:listing")} activity={reservations.loading}>
            <DataTable
               data={reservations.data?.data || []}
               onSearching={(query) => setSearchRes(query)}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               pagination
               total={reservations.data?.total}
            >
               <TableColumn headerText="ID" render={(item) => `R: ${item.id}`} />
               <TableColumn headerText={t("translations:client")} render={(item) => item.client.name} />
               <TableColumn headerText={t("translations:vehicl")} render={(item) => <CarItem car={item.car} />} />
               <TableColumn
                  headerText={t("translations:categor")}
                  render={(item) => <TableItem title={item.category.name} subtitle={item.category.cta} />}
               />
               <TableColumn
                  headerText={t("translations:duration")}
                  render={(item) => (
                     <TableItem
                        title={moment(item.startDate).format("DD/MM/YYYY HH:mm")}
                        subtitle={moment(item.endDate).format("DD/MM/YYYY HH:mm")}
                     />
                  )}
               />
               <TableColumn
                  headerText={t("translations:store")}
                  render={(item) => <TableItem title={item.pickupOrganization.name} subtitle={item.returnOrganization.name} icon />}
               />
               <TableColumn
                  headerText={t("translations:status")}
                  render={(item) => (
                     <>
                        <StatusLabel status={item.status} />
                        <div className={globalClasses.marginSmall}>{item.hasUnpaidBill && <span className={globalClasses.redBox}>$</span>}</div>
                     </>
                  )}
               />
               <TableColumn
                  headerText={t("translations:details")}
                  render={(item) => <TableDropDown options={getDropdownOptions(item)} onClick={(action) => handleDropdownClick(action, item.id)} />}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default ListReservations;
