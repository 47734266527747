import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import { useEffect, useState } from "react";
import { AlertModal, LoginForm } from "../../components/organisms";
import { CopyrightText, Loader, Margin } from "../../components/atoms/index";
import useAuth from "../../data/context/authContext/AuthContext";
import logo from "../../assets/logo.png";
import { Card } from "@material-ui/core";

const Login = () => {
   const { login, loading, error, closeModal } = useAuth();
   const [showModal, setShowModal] = useState(false);
   useEffect(() => {
      if (error) {
         setShowModal(true);
      }
   }, [error]);

   const onSubmit = (values: any) => {
      login(values.email, values.password);
   };

   return (
      <Container component="main" maxWidth="xs">
         <Loader loading={loading} />
         <Margin />
         <AlertModal open={showModal} handleClose={() => setShowModal(false)} error={error} />
         <CssBaseline />
         <Box mt={3} />
         <img src={logo} height={"100%"} width={"100%"} alt="Company Logo" />
         <LoginForm onSubmit={onSubmit} />
         <Box mt={2}>
            <CopyrightText />
         </Box>
      </Container>
   );
};

export default Login;
