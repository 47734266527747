declare global {
   interface String {
      format(...args: any): string;
      formatMap(args: any): string;
   }
}

String.prototype.format = function (...args: any) {
   return this.replace(/{([0-9]+)}/g, function (match, index) {
      // check if the argument is there
      return typeof args[index] == "undefined" ? match : args[index];
   });
};

String.prototype.formatMap = function (args: any) {
   return this.replace(/:([a-zA-Z]+)(\/|)/g, function (match, index) {
      let key = match.replace(/[:\/]/g, "");
      // check if the argument is there
      return !!args[key] ? args[key] + "/" : match;
   });
};

export {};
