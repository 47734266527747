import { Box, Divider, Modal, Typography } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { VALUES } from "../../../../data/constants/Colors";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import StatusHistory from "../../../../data/models/status-history.modal";
import { CommonButton, StatusLabel } from "../../../atoms";
import DataTable from "../../../molecules/tables/datatable/DataTable";
import TableColumn from "../../../molecules/tables/table-column/TableColumn";

interface ModalProps {
   data: StatusHistory[];
   handleClose: () => void;
   open: boolean;
}

const Popup: FC<ModalProps> = ({ open, handleClose, data }) => {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["transaction", "translations"]);

   return (
      <div>
         <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={historyModalBoxSx}>
               <Box className={`${globalClasses.cardTitleCOntainer}`}>
                  <Typography className={globalClasses.cardTitle}>{t("transaction:history")}</Typography>

                  <Clear className={globalClasses.icon} onClick={handleClose} />
               </Box>

               <DataTable data={data} search={false}>
                  <TableColumn headerText={t("translations:user")} render={(item) => item.user.name} />

                  <TableColumn headerText={t("translations:date")} render={(item) => item.user.name} />

                  <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               </DataTable>

               <Divider className={globalClasses.dividerMargin} />

               <Box className={globalClasses.flexEnd}>
                  <CommonButton title={t("transaction:close")} onClick={handleClose} />
               </Box>
            </Box>
         </Modal>
      </div>
   );
};

export const historyModalBoxSx = {
   position: "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 450,
   bgcolor: "background.paper",
   borderRadius: VALUES.borderRadius,
   boxShadow: 24,
   padding: "20px 10px 20px 10px",
};

export default Popup;
