import React from "react";
import { Box, Typography } from "@material-ui/core";
import { announcementStyles } from "./index.styles";
import { useTranslation } from "react-i18next";

const AnnouncementUser = () => {
  const classes = announcementStyles();

  const { t } = useTranslation(["users"]);

  return (
    <Box className={classes.announcementBox}>
      <Typography variant="subtitle1">{t("users:important")}!</Typography>
      <ul>
        <li className={classes.listItem}>{t("users:existingUserCheck")};</li>
        <li className={classes.listItem}>{t("users:completeFormCheck")};</li>
      </ul>
    </Box>
  );
};

export default AnnouncementUser;
