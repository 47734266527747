import { Padding, TableCell } from "@material-ui/core";
import React from "react";

export interface TableHeaderOptions {
   align?: "inherit" | "left" | "center" | "right" | "justify";
   padding?: Padding;
   render?: (index: number) => string | React.ReactNode;
}

export interface TableColumnProps {
   align?: "inherit" | "left" | "center" | "right" | "justify";
   padding?: Padding;
   render: (item: any, index: number) => string | React.ReactNode;
   headerText?: string;
   headerOptions?: TableHeaderOptions;
}

const defaultOptions = {
   align: "inherit" as const,
   padding: "normal" as Padding,
   headerText: "",
   headerOptions: {
      align: "inherit" as const,
      padding: "normal" as Padding,
   } as TableHeaderOptions,
};

const TableColumn: React.FC<TableColumnProps> = (props) => {
   props = { ...defaultOptions, ...props };
   return <></>;
};
export default TableColumn;
