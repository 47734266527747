import { createRef, FC, MutableRefObject, useEffect, useRef } from "react";
import { Box, Divider, Grid, Typography } from "@material-ui/core";
import { AutoComplete, CommonButton, ImagePicker, InputLabel, TextArea, TextInput, DropdownItem, CurrencyInput } from "../../../atoms";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { newFineStyles } from "./index.styles";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import DataTable from "../../../molecules/tables/datatable/DataTable";
import TableColumn from "../../../molecules/tables/table-column/TableColumn";
import { useFormik } from "formik";
import { useValidation } from "../../../../utills/validation";
import { currency, normalizeCurrency, putDecimalPoints } from "../../../../utills/masks";
import moment from "moment";
import { encodeDate } from "../../../../utills/utills";
import { useEffectAfterMount } from "../../../../data/useEffectAfterMount";

interface FineProps {
   handleSubmit: (item: any) => void;
   data?: any;
   clearForm: MutableRefObject<() => void>;
}

const NewFineForm: FC<FineProps> = ({ handleSubmit, data, clearForm }) => {
   const globalClasses = globalStyles();

   const classes = newFineStyles();

   const { fineSchema } = useValidation();
   const fileRef = useRef() as MutableRefObject<HTMLInputElement>;

   const { t } = useTranslation(["translations", "gen", "vehicles", "organization"]);

   const { cars } = useDropdown();

   const formik = useFormik({
      initialValues: {
         carId: 0,
         amount: "",
         note: "",
         date: moment(new Date().getTime()).format("YYYY-MM-DD"),
         time: moment(new Date().getTime()).format("HH:mm"),
         local: "",
         file: File,
         fileNote: "",
         fileString: "",
      },
      validationSchema: fineSchema,
      onSubmit: (values) => {
         const date = encodeDate(values.date, values.time);
         let finalValue: any = {
            ...values,
            date: date,
            amount: values.amount.replace(",", "."),
         };
         delete finalValue["time"];
         delete finalValue["fileString"];
         handleSubmit(finalValue);
      },
   });
   useEffect(() => {
      if (data) {
         formik.values.carId = data.car.id;
         formik.values.amount = data.amount;
         formik.values.note = data.note;
         formik.values.date = moment(data.date).format("YYYY-MM-DD");
         formik.values.time = moment(data.date).format("HH:mm");
         formik.values.local = data.local;
      }
   }, [data]);

   useEffectAfterMount(() => {
      clearForm.current = clearFormik;
   }, []);
   const clearFormik = () => {
      formik.resetForm({});
      fileRef.current.value = "";
   };

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
               <InputLabel label={t("translations:vehicle")} />
               <AutoComplete
                  key="ad-12"
                  label={""}
                  placeholder={""}
                  options={cars}
                  defaultValue={cars.find((item) => item.id == data?.car.id)}
                  getOptionLabel={(item) => `${item?.licensePlate}  ${item?.name}`}
                  renderOption={(item) => <DropdownItem car={item} />}
                  onChange={(event, item) => {
                     formik.setFieldValue("carId", item?.id);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.carId}</Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
               <InputLabel label={t("gen:fineValue")} />
               <CurrencyInput
                  type={"text"}
                  id="amount"
                  value={formik.values.amount}
                  disabled={false}
                  title=""
                  error={formik.touched.amount && Boolean(formik.errors.amount)}
                  helperText={formik.touched.amount && formik.errors.amount}
                  onChange={(value) => formik.setFieldValue("amount", value)}
                  clear={clearForm}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
               <InputLabel label={t("translations:date")} />
               <TextInput
                  title=""
                  type="date"
                  value={formik.values.date}
                  id="date"
                  error={formik.touched.date && Boolean(formik.errors.date)}
                  helperText={formik.touched.date && formik.errors.date}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={6} sm={6} xs={12}>
               <InputLabel label={t("translations:hour")} />
               <TextInput
                  title=""
                  type="time"
                  value={formik.values.time}
                  id="time"
                  error={formik.touched.time && Boolean(formik.errors.time)}
                  helperText={formik.touched.time && formik.errors.time}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container className={classes.gridContainer}>
            <Grid item xl={4} lg={4} md={6} sm={6} xs={12}>
               <InputLabel label={t("organization:local")} />
               <TextInput
                  title=""
                  type="text"
                  value={formik.values.local}
                  id="local"
                  error={formik.touched.local && Boolean(formik.errors.local)}
                  helperText={formik.touched.local && formik.errors.local}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container className={classes.gridContainer}>
            <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>
               <InputLabel label={t("translations:observation")} />
               <TextArea onChange={formik.handleChange} id={"note"} value={formik.values.note} />
               <Typography className={globalClasses.errorText}>{formik.errors.note}</Typography>
            </Grid>
         </Grid>
         <Grid container className={classes.gridContainer}>
            <Grid item xl={6} lg={6} md={8} sm={6} xs={12}>
               <Typography className={globalClasses.titleText}>{t("translations:attachment")}</Typography>
               <ImagePicker
                  imageRef={fileRef}
                  onChange={(e) => {
                     formik.setFieldValue("file", e.target.files[0]);
                     formik.setFieldValue("fileString", URL.createObjectURL(e.target.files[0]));
                  }}
               />
            </Grid>
         </Grid>
         <Grid container>
            <Grid item xl={6} lg={6} md={8} sm={6} xs={12}>
               <TextInput
                  title={t("translations:description")}
                  type="text"
                  value={formik.values.fileNote}
                  id="fileNote"
                  error={formik.touched.fileNote && Boolean(formik.errors.fileNote)}
                  helperText={formik.touched.fileNote && formik.errors.fileNote}
                  disabled={false}
                  onChange={formik.handleChange}
               />
            </Grid>
         </Grid>
         <Grid container className={classes.gridContainer}>
            <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
               <DataTable data={data?.attachements || []} search={false}>
                  <TableColumn
                     headerText={t("gen:file")}
                     render={(item) => (
                        <a className={globalClasses.greyBox} href={`${item.name}`}>
                           {t("gen:download")}
                        </a>
                     )}
                  />
                  <TableColumn headerText={t("gen:size")} render={(item) => `${item.size} kB`} />
                  <TableColumn headerText={t("translations:description")} render={(item) => ""} />
                  <TableColumn
                     headerText={t("gen:sendBy")}
                     render={(item) => (
                        <>
                           <div className={globalClasses.tableText}>{item.createdBy.name}</div>
                           <div className={globalClasses.tableSmallText}>{moment(item.registration).format("DD/MM/YYYY HH:mm")}</div>
                        </>
                     )}
                  />
               </DataTable>
            </Grid>
         </Grid>
         <Divider className={globalClasses.dividerMargin} />
         <CommonButton title="Salvar" onClick={formik.handleSubmit} />
      </Box>
   );
};

export default NewFineForm;
