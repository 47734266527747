import { Backdrop, Box, Fade, Grow, Modal, Typography } from "@material-ui/core";
import { FC, ReactElement, ReactNode } from "react";
import { CommonButton } from "..";
import { COLORS } from "../../../data/constants/Colors";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { alertStyles, historyModalBoxSx } from "./index.styles";

export interface AlertDialogProps {
   show: boolean;
   icon?: ReactElement;
   title: string;
   message: string;
   primaryButtonText?: string;
   primaryButtonHandle?: () => void;
   secondaryButtonText?: string;
   secondaryButtonHandle?: () => void;
}

const AlertDialog: FC<AlertDialogProps> = (props) => {
   const { show, icon, title, message, primaryButtonText, primaryButtonHandle, secondaryButtonHandle, secondaryButtonText } = props;

   const doNothing = () => {};

   const globalClasses = globalStyles();
   const classes = alertStyles();

   return (
      <div>
         <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={show}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
               timeout: 500,
            }}
         >
            <Fade in={show}>
               <Box sx={historyModalBoxSx}>
                  <Box className={classes.container}>
                     {icon}
                     <Typography className={globalClasses.title}>{title}</Typography>
                     <Typography className={`${globalClasses.textAlign}`}>{message}</Typography>
                     <Box className={globalClasses.flexRow}>
                        <CommonButton
                           color={COLORS.greyText}
                           title={primaryButtonText ?? "OK"}
                           onClick={primaryButtonHandle ?? doNothing}
                        ></CommonButton>
                        <span className={globalClasses.marginLeft} />
                        {secondaryButtonText && (
                           <CommonButton title={secondaryButtonText ?? "OK"} onClick={secondaryButtonHandle ?? doNothing}></CommonButton>
                        )}
                     </Box>
                  </Box>
               </Box>
            </Fade>
         </Modal>
      </div>
   );
};

export default AlertDialog;
