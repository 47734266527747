import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "../../../atoms";
import { TableColumnProps } from "../table-column/TableColumn";
import { COLORS } from "../../../../data/constants/Colors";

interface DataTableProps {
   data: any[];
   children: React.ReactElement<TableColumnProps>[] | React.ReactElement<TableColumnProps>;
   pagination?: boolean;
   search?: boolean;
   shadedHead?: boolean;
   total?: number;
   currentPage?: number;
   onChange?: (page: number, rowsPerPage: number) => void;
   onSearching?: (query: string) => void;
   stripedRows?: boolean;
}

const defaultOptions = {
   pagination: false,
   search: true,
};

const DataTable: React.FC<DataTableProps> = (props) => {
   props = { ...defaultOptions, ...props };

   const { t } = useTranslation("translations");
   const [page, setPage] = React.useState(0);
   const [rowsPerPage, setRowsPerPage] = React.useState(10);
   const isSearching = React.useRef(false);

   const total = props.total || props.data.length;

   let children = (Array.isArray(props.children) ? props.children : [props.children]) as React.ReactElement<TableColumnProps>[];

   const headers = children.map((child, index) => {
      let header = child.props.headerOptions?.render == undefined ? child.props.headerText : child.props.headerOptions?.render(index);
      return (
         <TableCell key={index} align={child.props.headerOptions?.align} padding={child.props.headerOptions?.padding}>
            {header}
         </TableCell>
      );
   });

   const rows = props.data.map((item, itemIndex) => {
      return (
         <TableRow key={itemIndex} style={{ backgroundColor: props.stripedRows ? (itemIndex % 2 === 0 ? COLORS.greyBorder : "") : "" }}>
            {children.map((child, index) => {
               return (
                  <TableCell key={index} align={child.props.align} padding={child.props.padding}>
                     {child.props.render(item, index)}
                  </TableCell>
               );
            })}
         </TableRow>
      );
   });

   const onChange = () => {
      props.onChange?.call(0, page + 1, rowsPerPage);
   };

   const onSearching = (event: React.ChangeEvent<HTMLInputElement>) => {
      isSearching.current = true;
      setPage(0);
      props.onSearching?.call(0, event.target.value);
      isSearching.current = false;
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   useEffect(() => {
      if (!isSearching.current) {
         onChange();
      }
   }, [page, rowsPerPage]);

   return (
      <Box>
         {props.search && (
            // <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
            <TextInput helperText="" id="" title={t("search")} disabled={false} type="text" onChange={onSearching} />
         )}
         <TableContainer>
            <Table size="small">
               <TableHead style={{ backgroundColor: props.shadedHead ? COLORS.greyBorder : "" }}>
                  <TableRow>{headers}</TableRow>
               </TableHead>
               <TableBody>{rows}</TableBody>
            </Table>
         </TableContainer>
         {props.pagination && (
            <TablePagination
               rowsPerPageOptions={[10, 25, 50, 100]}
               component="div"
               count={total}
               rowsPerPage={rowsPerPage}
               page={page}
               onPageChange={handleChangePage}
               onRowsPerPageChange={handleChangeRowsPerPage}
            />
         )}
      </Box>
   );
};

export default DataTable;
