import {
   Edit,
   Menu,
   Loop,
   People,
   AccountBalance,
   Store,
   DriveEta,
   Build,
   LocalAtm,
   Euro,
   Toll,
   Assessment,
   LiveHelp,
   ViewCarousel,
   MenuBook,
   AddCircle,
   List,
   AccountCircle,
   ArrowRight,
   ConfirmationNumber,
   FormatListBulleted,
   ListAlt,
   CreditCard,
   Send,
   AllInclusive,
} from "@material-ui/icons";
import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { COLORS } from "../../data/constants/Colors";
import useAuth from "../../data/context/authContext/AuthContext";
import useSetting from "../../data/context/setting-context/SettingContext";
import { AppBanners, Dashboard, Definitions, FAQ } from "../../pages";
import { ROUTE_LINKS } from "../routeLinks";

const iconStyle = {
   color: COLORS.iconInactive,
   fontSize: 15,
   padding: 0,
};

export interface ROUTES_TYPES {
   route: string;
   icon: any;
   component: any;
   link: string;
   children: { title: string; icon: ReactNode; route: string }[] | null;
   active: Boolean;
}

export const useSidebarRoutes = () => {
   const { t, i18n } = useTranslation(["translations"]);
   const { user } = useAuth();
   const { counter } = useSetting();

   const [sidebarRoutes, setSidebarRoutes] = useState([] as ROUTES_TYPES[]);

   const history = useHistory();

   useEffect(() => {
      setRoutes();
   }, [counter]);

   useEffect(() => {
      setRoutes();
   }, [i18n.language]);

   const setRoutes = () => {
      if (counter == 1) {
         setSidebarRoutes([
            {
               route: t("translations:dashboard"),
               icon: <Menu style={iconStyle} />,
               link: "/dashboard",
               component: Dashboard,
               children: null,
               active: false,
            },
            {
               route: t("translations:reservation"),
               icon: <Edit style={iconStyle} />,
               link: "/reservation",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.NEW_RESERVATION,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: ROUTE_LINKS.LIST_ALL_RESERVATIONS,
                  },
                  {
                     title: t("translations:tickets"),
                     icon: <ConfirmationNumber style={iconStyle} />,
                     route: ROUTE_LINKS.RESERVATION_TICKETS,
                  },
               ],
               active: false,
            },
            {
               route: t("translations:wayBills"),
               icon: <Loop style={iconStyle} />,
               link: "/transportguides",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_TRANSPORT_GUIDE,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: ROUTE_LINKS.LIST_ALL_TRANSPORT_GUIDES,
                  },
               ],
               active: false,
            },
            {
               route: t("translations:users"),
               icon: <People style={iconStyle} />,
               link: "/users",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.CREATE_USER,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/users/list",
                  },
                  {
                     title: t("translations:administrators"),
                     icon: <AccountCircle style={iconStyle} />,
                     route: "/administrators",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:organizations"),
               icon: <AccountBalance style={iconStyle} />,
               link: "/organizations",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_ORGANIZATION,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/organizations",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:counters"),
               icon: <Store style={iconStyle} />,
               link: "/counters",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_COUNTER,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/counters/listing",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:maintenance"),
               icon: <Build style={iconStyle} />,
               link: "/maintenance",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: "/maintenance/form",
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/maintenance",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:vehicleManagement"),
               icon: <DriveEta style={iconStyle} />,
               link: "/vehiclemanagment",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:vehicles"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/vehiclemanagment/vehicles",
                  },
                  {
                     title: t("translations:fines"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/vehiclemanagment/fines",
                  },
                  {
                     title: t("translations:categories"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/vehiclemanagment/categories",
                  },
                  {
                     title: t("translations:brands"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/vehiclemanagment/brands",
                  },
                  {
                     title: t("translations:characteristics"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/vehiclemanagment/characteristics",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:teriffs"),
               icon: <LocalAtm style={iconStyle} />,
               link: "/terrifs",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: "/tarrifs/form",
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/tarrifs/listall",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:discounts"),
               icon: <Euro style={iconStyle} />,
               link: "/discounts",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:campaign"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/discounts/campaign",
                  },
                  {
                     title: t("translations:quantity"),
                     icon: <ArrowRight style={iconStyle} />,
                     route: "/discounts/byquantity",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:tolls"),
               icon: <Toll style={iconStyle} />,
               link: "/tolls",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:noBonds"),
                     icon: <AllInclusive style={iconStyle} />,
                     route: "/tolls/nobonds",
                  },
                  {
                     title: t("translations:notBilled"),
                     icon: <CreditCard style={iconStyle} />,
                     route: "/tolls/notbilled",
                  },
                  {
                     title: t("translations:process"),
                     icon: <Send style={iconStyle} />,
                     route: "/tolls/process",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:reports"),
               icon: <Assessment style={iconStyle} />,
               link: "/reports",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:divergentCategories"),
                     icon: <ListAlt style={iconStyle} />,
                     route: ROUTE_LINKS.REPORTS_DIVERGENT_CATEGORIES,
                  },
                  {
                     title: t("translations:pendingFinancials"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/pending-financials",
                  },
                  {
                     title: t("translations:maintenanceCosts"),
                     icon: <FormatListBulleted style={iconStyle} />,
                     route: "/reports/maintenance-cost",
                  },
                  {
                     title: t("translations:tollBooking"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/toll-bookings",
                  },
                  {
                     title: t("translations:commissions"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/commissions",
                  },
                  {
                     title: t("translations:accounting"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/accounting",
                  },
                  {
                     title: t("translations:ungeneratedInvoices"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/ungenerated-invoices",
                  },
                  {
                     title: t("translations:commissionAdjustments"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/commission-adjustment",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:faqs"),
               icon: <LiveHelp style={iconStyle} />,
               link: "/faqs",
               component: FAQ,
               children: null,
               active: false,
            },
            {
               route: t("translations:appBanners"),
               icon: <ViewCarousel style={iconStyle} />,
               link: "/app-banners",
               component: AppBanners,
               children: null,
               active: false,
            },
            {
               route: t("translations:definitions"),
               icon: <MenuBook style={iconStyle} />,
               link: "/definitions",
               component: Definitions,
               children: null,
               active: false,
            },
         ]);
      } else {
         setSidebarRoutes([
            {
               route: t("translations:dashboard"),
               icon: <Menu style={iconStyle} />,
               link: "/dashboard",
               component: Dashboard,
               children: null,
               active: false,
            },
            {
               route: t("translations:reservation"),
               icon: <Edit style={iconStyle} />,
               link: "/reservation",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.NEW_RESERVATION,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: ROUTE_LINKS.LIST_ALL_RESERVATIONS,
                  },
                  {
                     title: t("translations:tickets"),
                     icon: <ConfirmationNumber style={iconStyle} />,
                     route: ROUTE_LINKS.RESERVATION_TICKETS,
                  },
               ],
               active: false,
            },
            {
               route: t("translations:wayBills"),
               icon: <Loop style={iconStyle} />,
               link: "/transportguides",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_TRANSPORT_GUIDE,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: ROUTE_LINKS.LIST_ALL_TRANSPORT_GUIDES,
                  },
               ],
               active: false,
            },
            {
               route: t("translations:users"),
               icon: <People style={iconStyle} />,
               link: "/users",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.CREATE_USER,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/users/list",
                  },
                  {
                     title: t("translations:administrators"),
                     icon: <AccountCircle style={iconStyle} />,
                     route: "/administrators",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:organizations"),
               icon: <AccountBalance style={iconStyle} />,
               link: "/organizations",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_ORGANIZATION,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/organizations",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:counters"),
               icon: <Store style={iconStyle} />,
               link: "/counters",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: ROUTE_LINKS.ADD_NEW_COUNTER,
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/counters/listing",
                  },
               ],
               active: false,
            },
            {
               route: t("translations:maintenance"),
               icon: <Build style={iconStyle} />,
               link: "/maintenance",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:new"),
                     icon: <AddCircle style={iconStyle} />,
                     route: "/maintenance/form",
                  },
                  {
                     title: t("translations:listAll"),
                     icon: <List style={iconStyle} />,
                     route: "/maintenance",
                  },
                  // { title: "Tickets", icon: <Label style={iconStyle} /> },
               ],
               active: false,
            },
            {
               route: t("translations:reports"),
               icon: <Assessment style={iconStyle} />,
               link: "/reports",
               component: Dashboard,
               children: [
                  {
                     title: t("translations:divergentCategories"),
                     icon: <ListAlt style={iconStyle} />,
                     route: ROUTE_LINKS.REPORTS_DIVERGENT_CATEGORIES,
                  },
                  {
                     title: t("translations:pendingFinancials"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/pending-financials",
                  },
                  {
                     title: t("translations:maintenanceCosts"),
                     icon: <FormatListBulleted style={iconStyle} />,
                     route: "/reports/maintenance-cost",
                  },
                  {
                     title: t("translations:tollBooking"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/toll-bookings",
                  },
                  {
                     title: t("translations:commissions"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/commissions",
                  },
                  {
                     title: t("translations:accounting"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/accounting",
                  },
                  {
                     title: t("translations:ungeneratedInvoices"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/ungenerated-invoices",
                  },
                  {
                     title: t("translations:commissionAdjustments"),
                     icon: <ListAlt style={iconStyle} />,
                     route: "/reports/commission-adjustment",
                  },
               ],
               active: false,
            },
         ]);
      }
   };

   return { sidebarRoutes, setSidebarRoutes };
};
