import React, { useEffect, useState } from "react";
import {
   Autocomplete as AutocompleteMUI,
   AutocompleteChangeDetails,
   AutocompleteChangeReason,
   AutocompleteRenderInputParams,
   AutocompleteRenderOptionState,
} from "@material-ui/lab";
import { TextField, Theme } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { autocompleteStyles } from "./index.styles";
import { COLORS, VALUES } from "../../../data/constants/Colors";
import { withStyles } from "@material-ui/styles";

//Change the type of Value looking to other property, in this case 'Multiple'
type Value<T, Multiple> = Multiple extends undefined | false ? T | null : Array<T>;

interface AutocompleteProps<T, Multiple extends undefined | boolean> {
   label: string;
   placeholder: string;
   multiple?: Multiple;
   loading?: boolean;
   options: T[];
   groupBy?: (option: T) => string;
   getOptionLabel?: (option: T) => string;
   limitTags?: number;
   defaultValue?: Value<T, Multiple>;
   disabled?: boolean;
   renderOption?: (option: T, state: AutocompleteRenderOptionState) => React.ReactNode;
   getOptionDisabled?: (option: T) => boolean;
   /**
    * Callback fired when the value changes.
    *
    * @param {object} event The event source of the callback.
    * @param {T|T[]} value The new value of the component.
    * @param {string} reason One of "create-option", "select-option", "remove-option", "blur" or "clear".
    */
   onChange?: (
      event: React.ChangeEvent<{}>,
      value: Value<T, Multiple>,
      reason: AutocompleteChangeReason,
      details?: AutocompleteChangeDetails<T>
   ) => void;

   onInputChange?: (text: string) => void;
}

const defaultOptions = {
   loading: false,
};

const CustomTextField = withStyles((theme: Theme) => ({
   root: {
      "& .MuiOutlinedInput-root": {
         "& fieldset": {
            borderColor: COLORS.greyBorder,
            transition: "border-color 0.3s",
         },
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: COLORS.primary,
      },
   },
}))(TextField);

const Autocomplete = <T, Multiple extends boolean | undefined = undefined>(props: AutocompleteProps<T, Multiple>) => {
   props = { ...defaultOptions, ...props };
   const classes = autocompleteStyles();
   const [val, setVal] = useState<Value<T, Multiple> | undefined>();
   useEffect(() => {
      if (props.defaultValue !== val) {
         setVal(props.defaultValue);
      }
   }, [props.defaultValue]);

   const renderInput = (params: AutocompleteRenderInputParams) => {
      return (
         <CustomTextField
            {...params}
            onChange={(event) => {
               props.onInputChange?.call(0, event.target.value);
            }}
            // style={{ marginTop: VALUES.margin_small }}
            // InputLabelProps={{ style: { height: 0 } }}
            variant="outlined"
            label={props.label}
            size="small"
            placeholder={props.placeholder}
            autoComplete="new-password"
            InputProps={{
               ...params.InputProps,
               endAdornment: (
                  <>
                     {props.loading ? <CircularProgress color="inherit" size={15} /> : null}
                     {params.InputProps.endAdornment}
                  </>
               ),
            }}
         />
      );
   };

   const aParams = {
      autoHighlight: true,
      filterSelectedOptions: true,
      multiple: props.multiple,
      limitTags: props.limitTags,
      options: props.options,
      groupBy: props.groupBy,
      getOptionLabel: props.getOptionLabel,
      renderOption: props.renderOption,
      getOptionDisabled: props.getOptionDisabled,
      onChange: props.onChange,
      // classes: classes,
   };

   return (
      <>
         {val == undefined ? (
            <AutocompleteMUI
               key="auto-1"
               {...aParams}
               size="small"
               disabled={props.disabled}
               renderInput={(params) => renderInput(params)}
               getOptionSelected={(option, value) => option === value}
               style={{ marginTop: VALUES.margin_small }}
            />
         ) : (
            <AutocompleteMUI
               key="auto-2"
               {...aParams}
               size="small"
               disabled={props.disabled}
               renderInput={(params) => renderInput(params)}
               getOptionSelected={(option, value) => option === value}
               value={val}
               style={{ marginTop: VALUES.margin_small }}
            />
         )}
      </>
   );
};

export default Autocomplete;
