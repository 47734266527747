import moment from "moment";
import { array } from "prop-types";
import { NewDiscount } from "../../../../components/organisms/forms/editDiscountForm/index.types";
import { NewCampaignModal } from "../../../../components/organisms/forms/new-campaign/index.types";
import { normalizeCurrency, putDecimalPoints, replaceComma } from "../../../../utills/masks";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import DiscountBillable from "../../../models/billable-discount.modal";
import DiscountCampaigns from "../../../models/discount-campaign.modal";
import { Item } from "../../../models/discount-item.model";
import { ItemRelatedToDiscount } from "../../../models/item-discount-response.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getBillableDiscounts(): Promise<ApiResponse<Pagination<DiscountBillable[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.discountsBillable,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<DiscountBillable[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getAllItemsOfDiscount(itemId: number | string): Promise<ApiResponse<ItemRelatedToDiscount<Item[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.billableDiscounts.format(itemId),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      let data: ApiResponse<ItemRelatedToDiscount<Item[]>> = resp.data;
      data.data.items = data.data.items.sort((a, b) => a.category.tariff.name.localeCompare(b.category.tariff.name));
      return data;
   } catch (error) {
      throw error;
   }
}

export async function deleteBillableDiscount(itemId: number | string, discountId: number): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.DELETE,
         url: API_ROUTES.deleteDiscount.format(itemId, discountId),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function addNewBillableItem(itemId: string, item: any): Promise<ApiResponse<void>> {
   const dataToUpload = { ...item, itemId, discount: replaceComma(item.discount) };

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.billableDiscounts.format(itemId),
         data: dataToUpload,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getDiscountCampaign(page: number, size: number, search: string): Promise<ApiResponse<Pagination<DiscountCampaigns[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: API_ROUTES.discountCampaign + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<DiscountCampaigns[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDiscountCampaignById(id: number | string): Promise<ApiResponse<DiscountCampaigns>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.discountCampaign + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      let data: ApiResponse<DiscountCampaigns> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getDiscountsByReservation(reservationId: string, type?: number): Promise<ApiResponse<DiscountBillable[]>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.getReservationDiscounts.format(reservationId) + `?typeId=${type}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<DiscountBillable[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function requestDiscount(payload: any): Promise<ApiResponse<void>> {
   let dataToupload: any = { ...payload, amount: payload.discount, itemId: payload.itemId.filter((el: any) => el != 0) };
   delete dataToupload.discount;
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.requestDiscount,
         data: dataToupload,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      console.log(error);
      throw error;
   }
}

export async function changeCampaignStatus(id: number): Promise<ApiResponse<DiscountCampaigns>> {
   try {
      const resp = await request({
         method: "POST",
         url: API_ROUTES.changeCampaignStatus.format(id),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<DiscountCampaigns> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewCampaign(item: any): Promise<ApiResponse<void>> {
   const campaignToAdd = {
      ...item,
      startDate: encodeDate(item.startDate, moment(new Date().getTime()).format("HH:mm:ss")),
      endDate: encodeDate(item.endDate, "23:59:59"),
      discount: replaceComma(item.discount),
   };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.discountCampaign,
         data: campaignToAdd,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function updateCompaign(item: NewCampaignModal, id: number | string): Promise<ApiResponse<void>> {
   const startDate = new Date(item.startDate).toISOString();
   const endDate = new Date(item.endDate).toISOString();
   const campaignToAdd = { ...item, startDate, endDate };
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.discountCampaign + `/${id}`,
         data: campaignToAdd,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
