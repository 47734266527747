import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CommonButton, StatusLabel, TableDropDown, Toast } from "../../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../../components/molecules";
import { ROUTE_LINKS } from "../../../../routes/routeLinks";
import * as categoryApi from "../../../../data/api/requests/categories/index";
import { useRequest } from "../../../../data/api/wrapper";
import DataTable from "../../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../../components/molecules/tables/table-column/TableColumn";
import { currencyNumber } from "../../../../utills/masks";
import { AddCircle } from "@material-ui/icons";

const Categories = () => {
   const history = useHistory();
   const { t } = useTranslation(["vehicles", "translations"]);
   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchString, setSearchString] = useState("");
   const category = useRequest(() => categoryApi.getCategories(pagination.page, pagination.size, searchString));
   const changeCategoryStatus = useRequest((params) => categoryApi.updateCategoryStatus(params));

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         category.execute();
      }
      return () => {
         unsubscribe = true;
      };
   }, [pagination, searchString]);

   useEffect(() => {
      if (changeCategoryStatus.data) {
         category.setData((prev) => {
            const index = prev?.data.findIndex((el) => el.id == changeCategoryStatus?.data?.id);
            if (prev) {
               prev.data[index!!] = changeCategoryStatus.data!!;
            }
            return prev;
         });
      }
   }, [changeCategoryStatus.data]);

   const _changeStatus = (id: number) => {
      changeCategoryStatus.execute(id);
   };

   const handleDropdownClick = (action: string, id: number) => {
      switch (action) {
         case t("translations:edit"):
            history.push(ROUTE_LINKS.NEW_VEHICLE_CATREGORY, id);
            break;
         case t("translations:changeStatus"):
            _changeStatus(id);
            break;
         default:
            break;
      }
   };
   return (
      <div>
         {changeCategoryStatus.show.toast && (
            <Toast show={changeCategoryStatus.show.toast} message={changeCategoryStatus.message} handleClose={() => changeCategoryStatus.close()} />
         )}

         <TitleContainer title={t("vehicles:vehicleCategories")} />
         <CommonButton
            icon={<AddCircle />}
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.NEW_VEHICLE_CATREGORY);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={category.loading}>
            <DataTable
               data={category.data?.data || []}
               pagination
               onSearching={(query) => setSearchString(query)}
               onChange={(page, rowsPerPage) => setPagination({ page: page, size: rowsPerPage })}
               total={category.data?.total}
            >
               <TableColumn headerText="ID" render={(item) => item.id} />
               <TableColumn headerText={t("vehicles:category")} render={(item) => item.name} />
               <TableColumn headerText={"CTA"} render={(item) => item.cta} />
               <TableColumn headerText={t("vehicles:daily")} render={(item) => `€${currencyNumber(item.pricePerDay)}`} />
               <TableColumn headerText={t("translations:teriff")} render={(item) => item.tariff.name} />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:action")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("translations:changeStatus")]}
                        onClick={(action) => {
                           handleDropdownClick(action, item.id);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default Categories;
