import { FC } from "react";
import { buttonStyles } from "./index.styles";
import { ButtonProps } from "./index.types";

const IconButton: FC<ButtonProps> = ({ onClick, icon, color }) => {
  const classes = buttonStyles({ color });
  return (
    <div style={{ marginTop: 10 }}>
      <span className={classes.container} onClick={onClick}>
        {icon}
      </span>
    </div>
  );
};

export default IconButton;
