import { AnyRecord } from "dns";
import ApiResponse from "../../../models/api-response.model";
import Banner from "../../../models/banner.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export const getBanners = async (
  page: number,
  size: number,
  search: string
): Promise<ApiResponse<Pagination<Banner[]>>> => {
  console.log(`?page=${page}&size=${size}&search=${search}`);
  try {
    const resp = await request({
      method: "GET",
      url: API_ROUTES.banners + `?page=${page}&size=${size}&search=${search}`,
      data: {},
    });

    if (resp.status !== 200) {
      throw new Error("Something went wrong");
    }
    const data: ApiResponse<Pagination<Banner[]>> = resp.data;
    console.log(data);
    return data;
  } catch (error) {
    throw error;
  }
};

export const saveBanner = async (banner: any): Promise<ApiResponse<void>> => {
  let downlink = "";
  if (banner.file?.size) {
    const image = new FormData();
    image.append("file", banner.file);
    const res = await uploadFile(image);
    downlink = res.data.file;
  }
  const dataToUpload = {
    name: banner.name,
    file: downlink,
  };
  try {
    const resp = await request({
      method: API_METHODS.POST,
      url: API_ROUTES.banners,
      data: dataToUpload,
    });

    if (resp.status !== 200) {
      throw new Error("Something went wrong");
    }
    const data: ApiResponse<void> = await resp.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const getBannerByID = async (
  id: string
): Promise<ApiResponse<Banner>> => {
  try {
    const resp = await request({
      method: "GET",
      url: API_ROUTES.banners + `/${id}`,
      data: {},
    });

    if (resp.status !== 200) {
      throw new Error("Something went wrong");
    }
    const data: ApiResponse<Banner> = await resp.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteBannerByID = async (
  id: number
): Promise<ApiResponse<void>> => {
  try {
    const resp = await request({
      method: API_METHODS.DELETE,
      url: API_ROUTES.banners + `/${id}`,
      data: {},
    });

    if (resp.status !== 200) {
      throw new Error("Something went wrong");
    }
    const data: ApiResponse<void> = await resp.data;
    return data;
  } catch (error) {
    throw error;
  }
};

export const updateBanner = async (
  banner: any,
  id: string
): Promise<ApiResponse<void>> => {
  let downlink = banner.fileString;
  if (banner.file?.size) {
    const image = new FormData();
    image.append("file", banner.file);
    const res = await uploadFile(image);
    downlink = res.data.file;
  }
  const dataToUpload = {
    name: banner.name,
    file: downlink,
  };
  try {
    const resp = await request({
      method: "POST",
      url: API_ROUTES.banners + `/${id}`,
      data: dataToUpload,
    });

    if (resp.status !== 200) {
      throw new Error("Something went wrong");
    }
    const data: ApiResponse<void> = await resp.data;
    return data;
  } catch (error) {
    throw error;
  }
};
