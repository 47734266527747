import { Box, Grid } from "@material-ui/core";
import React, { FC } from "react";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { AutoComplete, CommonButton, InputLabel, TextInput } from "../../../atoms";
import { useTranslation } from "react-i18next";
import { TollFormProps } from "./index.types";

const dropdownOptions = [
   { name: "Regular (Reserva)", id: 1 },
   { name: "Guia de transporte", id: 2 },
];

const TollBookingSearchForm: FC<TollFormProps> = ({ onChange, onClick, value, loading }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations"]);
   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:type")} />
               <AutoComplete
                  placeholder=""
                  label=""
                  multiple
                  options={dropdownOptions}
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     const idList: number[] = item.reduce((newArray, el) => {
                        newArray.push(el.id);
                        return newArray;
                     }, [] as number[]);
                     onChange("types", idList!!);
                  }}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput
                  id="startDate"
                  helperText=""
                  title=""
                  type="date"
                  disabled={false}
                  onChange={(event) => {
                     onChange("startDate", event.target.value);
                  }}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput
                  id="endDate"
                  helperText=""
                  title=""
                  type="date"
                  disabled={false}
                  onChange={(event) => onChange("endDate", event.target.value)}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={onClick} loading={loading} disable={loading} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default TollBookingSearchForm;
