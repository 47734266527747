import React, { FC } from "react";
import { Box, Typography } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
interface TextProps {
  title: string;
  value: string | number;
}
const TextPair: FC<TextProps> = ({ title, value }) => {
  const globalClasses = globalStyles();
  return (
    <Box className={globalClasses.margin}>
      <Typography className={globalClasses.cardTitle}>{title}:</Typography>
      <Typography className={globalClasses.cardValue}>{value}</Typography>
    </Box>
  );
};

export default TextPair;
