import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS, VALUES } from "../../../data/constants/Colors";

export const autocompleteStyles = makeStyles((theme: Theme) =>
   createStyles({
      option: {
         fontSize: 13,
         "& > span": {
            // marginRight: 10,
            fontSize: 12,
         },
      },
      inputRoot: {
         borderRadius: 4,
         fontSize: 12,
         color: COLORS.greyText,
         height: 30,
         marginTop: 0,

         ".MuiInputBase-input": {
            // height: 30,
         },
         "& .MuiOutlinedInput-notchedOutline": {
            borderColor: COLORS.greyBorder,
            outerHeight: "100%",
         },
         "&:hover .MuiOutlinedInput-notchedOutline": {
            borderWidth: "1px",
            borderColor: theme.palette.primary.main,
         },
         "&.Mui-focused .MuiOutlinedInput-notchedOutline": {},
      },
      root: {
         marginTop: 11,
         height: 30,
         fontSize: 10,
         alignItems: "center",
         justifyContent: "center",
         paddingBottom: 1,
         // background: "red",

         "& label + .MuiInput-formControl": {
            marginTop: 0,
         },
      },
   })
);
