import { Grid, Typography } from "@material-ui/core";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { useValidation } from "../../../../utills/validation";
import {
  AutoComplete,
  InputLabel,
  TextArea,
  CommonButton,
} from "../../../atoms";

interface CrmFormProps {
  submit: (values: any) => void;
}

const CrmForm: FC<CrmFormProps> = ({ submit }) => {
  const { subjectTypes, receptivities, modalTypes } = useDropdown();
  const { t } = useTranslation(["translations", "crm"]);
  const { crmSchema } = useValidation();
  const globalClasses = globalStyles();

  const formik = useFormik({
    initialValues: {
      comment: "",
      subjectTypeId: 0,
      modalTypeId: 0,
      receptivityId: 0,
    },
    validationSchema: crmSchema,
    onSubmit: (values, { resetForm }) => {
      submit(values);
      resetForm({});
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputLabel label={t("translations:subject")} />
          <AutoComplete
            options={subjectTypes}
            label=""
            placeholder=""
            renderOption={(item) => item.name}
            getOptionLabel={(item) => item.name}
            onChange={(_, item) =>
              formik.setFieldValue("subjectTypeId", item?.id)
            }
          />
          <Typography className={globalClasses.errorText}>
            {formik.errors.subjectTypeId}
          </Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputLabel label={t("crm:modal")} />
          <AutoComplete
            options={modalTypes}
            label=""
            placeholder=""
            renderOption={(item) => item.name}
            getOptionLabel={(item) => item.name}
            onChange={(_, item) =>
              formik.setFieldValue("modalTypeId", item?.id)
            }
          />
          <Typography className={globalClasses.errorText}>
            {formik.errors.modalTypeId}
          </Typography>
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <InputLabel label={t("crm:receptivity")} />
          <AutoComplete
            options={receptivities}
            label=""
            placeholder=""
            renderOption={(item) => item.name}
            getOptionLabel={(item) => item.name}
            onChange={(_, item) =>
              formik.setFieldValue("receptivityId", item?.id)
            }
          />
          <Typography className={globalClasses.errorText}>
            {formik.errors.receptivityId}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <InputLabel label={t("translations:observation")} />
          <TextArea
            onChange={(event) => {
              formik.setFieldValue("comment", event.target.value);
            }}
          />
          <Typography className={globalClasses.errorText}>
            {formik.errors.comment}
          </Typography>
          <CommonButton
            title={t("translations:add")}
            onClick={formik.handleSubmit}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CrmForm;
