import { Box, Grid, Typography } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { useFormik } from "formik";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { useValidation } from "../../../../utills/validation";
import { AutoComplete, CommonButton, InputLabel, TextInput } from "../../../atoms";

interface CommissionProps {
   Submit: (values: any) => void;
}

const CommissionSearchForm: FC<CommissionProps> = ({ Submit }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations"]);

   const { counters, tariffs } = useDropdown();
   const { commissionSchema } = useValidation();

   const formik = useFormik({
      initialValues: {
         pickups: [] as { id: number; name: string }[],
         tariffs: [],
         startDate: "",
         endDate: "",
      },
      validationSchema: commissionSchema,
      onSubmit: (values) => {
         Submit(values);
      },
   });

   return (
      <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
         <Grid container spacing={3}>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:counters")} />
               <AutoComplete
                  multiple
                  options={counters || []}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.officialName}
                  getOptionLabel={(item) => item.officialName}
                  onChange={(_, item) => {
                     let temp: { id: number; name: string }[] = [];
                     item.map((el) => {
                        if(el.id){
                           temp.push({ id: el.id, name: el.officialName })
                        }
                     });
                     formik.setFieldValue("pickups", temp);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.pickups}</Typography>
            </Grid>
            <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:groups")} />
               <AutoComplete
                  multiple
                  options={tariffs || []}
                  label=""
                  placeholder=""
                  renderOption={(item) => item.name}
                  getOptionLabel={(item) => item.name}
                  onChange={(_, item) => {
                     let temp: number[] = [];
                     item.map((el) => temp.push(el.id));
                     formik.setFieldValue("tariffs", temp);
                  }}
               />
               <Typography className={globalClasses.errorText}>{formik.errors.tariffs}</Typography>
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput
                  title=""
                  disabled={false}
                  type="date"
                  id="startDate"
                  error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                  helperText={formik.touched.startDate && formik.errors.startDate}
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput
                  title=""
                  disabled={false}
                  type="date"
                  id="endDate"
                  error={formik.touched.endDate && Boolean(formik.errors.endDate)}
                  helperText={formik.touched.endDate && formik.errors.endDate}
                  value={formik.values.endDate}
                  onChange={formik.handleChange}
               />
            </Grid>
            <Grid item xl={1} lg={1} md={3} sm={12} xs={12}>
               <div className={globalClasses.margin} />
               <CommonButton title={t("translations:search")} onClick={formik.handleSubmit} icon={<Search />} />
            </Grid>
         </Grid>
      </Box>
   );
};

export default CommissionSearchForm;
