import { Grid } from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import useDropdown from "../../../../data/context/dropDown/DropdownContext";
import { AutoComplete, CommonButton, InputLabel, DropdownItem, TextInput } from "../../../atoms";

interface MaintenanceProps {
   onSearch: () => void;
   onChange: (key: string, value: string | number | undefined) => void;
   data: any;
   loading: boolean;
}

const MaintenanceReportForm: FC<MaintenanceProps> = ({ onSearch, onChange, data, loading }) => {
   const { t } = useTranslation(["translations"]);
   const { cars } = useDropdown();
   return (
      <>
         <Grid container spacing={3}>
            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
               <InputLabel label={t("translations:vehicle")} />
               <AutoComplete
                  label=""
                  placeholder=""
                  options={cars}
                  defaultValue={cars.find((el) => el.id === data.carId)}
                  renderOption={(item) => <DropdownItem car={item} />}
                  getOptionLabel={(item) => item?.name || ""}
                  onChange={(e, item) => onChange("CarId", item?.id)}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:startDate")} />
               <TextInput
                  title=""
                  type="date"
                  value={data.StartDate}
                  id="name"
                  error={false}
                  helperText={""}
                  disabled={false}
                  onChange={(e) => {
                     onChange("StartDate", e.target.value);
                  }}
               />
            </Grid>
            <Grid item xl={3} lg={3} md={4} sm={12} xs={12}>
               <InputLabel label={t("translations:endDate")} />
               <TextInput
                  title=""
                  type="date"
                  value={data.EndDate}
                  id="name"
                  error={false}
                  helperText={""}
                  disabled={false}
                  onChange={(e) => {
                     onChange("EndDate", e.target.value);
                  }}
               />
            </Grid>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
               <CommonButton margin={30} title="Buscar" onClick={onSearch} icon={<Search />} loading={loading} disable={loading} />
            </Grid>
         </Grid>
      </>
   );
};

export default MaintenanceReportForm;
