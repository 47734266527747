import { TextField, Theme } from "@material-ui/core";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { InputComponentProps } from "./index.types";
import { COLORS, VALUES } from "../../../../data/constants/Colors";
const CustomTextField = withStyles((theme: Theme) => ({
   root: {
      "& .MuiOutlinedInput-root": {
         "& fieldset": {
            borderColor: COLORS.greyBorder,
            transition: "border-color 0.3s",
         },
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
         borderColor: COLORS.primary,
      },
   },
}))(TextField);

const CustomTextInput: React.FC<InputComponentProps> = ({ disabled, title, type, value, error, onChange, helperText, id, maxLength, min, max }) => {
   return (
      <CustomTextField
         variant="outlined"
         id={id}
         placeholder={title}
         autoComplete="new-password"
         size="small"
         type={type}
         error={error}
         helperText={helperText}
         fullWidth
         value={value}
         disabled={disabled}
         onChange={onChange}
         style={{ marginTop: VALUES.margin_small }}
      ></CustomTextField>
   );
};

export default CustomTextInput;
