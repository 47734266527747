import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";
export const containerStyles = makeStyles((theme: Theme) =>
   createStyles({
      container: {
         paddingTop: 15,
         backgroundColor: theme.palette.background.paper,
         marginTop: 20,
         // paddingBottom: 20,
      },
      cardTitleCOntainer: {
         paddingLeft: 15,
         paddingRight: 15,
         paddingBottom: 10,
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
      },
      cardTitle: {
         fontSize: 14,
         fontWeight: "bold",
         color: COLORS.darkText,
      },
      icon: { "&:hover": { cursor: "pointer" } },
      lists: {
         backgroundColor: theme.palette.background.paper,
         marginTop: 20,
         padding: 13,
         display: "flex",
         flexDirection: "row",
         justifyContent: "space-between",
      },
      label: { display: "flex", flexDirection: "row" },
      redLabel: {
         maxHeight: 18,
         minWidth: 18,
         paddingLeft: 3,
         paddingRight: 3,
         borderRadius: 18 / 2,
         marginLeft: 3,
         color: theme.palette.background.paper,
         fontSize: 10,
         fontWeight: "bold",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      greenLabel: {
         maxHeight: 18,
         minWidth: 18,
         paddingLeft: 3,
         paddingRight: 3,
         borderRadius: 18 / 2,
         marginLeft: 3,
         color: theme.palette.background.paper,
         fontSize: 10,
         fontWeight: "bold",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
      box: {
         paddingLeft: 6,
         paddingRight: 6,
         paddingTop: 4,
         paddingBottom: 4,
         fontSize: 11,
         borderRadius: "0.25rem",
         borderStyle: "solid",
         borderWidth: 1,
         color: theme.palette.grey[400],
         borderColor: theme.palette.grey[400],
         "&:hover": {
            cursor: "pointer",
         },
      },
      contentContainer: {
         paddingLeft: 15,
         paddingRight: 15,
         marginTop: 15,
         paddingBottom: 15,
      },
   })
);
