import { Star, StarBorder } from "@material-ui/icons";
import React, { FC } from "react";
import { checkStyles } from "./index.styles";

interface CheckProps {
   active: boolean | undefined;
   onClick: () => void;
}

const StarCheckbox: FC<CheckProps> = ({ active, onClick }) => {
   const classes = checkStyles();
   return (
      <>
         {!active ? (
            <span onClick={onClick}>
               <StarBorder className={classes.icon} />
            </span>
         ) : (
            <span onClick={onClick}>
               <Star className={classes.icon} />
            </span>
         )}
      </>
   );
};

export default StarCheckbox;
