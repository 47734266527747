import { Typography } from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import React, { FC, ReactNode } from "react";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";
import { cardStyles } from "./index.styles";

interface CardProps {
   title: string;
   render: () => ReactNode;
   priceText: string;
   onCreateClick: () => void;
}

const InvoiceCard: FC<CardProps> = ({ title, render, priceText, onCreateClick }) => {
   const classes = cardStyles();
   const globalClasses = globalStyles();
   return (
      <div className={classes.creditContainer}>
         <div className={classes.topTextContainer}>
            <Typography className={globalClasses.titleText}>{title}</Typography>
            {render()}
         </div>
         <div className={classes.bottomLabel}>
            <Typography className={globalClasses.cardTitle}>{priceText}</Typography>
            <CommonButton title="Criar" icon={<AddCircle />} onClick={onCreateClick} margin={1} />
         </div>
      </div>
   );
};

export default InvoiceCard;
