import React, { FC } from "react";
import { COLORS } from "../../../data/constants/Colors";
import { levelStyles } from "./index.styles";

interface FuelLevelProps {
  level: number;
}

const FuelLevel: FC<FuelLevelProps> = ({ level }) => {
  const classes = levelStyles();
  return (
    <div className={classes.outerContainer}>
      <div
        style={{
          backgroundColor: COLORS.primary,
          maxWidth: 15 * level,
          height: 10,
          borderTopLeftRadius: 5,
          borderBottomLeftRadius: 5,
          borderTopRightRadius: 30 * level === 300 ? 5 : 0,
          borderBottomRightRadius: 30 * level === 300 ? 5 : 0,
        }}
      />
    </div>
  );
};

export default FuelLevel;
