import { min } from "moment";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { validateAtleastOne, validateLatitude, validateVehicleHighlights } from "./helper";

//HOC
export const useValidation = () => {
   const { t } = useTranslation(["vehicles", "gen", "organization"]);

   let login = yup.object({
      email: yup.string().email(t("gen:validEmail")).required(t("vehicles:emptyField")),
      password: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let yupObject = yup.object({
      name: yup.string().min(4, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      cta: yup.string().min(4, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      pricePerDay: yup.string().min(1).required(t("vehicles:emptyField")),
      pricePerHour: yup.string().min(1).required(t("vehicles:emptyField")),
      tariffId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let ticketMessage = yup.object({
      message: yup.string().required(t("vehicles:emptyField")),
      fileAttachmentDescription: yup.string().when("fileString", {
         is: (value: string) => value && value.length > 0,
         then: yup.string().required(t("vehicles:emptyField")),
      }),
   });

   let crm = yup.object({
      comment: yup.string().required(t("vehicles:emptyField")),
      subjectTypeId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      modalTypeId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      receptivityId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let newWaybillObject = yup.object({
      startDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      endDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      userId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      mainDriverId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      pickupOrganizationId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      returnOrganizationId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      carId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      statusId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let car = yup.object({
      fuelTypeId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      licenseDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      cubicCapacity: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      firstInspection: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      inspectionFrequency: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      colorId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      supplierId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      officeId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      licensePlate: yup.string().min(8, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      carTypeId: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      fleetId: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      year: yup.number().typeError("").min(2000, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      mileage: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      chassis: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      brandId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      name: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      categories: yup.array().length(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      vehicleFeatures: yup.array().test("", t("vehicles:vehicleFeaturesError"), function () {
         return validateVehicleHighlights(this.parent.vehicleFeatures, 4);
      }),
      contractFeatures: yup.array().test("", t("vehicles:contractFeaturesError"), function () {
         return validateVehicleHighlights(this.parent.contractFeatures, 1);
      }),
      features: yup.array().of(
         yup.object({
            value: yup.number().typeError("").min(0, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         })
      ),
   });

   let feature = yup.object({
      name: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      shortName: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      typeId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let newService = yup.object({
      billableItemId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      value: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      quantity: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let discount = yup.object({
      categoryId: yup.number().typeError("").min(1).required(t("vehicles:emptyField")),
      minAmount: yup.number().typeError("vehicles:emptyField").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      discount: yup.string().min(1).required(t("vehicles:emptyField")),
   });

   let reservation = yup.object({
      userId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      mainDriverId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      pickupOrganizationId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      returnOrganizationId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      startDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      endDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      startTime: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      endTime: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      categoryId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      carId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      statusId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      groupId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      mileage: yup.number().when("carChanged", {
         is: true,
         then: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
   });

   let newOrganization = yup.object({
      taxId: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      phone: yup.string().min(9, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      phoneCountryId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      email: yup.string().email(t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      address: yup.string().required(t("vehicles:emptyField")),
      postalCode: yup.string().min(8, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      neighborhood: yup.string().required(t("vehicles:emptyField")),
      fantasyName: yup.string().required(t("vehicles:emptyField")),
      officialName: yup.string().required(t("vehicles:emptyField")),
      cityId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      stateId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      countryId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      latitude: yup
         .string()
         .min(5, t("organization:latitudeLengthError"))
         .test("", t("organization:latitudeGreaterError") + "90", function () {
            return validateLatitude(this, 90);
         })
         .required(t("vehicles:emptyField")),
      longitude: yup
         .string()
         .min(5, t("organization:latitudeLengthError"))
         .test("", t("organization:latitudeGreaterError") + "180", function () {
            return validateLatitude(this, 180);
         })
         .required(t("vehicles:emptyField")),
   });

   let newQuestion = yup.object({
      question: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      answer: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      languageId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let newFine = yup.object({
      carId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      amount: yup.string().min(1).required(t("vehicles:emptyField")),
      note: yup.string().min(1).required(t("vehicles:emptyField")),
      date: yup.string().min(10).required(t("vehicles:emptyField")),
      local: yup.string().min(1).required(t("vehicles:emptyField")),
      fileNote: yup.string().when("fileString", {
         is: (value: string) => value && value.length > 0,
         then: yup.string().required(t("vehicles:emptyField")),
      }),
   });

   let newfinalizeReservation = yup.object({
      // date: yup.string().min(10).required(t("vehicles:emptyField")),
      notes: yup.string().min(1).required(t("vehicles:emptyField")),
      checked: yup.bool().isTrue(),
   });

   let newUser = yup.object({
      nationalityId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      name: yup.string().min(1).required(t("vehicles:emptyField")),
      birthdate: yup.string().min(10).required(t("vehicles:emptyField")),
      phoneCountryId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      phone: yup.string().min(9).required(t("vehicles:emptyField")),
      email: yup.string().email(t("gen:validEmail")).required(t("vehicles:emptyField")),
      countryId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      stateId: yup.number().when("countryId", {
         is: 1,
         then: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      cityId: yup.number().when("countryId", {
         is: 1,
         then: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      alternateCityName: yup.string().when("countryId", {
         is: (value: number) => value != 1,
         then: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
      address: yup.string().min(3).required(t("vehicles:emptyField")),
      neighborhood: yup.string().min(3).required(t("vehicles:emptyField")),
      postalCode: yup
         .string()
         .when("countryId", {
            is: (value: number) => value == 1,
            then: yup.string().length(7, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         })
         .required(t("vehicles:emptyField")),
      taxId: yup
         .string()
         .when("countryId", {
            is: (value: number) => value == 1,
            then: yup.string().length(9, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
         })
         .required(t("vehicles:emptyField")),
      disableRequireDocs: yup.boolean(),
      documents: yup.array().when("disableRequireDocs", {
         is: false,
         then: yup.array(
            yup.object().shape({
               countryId: yup
                  .number()
                  .typeError("")
                  .test("", t("vehicles:emptyField"), function () {
                     return validateAtleastOne(this);
                  }),
               value: yup.string().test("", t("vehicles:emptyField"), function () {
                  return validateAtleastOne(this);
               }),
               expirationDate: yup.string().test("", t("vehicles:emptyField"), function () {
                  return validateAtleastOne(this);
               }),
               issueDate: yup.string().test("", t("vehicles:emptyField"), function () {
                  return validateAtleastOne(this);
               }),
            })
         ),
      }),
   });

   let commission = yup.object({
      pickups: yup.array().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      tariffs: yup.array().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      startDate: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      endDate: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let billing = yup.object({
      dueDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      paymentMethodId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      clientId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      accountId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      // discountRequestId: yup.number().typeError("").min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let campaign = yup.object({
      startDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      endDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      name: yup.string().min(3, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      description: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      rules: yup.string().min(5, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      discount: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      isPercentage: yup.boolean().typeError(t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      itemId: yup.number().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      categories: yup.array().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
   });

   let maintenance = yup.object({
      startDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      intendedOutDate: yup.string().min(10, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      carId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      typeId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      organizationId: yup.number().typeError(t("vehicles:emptyField")).min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      totalAmount: yup.string().min(1, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      fileDescription: yup.string().when("fileName", {
         is: (value: File) => value.size,
         then: yup.string().min(2, t("vehicles:emptyField")).required(t("vehicles:emptyField")),
      }),
   });

   return {
      schema: yupObject,
      waybillSchema: newWaybillObject,
      serviceSchema: newService,
      organizationSchema: newOrganization,
      questionSchema: newQuestion,
      loginSchema: login,
      fineSchema: newFine,
      userSchema: newUser,
      finalizeResevationSchema: newfinalizeReservation,
      discountSchema: discount,
      reservationSchema: reservation,
      ticketSchema: ticketMessage,
      crmSchema: crm,
      carSchema: car,
      featureSchema: feature,
      commissionSchema: commission,
      billingSchema: billing,
      campaignSchema: campaign,
      maintenanceSchema: maintenance,
   };
};
