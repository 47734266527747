import { FC } from "react";
import { Box } from "@material-ui/core";
import { Invoice } from "../../../data/models/invoice.model";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import DataTable from "../../molecules/tables/datatable/DataTable";
import TableColumn from "../../molecules/tables/table-column/TableColumn";
import { useTranslation } from "react-i18next";
import { TableButton } from "../../atoms";

interface InvoicesProps {
   data: Invoice[] | undefined;
   onReservationClick: (id: number) => void;
   onInvoiceClick: (id: number) => void;
}
const NotGenInvoices: FC<InvoicesProps> = ({ data, onReservationClick, onInvoiceClick }) => {
   const globalClasses = globalStyles();
   const { t } = useTranslation(["translations", "organization", "dashboardStrings"]);
   return (
      <Box className={globalClasses.container}>
         <DataTable data={data || []} search={false}>
            <TableColumn headerText={t("organization:counter")} render={(item) => item.fantasyName} />
            <TableColumn headerText={t("translations:saleman")} render={(item) => item.sellerName} />
            <TableColumn
               headerText={t("translations:invoice")}
               render={(item) => (
                  <span className={globalClasses.linkText} onClick={() => onInvoiceClick(item.reservationId)}>
                     #{item.id}
                  </span>
               )}
            />
            <TableColumn
               align="right"
               render={(item) => (
                  <TableButton
                     title={`${t("dashboardStrings:reservation")} # ${item.reservationId}`}
                     onClick={() => onReservationClick(item.reservationId)}
                  />
               )}
            />
         </DataTable>
      </Box>
   );
};

export default NotGenInvoices;
