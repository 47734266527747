import { AccountBox, FilterList, Launch } from "@material-ui/icons";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { crm_action } from "../../../data/constants/button-action";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { CommonButton } from "../../atoms";

interface CrmButtonProps {
   userView: boolean;
   onClick: (action: string) => void;
}

const CrmButtons: FC<CrmButtonProps> = ({ userView, onClick }) => {
   const { t } = useTranslation(["translations", "crm"]);
   const globalClasses = globalStyles();
   console.log(userView);
   return (
      <>
         {userView ? (
            <>
               <CommonButton
                  title={t("crm:userDetails")}
                  onClick={() => {
                     onClick(crm_action.USER_DETAIL);
                  }}
                  icon={<AccountBox />}
               />
            </>
         ) : (
            <>
               <CommonButton
                  title={t("translations:listAll")}
                  onClick={() => {
                     onClick(crm_action.LIST_ALL);
                  }}
                  icon={<FilterList />}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  title={t("crm:reservationDetails")}
                  onClick={() => {
                     onClick(crm_action.RESERVATION_DETAIL);
                  }}
                  icon={<Launch />}
               />
               <span className={globalClasses.marginLeft} />
               <CommonButton
                  title={t("crm:userDetails")}
                  onClick={() => {
                     onClick(crm_action.USER_DETAIL);
                  }}
                  icon={<AccountBox />}
               />
            </>
         )}
      </>
   );
};

export default CrmButtons;
