import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { CarItem, CommonButton, StatusLabel, TableDropDown, TableItem } from "../../../components/atoms";
import { ContainerTopBar, TitleContainer } from "../../../components/molecules";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import * as waybillApi from "../../../data/api/requests/waybills/index";
import * as reservationApi from "../../../data/api/requests/reservations";
import { useRequest } from "../../../data/api/wrapper";
import DataTable from "../../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../../components/molecules/tables/table-column/TableColumn";
import moment from "moment";
import { COLORS } from "../../../data/constants/Colors";
const ListWayBills = () => {
   const history = useHistory();
   const { t } = useTranslation(["translations", "waybills"]);

   const waybills = useRequest(() => waybillApi.getWayBills(pagination.page, pagination.size, searchRes));

   const [pagination, setPagination] = useState({ page: 0, size: 10 });
   const [searchRes, setSearchRes] = useState("");

   useEffect(() => {
      waybills.execute();
   }, [pagination, searchRes]);

   const handleDropdownClick = (name: string, id: number) => {
      switch (name) {
         case t("translations:edit"):
            history.push(`/way-bills/form/${id}`);
            break;
         case t("waybills:print"):
            getContract(id);
            break;
         default:
            break;
      }
   };

   const getContract = (id: number) => {
      reservationApi
         .reservationContract(id)
         .then((res) => {
            window.open("");
            let pdfWindow = window.open("");
            setTimeout(() => {
               if (pdfWindow) {
                  pdfWindow.document.write(
                     `<iframe
                     src={data:application/pdf;base64,${res?.data?.pdfFileBase64}}
                     type="application/pdf;base64,base64encodedpdf"
                     width="100%"
                     height="100%"
                  ></iframe>`
                  );
               }
            }, 0);
         })
         .catch((err) => {});
   };

   const handlePagination = (page: number, rowsPerPage: number) => {
      setPagination({ page: page, size: rowsPerPage });
   };
   return (
      <div>
         <TitleContainer title={t("translations:wayBills")} />
         <CommonButton
            title={t("translations:new")}
            onClick={() => {
               history.push(ROUTE_LINKS.ADD_NEW_TRANSPORT_GUIDE);
            }}
         />
         <ContainerTopBar title={t("translations:listing")} activity={waybills.loading}>
            <DataTable
               data={waybills.data?.data || []}
               onSearching={(query) => setSearchRes(query)}
               pagination
               onChange={handlePagination}
               total={waybills.data?.total}
            >
               <TableColumn headerText={"ID"} render={(item) => item.id} />
               <TableColumn
                  headerText={t("translations:responsible") + "/" + t("translations:driver")}
                  render={(item) => <TableItem title={item.responsible.name} subtitle={item.driver.name} />}
               />
               <TableColumn headerText={t("translations:vehicl")} render={(item) => <CarItem car={item.car} />} />
               <TableColumn headerText={t("translations:duration")} render={(item) => `${item.duration} ${t("translations:days")}`} />
               <TableColumn
                  headerText={"Info"}
                  render={(item) => (
                     <TableItem
                        title={moment(item.startDate).format("DD/MM/YYYY HH:mm")}
                        subtitle={moment(item.endDate).format("DD/MM/YYYY HH:mm")}
                        icon
                     />
                  )}
               />
               <TableColumn
                  headerText={t("translations:store")}
                  render={(item) => <TableItem title={item.pickupCounter.name} subtitle={item.returnCounter.name} icon />}
               />
               <TableColumn headerText={t("translations:status")} render={(item) => <StatusLabel status={item.status} />} />
               <TableColumn
                  headerText={t("translations:details")}
                  render={(item) => (
                     <TableDropDown
                        options={[t("translations:edit"), t("waybills:print")]}
                        onClick={(action) => {
                           handleDropdownClick(action, item.id);
                        }}
                     />
                  )}
               />
            </DataTable>
         </ContainerTopBar>
      </div>
   );
};

export default ListWayBills;
