import { makeStyles, Theme, createStyles } from "@material-ui/core";

export const checkStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    checkboxText: {
      fontWeight: "lighter",
      fontSize: 13,
    },
  })
);
