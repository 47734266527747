import { ContainerTopBar, CrmButtons, TitleContainer } from "../../components/molecules";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { CrmForm } from "../../components/organisms";
import { Box } from "@material-ui/core";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import DataTable from "../../components/molecules/tables/datatable/DataTable";
import TableColumn from "../../components/molecules/tables/table-column/TableColumn";
import { useRequest } from "../../data/api/wrapper";
import * as commLogApi from "../../data/api/requests/communication-logs/index";
import { useEffect, useState } from "react";
import { Loader, TableButton, TableItem, Toast } from "../../components/atoms";
import { FilterList } from "@material-ui/icons";
import { crm_action } from "../../data/constants/button-action";

const CRM = () => {
   const { state } = useLocation<{ id: string; userId: number }>();

   const { t } = useTranslation(["translations", "crm"]);

   const globalClasses = globalStyles();

   const history = useHistory();

   const commLogReservation = useRequest((params) => commLogApi.getCommLogsByRes(params));
   const commLogUser = useRequest((params) => commLogApi.getCommLogsByUser(params));
   const addCommLog = useRequest((params) => commLogApi.addNewCommLog(params));

   const [byReservation, setByReservation] = useState(true);

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         getData();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const getData = () => {
      if (state.id) {
         commLogReservation.execute(state.id);
      } else {
         setByReservation(false);
         commLogUser.execute(state.userId);
      }
   };

   const addCrm = async (item: any) => {
      const dataToUpload = {
         ...item,
         reservationId: state.id || null,
         userId: state.userId,
      };
      addCommLog.execute(dataToUpload);
   };

   const handleResClick = (id: number) => {
      history.push(`/reservation/form/${state.id}`);
   };

   const onButtonClick = (action: string) => {
      switch (action) {
         case crm_action.LIST_ALL:
            setByReservation(false);
            break;
         case crm_action.RESERVATION_DETAIL:
            history.push(`/reservation/form/${state.id}`);
            break;
         case crm_action.USER_DETAIL:
            history.push(`/users/form/${state.userId}`);
            break;
         default:
            break;
      }
   };

   console.log(commLogUser.data);

   return (
      <>
         <Loader loading={addCommLog.loading} />
         <Toast
            message={addCommLog.message}
            show={addCommLog.show.toast}
            handleClose={() => {
               addCommLog.close();
               commLogReservation.execute(state.id);
            }}
         />
         <TitleContainer title={`CRM`} />
         <CrmButtons userView={!byReservation} onClick={onButtonClick} />
         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <CrmForm submit={addCrm} />
            <ContainerTopBar title={t("translations:listing")}>
               <DataTable data={byReservation ? commLogReservation.data || [] : commLogUser.data || []}>
                  <TableColumn headerText={"ID"} render={(item) => item.id} />
                  <TableColumn headerText={t("translations:subject")} render={(item) => item.subjectType.name} />
                  <TableColumn headerText={t("crm:modal")} render={(item) => item.modalType.name} />
                  <TableColumn headerText={t("crm:receptivity")} render={(item) => item.receptivity.name} />
                  <TableColumn headerText={t("translations:observation")} render={(item) => item.comment} />
                  <TableColumn headerText={t("translations:user")} render={(item) => item.user.name} />
                  <TableColumn
                     headerText={t("translations:registeredIn")}
                     render={(item) => <TableItem title={""} subtitle={`${t("translations:by")}: `} />}
                  />
                  <TableColumn
                     render={(item) => (
                        <TableButton
                           icon={<FilterList className={globalClasses.tableSmallIcon} />}
                           title={`${t("translations:reservation")}#${item.reservation.id}`}
                           onClick={() => handleResClick(item.reservation.id)}
                        />
                     )}
                  />
               </DataTable>
            </ContainerTopBar>
         </Box>
      </>
   );
};

export default CRM;
