import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { PasswordForm } from "../../components/organisms";
import { globalStyles } from "../../data/globalStyles/GlobalStyles";
import { useRequest } from "../../data/api/wrapper";
import * as AuthApi from "../../data/api/requests/auth/index";
import { TitleContainer } from "../../components/molecules";
import { useTranslation } from "react-i18next";
import { Alert, Loader, Toast } from "../../components/atoms";
import { useHistory } from "react-router";
import { ROUTE_LINKS } from "../../routes/routeLinks";

const ChangePassword = () => {
  const globalClasses = globalStyles();

  const { t } = useTranslation("translations");

  const history = useHistory();

  const changePassword = useRequest((params) => AuthApi.changePassword(params));

  const Submit = (password: any) => {
    changePassword.execute(password);
  };

  return (
    <div>
      <Loader loading={changePassword.loading} />

      {changePassword.show.toast && (
        <Toast
          show={changePassword.show.toast}
          message={changePassword.message}
          handleClose={() => {
            changePassword.close();
          }}
        />
      )}

      {changePassword.show.modal && (
        <Alert
          show={changePassword.show.modal}
          message={changePassword.message}
          handleClose={() => {
            changePassword.close();
            history.push(ROUTE_LINKS.DASHBOARD);
          }}
        />
      )}
      <TitleContainer title={t("changePassword")} />
      <Box
        className={`${globalClasses.container} ${globalClasses.paddingContainer}`}
      >
        <PasswordForm Submit={Submit} />
      </Box>
    </div>
  );
};

export default ChangePassword;
