import React, { FC } from "react";
import { Box, Checkbox, Divider, FormControlLabel, FormGroup, Grid, Typography } from "@material-ui/core";
import { globalStyles } from "../../../../data/globalStyles/GlobalStyles";
import { TextArea, TextInput } from "../../../atoms";
import { newMessageStyles } from "./index.styles";
import { NewMessageProps } from "./index.types";
const NewMessageForm: FC<NewMessageProps> = ({ name, checkBox, data, onCheckboxClick, onChange }) => {
   const globalClasses = globalStyles();
   const classes = newMessageStyles();

   console.log(name);
   return (
      <Box className={globalClasses.container}>
         <Grid container spacing={4}>
            <Grid item sm={8} xs={12}>
               <div>
                  <Typography className={globalClasses.labelText}>Destinatário</Typography>
                  <TextInput id="" helperText="" title={name ? name : ""} disabled={true} type="text" />
               </div>
               <div className={globalClasses.margin}>
                  <Typography className={globalClasses.labelText}>Título</Typography>
                  <TextInput
                     id="title"
                     helperText=""
                     title=""
                     value={data.title}
                     disabled={false}
                     type="text"
                     onChange={(e) => {
                        onChange("title", e);
                     }}
                  />
               </div>
               <div className={globalClasses.margin}>
                  <TextArea
                     value={data.message}
                     onChange={(e) => {
                        onChange("message", e);
                     }}
                  />
               </div>
               <FormGroup row>
                  <FormControlLabel
                     control={<Checkbox checked={checkBox.pushNotification} onChange={() => onCheckboxClick("pushNotification")} color={"primary"} />}
                     label={<Typography className={classes.checkboxText}>Push notifications</Typography>}
                  />
                  <FormControlLabel
                     className={classes.checkbox}
                     control={<Checkbox checked={checkBox.email} onChange={() => onCheckboxClick("email")} color={"primary"} />}
                     label={<Typography className={classes.checkboxText}>E-mail</Typography>}
                  />
                  <FormControlLabel
                     classes={{ root: classes.checkbox }}
                     control={<Checkbox checked={checkBox.sms} onChange={() => onCheckboxClick("sms")} color={"primary"} />}
                     label={<Typography className={classes.checkboxText}>SMS</Typography>}
                  />
               </FormGroup>
            </Grid>
         </Grid>
      </Box>
   );
};

export default NewMessageForm;
