import ApiResponse from "../../../models/api-response.model";
import Pagination from "../../../models/pagination.model";
import Question from "../../../models/question.modal";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getQuestions(search?: string, page?: number, size?: number): Promise<ApiResponse<Pagination<Question[]>>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.questions + `?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Pagination<Question[]>> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function getQuestionById(id: number | string): Promise<ApiResponse<Question>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.questions + `/${id}`,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Question> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function changeQuestionStatus(questionId: number): Promise<ApiResponse<Question>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeQuestionStatus.format(questionId),
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<Question> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function addNewQuestion(faq: { question: string; answer: string }): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.questions,
         data: faq,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
export async function updateQuestion(
   faq: {
      question: string;
      answer: string;
   },
   id: number | string
): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.questions + `/${id}`,
         data: faq,
      });
      if (resp.status !== 200) {
         throw new Error("Algo deu errado");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}
