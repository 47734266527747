import moment from "moment";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { getUserById, getUserPendingDocuments, getUserPendingFields, getUserPendingFinancials } from "../api/requests/users";

export const useCheckUserForRole = () => {
   const [modalFields, setModalFields] = useState({ title: "", body: "" });
   const [openModal, setOpenModal] = useState(false);
   const [userTyp, setUserType] = useState("");
   const [resetValue, setResetValue] = useState(false);

   const { t } = useTranslation(["translations", "gen", "users"]);

   const getData = async (id: number, userType: string) => {
      const [pendingFields, pendingDocuments, pendingFinancials, user] = await Promise.all([
         getUserPendingFields(id),
         getUserPendingDocuments(id),
         getUserPendingFinancials(id),
         getUserById(id.toString()),
      ]);

      setUserType(userType);
      const licenseExpiry = user.data.documents.find((el) => el.typeId == 3)?.expirationDate;
      if (userType === "mainDriverId" || userType === "additionalDriverId") {
         if (!licenseExpiry) {
            setModalFields({
               title: t("users:expiredLicense"),
               body: t("users:expiredMessage"),
            });
            setOpenModal(true);
            setResetValue(true);
         } else {
            let licenseExpiryDate = new Date(moment(licenseExpiry).format("YYYY-MM-DD")).getTime();
            let today = new Date().getTime();
            if (licenseExpiryDate < today) {
               setModalFields({
                  title: t("users:expiredLicense"),
                  body: t("users:expiredMessage"),
               });
               setOpenModal(true);
            } else {
               setResetValue(false);
            }
         }
      } else {
         if (pendingFinancials.data.length > 0) {
            setModalFields({ title: t("translations:pendingFinancials"), body: t("gen:modalFinancialMessage") });
            setOpenModal(true);
         }
         if (pendingDocuments.data.length > 0) {
            setModalFields({ title: t("translations:pendingFinancials"), body: t("gen:modalFinancialMessage") });
            setOpenModal(true);
         }
         if (pendingFields.data.length > 0) {
            setModalFields({
               title: t("users:issuesFound"),
               body: t("users:theFields") + " " + JSON.stringify(pendingFields.data) + " " + t("users:fieldsMessage"),
            });
            setOpenModal(true);
            setResetValue(true);
         }
      }
   };

   const close = () => {
      setOpenModal(false);
   };

   return { modalFields, userTyp, resetValue, openModal, getData, close };
};
