import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";

export const formStyles = makeStyles((theme: Theme) =>
   createStyles({
      input: { marginTop: 15 },
      image: {
         height: 200,
         width: 200,
      },
      imageContainer: {
         borderWidth: 1,
         borderColor: COLORS.greyBorder,
         borderStyle: "solid",
         height: 210,
         width: 210,
         marginTop: 20,
         padding: "auto",
         display: "flex",
         alignItems: "center",
         justifyContent: "center",
      },
   })
);
