import ApiResponse from "../../../models/api-response.model";
import Organization from "../../../models/organization.model";
import Pagination from "../../../models/pagination.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";

export async function getOrganizations(page: number, size: number, search: string): Promise<ApiResponse<Pagination<Organization[]>>> {
   try {
      const resp = await request({
         method: "GET",
         url: `${API_ROUTES.baseUrl}${API_ROUTES.organizations}?page=${page}&size=${size}&search=${search}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Organization[]>> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function changeOrganizationStatus(organizationId: number): Promise<ApiResponse<Organization>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeOrgStatus.format(organizationId),
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Organization> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function addNewOrganization(organization: any): Promise<ApiResponse<void>> {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.baseUrl + API_ROUTES.organizations,
         data: organization,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export async function getOrganizationById(organizationId: number): Promise<ApiResponse<Organization>> {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.organizations + `/${organizationId}`,
      });
      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Organization> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
}

export const updateOrganization = async (organization: any, organizationId: number | string): Promise<ApiResponse<void>> => {
   console.log(organization);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.organizations + `/${organizationId}`,
         data: organization,
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<void> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
