import { MutableRefObject, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import { NewMaintenenceForm } from "../../../components/organisms";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import * as maintenanceApi from "../../../data/api/requests/maintenance/index";
import { TitleContainer } from "../../../components/molecules";
import { useRequest } from "../../../data/api/wrapper";
import { AlertDailog, Loader, Toast } from "../../../components/atoms";
import { ROUTE_LINKS } from "../../../routes/routeLinks";
import { ErrorOutline } from "@material-ui/icons";

function NewVehicleMaintenance() {
   const globalClasses = globalStyles();

   const { t } = useTranslation(["maintenance", "translations"]);

   const { id } = useParams<{ id: string }>();

   const clearForm = useRef() as MutableRefObject<() => void>;

   const addMaintenance = useRequest((params) => maintenanceApi.createNewMaintenance(params));
   const getMaintenance = useRequest((params) => maintenanceApi.getMaintenanceById(id));
   const updateMaintenance = useRequest((params) => maintenanceApi.updateMaintenanceById(params, id));

   const history = useHistory();
   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe && id) {
         _getMaintenance();
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   const _getMaintenance = () => {
      getMaintenance.execute();
   };

   const submit = (values: any) => {
      if (id) {
         updateMaintenance.execute(values);
      } else {
         addMaintenance.execute(values);
      }
   };

   return (
      <div>
         <Loader loading={addMaintenance.loading || updateMaintenance.loading} />
         {addMaintenance.show.modal && (
            <AlertDailog
               show={addMaintenance.show.modal}
               title={addMaintenance.message.message}
               icon={<ErrorOutline className={globalClasses.bigErrorIcon} />}
               message={addMaintenance.message.description}
               primaryButtonHandle={() => {
                  history.push(ROUTE_LINKS.MAINTENANCE);
               }}
               primaryButtonText={t("translations:backButton")}
               secondaryButtonText={t("translations:addNew")}
               secondaryButtonHandle={() => {
                  clearForm.current();
                  addMaintenance.close();
               }}
            />
         )}

         {updateMaintenance.show.toast && (
            <Toast
               show={updateMaintenance.show.toast}
               message={updateMaintenance.message}
               handleClose={() => {
                  updateMaintenance.close();
                  getMaintenance.execute();
               }}
            />
         )}

         <TitleContainer title={t("maintenance:vehicleMaintenance")} />

         <Box className={`${globalClasses.container} ${globalClasses.paddingContainer}`}>
            <NewMaintenenceForm handleSubmit={submit} data={getMaintenance.data} clearForm={clearForm} />
         </Box>
      </div>
   );
}

export default NewVehicleMaintenance;
