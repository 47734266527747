import { ArrowBack } from "@material-ui/icons";
import * as reservationApi from "../../../data/api/requests/reservations/index";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { CommonButton, InputLabel, Loader, Toast } from "../../../components/atoms";
import { TitleContainer } from "../../../components/molecules";
import { FinalizeForm } from "../../../components/organisms";
import { useRequest } from "../../../data/api/wrapper";
import { useEffect } from "react";
import { carFeatures } from "../../../data/api/requests/dropdowns";
import { createAsExpression } from "typescript";
import useAuth from "../../../data/context/authContext/AuthContext";
import { Box } from "@material-ui/core";
import { globalStyles } from "../../../data/globalStyles/GlobalStyles";
import moment from "moment";
import { List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { getReservationService } from "../../../data/api/requests/reservation-services";
import Service from "../../../data/models/reservation-service.model";
import { ROUTE_LINKS } from "../../../routes/routeLinks";

const FinalizeReservation = () => {
   const { t } = useTranslation(["gen", "translations", "dashboardStrings"]);

   const { id } = useParams<{ id: string }>();

   const { user } = useAuth();

   const history = useHistory();

   const reservation = useRequest((params) => reservationApi.getReservationById(params));

   const reservationItems = useRequest((params) => getReservationService(params));

   const addPendingItemsRequest = useRequest((params) => reservationApi.addPendingItems(params));

   const finalizeReseravation = useRequest((params) => reservationApi.finalizeReservation(params));

   const reservationConflicts = useRequest((params) => reservationApi.getConflicts(params));

   const submit = (values: any) => {
      values = { ...values, sessionUserId: user?.userId };
      finalizeReseravation.execute(values);
   };

   useEffect(() => {
      let unsubscribe = false;
      if (!unsubscribe) {
         reservation.execute(id);
         reservationItems.execute(id);
         reservationConflicts.execute(id);
      }
      return () => {
         unsubscribe = true;
      };
   }, []);

   // var daysFromStartDate = moment().diff(reservation.data?.startDate, 'days');
   // var totalOriginalDaysToReturn = moment(reservation.data?.endDate).diff(reservation.data?.startDate, 'days') + 1;

   // var isDaysShort = moment(reservation.data?.endDate).isAfter();
   // var isDaysExceeded =  moment(reservation.data?.endDate).isBefore();

   // if(isDaysShort) {
   //    daysFromStartDate++;
   // }

   // var hasDiscrepancies = isDaysShort || isDaysExceeded;

   var warningBoxStyle = globalStyles().alertWarning;
   var alertWarningSevere = globalStyles().alertWarningSevere;

   return (
      <>
         <Loader loading={addPendingItemsRequest.loading} />

         {addPendingItemsRequest.show.toast && (
            <Toast
               show={addPendingItemsRequest.show.toast}
               message={addPendingItemsRequest.message}
               handleClose={() => {
                  addPendingItemsRequest.close();
                  history.push(`/reservation/transactions/${id}`);
               }}
            />
         )}

         <TitleContainer title={t("gen:finalizeRental") + `- ${id}`} />

         <CommonButton
            icon={<ArrowBack />}
            title={t("translations:backTo") + " " + t("dashboardStrings:reservation") + ` ${id}`}
            onClick={() => {
               history.goBack();
            }}
         />

         {reservationItems.data && (
            <Box className={alertWarningSevere}>
               <InputLabel label={t("translations:unBilledCreatedItems")} />
               <InputLabel label={t("translations:unBilledCreatedItemsMessage")} />
               <List dense={false}>
                  {reservationItems.data?.map((item) => (
                     <ListItem>
                        {" "}
                        <InputLabel key={item.item.itemId} label={item.item.name} />
                     </ListItem>
                  ))}
               </List>
               <CommonButton
                  color={"#ed5565"}
                  title={t("translations:gotoTransactions")}
                  onClick={() => {
                     history.push(`/reservation/transactions/${id}`);
                  }}
               />
            </Box>
         )}

         {reservationConflicts.data && (
            <Box className={warningBoxStyle}>
               <InputLabel label={t("translations:preClosing")} />
               <InputLabel label={t("translations:inconsistenciesFound")} />
               <List dense={false}>
                  {reservationConflicts.data?.conflicts?.map((item) => (
                     <ListItem>
                        {" "}
                        <InputLabel key={item} label={item} />
                     </ListItem>
                  ))}
               </List>
               <CommonButton
                  color={"#f8ac59"}
                  title={t("translations:automaticallyResolveIssues")}
                  onClick={() => addPendingItemsRequest.execute(id)}
               />
            </Box>
         )}

         {finalizeReseravation.show.toast && (
            <Toast show={finalizeReseravation.show.toast} message={finalizeReseravation.message} handleClose={() => finalizeReseravation.close()} />
         )}

         <FinalizeForm handleSubmit={submit} reservation={reservation.data} />
      </>
   );
};

export default FinalizeReservation;
