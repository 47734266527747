import { makeStyles, Theme } from "@material-ui/core";

export const financialStyles = makeStyles((theme: Theme) => ({
  greyContainer: {
    background: theme.palette.grey[400],
    padding: theme.spacing(1),
    fontSize: 14,
    fontWeight: "bold",
    marginTop: theme.spacing(1),
  },
}));
