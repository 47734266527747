import { formatDate } from "../../../../utills/helper";
import { normalizeCurrency } from "../../../../utills/masks";
import { encodeDate } from "../../../../utills/utills";
import ApiResponse from "../../../models/api-response.model";
import CarRecReserva from "../../../models/car-recent-reservation.model";
import Car from "../../../models/car.model";
import CarFreePeriods from "../../../models/free-periods.model";
import Pagination from "../../../models/pagination.model";
import Reservation from "../../../models/reservation.model";
import { API_ROUTES } from "../../apiRoutes";
import { API_METHODS } from "../../methods";
import { request } from "../../wrapper";
import { uploadFile } from "../upload";

export const getCars = async (page: number, size: number, search: string): Promise<ApiResponse<Pagination<Car[]>>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.cars + `?Page=${page}&Size=${size}&search=${search}`,
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<Pagination<Car[]>> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const saveCar = async (car: any): Promise<ApiResponse<void>> => {
   let tempValues: any = { ...car, licenseDate: encodeDate(car.licenseDate, ""), purchasePrice: normalizeCurrency(car.purchasePrice), carTypeId: 2 };
   let tempFeatures = tempValues.features.concat(tempValues.contractFeatures, tempValues.vehicleFeatures);
   tempFeatures = tempFeatures.filter((el: any) => el.featureId != 0);
   delete tempValues.vehicleFeatures;
   delete tempValues.contractFeatures;
   delete tempValues.imageUrl;
   delete tempValues.coverImageUrl;
   tempValues.features = tempFeatures;
   let downlink = "";
   let coverDownlink = "";
   if (car.image?.size || car.coverImage?.size) {
      const image = new FormData();
      const coverImage = new FormData();
      image.append("file", car.image);
      coverImage.append("file", car.coverImage);
      if (car.coverImage?.size) {
         const res = await uploadFile(coverImage);
         coverDownlink = res.data.file;
      }
      if (car.image?.size) {
         const resp = await uploadFile(image);
         downlink = resp.data.file;
      }
   }
   tempValues.coverImage = coverDownlink;
   tempValues.image = downlink;

   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.cars,
         data: { ...tempValues },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCarByID = async (id: string): Promise<ApiResponse<Car>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.cars + `/${id}`,
         data: {},
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }

      const data: ApiResponse<Car> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const freePeriods = async (id: string): Promise<ApiResponse<CarFreePeriods[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.freePeriods.format(id),
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<CarFreePeriods[]> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const updateCar = async (car: any, id: string): Promise<ApiResponse<void>> => {
   let tempValues: any = {
      ...car,
      licenseDate: encodeDate(car.licenseDate, ""),
      purchasePrice: normalizeCurrency(car.purchasePrice),
      carTypeId: 2,
      cubicCapacity: parseFloat(car.cubicCapacity),
   };

   let tempFeatures = tempValues.features.concat(tempValues.contractFeatures, tempValues.vehicleFeatures);
   tempFeatures = tempFeatures.filter((el: any) => el.featureId != 0);
   delete tempValues.vehicleFeatures;
   delete tempValues.contractFeatures;
   delete tempValues.imageUrl;
   delete tempValues.coverImageUrl;
   tempValues.features = tempFeatures;

   let downlink = car.imageUrl || "";
   let coverDownlink = car.coverImageUrl || "";

   if (car.image?.size || car.coverImage?.size) {
      const image = new FormData();
      const coverImage = new FormData();
      image.append("file", car.image);
      coverImage.append("file", car.coverImage);
      if (car.coverImage?.size) {
         const res = await uploadFile(coverImage);
         coverDownlink = res.data.file; // todo
      }
      if (car.image?.size) {
         const resp = await uploadFile(image);
         downlink = resp.data.file; // Todo
      }
   }

   tempValues.coverImage = coverDownlink;
   tempValues.image = downlink;
   tempValues.features.map((el: any) => {
      delete el.name;
      return el;
   });

   try {
      const resp = await request({
         method: API_METHODS.POST, //TODO change to POST after API refactor
         url: API_ROUTES.updateCars.format(id),
         data: { ...tempValues },
      });

      if (resp.status !== 200) {
         throw new Error("Something went wrong");
      }
      const data: ApiResponse<void> = await resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const changeCarStatus = async (carId: number): Promise<ApiResponse<Car>> => {
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.changeCarStatus.format(carId),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Car> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const getCarRecentReservations = async (carId: string): Promise<ApiResponse<CarRecReserva[]>> => {
   try {
      const resp = await request({
         method: API_METHODS.GET,
         url: API_ROUTES.recentReservations.format(carId),
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<CarRecReserva[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};

export const carReservationByDate = async (date: { date: string; time: string }, carId: string): Promise<ApiResponse<Reservation[]>> => {
   const dateForRequest = encodeDate(date.date, date.time);
   try {
      const resp = await request({
         method: API_METHODS.POST,
         url: API_ROUTES.carReservavtionByDate.format(carId),
         data: { date: dateForRequest },
      });
      if (resp.status !== 200) {
         throw new Error("");
      }
      const data: ApiResponse<Reservation[]> = resp.data;
      return data;
   } catch (error) {
      throw error;
   }
};
