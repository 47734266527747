import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { COLORS } from "../../../../data/constants/Colors";

export const navItemStyle = makeStyles((theme: Theme) =>
  createStyles({
    selectedMenuItem: {
      height: 45,
      paddingLeft: 10,
      opacity: 1,
      backgroundColor: COLORS.menuActive,
      borderRadius: 6,
      fontWeight: "bold",
    },
    active: {
      opacity: 0.7,
      height: 45,
      paddingLeft: 10,
      // paddingRight: 10,
      "&:hover": {
        opacity: 1,
        backgroundColor: COLORS.menuActive,
        borderRadius: 6,
        // margin: 10,
      },
    },
    activeIcon: { minWidth: 25 },
    text: {
      color: COLORS.iconInactive,
      fontSize: 12,
    },
    listItem: {
      color: COLORS.iconInactive,
      fontSize: 20,
      padding: 0,
    },
  })
);
